import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loading$: Subject<boolean> = new Subject<boolean>();

  setLoader(vl: boolean): void {
    // https://stackoverflow.com/questions/48870648/angular-expressionchangedafterithasbeencheckederror-in-same-component
    setTimeout(() => this.loading$.next(vl));
  }
}
