import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { toLabel } from '@app/shared/utils/string-utils';
import { FlightRequestTripNode, LeadTripNode } from '@generated/graphql';
import { RdrDatePipe } from '@ui/pipes/rdr-date.pipe';

@Component({
  selector: 'trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RdrDatePipe],
})
export class TripInfoComponent implements OnChanges {
  @Input() trip: LeadTripNode | FlightRequestTripNode;
  tripInfoStr: string;

  constructor(private rdrDatePipe: RdrDatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trip?.currentValue) {
      const trip = changes.trip.currentValue as LeadTripNode | FlightRequestTripNode;
      this.tripInfoStr = `${trip.departureAirport?.locationCode || ''}-${
        trip.arrivalAirport?.locationCode || ''
      } | ${this.rdrDatePipe.transform(trip.departureAt) || ''} | Pax: ${
        trip.passengerCount || 0
      } | ${toLabel(trip.serviceClass || '')}`;
    }
  }
}
