import { Interaction } from '@app/generated/graphql';

export const isWholeCalls = ({ __typename }: Interaction) => __typename === 'WholeCalls';

export const isLeadMessageNode = ({ __typename }: Interaction) => __typename === 'LeadMessageNode';

export const isEmailMessageNode = ({ __typename }: Interaction) =>
  __typename === 'EmailMessageNode';

export const isLeadStatusInteraction = ({ __typename }: Interaction) =>
  __typename === 'LeadStatusInteraction';

export const isFlightRequestStatusInteraction = ({ __typename }: Interaction) =>
  __typename === 'FlightRequestStatusInteraction';

export const isOrderStatusInteraction = ({ __typename }: Interaction) =>
  __typename === 'OrderStatusInteraction';
