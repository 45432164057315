import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RdrDateRange } from '@app/formly/types/date-range/date-range.component';

@Component({
  selector: 'date-range-value-view',
  templateUrl: './date-range-value-view.component.html',
  styleUrls: ['./date-range-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeValueViewComponent {
  @Input() value: RdrDateRange;
  @Input() placeholder?: string;
}
