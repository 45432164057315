import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

export interface rdrToastParams {
  message: string;
  config?: Partial<IndividualConfig>;
}

@Injectable({
  providedIn: 'root',
})
export class RdrToastService {
  defaultConfig: Partial<IndividualConfig> = {
    disableTimeOut: true,
    tapToDismiss: false,
    closeButton: false,
  };

  constructor(private toastrService: ToastrService) {}

  info(params: rdrToastParams) {
    const config: Partial<IndividualConfig> = {};

    Object.assign(config, this.defaultConfig, params.config);
    return this.toastrService.info(params.message, undefined, config);
  }

  clear(toastId: number) {
    return this.toastrService.clear(toastId);
  }

  error(params: rdrToastParams) {
    const config: Partial<IndividualConfig> = {};

    Object.assign(config, this.defaultConfig, { closeButton: true }, params.config);

    this.toastrService.error(params.message, undefined, config);
  }
}
