<ng-container *ngIf="client$ | async as client; else loadingPageState">
  <inner-layout-v2>
    <div middle class="d-flex">
      <div class="d-flex flex-column flex-1 v2-mr-1 client-info-column">
        <client-details-section
          (formSubmit)="clientFormSubmit($event)"
          [client]="client"
          class="v2-mb-1"
        ></client-details-section>
        <balance-section [client]="client"></balance-section>
        <client-sales-agent-section
          class="v2-mt-1"
          [client]="client"
          (assignSalesAgent)="reassignAgent($event)"
        ></client-sales-agent-section>
      </div>

      <div class="d-flex flex-column flex-1 client-details">
        <passengers-section
          (downloadDocument)="downloadDocument($event)"
          (savePassenger)="savePassenger($event)"
          (deletePassenger)="deletePassenger($event)"
          [passengers]="client.passengers?.edges | extractNodes"
          class="v2-mb-1"
        ></passengers-section>

        <client-cards-section
          (createCard)="createCard($event)"
          (updateCard)="updateCard($event)"
          (deleteCard)="deleteCard($event)"
          [cards]="client.creditCards?.edges | extractNodes"
          class="v2-mb-1"
        ></client-cards-section>

        <client-trips-section
          *featureToggleOn="RdrFeature.trips"
          (clickOnTrip)="clickOnTrip($event)"
          (paginateTrips)="paginateTrips($event)"
          (createNewFlightRequest)="createNewFlightRequest($event)"
          [trips]="paginatedSortedTrips || []"
          [totalPaginatedSortedTrips]="totalPaginatedSortedTrips"
          [paginatedSortedTripsOffset]="paginatedSortedTripsOffset"
          class="v2-mb-1"
        >
        </client-trips-section>

        <client-requests-section
          *featureToggleOff="RdrFeature.trips"
          (clickOnFlightRequest)="clickOnFlightRequest($event)"
          (paginateFlightRequests)="paginateFlightRequests($event)"
          (createNewFlightRequest)="createNewFlightRequest($event)"
          [flightRequests]="paginatedSortedFlightRequests || []"
          [totalPaginatedSortedFlightRequests]="totalPaginatedSortedFlightRequests"
          [paginatedSortedFlightRequestsOffset]="paginatedSortedFlightRequestsOffset"
          class="v2-mb-1"
        ></client-requests-section>

        <client-orders-section
          *featureToggleOff="RdrFeature.trips"
          (clickOnOrder)="clickOnOrder($event)"
          (paginateOrders)="paginateOrders($event)"
          [orders]="paginatedSortedOrders || []"
          [totalPaginatedSortedOrders]="totalPaginatedSortedOrders"
          [paginatedSortedOrdersOffset]="paginatedSortedOrdersOffset"
        ></client-orders-section>
      </div>
    </div>
  </inner-layout-v2>

  <communication-container
    *ngIf="client.firstLeadId"
    [leadId]="client.firstLeadId"
  ></communication-container>
</ng-container>

<ng-template #loadingPageState>
  <client-details-page-loading-state></client-details-page-loading-state>
</ng-template>
