import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientNode, UserType } from '@app/generated/graphql';

@Component({
  selector: 'order-client-v2',
  templateUrl: './order-client-v2.component.html',
  styleUrls: ['./order-client-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderClientV2Component {
  @Input() client?: ClientNode;
  @Input() isRepeat: boolean | undefined;

  showClientPopup = false;
  readonly UserType = UserType;
}
