import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreditCardTypeEnum } from '@app/generated/graphql';

@Component({
  selector: 'credit-card-logo',
  templateUrl: './credit-card-logo.component.html',
  styleUrls: ['./credit-card-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardLogoComponent {
  CreditCardTypeEnum = CreditCardTypeEnum;

  @Input() creditCardType?: CreditCardTypeEnum;
}
