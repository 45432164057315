import { Directive, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[showBuildVersionOnHover]',
})
export class ShowBuildVersionOnHoverDirective {
  @HostListener('mouseenter') mouseenter() {
    void fetch(`/assets/commit-id/${environment.COMMIT_ID}`)
      .then((res) => {
        try {
          return res.json();
        } catch (err) {
          throw new Error();
        }
      })
      .then((res) => {
        if (res == environment.COMMIT_ID) {
          console.log('> up to date');
        } else {
          throw new Error();
        }
      })
      .catch(() => console.log('> new version is available'));
  }
}
