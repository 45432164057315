import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { DropdownItem } from '@ui/components/dropdown.model';
import { DropdownType } from '@ui/components/dropdown/dropdown.component';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'select-v2',
  templateUrl: './select-v2.component.html',
  styleUrls: ['./select-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectV2Component extends BasefieldV2Component implements OnInit {
  selectedItem: DropdownItem;
  dropdownType = DropdownType;

  items: DropdownItem[] = [];

  loading = false;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.setInitial();
  }

  setInitial() {
    if (this.to.initial) {
      this.selectedItem = this.to.initial;
    }
  }

  optionSelected(item: DropdownItem): void {
    this.selectedItem = item;

    if (this.to.emitFullObject) {
      this.formControl.setValue(item);
    } else {
      this.formControl.setValue(item?.value);
    }

    this.formControl.markAsDirty();
  }

  onCollapse(collapsed: boolean) {
    if (this.checkIsLoad(collapsed)) {
      this.loading = true;

      this.to.optionsService
        .pipe(
          map((items: DropdownItem[]) => {
            this.items = items;
            this.loading = false;

            this.cdRef.detectChanges();
          })
        )
        .pipe(UntilDestroyed(this))
        .subscribe();
    }
  }

  checkIsLoad(collapsed: boolean) {
    return !collapsed;
  }
}
