<div class="d-flex flex-column">
  <div class="d-flex align-items-center v2-mb-2" data-id="title">
    <typography-v2 class="v2-mr-1" look="text-semibold">Costs/Commissions</typography-v2>
    <button-icon-v2
      *ngIf="!readonly"
      (click)="$event.stopPropagation()"
      [modalTpl]="modalTemplate"
      [modalClass]="'wide'"
      showModalOnClick
      key="plus"
      size="sm"
      class="cursor-pointer add-cost"
      color="var(--color-black)"
      data-id="add-cost"
    ></button-icon-v2>
  </div>
  <div class="d-flex costs">
    <ng-container *ngIf="costs?.length; else emptyState">
      <order-booking-cost-v2
        *ngFor="let cost of costs"
        [order]="order"
        [orderId]="orderId"
        [bookingType]="bookingType"
        [cost]="cost.node"
        [readonly]="readonly"
        [withDirect]="withDirect"
        [supplierFromBooking]="supplierFromBooking"
        (costUpdated)="bookingCostUpserted.emit($event)"
        (costDeleted)="bookingCostDeleted.emit($event)"
        class="cost-card"
      ></order-booking-cost-v2>
    </ng-container>
  </div>
</div>
<ng-template #modalTemplate>
  <modal-v2 [header]="'New Cost'">
    <order-booking-cost-form
      [order]="order"
      [orderId]="orderId"
      [bookingType]="bookingType"
      [withDirect]="withDirect"
      (formSubmit)="bookingCostUpserted.emit($event.data)"
    ></order-booking-cost-form>
  </modal-v2>
</ng-template>
<ng-template #emptyState>
  <typography-v2 class="text-black-50">This booking has no costs yet</typography-v2>
</ng-template>
