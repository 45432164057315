<div class="modal-header">
  <typography look="H2">{{ modalHeader }}</typography>
  <rdr-svg-icon
    (click)="onCancel()"
    data-id="close"
    class="cross"
    key="plus"
    size="lg"
  ></rdr-svg-icon>
</div>
<div class="modal-body">
  <div *ngIf="formErrors?.length" class="mb-2">
    <alert type="danger" *ngFor="let error of formErrors"> {{ error }} </alert>
  </div>
  <div
    *ngIf="isFormFetching"
    class="position-absolute d-flex align-items-center justify-content-center loader-overlay"
  >
    <loading-spinner></loading-spinner>
  </div>

  <ng-template #formTemplate> </ng-template>
</div>
