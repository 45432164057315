import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';

@Pipe({
  name: 'filterByLabel',
})
export class FilterByLabelPipe implements PipeTransform {
  transform(
    itemsToFilter: DropdownItem[],
    searchValue: string | null,
    filterFunction?: (items: DropdownItem[], searchValue: string) => DropdownItem[],
    limit = 40
  ): DropdownItem[] {
    if (!searchValue) return itemsToFilter.slice(0, limit);

    const filterCallback =
      filterFunction ||
      ((items: DropdownItem[]) =>
        items.filter((item) =>
          item.label?.toLowerCase().includes(searchValue.trim().toLowerCase())
        ));

    return filterCallback(itemsToFilter, searchValue).slice(0, limit);
  }
}
