import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BasefieldComponent } from '../basefield/basefield.component';
import IMask from 'imask';

@Component({
  selector: 'formly-field-input',
  templateUrl: './formly-field-input.component.html',
  styleUrls: ['./formly-field-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInputComponent extends BasefieldComponent implements OnInit {
  imask: IMask.AnyMaskedOptions;
  masks: Record<string, RegExp> = {
    alphanumeric: /^[0-9a-zA-Z]+$/,
    email: /^\S*@?\S*$/,
  };

  ngOnInit() {
    if (this.field.templateOptions?.mask) {
      this.imask = {
        mask: this.masks[this.field.templateOptions?.mask],
      };
    }
  }
}
