import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LeadEdge } from '@generated/graphql';

@Component({
  selector: 'hot-leads-block',
  templateUrl: './hot-leads-block.component.html',
  styleUrls: ['./hot-leads-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotLeadsBlockComponent {
  @Input() leads?: LeadEdge[];
  @Output() rowClick: EventEmitter<string> = new EventEmitter();
}
