import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import IMask from 'imask';

@Component({
  selector: 'numeric-value-view',
  templateUrl: './numeric-value-view.component.html',
  styleUrls: ['./numeric-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericValueViewComponent {
  @Input() mask: IMask.MaskedNumberOptions;
  @Input() value: number;
  @Input() placeholder?: string;
}
