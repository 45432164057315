import { Directive, Input } from '@angular/core';
import { RdrFeature } from '@ui/services/feature-toggle.service';
import { FeatureToggleAbstractDirective } from '@ui/directives/feature-toggle/feature-toggle-abstract.directive';

@Directive({
  selector: '[featureToggleOn]',
})
export class FeatureToggleOnDirective extends FeatureToggleAbstractDirective {
  @Input('featureToggleOn') feature: RdrFeature;
}
