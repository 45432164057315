import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { RadarTableCellComponent } from '../radar-table-cell/radar-table-cell.component';
import { RadarTableSort } from '../radar-table.types';

@Component({
  selector: 'radar-table-head-cell',
  templateUrl: './radar-table-head-cell.component.html',
  styleUrls: ['./radar-table-head-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableHeadCellComponent extends RadarTableCellComponent {
  @Input() sort: RadarTableSort;

  @Output() sorting: EventEmitter<RadarTableSort> = new EventEmitter();

  @HostBinding('style.width') get width() {
    return this.column.width && `${this.column.width}px`;
  }

  onSort(): void {
    const sort =
      this.column.sort?.asc === this.sort ? this.column.sort?.desc : this.column.sort?.asc;

    sort && this.sorting.emit(sort);
  }
}
