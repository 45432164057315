<div class="d-flex flex-column">
  <div class="d-flex align-items-center">
    <typography-v2 look="text-semibold" dataId="airport"
      >{{ booking.bookingHotel?.airport?.cityName }}</typography-v2
    >

    <span class="v2-mx-2">&middot;</span>

    <typography-v2 dataId="check-in"
      >{{ booking.bookingHotel?.checkInDate | rdrDate }}</typography-v2
    >
  </div>

  <div class="v2-mt-2 v2-px-3 v2-py-2 d-flex align-items-center block">
    <typography-v2 dataId="hotel-name">{{ booking.bookingHotel?.name }}</typography-v2>

    <ng-container *ngIf="booking.bookingHotel?.city">
      <span class="v2-mx-2">&middot;</span>

      <typography-v2 dataId="hotel-city">{{ booking.bookingHotel?.city }}</typography-v2>
    </ng-container>

    <span class="v2-mx-2">&middot;</span>

    <typography-v2
      ><span data-id="check-in">{{ booking.bookingHotel?.checkInDate | rdrDate }}</span> –
      <span data-id="check-out"
        >{{ booking.bookingHotel?.checkoutDate | rdrDate }}</span
      ></typography-v2
    >

    <div class="m-auto v2-mr-0 d-flex align-items-center">
      <rdr-svg-icon key="user-v-2" color="var(--color-jazlyn-700)"></rdr-svg-icon>
      <typography-v2 class="v2-ml-1" dataId="guests"
        >{{ booking.bookingHotel?.guestCount }}</typography-v2
      >
    </div>
  </div>
</div>
