import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientNode } from '@generated/graphql';

@Component({
  selector: 'search-client-row',
  templateUrl: './search-client-row.component.html',
  styleUrls: ['./search-client-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchClientRowComponent {
  @Input() item: ClientNode;
}
