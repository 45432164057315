import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';

@Component({
  selector: 'modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalV2Component {
  @Input() header = '';

  constructor(private modalV2Service: ModalV2Service) {}

  hideModal(): void {
    this.modalV2Service.hideFirst();
  }
}
