<editable [attr.data-id]="field.dataId">
  <ng-template viewMode>
    <div class="view-mode">
      <typography *ngIf="!formControl.value" look="inputPlaceholder"
        >{{ field.templateOptions?.placeholder }}</typography
      >

      <typography *ngIf="formControl.value"> {{ formControl.value }} </typography>
      <rdr-svg-icon
        class="pencil-icon me-1 ms-auto"
        key="pencil-create"
        color="var(--in-place-icon)"
      ></rdr-svg-icon>
    </div>
  </ng-template>
  <ng-template editMode>
    <input
      type="tel"
      class="formly-input"
      editableOnEnter
      editableOnEscape
      radarOnEnter
      (radarOnEscape)="cancel()"
      radarEditableFocus
      [formControl]="innerModel"
      (blur)="onBlur()"
      [formlyAttributes]="field"
      ccExp
    />
  </ng-template>
</editable>
