<div class="d-flex align-items-center position-relative">
  <ng-container *hideForRole="UserType.TicketingAgent">
    <rdr-svg-icon
      *ngIf="isRepeat"
      tooltip="Repeat Client"
      placement="bottom"
      key="crown"
      color="var(--color-yellow-2)"
      class="repeat v2-mr-1"
      data-id="repeat-client"
    ></rdr-svg-icon>
  </ng-container>

  <div
    (mouseleave)="showClientPopup = false"
    (mouseenter)="showClientPopup = true"
    class="position-relative"
  >
    <typography-v2 class="v2-pt-3 v2-pb-5 nowrap" data-id="client"
      >{{ client | userName }}</typography-v2
    >
    <ng-container *ngIf="showClientPopup">
      <context-menu-v2
        (clickOutside)="showClientPopup = false"
        class="position-absolute"
        data-id="client-context-menu"
      >
        <client-card-preview
          *ngIf="client"
          [client]="client"
          [showSendNoAnswerEmail]="false"
        ></client-card-preview>
      </context-menu-v2>
    </ng-container>
  </div>
</div>
