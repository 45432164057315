<radio-buttons-v2
  (selectItem)="tripType = $event"
  [value]="tripType"
  [key]="'tripType'"
  [items]="tripTypes"
  [horizontal]="true"
  [ngClass]="{ 'd-none': formStep === CreateTaskSteps.Second }"
></radio-buttons-v2>

<div [ngClass]="{ 'd-none': formStep === CreateTaskSteps.Second }" class="v2-mt-4">
  <oneway-form
    (formSubmit)="submitFirstStep($event)"
    [data]="firstStepData"
    [ngClass]="{ 'd-none': tripType !== TripType.Oneway }"
  ></oneway-form>
  <round-form
    (formSubmit)="submitFirstStep($event)"
    [data]="firstStepData"
    [ngClass]="{ 'd-none': tripType !== TripType.Round }"
  ></round-form>
  <multi-form
    (formSubmit)="submitFirstStep($event)"
    [data]="firstStepData"
    [ngClass]="{ 'd-none': tripType !== TripType.Multi }"
  ></multi-form>
</div>

<second-step-form
  (formSubmit)="submitSecondStep($event)"
  (goBack)="formStep = CreateTaskSteps.First"
  [data]="secondStepData"
  [ngClass]="{ 'd-none': formStep === CreateTaskSteps.First }"
></second-step-form>
