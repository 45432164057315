import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FlightRequestReasonType, LeadStatusReasonType } from '@app/generated/graphql';

@Component({
  selector: 'reason-cell',
  templateUrl: './reason-cell.component.html',
  styleUrls: ['./reason-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReasonCellComponent {
  @Input() data: LeadStatusReasonType | FlightRequestReasonType;
}
