import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'user-picture-v2',
  templateUrl: './user-picture-v2.component.html',
  styleUrls: ['./user-picture-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPictureV2Component {
  @Input() picture? = '';
  @Input() label? = '';

  @Input() size: UserPictureSize = 'normal';
}

export type UserPictureSize = 'big' | 'normal' | 'small' | 'semi';
