<card>
  <copy-to-clipboard-v2 *ngIf="creditCard?.holder" [value]="creditCard?.holder">
    <typography-v2 data-id="client-name">{{ creditCard?.holder }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2
    *ngIf="creditCard?.number"
    class="v2-mt-1"
    [value]="creditCard?.number?.split(' ')?.join('') || ''"
  >
    <typography-v2 data-id="number"
      >&middot;&middot;&middot;&middot;&nbsp;{{ creditCard?.number?.slice(-4) }}</typography-v2
    >
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2
    *ngIf="creditCard?.expiration"
    class="v2-mt-1"
    [value]="creditCard?.expiration"
  >
    <typography-v2 data-id="expiration">{{ creditCard?.expiration || 'mm / yy' }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2 *ngIf="creditCard?.cvv2" class="v2-mt-1" [value]="creditCard?.cvv2">
    <typography-v2 data-id="cvv">{{ creditCard?.cvv2 }}</typography-v2>
  </copy-to-clipboard-v2>

  <hr
    *ngIf="creditCard?.billingAddress1 || creditCard?.billingAddress2 || creditCard?.city || creditCard?.state || creditCard?.country?.name || creditCard?.zipCode"
    class="hr v2-my-2"
  />
  <copy-to-clipboard-v2 *ngIf="creditCard?.billingAddress1" [value]="creditCard?.billingAddress1">
    <typography-v2 data-id="billingAddress1">{{ creditCard?.billingAddress1 }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2
    *ngIf="creditCard?.billingAddress2"
    class="v2-mt-1"
    [value]="creditCard?.billingAddress2"
  >
    <typography-v2 data-id="billingAddress2">{{ creditCard?.billingAddress2 }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2 *ngIf="creditCard?.city" class="v2-mt-1" [value]="creditCard?.city">
    <typography-v2 data-id="city">{{ creditCard?.city }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2 *ngIf="creditCard?.state" class="v2-mt-1" [value]="creditCard?.state">
    <typography-v2 data-id="state">{{ creditCard?.state }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2
    *ngIf="creditCard?.country?.name"
    class="v2-mt-1"
    [value]="creditCard?.country?.name"
  >
    <typography-v2 data-id="country">{{ creditCard?.country?.name }}</typography-v2>
  </copy-to-clipboard-v2>
  <copy-to-clipboard-v2 *ngIf="creditCard?.zipCode" class="v2-mt-1" [value]="creditCard?.zipCode">
    <typography-v2 data-id="zipCode">{{ creditCard?.zipCode }}</typography-v2>
  </copy-to-clipboard-v2>
</card>
