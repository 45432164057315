<div class="d-flex flex-column">
  <div class="d-flex justify-content-between v2-mb-1">
    <typography-v2 *ngIf="expanded" class="text-jazlyn-700" look="small">
      from: {{ interaction.messageFrom }} <br />
      to: {{ interaction.messageTo }}
    </typography-v2>

    <typography-v2 *ngIf="!expanded" class="text-jazlyn-700" look="small" dataId="full-name"
      >{{ interaction.clientName || interaction.agent?.fullName }}</typography-v2
    >

    <typography-v2 class="text-jazlyn-700" look="small" dataId="created-at"
      >{{ interaction.createdAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <div *ngIf="!expanded" class="d-flex">
    <typography-v2 class="text-truncate w-580" dataId="body">{{ interaction.body }}</typography-v2>

    <a class="view text-purple v2-ml-1">View</a>
  </div>

  <ng-container *ngIf="expanded">
    <typography-v2 class="content text-truncate w-580" dataId="body"
      >{{ interaction.body }}</typography-v2
    >

    <div class="v2-mt-2">
      <a (click)="$event.stopPropagation(); expanded = false" class="text-purple">Hide</a>
    </div>
  </ng-container>

  <div *ngIf="interaction.hasRecording" class="d-flex align-items-center v2-mt-1">
    <button-icon-v2
      *ngIf="playing"
      (click)="pause()"
      color="var(--color-jazlyn-700)"
      key="pause"
      type="just-icon"
      size="md"
      data-id="pause"
    ></button-icon-v2>

    <button-icon-v2
      *ngIf="!playing"
      (click)="play()"
      [disabled]="loading"
      key="play"
      type="just-icon"
      size="md"
      data-id="play"
    ></button-icon-v2>

    <lead-call-track
      #leadCallTrack
      (onAudioEnded)="onAudioEnded()"
      (setPlaying)="setPlaying($event)"
      [audioLink]="audioLinks[currentLinkIndex]"
      class="v2-mx-2"
    ></lead-call-track>
  </div>
</div>
