import { Pipe, PipeTransform } from '@angular/core';
import { BookingNode, CostNode } from '@generated/graphql';

@Pipe({
  name: 'costSupplier',
})
export class CostSupplierPipe implements PipeTransform {
  transform(cost: CostNode, booking?: BookingNode): string | undefined {
    return (
      cost.milesSupplier?.name ||
      cost.revenueSupplier?.name ||
      cost.supplierOrBookingSupplierName ||
      booking?.bookingRevenueSupplierName
    );
  }
}
