import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GLOBAL_SEARCH_QUERY_LIMIT } from '@ui/global-search/constants';

@Component({
  selector: 'global-search-block-wrapper',
  templateUrl: './global-search-block-wrapper.component.html',
  styleUrls: ['./global-search-block-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchBlockWrapperComponent {
  queryLimit = GLOBAL_SEARCH_QUERY_LIMIT;

  @Input() caption: string;
  @Input() totalCount: number;
  @Input() itemsCount?: number;
}
