import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'datetime-value-view',
  templateUrl: './datetime-value-view.component.html',
  styleUrls: ['./datetime-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeValueViewComponent {
  @Input() value: Date | string | number | undefined;
  @Input() placeholder?: string;
}
