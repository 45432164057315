import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BasefieldComponent } from '../basefield/basefield.component';

@Component({
  selector: 'cc-number',
  templateUrl: './cc-number.component.html',
  styleUrls: ['./cc-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCcNumberComponent extends BasefieldComponent implements OnInit {
  innerModel = new UntypedFormControl('');

  ngOnInit(): void {
    if (this.formControl.value) {
      this.innerModel.setValue(this.formControl.value);
    }
  }

  onBlur() {
    this.formControl.setValue(this.innerModel.value);
  }
}
