import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

export enum ButtonSize {
  small = 'small',
  big = 'big',
}

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
}

export type ButtonLook =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'outline-danger'
  | 'dangerously'
  | 'outline-secondary'
  | 'dark-secondary'
  | 'transparent'
  | 'cancellation';

@Component({
  selector: 'btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnComponent implements OnInit {
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() type = 'button';
  @Input() size: 'small' | 'big';
  @Input() disabledBtn: boolean | undefined = false;
  @Input() look: ButtonLook;
  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  btnSize = ButtonSize;
  iconColor: string;

  ngOnInit(): void {
    this.iconColor = COLORS_BY_TYPE[this.look] || '';
  }

  handleClick(event: Event) {
    this.btnClick.emit(event);
    event.stopPropagation();
  }
}

const COLORS_BY_TYPE: { [key: string]: string } = {
  secondary: 'var(--main-blue)',
  primary: 'var(--secondary-btn-active)',
  dangerously: 'var(--red)',
};
