import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnInit, AfterViewInit {
  mousedownEvent: MouseEvent | null;

  @Input() min: number;
  @Input() max: number;

  @Input() from: number;
  @Input() to: number;

  @Input() step: number;
  @Input() type: 'currency' | 'minutes' | 'time';

  @Output() setRange = new EventEmitter();

  @ViewChild('fromTemplate') fromTemplate: ElementRef;
  @ViewChild('lineTemplate') lineTemplate: ElementRef;
  @ViewChild('valueTemplate') valueTemplate: ElementRef;
  @ViewChild('toTemplate') toTemplate: ElementRef;

  @HostBinding('class.cursor-pointer') get style() {
    return !!this.mousedownEvent;
  }

  @HostListener('mousedown', ['$event']) mousedown(event: MouseEvent) {
    this.mousedownEvent = event;
  }

  @HostListener('window:mouseup') mouseup() {
    if (this.mousedownEvent) {
      this.mousedownEvent = null;
      this.setRange.emit([this.from, this.to]);
    }
  }

  @HostListener('window:mousemove', ['$event']) mousemove(event: MouseEvent) {
    if (this.mousedownEvent) {
      const isFrom = (this.mousedownEvent.target as HTMLElement).classList.contains('from');
      const isTo = (this.mousedownEvent.target as HTMLElement).classList.contains('to');

      const { left } = this.lineTemplate.nativeElement.getBoundingClientRect();
      let value = this.offsetXToValue(event.pageX - left);

      if (this.step) {
        value = Math.ceil(value / this.step) * this.step;
      }

      if (isFrom) {
        if (value >= this.min && value <= this.to) {
          this.from = value;
        }
      }

      if (isTo) {
        if (value <= this.max && value >= this.from) {
          this.to = value;
        }
      }

      this.updatePosition();
    }
  }

  ngOnInit() {
    if (!this.from) {
      this.from = this.min;
    }
    if (!this.to) {
      this.to = this.max;
    }
  }

  ngAfterViewInit() {
    this.updatePosition();
  }

  updatePosition() {
    this.fromTemplate.nativeElement.style.left = this.valueToOffsetX(this.from);
    this.toTemplate.nativeElement.style.left = this.valueToOffsetX(this.to);

    this.valueTemplate.nativeElement.style.left = this.valueToOffsetX(this.from);

    this.valueTemplate.nativeElement.style.right = this.valueToOffsetX(this.to);

    const width = `calc(${this.valueToOffsetX(this.to)} - ${this.valueToOffsetX(this.from)})`;

    this.valueTemplate.nativeElement.style.width = width;
  }

  offsetXToValue(left: number) {
    const width = (this.lineTemplate.nativeElement as HTMLElement).clientWidth;

    return left / (width / (this.max - this.min)) + this.min;
  }

  valueToOffsetX(value: number): string {
    const width = (this.lineTemplate.nativeElement as HTMLElement).clientWidth;

    return `${(value - this.min) * (width / (this.max - this.min))}px`;
  }
}
