import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'skeleton-loader-small',
  templateUrl: './skeleton-loader-small.component.html',
  styleUrls: ['./skeleton-loader-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderSmallComponent {
  @HostBinding('style.width.px') @Input() width = 60;
  @HostBinding('style.height.px') @Input() height = 28;
}
