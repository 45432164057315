import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef,
  AfterViewInit,
  EventEmitter,
  Type,
  ComponentRef,
} from '@angular/core';
import { ModalFormService, ModalFormState } from '@app/shared/services/modal-form.service';
import { GraphQLError } from 'graphql';
import { Observable } from 'rxjs';
import { GenericNode, GraphqlID } from '@app/shared/types';

@Component({
  selector: 'modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFormComponent implements AfterViewInit {
  formSubmit: EventEmitter<any> = new EventEmitter();

  componentRef: ComponentRef<any>;
  modalHeader: string;
  formComponent: Type<any>;
  formErrors: string[];
  entityId: GraphqlID;
  formData: ModalFormState['formData'];
  initials: ModalFormState['initials'];
  submitHandler: any;
  readonly: boolean;
  formDataSubject$: Observable<GenericNode>;
  isFormFetching: boolean;

  @ViewChild('formTemplate', { read: ViewContainerRef }) formTemplate: ViewContainerRef;

  constructor(private cdRef: ChangeDetectorRef, private modalFormService: ModalFormService) {}

  ngAfterViewInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.formTemplate.clear();

    this.componentRef = this.formTemplate.createComponent<any>(this.formComponent);

    this.componentRef.instance.formSubmit = this.formSubmit;
    this.componentRef.instance.entityId = this.entityId;
    this.componentRef.instance.data = this.formData;
    this.componentRef.instance.initials = this.initials;
    this.componentRef.instance.readonly = this.readonly;
    this.componentRef.instance.formDataSubject$ = this.formDataSubject$;
    this.componentRef.instance.setErrors = this.setErrors.bind(this);
    this.componentRef.instance.onCancel = this.onCancel.bind(this);

    this.cdRef.markForCheck();
  }

  public setErrors(errs: GraphQLError[]) {
    this.formErrors = errs.map((err: GraphQLError) => err.message);
    this.cdRef.markForCheck();
  }

  public setFetching(fetching: boolean) {
    this.isFormFetching = fetching;
    this.componentRef.instance.setFetching(fetching);
    this.cdRef.detectChanges();
  }

  onCancel(): void {
    this.modalFormService.hideModal();
  }
}
