import { Pipe, PipeTransform } from '@angular/core';
import { toLabel } from '@shared/utils/string-utils';

@Pipe({
  name: 'label',
})
export class LabelPipe implements PipeTransform {
  transform(value?: string, defaultValue?: string): string {
    if (!value) {
      return defaultValue || '';
    }

    return toLabel(value);
  }
}
