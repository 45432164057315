<div class="d-flex align-items-center">
  <ng-template #fieldComponent></ng-template>

  <label class="label ms-3" [attr.for]="id" [attr.data-id]="id + '-label'">
    <typography look="formLabel">{{ to.label }}</typography>
    <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
  </label>

  <div *ngIf="showError" class="col-sm-3 invalid-feedback d-block">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
</div>
