import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AirportNode } from '@generated/graphql';

@Component({
  selector: 'airport-name-code-cell',
  templateUrl: './airport-name-code.component.html',
  styleUrls: ['./airport-name-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirportNameCodeComponent {
  @Input() data: AirportNode;
}
