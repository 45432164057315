import { Injectable } from '@angular/core';
import { AvailabilityPingGQL } from '@app/generated/graphql';
import {
  BehaviorSubject,
  debounce,
  from,
  fromEvent,
  interval,
  mergeMap,
  Subscription,
  timer,
} from 'rxjs';
import { TwilioService } from '../twilio/twilio.service';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  availableStatus$ = new BehaviorSubject(AvailableStatus.Unavailable);

  pingInterval$: Subscription | null;
  activity$: Subscription | null;

  activityTimeout: number;

  constructor(
    private availabilityPingGQL: AvailabilityPingGQL,
    private twilioService: TwilioService
  ) {}

  addPageListeners() {
    this.activity$ = from(['mousemove', 'click', 'keydown', 'wheel'])
      .pipe(
        mergeMap((event) => fromEvent(window, event)),
        debounce(() => timer(500))
      )
      .subscribe(() => this.handleActivity());
  }

  removePageListeners() {
    this.activity$?.unsubscribe();
    this.activity$ = null;
  }

  handleActivity() {
    if (!this.pingInterval$) {
      this.pingInterval$ = interval(pingIntervalTime).subscribe(() => this.availabilityPing());
    }

    clearTimeout(this.activityTimeout);

    this.activityTimeout = window.setTimeout(() => {
      this.pingInterval$?.unsubscribe();
      this.pingInterval$ = null;
    }, activityTimeoutTime);
  }

  startPolling() {
    this.pingInterval$?.unsubscribe();
    this.pingInterval$ = interval(pingIntervalTime).subscribe(() => this.availabilityPing());

    this.availabilityPing();

    this.addPageListeners();
  }

  setStatusReconnecting() {
    this.pingInterval$?.unsubscribe();
    this.pingInterval$ = null;
    this.availableStatus$.next(AvailableStatus.Reconnecting);
  }

  stopPolling() {
    this.removePageListeners();

    this.pingInterval$?.unsubscribe();
    this.pingInterval$ = null;
    this.availableStatus$.next(AvailableStatus.Unavailable);
  }

  availabilityPing() {
    return this.availabilityPingGQL.mutate().subscribe({
      next: () => this.availableStatus$.next(AvailableStatus.Available),
      error: () => this.availableStatus$.next(AvailableStatus.Reconnecting),
    });
  }
}

export const pingIntervalTime = 10 * 1000;
export const activityTimeoutTime = 5 * 60 * 1000;

export enum AvailableStatus {
  Available = 'available',
  Unavailable = 'unavailable',
  Reconnecting = 'reconnecting',
}
