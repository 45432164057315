<typography-v2 *ngIf="to.label" class="v2-mb-1">{{ to.label }}</typography-v2>

<div
  *ngFor="let value of to.values.slice(0, showedItemsCount); let last = last"
  [ngClass]="{ 'v2-mb-2': !last }"
  class="d-flex align-items-center"
>
  <checkbox-v2
    (check)="change(value)"
    [label]="value.description"
    [checked]="getChecked(value)"
    [id]="value.key"
    [attr.data-id]="value.key"
  ></checkbox-v2>
</div>

<typography-v2
  *ngIf="to.values?.length > showedItemsCount"
  (click)="showedItemsCount = to.values?.length"
  look="small"
  class="text-jazlyn-700 v2-mt-2 cursor-pointer"
  data-id="show-more"
  >Show more</typography-v2
>
