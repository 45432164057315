<table *ngIf="rows?.length; else emptyState" class="rdr-table {{ skin }}">
  <thead>
    <tr>
      <th
        [style.width.px]="column.width"
        [style.max-width.px]="column.maxWidth"
        [style.vertical-align]="'top'"
        [class.stickHeaders]="stickHeaders"
        *ngFor="let column of columns;"
      >
        <radar-table-head-cell
          (sorting)="onSort($event)"
          [column]="column"
          [sort]="sort"
        ></radar-table-head-cell>
      </th>
    </tr>
  </thead>
  <div class="tbody">
    <ng-container *ngIf="rowLink; else defaultRow">
      <a
        *ngFor="let row of rows"
        [routerLink]="rowLink | getRowLink: row"
        class="rdr-row link"
        hoverClass
      >
        <ng-container
          [ngTemplateOutlet]="rowTemplate"
          [ngTemplateOutletContext]="{ row:row }"
        ></ng-container>
      </a>
    </ng-container>
    <ng-template #defaultRow>
      <ng-container *ngFor="let row of rows">
        <div
          (click)="onRowClick($event, row)"
          (mouseenter)="onHover($event, row)"
          (mouseleave)="hoveredRow = undefined"
          [class.hovered]="row === hoveredRow"
          class="rdr-row position-relative"
          hoverClass
        >
          <ng-container
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ row:row }"
          ></ng-container>
        </div>
        <ng-container
          [ngTemplateOutlet]="rowDetailsTemplate || defaultRowDetails"
          [ngTemplateOutletContext]="{ row:row }"
        >
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</table>

<ng-template #rowTemplate let-row="row">
  <radar-table-cell
    *ngFor="let column of columns; let first = first; let last = last"
    [ngClass]="{
      'v2-ml-4 v2-pr-2': !(last || first)
    }"
    [row]="row"
    [column]="column"
    [selected]="isSelectedRow(row)"
    (selectRow)="onSelectRow(row)"
  ></radar-table-cell>

  <ng-container *ngIf="!readonly">
    <td class="context-menu-cell" *ngIf="hoveredRow?.id === row?.id">
      <ng-container
        *ngTemplateOutlet="rowHoverContextMenuTpl; context: { row: row }"
      ></ng-container>
    </td>
  </ng-container>
</ng-template>

<ng-template #defaultRowDetails></ng-template>

<ng-template #emptyState>
  <block-empty-msg>No data</block-empty-msg>
</ng-template>
