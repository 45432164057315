<ng-container *ngIf="inPlace; else default">
  <div class="d-flex align-items-center">
    <button-icon-v2
      (click)="setActiveTab('notes')"
      [pressed]="activeTab === 'notes'"
      color="var(--color-black)"
      key="file-v-2"
      data-id="notes"
    ></button-icon-v2>

    <button-icon-v2
      (click)="setActiveTab('interactions')"
      [pressed]="activeTab === 'interactions'"
      color="var(--color-black)"
      key="overview"
      data-id="interactions"
    ></button-icon-v2>
  </div>

  <context-menu-v2
    *ngIf="activeTab"
    (clickOutside)="clickOutside($event)"
    [ngClass]="{ 'in-place-menu': inPlace }"
    class="v2-mt-1 menu"
  >
    <div
      [ngClass]="{ 'interactions v2-my-4': activeTab === 'interactions' }"
      class="content-container"
    >
      <communication-container-notes
        *ngIf="activeTab === 'notes'"
        [leadId]="leadId"
      ></communication-container-notes>

      <interactions *ngIf="activeTab === 'interactions'" [leadId]="leadId"></interactions>
    </div>
  </context-menu-v2>
</ng-container>

<ng-template #default>
  <ng-container *ngIf="activeTab; else controls">
    <context-menu-v2 class="back">
      <button-icon-v2 (click)="setActiveTab(null)" key="arrow-right-v-2"></button-icon-v2>
    </context-menu-v2>

    <context-menu-v2 class="v2-mt-1 menu">
      <div
        [ngClass]="{ 'interactions v2-py-4': activeTab === 'interactions' }"
        class="content-container"
      >
        <communication-container-notes
          *ngIf="activeTab === 'notes'"
          [leadId]="leadId"
        ></communication-container-notes>

        <interactions *ngIf="activeTab === 'interactions'" [leadId]="leadId"></interactions>
      </div>
    </context-menu-v2>
  </ng-container>

  <ng-template #controls>
    <context-menu-v2>
      <div class="d-flex flex-column">
        <button-icon-v2 (click)="setActiveTab('notes')" key="file-v-2"></button-icon-v2>

        <button-icon-v2
          (click)="setActiveTab('interactions')"
          [pressed]="activeTab === 'interactions'"
          color="var(--color-black)"
          key="overview"
          data-id="interactions"
        ></button-icon-v2>
      </div>
    </context-menu-v2>
  </ng-template>
</ng-template>
