<div *ngIf="changeRequestFlightItineraries" class="d-flex align-items-center text-black-50 v2-mb-3">
  <typography-v2
    *ngFor="let tab of tabs; let last = last"
    (click)="setActiveTab(tab)"
    [ngClass]="{ 'text-black': activeTab === tab, 'v2-mr-4': !last }"
    class="cursor-pointer"
    dataId="tab"
    >{{ tab | label }}</typography-v2
  >

  <button-v2
    *ngIf="visibleCancellation && !isCancellationItineraries && !readonly"
    (click)="cancelAllSegments.emit()"
    data-id="cancel-all-button"
    type="destructive"
    class="ms-auto"
    >Cancel All</button-v2
  >
</div>

<typography-v2
  data-id="all-segments-removed"
  *ngIf="isCancellationItineraries && activeTab === ItineraryTabsEnum.New"
  class="text-black-50"
  >All segments are cancelled</typography-v2
>

<div *ngIf="flightItineraries" data-id="itinerary-block">
  <div *ngFor="let group of flightItineraries; let firstGroup = first" data-id="group">
    <div [ngClass]="{ 'v2-mt-2': !firstGroup }" data-id="group-head" class="v2-mb-2">
      <typography-v2 look="text-bold" dataId="itinerary-departure-airport"
        >{{ group.departureAirport?.cityName }}</typography-v2
      >
      &rarr;
      <typography-v2 look="text-bold" dataId="itinerary-arrival-airport"
        >{{ group.arrivalAirport?.cityName }}</typography-v2
      >
      <span class="v2-mx-3">&middot;</span>
      <typography-v2 dataId="itinerary-departure-at"
        >{{ group.departureAt | rdrDate: 'short_date' }}</typography-v2
      >
      <span class="v2-mx-3">&middot;</span>
      <typography-v2
        >Travel time
        <span data-id="group-total-time">{{ group.travelTime | travelTimeFormat }}</span>
      </typography-v2>
    </div>

    <div
      *ngFor="let segment of group.segments; let first = first; let last = last"
      [ngClass]="{ 'first-itinerary': first, 'last-itinerary': last, 'text-jazlyn-700': segment.flightSegment?.isExtraLeg }"
      class="v2-p-1 itinerary"
    >
      <div
        [ngClass]="{
          'new': segment.new && activeTab === ItineraryTabsEnum.Mixed,
          'deleted': segment.originalDeleted && activeTab === ItineraryTabsEnum.Mixed,
          'is-deleted': segment.flightSegment.isDeleted
        }"
        data-id="itinerary-row"
        class="d-flex align-items-center v2-py-1 v2-px-2 itinerary-row"
      >
        <div class="w-32">
          <img
            [src]="segment.flightSegment.airline?.logoUrl"
            [ngClass]="{ 'extra-logo': segment.flightSegment?.isExtraLeg }"
            width="32"
            height="32"
          />
        </div>

        <div class="v2-ml-3 w-64 text-truncate">
          <typography-v2
            ><span data-id="airline-code">{{ segment.flightSegment.airline?.code }}</span
            >&nbsp;<span data-id="flight-number">{{ segment.flightSegment.flightNumber }}</span>
          </typography-v2>
        </div>

        <div class="v2-ml-3 w-280 text-truncate">
          <typography-v2
            ><span data-id="departure-airport"
              >{{ segment.flightSegment.departureAirport?.cityName }}</span
            >
            (<span data-id="departure-airport-code"
              >{{ segment.flightSegment.departureAirport?.locationCode }}</span
            >) &rarr;
            <span data-id="arrival-airport"
              >{{ segment.flightSegment.arrivalAirport?.cityName }}</span
            >
            (<span data-id="arrival-airport-code"
              >{{ segment.flightSegment.arrivalAirport?.locationCode }}</span
            >)</typography-v2
          >
        </div>

        <div class="v2-ml-3 w-96 text-truncate">
          <typography-v2 dataId="departure-at-date"
            >{{ segment.flightSegment.departureAtAirportTimeZone | rdrDate: { format: 'short_date',
            withoutTimezones: true } }}</typography-v2
          >
        </div>

        <div class="v2-ml-3 w-168 text-truncate">
          <typography-v2>
            <span data-id="departure-at"
              >{{ segment.flightSegment.departureAtAirportTimeZone | rdrDate: { format: 'shortTime',
              withoutTimezones: true } }}</span
            >
            &rarr;
            <span data-id="arrival-at"
              >{{ segment.flightSegment.arrivalAtAirportTimeZone | rdrDate: { format: 'shortTime',
              withoutTimezones: true } }}</span
            ><span
              *ngIf="segment.daysShift | getDaysShift as daysShift"
              [ngClass]="{ 'text-jazlyn-700': segment.flightSegment?.isExtraLeg }"
              class="text-red"
              data-id="shift"
            >
              {{ daysShift }}</span
            ></typography-v2
          >
        </div>

        <div class="v2-ml-3 w-64 text-truncate">
          <typography-v2 dataId="travel-time"
            >{{ segment.travelTime | travelTimeFormat }}</typography-v2
          >
        </div>

        <div class="v2-ml-3 w-16 text-truncate">
          <typography-v2 dataId="airline-service-class"
            >{{ segment.flightSegment.airlineServiceClass }}</typography-v2
          >
        </div>

        <div class="v2-ml-3 w-124 text-truncate">
          <typography-v2 dataId="service-class"
            >{{ segment.flightSegment.serviceClass | label }}</typography-v2
          >
        </div>

        <div *ngIf="segment.flightSegment.aircraftName" class="v2-ml-3" data-id="aircraft-name">
          <rdr-svg-icon
            [tooltip]="segment.flightSegment.aircraftName"
            [color]="segment.flightSegment?.isExtraLeg ? 'var(--color-jazlyn-600)' : 'var(--color-jazlyn-700)'"
            container="body"
            key="airplane"
            size="lg"
          ></rdr-svg-icon>
        </div>

        <ng-container *featureToggleOn="RdrFeature.extra_leg">
          <ng-container *ngIf="!readonlyExtra && !booking?.readOnly">
            <div
              *ngIf="!(booking | isAfterExtraLegSegment: segment)"
              class="v2-ml-3"
              tooltip="Bonus Flight"
              container="body"
            >
              <button-icon-v2
                (click)="setExtra(segment.flightSegment?.isExtraLeg ? false : true, segment.flightSegment?.id)"
                [key]="segment.flightSegment?.isExtraLeg ? 'bonus-flight-red' : 'bonus-flight'"
              ></button-icon-v2>
            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="showSeatsSelection" class="ms-auto">
          <ng-container *ngIf="segment | seatsFromSegment as seats">
            <div
              class="d-flex align-items-center justify-content-center"
              [tooltip]="seats.length ? tooltipTemplate : undefined"
              container="body"
            >
              <button-v2
                *ngIf="!seats.length && !readonly; else seatsTemplate"
                (click)="(activeTab === ItineraryTabsEnum.New && !readonly) && selectSeats.emit(segment.flightSegment)"
                [disabled]="(activeTab !== ItineraryTabsEnum.New) || readonly"
                type="secondary"
                data-id="add-seats"
              >
                <typography-v2 look="caption-bold">Add seats</typography-v2>
              </button-v2>
            </div>

            <ng-template #tooltipTemplate>
              <div class="d-flex flex-column">
                <typography-v2
                  class="w-100 d-flex justify-content-start"
                  look="small"
                  *ngFor="let seatEdge of segment?.flightSegment?.seatAssignments?.edges"
                >
                  <span class="seat-number v2-mr-1">{{ seatEdge.node?.seat | uppercase }}</span>{{
                  seatEdge.node?.passenger?.fullName}}
                </typography-v2>
              </div>
            </ng-template>

            <ng-template #seatsTemplate>
              <button-v2
                (click)="(activeTab === ItineraryTabsEnum.New && !readonly) && selectSeats.emit(segment.flightSegment)"
                [disabled]="(activeTab !== ItineraryTabsEnum.New) || readonly"
                type="tertiary"
                data-id="edit-seats"
              >
                <typography-v2
                  *ngFor="let seat of seats; let isLast = last"
                  [class.v2-mr-4]="!isLast"
                  class="text-capitalize"
                >
                  {{ seat }}
                </typography-v2>
              </button-v2>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div *ngIf="segment.layover || segment.flightSegment.operatedBy" class="v2-mt-2 layover">
        <typography-v2 look="small" class="text-black-50">
          <span *ngIf="segment.layover" data-id="layover"
            >Layover {{ segment.layover.waitTime | travelTimeFormat }} in {{
            segment.flightSegment.arrivalAirport?.cityName }}</span
          ><span *ngIf="segment.layover && segment.flightSegment.operatedBy" class="v2-mx-3">
            &middot; </span
          ><span *ngIf="segment.flightSegment.operatedBy" data-id="operated-by"
            >Operated by {{ segment.flightSegment.operatedBy }}</span
          >
        </typography-v2>
      </div>
    </div>
  </div>
</div>
