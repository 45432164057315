import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'number-cell',
  templateUrl: './number-cell.component.html',
  styleUrls: ['./number-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCellComponent {
  @Input() data: number;
}
