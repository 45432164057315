import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'phone-cell',
  templateUrl: './phone-cell.component.html',
  styleUrls: ['./phone-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCellComponent {
  @Input() data: string[];
}
