<div
  [ngClass]="{ deleted: withColors && segment.originalDeleted, new: withColors && segment.new }"
  class="segment d-flex row align-items-center"
>
  <div class="col-flight-number p-0 d-flex align-items-center">
    <airline-logo
      class="ms-2 me-1"
      [logoUrl]="segment.flightSegment.airline!.logoUrl"
    ></airline-logo>

    <typography data-id="airline" look="details">
      {{ segment.flightSegment.airline!.code }} {{ segment.flightSegment.flightNumber }}
    </typography>
  </div>
  <div class="col-flight-segment">
    <typography data-id="direction" look="H4">
      {{ segment.flightSegment.departureAirport!.cityName }} ({{
      segment.flightSegment.departureAirport!.locationCode }}) to {{
      segment.flightSegment.arrivalAirport!.cityName }} ({{
      segment.flightSegment.arrivalAirport!.locationCode }})
    </typography>
  </div>
  <div class="col-departure p-0">
    <typography data-id="departure-date" look="details">
      {{ segment.flightSegment.departureAtAirportTimeZone | rdrDate: { withoutTimezones: true } }}
    </typography>
  </div>
  <div class="px-1 col-flight-date d-flex justify-content-start align-items-center">
    <typography data-id="departure-time" look="details">
      {{ segment.flightSegment.departureAtAirportTimeZone | rdrDate: { format: 'shortTime',
      withoutTimezones: true } }}
    </typography>
    <rdr-svg-icon class="mx-1" key="arrow-forward" size="xs"></rdr-svg-icon>
    <typography data-id="arrival-time" look="details">
      {{ segment.flightSegment.arrivalAtAirportTimeZone | rdrDate: { format: 'shortTime',
      withoutTimezones: true } }}
      <typography
        class="overnight"
        *ngIf="segment.daysShift | getDaysShift as daysShift"
        look="details"
        data-id="shift"
      >
        {{ daysShift }}</typography
      >
    </typography>
  </div>
  <div class="p-0 px-1 col-flight-time">
    <typography data-id="total-time" look="details"
      >{{ segment.travelTime | travelTimeFormat }}</typography
    >
  </div>
  <div class="col-flight-class p-0">
    <typography data-id="service-class" class="text-capitalize" look="details"
      >{{ segment.flightSegment.serviceClass | label }}
    </typography>
  </div>
  <div class="px-1 col-aircraft">
    <typography *ngIf="segment.flightSegment.aircraftName" data-id="aircraft" look="details">
      {{ segment.flightSegment.aircraftName }}
    </typography>
  </div>
  <div
    *ngIf="segment.flightSegment.seatAssignments?.edges?.length"
    class="col-seats p-0 d-flex align-items-center"
  >
    <ng-container *ngIf="segment.flightSegment.booking as booking">
      <ng-container *ngIf="booking.status === bookingStatus.Ticketed">
        <rdr-svg-icon key="seat" class="me-1"></rdr-svg-icon>
        <span *ngFor="let seat of segment | seatsFromSegment; let last = last">
          {{ seat }}<span *ngIf="!last" class="me-1">,</span>
        </span>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="row">
  <div *ngIf="segment.flightSegment.operatedBy" class="col-6 col-offset p-0">
    <typography look="details">Operated by {{ segment.flightSegment.operatedBy }}</typography>
  </div>
  <div *ngIf="segment.layover" data-id="layover" class="col-6 col-offset p-0 mt-1 mb-1 details">
    <typography look="details">
      Layover: {{ segment.flightSegment.arrivalAtAirportTimeZone | rdrDate: { format: 'fullDate',
      withoutTimezones: true } }}, {{ segment.layover.waitTime | travelTimeFormat }} in {{
      segment.flightSegment.arrivalAirport!.cityName }}
    </typography>
  </div>
</div>
