import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { CostNode, CostType, OrderNode, UserType } from '@app/generated/graphql';
import { BookingType } from '@app/shared/models/booking-type.enum';
import { GraphqlID } from '@app/shared/types';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';

@Component({
  selector: 'order-booking-cost-v2',
  templateUrl: './order-booking-cost-v2.component.html',
  styleUrls: ['./order-booking-cost-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookingCostV2Component implements OnInit {
  UserType = UserType;

  showedInvoices: boolean;
  isTicketing: boolean;

  @Input() orderId: GraphqlID;
  @Input() bookingType: BookingType;
  @Input() cost?: CostNode;
  @Input() readonly: boolean;
  @Input() supplierFromBooking?: string;
  @Input() order?: OrderNode;
  @Input() withDirect: boolean;

  @Output() costUpdated = new EventEmitter<CostNode>();
  @Output() costDeleted = new EventEmitter<string>();

  constructor(
    private modalV2Service: ModalV2Service,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit() {
    this.isTicketing = this.cognitoAuthService.isTicketingAgent;
  }

  onCostUpdated(cost: CostNode) {
    this.costUpdated.next({
      type: this.cost?.type,
      ...cost,
      id: this.cost?.id as string,
    });
  }
  get isHotelCost() {
    return this.cost?.type === CostType.Hotel;
  }

  showModal(template: TemplateRef<unknown>) {
    if (this.readonly && !this.isTicketing) return;

    this.modalV2Service.showModal({ template, className: 'wide' });
  }
}
