import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DropdownType } from '@ui/components/dropdown/dropdown.component';
import { DropdownItem } from '@ui/components/dropdown.model';
import { Observable } from 'rxjs';
import { BasefieldComponent } from '../basefield/basefield.component';
import { tap } from 'rxjs/operators';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'formly-select',
  templateUrl: './formly-select.component.html',
  styleUrls: ['./formly-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlySelectComponent extends BasefieldComponent implements OnInit {
  @ViewChild('editable') editable: EditableComponent;

  dropdownType = DropdownType;
  selectedItem: DropdownItem | undefined;

  ngOnInit(): void {
    if (this.formControl.value) {
      if (this.to.initial) {
        this.optionSelected(this.to.initial as DropdownItem);

        if (!this.to.withChangeOptsListener) {
          return;
        }
      }

      (this.to.options as Observable<DropdownItem[]>)
        .pipe(tap((opts) => this.setSelectedItem(opts)))
        .pipe(UntilDestroyed(this))
        .subscribe();
    }
  }

  setSelectedItem(opts: DropdownItem[]): void {
    this.selectedItem = opts.find((item: DropdownItem) => item.value === this.formControl.value);
    if (this.to.colored) {
      this.selectedItem!.colored = true;
    }
    this.cdRef.markForCheck();
  }

  optionSelected(item: DropdownItem): void {
    this.selectedItem = item;
    this.formControl.setValue(item.value);
    this.editable?.cancelEdit();
  }
}
