import { environment } from './environments/environment';

const awsconfig: any = {
  aws_project_region: environment.AWS_PROJECT_REGION,
  aws_cognito_region: environment.AWS_COGNITO_REGION,
  aws_user_pools_id: environment.AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: environment.AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: environment.OAUTH_DOMAIN,
    redirectSignIn: environment.AMPLIFY_REDIRECT_SIGN_IN,
    redirectSignOut: environment.AMPLIFY_REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
};

if (!environment.production) {
  // unsafe configuration to support Kotlin tests
  awsconfig['cookieStorage'] = {
    secure: false,
    path: '/',
    expires: 365,
  };
}

export default awsconfig;
