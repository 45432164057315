import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  CHIPS_V2,
  COUNT_V2,
  CURRENCY_V2,
  DATE_V3,
  REPEAT_V2,
  SELECT_V2,
} from '@app/formly/helpers/fields';
import { of } from 'rxjs';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { ServiceClassType } from '@app/generated/graphql';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { AirportsService } from '@app/shared/services/airports/airports.service';
import { getInitialServiceclass } from '@app/pages/flight-requests/components/create-task/helpers/entities';
import { AgeGroupLabels } from '@app/shared/constants';

@Component({
  selector: 'multi-form',
  templateUrl: './multi-form.component.html',
  styleUrls: ['./multi-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiFormComponent extends RadarFormAbstract implements OnInit {
  constructor(private airportsService: AirportsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.fields = [
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          REPEAT_V2({
            key: 'flights',
            className: 'w-100',
            templateOptions: {
              label: 'Destination',
            },
            fieldArray: {
              fieldGroupClassName: 'd-flex w-100',
              fieldGroup: [
                CHIPS_V2(
                  {
                    key: 'departureAirports',
                    className: 'col-4 flex-1',
                    templateOptions: {
                      label: 'From',
                      placeholder: 'From',
                      filterCallback: filterDDWithCode,
                    },
                    expressionProperties: {
                      'templateOptions.initial': 'field.formControl.value',
                    },
                  },
                  this.airportsService.getAirportsAsDropDownItems()
                ),
                CHIPS_V2(
                  {
                    key: 'arrivalAirports',
                    className: 'col-4 flex-1',
                    templateOptions: {
                      label: 'To',
                      placeholder: 'To',
                      filterCallback: filterDDWithCode,
                    },
                    expressionProperties: {
                      'templateOptions.initial': 'field.formControl.value',
                    },
                  },
                  this.airportsService.getAirportsAsDropDownItems()
                ),
                DATE_V3({
                  key: 'departureDate',
                  className: 'col-3',
                  templateOptions: {
                    label: 'Departure Date',
                  },
                }),
              ],
            },
          }),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          {
            fieldGroupClassName: 'd-flex flex-column w-100',
            className: 'col-6',
            fieldGroup: [
              COUNT_V2({
                key: 'numberOfAdults',
                templateOptions: {
                  label: 'Passengers',
                  extra: {
                    title: 'Adults',
                    info: AgeGroupLabels.Adult,
                  },
                  biggerLabelMargin: true,
                },
                defaultValue: 0,
              }),
              COUNT_V2({
                key: 'numberOfChildren',
                templateOptions: {
                  extra: {
                    title: 'Children',
                    info: AgeGroupLabels.Child,
                  },
                },
                defaultValue: 0,
              }),
              COUNT_V2({
                key: 'numberOfInfants',
                templateOptions: {
                  extra: {
                    title: 'Infants',
                    info: AgeGroupLabels.Infant,
                  },
                },
                defaultValue: 0,
              }),
            ],
          },
          {
            fieldGroupClassName: 'd-flex flex-column w-100',
            className: 'col-6',
            fieldGroup: [
              SELECT_V2(
                {
                  key: 'serviceClass',
                  templateOptions: {
                    label: 'Cabin',
                    initial: this.data.serviceClass
                      ? getInitialServiceclass(this.data.serviceClass as ServiceClassType)
                      : null,
                  },
                },
                of(mapEnumToDropdownItems(ServiceClassType, { withoutColors: true }))
              ),
              {
                fieldGroupClassName: 'd-flex align-items-end',
                fieldGroup: [
                  CURRENCY_V2({
                    className: 'flex-1',
                    key: 'budgetFrom',
                    templateOptions: {
                      label: "Client's Budget",
                    },
                  }),
                  {
                    className: 'v2-mx-2 v2-mb-2',
                    template: '–',
                  },
                  CURRENCY_V2({
                    className: 'flex-1',
                    key: 'budgetTo',
                  }),
                ],
              },
            ],
          },
        ],
      },
    ];
  }
}
