<div class="v2-mb-5 d-flex align-items-center justify-content-between">
  <typography-v2 look="title-bold" dataId="header">{{ header }}</typography-v2>
  <button-icon-v2
    type="just-icon"
    key="close"
    class="cursor-pointer"
    (click)="hideModal()"
  ></button-icon-v2>
</div>
<div class="body">
  <ng-content></ng-content>
</div>
