import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingNode, UserType } from '@app/generated/graphql';

@Component({
  selector: 'booking-revenue-head',
  templateUrl: './booking-revenue-head.component.html',
  styleUrls: ['./booking-revenue-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRevenueHeadComponent {
  UserType = UserType;

  @Input() booking: BookingNode;
}
