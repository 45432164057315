import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RdrCurrencyFormat } from '@app/ui/pipes/rdr-currency.pipe';

@Component({
  selector: 'currency-cell',
  templateUrl: './currency-cell.component.html',
  styleUrls: ['./currency-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyCellComponent {
  @Input() data: number;
  @Input() format?: RdrCurrencyFormat;
}
