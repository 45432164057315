<chips-v2
  [items]="to.optionsService | async"
  [filterCallback]="to.filterCallback"
  [groupByField]="to.groupByField"
  [initial]="to.initial"
  [isFilter]="to.isFilter"
  [placeholder]="to.placeholder"
  [attr.data-id]="field.key"
  [disabled]="to.disabled"
  (itemSelectionChanged)="onItemSelectionChanged($event)"
>
</chips-v2>
