<router-outlet></router-outlet>

<call-popup-v2
  *ngIf="pollerService.leadInValidation$ | async as lead"
  [lead]="lead"
></call-popup-v2>

<hot-lead-popup
  *ngIf="(pollerService.hotPopupAvailable$ | async) && !!!(pollerService.leadInValidation$ | async)"
></hot-lead-popup>

<no-lead-message *ngIf="pollerService.showUnsuccesfulLockMessage$ | async"></no-lead-message>

<ng-template #authorizeRcTemplate>
  <modal-v2 header="Authorize SMS Sending">
    <typography-v2
      >To enable SMS sending on your behalf through RingCentral, please authorize Radar to access
      your RingCentral account. This will allow you to manage your communications directly from
      Radar seamlessly.</typography-v2
    >

    <div class="d-flex justify-content-end v2-mt-5">
      <button-v2 (click)="auth()" data-id="ring-central-authorize"
        >Authorize with RingCentral</button-v2
      >
    </div>
  </modal-v2>
</ng-template>
