import { ChangeDetectorRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService, RdrFeature } from '@ui/services/feature-toggle.service';

@Directive()
export class FeatureToggleAbstractDirective implements OnInit {
  feature: RdrFeature;
  isInverted = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private featureToggleService: FeatureToggleService
  ) {}

  ngOnInit() {
    const isEnabled = this.featureToggleService.isFeatureEnabled(this.feature);

    if (this.isInverted) {
      this.updateDirectiveState(!isEnabled);
    } else {
      this.updateDirectiveState(isEnabled);
    }
  }

  public updateDirectiveState(isEnabled: boolean): void {
    if (isEnabled) {
      this.createView();
    } else {
      this.clearView();
    }
  }

  private createView(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.cdRef.markForCheck();
  }

  private clearView(): void {
    this.viewContainer.clear();
  }
}
