import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  CHECKBOX_V2,
  DATE_V3,
  EMAIL_V2,
  INPUT_V2,
  MULTI_CHECKBOX_V2,
  NUMERIC_V2,
  PHONE_V2,
  SELECT_V2,
} from '@app/formly/helpers/fields';
import { UserNode, UserType } from '@app/generated/graphql';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { of } from 'rxjs';
import { mapEnumToDropdownItems } from '@shared/utils/dropdown-utils';
import { RdrFeature } from '@ui/services/feature-toggle.service';
import { mapEntityToDropdownItem } from '@shared/utils/dropdown-utils';
import { DropdownItem } from '@app/ui/components/dropdown.model';

export type AdminUserForm = {
  isActive: boolean;
  email: string;
  phone: string;
  type: UserType;
  teamLeadId: string;
  salesDirector: boolean;
  dailyLeads: number;
  historyAccess: boolean;
  profile?: UserProfile;
};

export type UserProfile = {
  feFeatures: RdrFeature[];
  ringExtensionId: string;
  startDate: string;
};

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent extends RadarFormAbstract implements OnInit {
  @Input() teamLeads: UserNode[] = [];
  @Input() showFeatureToggles = true;
  @Input() allowedUserTypes: DropdownItem<UserType>[];
  @Input() shifts: DropdownItem[];

  ngOnInit(): void {
    super.ngOnInit();

    const features = this.showFeatureToggles
      ? [
          MULTI_CHECKBOX_V2({
            key: 'profile.feFeatures',
            templateOptions: {
              label: 'Features',
              type: 'array',
              options: mapEnumToDropdownItems(RdrFeature),
            },
          }),
        ]
      : [];

    const emailField = this.entityId
      ? [
          EMAIL_V2({
            key: 'email',
            templateOptions: {
              label: 'Email',
              disabled: true,
            },
          }),
        ]
      : [];

    this.fields = [
      ...emailField,
      CHECKBOX_V2({
        key: 'isActive',
        templateOptions: {
          label: 'Active',
        },
      }),
      EMAIL_V2({
        key: 'email',
        templateOptions: {
          label: 'Email',
        },
        hideExpression: this.entityId,
      }),
      PHONE_V2({
        key: 'phone',
        templateOptions: {
          label: 'Phone',
        },
        validators: {
          validation: ['phone'],
        },
      }),
      SELECT_V2(
        {
          key: 'type',
          templateOptions: {
            label: 'Type',
            required: true,
            initial: mapEnumToDropdownItems(UserType, { included: [this.data.type] })[0],
          },
        },
        of(this.allowedUserTypes || mapEnumToDropdownItems(UserType))
      ),
      SELECT_V2(
        {
          key: 'teamLeadId',
          templateOptions: {
            label: 'Team Lead',
            initial: this.getInitialTeamLead(),
          },
          hideExpression: 'model.type !== "SALES_AGENT" && model.type !== "SEARCHER"',
        },
        of(this.teamLeads.map(mapUserToDropdown))
      ),
      CHECKBOX_V2({
        key: 'salesDirector',
        templateOptions: {
          label: 'Sales Director',
        },
        hideExpression: 'model.type !== "SALES_TEAM_LEAD"',
      }),
      NUMERIC_V2({
        key: 'dailyLeads',
        templateOptions: {
          label: 'Daily Leads',
          positiveOnly: true,
          integerOnly: true,
        },
        hideExpression: 'model.type !== "SALES_AGENT" && model.type !== "SALES_TEAM_LEAD"',
      }),
      CHECKBOX_V2({
        key: 'historyAccess',
        templateOptions: {
          label: 'History Access',
        },
      }),
      CHECKBOX_V2({
        key: 'routeDomesticLeads',
        templateOptions: {
          label: 'Route Domestic Leads',
        },
        hideExpression: 'model.type !== "SALES_AGENT" && model.type !== "SALES_TEAM_LEAD"',
      }),
      CHECKBOX_V2({
        key: 'routeEconomyLeads',
        templateOptions: {
          label: 'Route Economy Leads',
        },
        hideExpression: 'model.type !== "SALES_AGENT" && model.type !== "SALES_TEAM_LEAD"',
      }),
      INPUT_V2({
        key: 'profile.ringExtensionId',
        templateOptions: {
          label: 'Ring Extension',
        },
      }),
      INPUT_V2({
        key: 'profile.slackId',
        templateOptions: {
          label: 'Slack ID',
        },
      }),
      DATE_V3({
        key: 'profile.startDate',
        templateOptions: {
          label: 'Start Date',
        },
      }),
      ...features,
      SELECT_V2(
        {
          key: 'shiftId',
          templateOptions: {
            label: 'Shift',
            initial: this.data.shiftId
              ? this.shifts.find(({ value }) => value === this.data.shiftId)
              : null,
          },
        },
        of(this.shifts)
      ),
    ];
  }

  getInitialTeamLead() {
    const foundTeamLead = this.teamLeads.find(({ id }) => id === this.data.teamLeadId);
    return foundTeamLead ? mapUserToDropdown(foundTeamLead) : null;
  }
}

export function mapUserToDropdown(user?: UserNode): DropdownItem {
  return {
    ...mapEntityToDropdownItem(user, 'fullName'),
    picture: user?.picture,
  };
}
