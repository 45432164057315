import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightItineraryGroupNode } from '@generated/graphql';

@Component({
  selector: 'flight-info-cell',
  templateUrl: './flight-info-cell.component.html',
  styleUrls: ['./flight-info-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightInfoCellComponent {
  @Input() data: FlightItineraryGroupNode[];
}
