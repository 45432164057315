<div class="d-flex align-items-center" hoverClass>
  <ng-template #fieldComponent></ng-template>
  <btn
    *ngIf="formControl.value"
    (btnClick)="onOpen($event)"
    size="big"
    look="secondary"
    icon="arrow-forward"
    iconPosition="right"
    class="ms-2 redirect-link"
    >Open</btn
  >
</div>
