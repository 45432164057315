import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { HotLeadPollingService } from '../service/hot-lead-polling.service';

@Component({
  selector: 'no-lead-message',
  templateUrl: './no-lead-message.component.html',
  styleUrls: ['./no-lead-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiV2Module],
})
export class NoLeadMessageComponent {
  public pollerService = inject(HotLeadPollingService);
}
