import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'percentage-cell',
  templateUrl: './percentage-cell.component.html',
  styleUrls: ['./percentage-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageCellComponent {
  @Input() data: number;
}
