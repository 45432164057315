import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'email-cell',
  templateUrl: './email-cell.component.html',
  styleUrls: ['./email-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailCellComponent {
  @Input() data: string[];
}
