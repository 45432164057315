<nav role="navigation">
  <a id="userLink" class="user-link d-flex align-items-center" (click)="toggleCollapsed($event)">
    <div class="d-flex align-items-center position-relative">
      <agent-card [agent]="user" [hideName]="true"></agent-card>

      <ng-container *featureToggleOn="RdrFeature.xmas">
        <img class="position-absolute hat" src="/assets/svg/hat-xmas.svg" />
      </ng-container>

      <ng-container *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead]">
        <ng-container *ngIf="availableStatus$ | async as availableStatus">
          <div
            *ngIf="availableStatus === AvailableStatus.Available"
            class="position-absolute status is-available"
          ></div>
          <div
            *ngIf="availableStatus === AvailableStatus.Reconnecting"
            class="position-absolute status is-reconnecting"
          ></div>
          <div
            *ngIf="availableStatus === AvailableStatus.Unavailable"
            class="position-absolute status is-unavailable"
          ></div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="!collapsed" class="navbar-dropdown">
      <ul>
        <ng-content></ng-content>
      </ul>
    </div>
  </a>
</nav>
