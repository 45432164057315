import { COST_WIRE_TRANSFER, MAX_DATE_RANGE } from '@shared/constants';
import { DropdownItem } from '@ui/components/dropdown.model';
import { RdrDateRange } from '@app/formly/types/date-range/date-range.component';

export function formOfPaymentFilterToQuery(formOfPayment: string) {
  if (formOfPayment === COST_WIRE_TRANSFER.value) {
    return { isWireTransfer: true };
  }
  return { companyCreditCardId: formOfPayment };
}

export function supplierFilterToQuery(data: DropdownItem) {
  const key = data.groupBy === 'mile' ? 'milesSupplierId' : 'revenueSupplierId';

  return {
    [key]: data.value,
  };
}

export const getDefaultStartDay = () => new Date(Date.now() - 1000 * 60 * 60 * 24 * MAX_DATE_RANGE);

export function endOfToday(): Date {
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  return endOfToday;
}

export const DEFAULT_PAGE_DATE_RANGE: { dateRange: RdrDateRange } = {
  dateRange: [getDefaultStartDay(), endOfToday()],
};
