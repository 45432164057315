<card>
  <div class="d-flex align-items-center v2-mb-3">
    <typography-v2 look="title-bold" data-id="title">Passengers</typography-v2>

    <button-icon-v2
      *ngIf="!readonlyPage"
      [modalTpl]="newPassengerTpl"
      [modalClass]="'wide'"
      showModalOnClick
      key="plus-v-2"
      size="md"
      color="var(--color-black)"
      class="v2-ml-1 add-new-passenger"
      data-id="add-passenger"
    ></button-icon-v2>
  </div>

  <div *ngIf="passengers?.length; else emptyState" class="d-flex passengers-cards">
    <div
      *ngFor="let passenger of passengers"
      class="d-flex align-items-center justify-content-between v2-px-3 v2-py-2 position-relative passenger-card"
      data-id="passenger"
    >
      <a
        (click)="showedPassengerDetails = passenger"
        class="d-flex align-items-center passenger-fullname"
        data-id="details-passenger"
      >
        <span class="text-truncate">{{ passenger.fullName }}</span>
        <rdr-svg-icon
          class="v2-ml-1"
          key="chevron-bottom"
          size="sm"
          color="var(--color-black)"
        ></rdr-svg-icon>
      </a>

      <context-menu-v2
        *ngIf="showedPassengerDetails === passenger"
        (clickOutside)="showedPassengerDetails = null"
        class="position-absolute passenger-details"
      >
        <passenger-details-card
          (downloadDocument)="downloadDocument.emit($event)"
          [passenger]="showedPassengerDetails"
          (closeDetails)="showedPassengerDetails = null"
        ></passenger-details-card>
      </context-menu-v2>

      <button-icon-v2
        *ngIf="!readonlyPage"
        (click)="setEditablePassenger(passenger)"
        [modalTpl]="updatePassengerTpl"
        [modalClass]="'wide'"
        showModalOnClick
        key="edit-pen"
        size="md"
        color="var(--color-black)"
        class="v2-ml-1 edit-passenger"
        data-id="edit-passenger"
      ></button-icon-v2>
    </div>
  </div>
</card>

<ng-template #updatePassengerTpl>
  <modal-v2 header="Edit Passenger">
    <passenger-form-v2
      (formSubmit)="savePassenger.emit($event)"
      (deletePassenger)="deletePassenger.emit(editablePassenger?.id)"
      [data]="editablePassenger"
      [entityId]="editablePassenger?.id"
      [allowDelete]="true"
    ></passenger-form-v2>
  </modal-v2>
</ng-template>

<ng-template #newPassengerTpl>
  <modal-v2 header="New Passenger">
    <passenger-form-v2
      (formSubmit)="savePassenger.emit($event)"
      [isOrder]="isOrder"
      [clientId]="clientId"
      [passengers]="passengers"
    ></passenger-form-v2>
  </modal-v2>
</ng-template>

<ng-template #emptyState>
  <typography-v2 class="text-black-50"
    >The {{ isOrder ? 'order' : 'client' }} has no passengers yet</typography-v2
  >
</ng-template>
