<form
  [formGroup]="formGroup"
  (ngSubmit)="submitFormV2()"
  (keydown.enter)="$event.preventDefault()"
  class="trip-notes-form"
>
  <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2
      [disabled]="formGroup.pristine"
      buttonType="submit"
      data-id="trip-notes-save"
      formButtonState
    >
      <typography-v2 look="caption-bold">Save</typography-v2>
    </button-v2>
  </div>
</form>
