import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'radar-table-checkbox',
  templateUrl: './radar-table-checkbox.component.html',
  styleUrls: ['./radar-table-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableCheckboxComponent {
  @Input() checked: boolean;

  @Output() check: EventEmitter<boolean> = new EventEmitter();

  onCheck(): void {
    this.check.emit();
  }
}
