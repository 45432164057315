import { Component, OnInit } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';
import IMask from 'imask';

@Component({
  selector: 'email-v2',
  templateUrl: './email-v2.component.html',
  styleUrls: ['./email-v2.component.scss'],
})
export class EmailV2Component extends BasefieldV2Component implements OnInit {
  imask: IMask.AnyMaskedOptions;

  ngOnInit() {
    this.imask = {
      mask: /^\S*@?\S*$/,
    };
  }
}
