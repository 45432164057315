import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HeaderComponent } from './header/header.component';
import { LeftPaneComponent } from './left-pane/left-pane.component';
import { InnerLayoutComponent } from './inner-layout/inner-layout.component';
import { UiModule } from '@ui/ui.module';
import { RightPaneComponent } from './right-pane/right-pane.component';
import { GlobalSearchModule } from '@ui/global-search/global-search.module';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { IsV2CheckerDirective } from './main-layout/is-v2-checker.directive';
import { IconsModule } from '@app/icons/icons.module';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { AlertsModule } from '@app/shared/components/alerts/alerts.module';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    LeftPaneComponent,
    InnerLayoutComponent,
    RightPaneComponent,
    IsV2CheckerDirective,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    UiModule,
    UiV2Module,
    GlobalSearchModule,
    IconsModule,
    PipesModule,
    AlertsModule,
  ],
  exports: [LeftPaneComponent, InnerLayoutComponent, IsV2CheckerDirective],
})
export class LayoutModule {}
