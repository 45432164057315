import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'typography-v2',
  templateUrl: './typography-v2.component.html',
  styleUrls: ['./typography-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyV2Component {
  @Input() look: TypographyLook = 'text';
  @Input() uppercase = false;
  @Input() dataId: string;
}

export type TypographyLook =
  | 'head'
  | 'title'
  | 'title-semi-bold'
  | 'title-bold'
  | 'section-title-bold'
  | 'section-title'
  | 'subtitle'
  | 'subtitle-bold'
  | 'text'
  | 'text-500'
  | 'text-bold'
  | 'text-semibold'
  | 'caption'
  | 'caption-bold'
  | 'small'
  | 'small-bold';
