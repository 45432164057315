import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'lead-call-track',
  templateUrl: './lead-call-track.component.html',
  styleUrls: ['./lead-call-track.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadCallTrackComponent {
  changing = false;
  playing = false;

  @Input() audioLink = '';

  @Output() setPlaying = new EventEmitter();
  @Output() audioEnded = new EventEmitter();

  @ViewChild('audioTag') audioTag: ElementRef;
  @ViewChild('track') track: ElementRef;

  play() {
    this.playing = true;

    void (this.audioTag.nativeElement as HTMLAudioElement).play();

    this.setPlaying.emit(this.playing);
  }

  pause() {
    this.playing = false;
    this.audioTag.nativeElement.pause();
    this.setPlaying.emit(this.playing);
  }

  mousedown() {
    this.changing = true;
    this.audioTag.nativeElement.pause();
  }

  mouseup() {
    this.changing = false;

    if (this.playing) {
      this.audioTag.nativeElement.play();
    }
  }

  change(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.audioTag.nativeElement.currentTime = value;
  }

  timeupdate() {
    if (this.changing) return;

    this.track.nativeElement.value = Math.floor(Number(this.audioTag.nativeElement.currentTime));
    this.track.nativeElement.max = Math.floor(Number(this.audioTag.nativeElement.duration));

    if (this.track.nativeElement.value === this.track.nativeElement.max) {
      this.track.nativeElement.value = 0;
      this.pause();
      this.audioEnded.emit();
    }
  }
}
