<ng-container *ngIf="cost.isDirect; else defaultTemplate">
  <div class="d-flex align-items-center">
    <span class="v2-mx-3">&middot;</span>
    <credit-card-logo [creditCardType]="CreditCardTypeEnum.Mc" class="v2-mr-2"></credit-card-logo>
    <typography-v2>****</typography-v2>
  </div>
</ng-container>

<ng-template #defaultTemplate>
  <div class="d-flex align-items-center">
    <span
      *ngIf="cost.isWireTransfer || cost.isAccountHolder || cost.secureCreditCard"
      class="v2-mx-3"
      >&middot;</span
    >
    <span data-id="method" *ngIf="cost.isWireTransfer">Check Payment</span>
    <span data-id="method" *ngIf="cost.isAccountHolder">Account Holder</span>
    <ng-container *ngIf="!cost.isWireTransfer && !cost.isAccountHolder && cost.secureCreditCard">
      <ng-container *ngIf="cost.secureCreditCard?.isCompany; else cardTemplate">
        <typography-v2 dataId="method"> {{ cost.secureCreditCard?.title}} </typography-v2>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #cardTemplate let-card>
    <payment-credit-card-v2 [creditCard]="cost.secureCreditCard"></payment-credit-card-v2>
  </ng-template>
</ng-template>
