import { Pipe, PipeTransform } from '@angular/core';

type GenericEdge<T> = {
  node?: T;
};

@Pipe({
  name: 'extractNodes',
})
export class ExtractNodesPipe implements PipeTransform {
  //TODO: remove | any as a separate effort
  transform<T>(edges?: GenericEdge<T>[]): (GenericEdge<T>['node'] | any)[] {
    return edges?.map((e) => e.node) || [];
  }
}
