import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { UntypedFormGroup } from '@angular/forms';
import { DATE_V3, NUMERIC_V2, SELECT_V2, TYPEAHEAD_V2 } from '@app/formly/helpers/fields';
import { FlexibilityType, FlightRequestTripNode, ServiceClassType } from '@generated/graphql';
import { Observable, of } from 'rxjs';
import { getModelDiff, purifyModel } from '@shared/utils/model-utils';
import lodash from 'lodash';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { mapEnumToDropdownItems, mapStatusToDropdownItem } from '@app/shared/utils/dropdown-utils';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { AppInjector } from '@app/app-injector.service';
import { FormLoadingStateService } from '@app/shared/services/form-loading-state.service';
import { flexibilityLabels } from '@app/shared/pipes/flexibility.pipe';
import {
  AirportsService,
  airportsDropDownMapper,
} from '@app/shared/services/airports/airports.service';

@Component({
  selector: 'flight-request-flight-form-v2',
  templateUrl: './flight-request-flight-form-v2.component.html',
  styleUrls: ['./flight-request-flight-form-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightRequestFlightFormV2Component extends RadarFormAbstract implements OnInit {
  @Input() data: FlightRequestTripNode;
  @Input() isReturnTrip: boolean;
  @Input() addButtonText = 'Add';

  @Output() deleteEntity: EventEmitter<FlightRequestTripNode> = new EventEmitter();

  formGroup = new UntypedFormGroup({});

  constructor(private airportsService: AirportsService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      TYPEAHEAD_V2(
        {
          key: 'departureAirportId',
          templateOptions: {
            label: 'Departure Airport',
            initial: this.data?.departureAirport
              ? airportsDropDownMapper(this.data.departureAirport)
              : null,
            required: true,
            filterCallback: filterDDWithCode,
          },
        },
        this.airportsService.getAirportsAsDropDownItems()
      ),
      TYPEAHEAD_V2(
        {
          key: 'arrivalAirportId',
          templateOptions: {
            label: 'Arrival Airport',
            required: true,
            initial: this.data?.arrivalAirport
              ? airportsDropDownMapper(this.data?.arrivalAirport)
              : null,
            filterCallback: filterDDWithCode,
          },
        },
        this.airportsService.getAirportsAsDropDownItems()
      ),
      DATE_V3({
        key: 'departureAt',
        templateOptions: {
          label: 'Departure Date',
          required: true,
        },
      }),
      SELECT_V2(
        {
          key: 'flexibility',
          templateOptions: {
            label: 'Flexibility',
            initial: this.data?.flexibility
              ? mapFlexibilityToDropdown(this.data.flexibility)
              : null,
          },
        },
        new Observable((sub) =>
          sub.next(Object.values(FlexibilityType).map(mapFlexibilityToDropdown))
        )
      ),
      SELECT_V2(
        {
          key: 'serviceClass',
          templateOptions: {
            label: 'Service Class',
            initial: this.data?.serviceClass
              ? mapStatusToDropdownItem<ServiceClassType>(this.data.serviceClass, true)
              : null,
            required: true,
          },
        },
        of(mapEnumToDropdownItems(ServiceClassType, { withoutColors: true }))
      ),
      NUMERIC_V2({
        key: 'passengerCount',
        validators: {
          validation: ['greater-then-zero'],
        },
        templateOptions: {
          required: true,
          label: 'PAX',
        },
      }),
    ];

    if (this.isReturnTrip) {
      this.formGroup.markAsDirty();
    }
  }

  /**
   * Override submit handler ONLY for this specific form. If we have more cases like this,
   * the parent form needs to be re-thinked.
   */
  submitFormV2() {
    const formLoadingStateService = AppInjector.injector.get(FormLoadingStateService);
    const model = purifyModel(this.formModel);

    if (this.entityId) {
      if (!lodash.isEqual(model, this.modelBackup || {})) {
        const diff = getModelDiff(model, this.modelBackup || {});
        this.formSubmit.emit({ data: diff, id: this.entityId });
      }
    } else {
      this.formSubmit.emit({ data: model });
    }

    formLoadingStateService.isLoading$.next(true);
  }
}

export const mapFlexibilityToDropdown = (status: FlexibilityType): DropdownItem => {
  return {
    label: getFlexibilityLabel(status),
    value: status,
  };
};

const getFlexibilityLabel = (status: FlexibilityType): string => {
  return flexibilityLabels[status];
};

export type FlightFormState = {
  departureAirportId: string;
  arrivalAirportId: string;
  departureAt: Date;
  flexibility: FlexibilityType;
  passengerCount: number;
  serviceClass: ServiceClassType;
};
