<ng-container *ngIf="formControl.value; else emptyState">
  <div class="text-input d-flex justify-content-between">
    <typography-v2 (click)="downloadFile()" class="cursor-pointer nowrap text-truncate v2-mr-1">
      {{ to.originName || 'Document' }}
    </typography-v2>

    <div class="d-flex align-items-center">
      <rdr-svg-icon
        (click)="deleteFile()"
        key="trash"
        color="var(--color-black-50)"
        class="cursor-pointer"
      ></rdr-svg-icon>
    </div>
  </div>
</ng-container>

<ng-template #emptyState>
  <div class="d-flex align-items-center justify-content-center">
    <button-v2 (click)="fileInput.click()" look="full-width" class="d-flex w-100">
      <typography-v2 look="caption" class="mx-auto">Upload</typography-v2>
    </button-v2>
  </div>

  <input type="file" class="d-none" #fileInput (change)="changeFile($event)" />
</ng-template>
