<div
  class="card-info position-relative d-flex align-items-center"
  (click)="$event.stopPropagation(); showCardPopup = !showCardPopup;"
>
  <credit-card-logo [creditCardType]="creditCard?.type" class="v2-mr-2"></credit-card-logo>

  <typography-v2 dataId="method"><a>{{ creditCard?.number?.slice(-4) }}</a></typography-v2>
  <rdr-svg-icon
    class="v2-ml-1"
    key="chevron-bottom"
    size="sm"
    color="var(--color-black)"
  ></rdr-svg-icon>
  <ng-container *ngIf="showCardPopup">
    <context-menu-v2
      [clickOutsideShouldIgnoreFirstClick]="false"
      (clickOutside)="showCardPopup = false"
      class="position-absolute"
      data-id="client-context-menu"
    >
      <credit-card-preview *ngIf="creditCard" [creditCard]="creditCard"></credit-card-preview>
    </context-menu-v2>
  </ng-container>
</div>
