import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'inner-layout-v2',
  templateUrl: './inner-layout-v2.component.html',
  styleUrls: ['./inner-layout-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerLayoutV2Component {
  @Input() wide = false;
  @HostBinding('class.vertical-padding') @Input() hasVerticalPadding = true;
}
