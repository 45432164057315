import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';

export function onlyDateInFutureValidator(control: AbstractControl) {
  if (!control.value) {
    return null;
  }

  const today = DateTime.now().startOf('day');
  const currentDate = DateTime.fromISO(control.value as string);

  return currentDate > today ? null : { onlyInTheFuture: true };
}
