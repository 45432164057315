<field-layout [isReadonly]="isReadonly">
  <editable editableView [attr.data-id]="field.dataId" #editableRef>
    <ng-template viewMode>
      <div class="view-mode" [ngClass]="{'input-error-border': showError}" #viewModeRef>
        <text-input-value-view
          [value]="formControl.value"
          [placeholder]="field.templateOptions?.placeholder"
        ></text-input-value-view>

        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <input
        [imask]="imask"
        [type]="to.type ? to.type : 'text'"
        class="formly-input"
        radarOnEnter
        (radarOnEscape)="cancel()"
        radarEditableFocus
        editableOnEnter
        editableOnEscape
        [placeholder]="field.templateOptions?.placeholder"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
    </ng-template>
  </editable>

  <text-input-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.templateOptions?.placeholder"
  ></text-input-value-view>
</field-layout>
