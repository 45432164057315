<div *ngFor="let currentField of field.fieldGroup; let index = index">
  <div class="d-flex align-items-center v2-mt-3">
    <booking-cost [field]="currentField"></booking-cost>

    <button-icon-v2
      *ngIf="!to.disabled"
      (click)="remove(index)"
      key="trash-v-2"
      class="v2-ml-1 mt-auto"
      color="var(--color-red)"
      data-id="remove-cost"
    ></button-icon-v2>
  </div>
</div>

<div *ngIf="!to.disabled" class="v2-mt-3">
  <button-v2 (click)="add()" type="filter" data-id="add-cost">Add Cost/Commission</button-v2>
</div>
