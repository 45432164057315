<div *ngIf="!showReopen && !onlyReopenButton" class="v2-p-4 d-flex align-items-center panel">
  <typography-v2 class="text-black m-auto">Thanks for the rating</typography-v2>
</div>

<button-v2
  *ngIf="showReopen || onlyReopenButton"
  (click)="reopenTask.emit()"
  look="full-width"
  class="d-flex w-100"
  data-id="reopen-task"
  >Create a new search task</button-v2
>
