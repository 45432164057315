import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AgentFragment } from '@generated/graphql';

@Component({
  selector: 'user-card-cell',
  templateUrl: './user-card-cell.component.html',
  styleUrls: ['./user-card-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardCellComponent {
  @Input() data: AgentFragment;
}
