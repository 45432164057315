import { Pipe, PipeTransform } from '@angular/core';
import { BookingNode } from '@app/generated/graphql';

@Pipe({
  name: 'extractETickets',
})
export class ExtractETicketsPipe implements PipeTransform {
  transform({ eTickets }: BookingNode): string {
    const result = [];

    if (eTickets?.edges) {
      for (const eTicket of eTickets?.edges) {
        const number = eTicket.node?.codeNumber;
        result.push(number);
      }
    }

    return result.join(', ');
  }
}
