import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';

@Component({
  selector: 'create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTaskComponent {
  TripType = TripType;
  CreateTaskSteps = CreateTaskSteps;

  formStep = CreateTaskSteps.First;

  tripTypes = [
    { label: 'One Way', value: TripType.Oneway },
    { label: 'Round Trip', value: TripType.Round },
    { label: 'Multi-city', value: TripType.Multi },
  ];

  @Input() tripType = TripType.Oneway;
  @Input() firstStepData: FormSubmitData['data'];
  @Input() secondStepData: FormSubmitData['data'];

  @Output() createTask = new EventEmitter<FormSubmitData['data']>();

  submitFirstStep({ data }: FormSubmitData) {
    this.firstStepData = data;
    this.formStep = CreateTaskSteps.Second;
  }

  submitSecondStep({ data }: FormSubmitData) {
    this.createTask.emit({
      ...data,
      ...this.firstStepData,
      tripType: this.tripType,
    });
  }
}

export enum CreateTaskSteps {
  First = 'first',
  Second = 'second',
}

export enum TripType {
  Oneway = 'oneway',
  Round = 'round',
  Multi = 'multi',
}
