import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AgentFragment, UserType } from '@app/generated/graphql';

@Component({
  selector: 'agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentCardComponent {
  @Input() agent?: AgentFragment;
  @Input() agentType: UserType.SalesAgent | UserType.TicketingAgent;
  @Input() hideName = false;
}
