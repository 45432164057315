import { Injectable } from '@angular/core';
import { AirlineNode } from '@app/generated/graphql';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AirlinesService {
  getAirlines(): Observable<DropdownItem<string>[]> {
    return from(import('static/airlines').then((mod) => mod.default)).pipe(
      map((airlines) => airlines)
    );
  }

  getAirlineById(id: string): Observable<DropdownItem | undefined> {
    return this.getAirlines().pipe(map((airlines) => airlines.find((el) => el.value === id)));
  }
}

export const mapAirlineToDD = (node: AirlineNode): DropdownItem => {
  return (
    node && {
      value: node?.id,
      label: getAirlineLabel(node),
      extras: {
        code: node?.code,
        logoUrl: node?.logoUrl,
      },
    }
  );
};

export const getAirlineLabel = (node?: AirlineNode): string => {
  return `${node?.shortName || node?.displayName || ''} (${node?.code || ''})`;
};
