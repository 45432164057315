import { Pipe, PipeTransform } from '@angular/core';
import { BookingNode } from '@generated/graphql';
import { BookingType } from '../models/booking-type.enum';
import { getBookingType } from '@app/pages/orders/utils/booking.utils';

@Pipe({
  name: 'bookingType',
})
export class BookingTypePipe implements PipeTransform {
  transform(booking: BookingNode): BookingType {
    return getBookingType(booking);
  }
}
