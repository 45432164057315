import { Pipe, PipeTransform } from '@angular/core';

type ConnectionNode<T extends { edges: unknown }> = T['edges'];
type NodeWithinEdge<T1 extends { node: unknown }> = T1['node'];

@Pipe({
  name: 'extractConnection',
})
export class ExtractConnectionPipe implements PipeTransform {
  transform<T extends { edges?: ConnectionNode<any> }>(connection?: T): NodeWithinEdge<any>[] {
    return connection?.edges?.map((e: any) => e.node) || [];
  }
}
