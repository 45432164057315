import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EmailMessageNode } from '@app/generated/graphql';

@Component({
  selector: 'email-message',
  templateUrl: './email-message.component.html',
  styleUrls: ['./email-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailMessageComponent {
  expanded = false;

  @Input() emailMessage: EmailMessageNode;

  toggleEmailMessage(): void {
    this.expanded = !this.expanded;
  }
}
