import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize } from '@app/icons/rdr-svg-icon/rdr-svg-icon.component';

@Component({
  selector: 'btn-icon',
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnIconComponent {
  @Input() icon: string;
  @Input() color: string;
  @Input() size: IconSize;
  @Input() pressed: boolean;
}
