import { Injectable } from '@angular/core';
import * as config from '@client-configs/lead-polling.json';
import {
  HasHotLeadGQL,
  LeadCallAnsweredGQL,
  LeadCallStartedGQL,
  LeadNode,
  LockHotLeadGQL,
  RejectLeadOnCallGQL,
  RejectLeadOnCallInput,
} from '@app/generated/graphql';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { FeatureToggleService, RdrFeature } from '@app/ui/services/feature-toggle.service';
import {
  filter,
  take,
  switchMap,
  delay,
  distinctUntilChanged,
  map,
  finalize,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, interval, Subscription, of, combineLatest } from 'rxjs';
import {
  AvailabilityService,
  AvailableStatus,
} from '@app/calls/services/availability/availability.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import { Router } from '@angular/router';

interface HotLeadPollConfig {
  timeoutMs: number;
  pollManualBreakDurationMs: number;
  pollAcceptLeadBreakDurationMs: number;
  activeCallIntervalMs: number;
}

const activeCallStartedAtKey = 'activeCallStartedAt';

@Injectable({
  providedIn: 'root',
})
export class HotLeadPollingService {
  private POLLING_DISMISSED_UNTIL_KEY = 'hot_lead_polling_stopped_until';
  private intervalSubscription: Subscription;
  private popupVisibility$$ = new BehaviorSubject<boolean>(false);
  private showUnsuccesfulLockMessage$$ = new BehaviorSubject<boolean>(false);
  private leadInValidation$$ = new BehaviorSubject<LeadNode | null>(null);

  activeCallStartedAt: number | null;

  constructor(
    private cognitoService: CognitoAuthService,
    private rdrAuthService: RdrAuthService,
    private featureService: FeatureToggleService,
    private hasHotLeadGQL: HasHotLeadGQL,
    private availabilityService: AvailabilityService,
    private lockLeadGraphql: LockHotLeadGQL,
    private callConfirmedGql: LeadCallAnsweredGQL,
    private callRejectedGql: RejectLeadOnCallGQL,
    private callStartedGql: LeadCallStartedGQL,
    private router: Router
  ) {
    window.addEventListener('storage', () => this.initStorageListener());
  }

  initStorageListener() {
    this.activeCallStartedAt = this.getActiveCallStartedAt();

    if (this.activeCallStartedAt) {
      this.hidePopup();
    }
  }

  getActiveCallStartedAt() {
    try {
      const item = localStorage.getItem(activeCallStartedAtKey);
      return item ? JSON.parse(item) : null;
    } catch (err) {
      return null;
    }
  }

  setActiveCallStartedAt() {
    localStorage.setItem(activeCallStartedAtKey, JSON.stringify(Date.now()));
  }

  removeActiveCallStartedAt() {
    localStorage.removeItem(activeCallStartedAtKey);
  }

  updateActiveCallStartedAt() {
    const pollConfig = config as HotLeadPollConfig;

    this.activeCallStartedAt = this.getActiveCallStartedAt();

    const expiredActiveCallInterval =
      this.activeCallStartedAt &&
      Date.now() - this.activeCallStartedAt > pollConfig.activeCallIntervalMs;

    if (expiredActiveCallInterval) {
      this.activeCallStartedAt = null;
      this.removeActiveCallStartedAt();
    }
  }

  get hotPopupAvailable$() {
    return this.popupVisibility$$.asObservable();
  }

  get showUnsuccesfulLockMessage$() {
    return this.showUnsuccesfulLockMessage$$.asObservable();
  }

  get leadInValidation$() {
    return this.leadInValidation$$.asObservable();
  }

  initPoller() {
    const session$ = this.cognitoService.hasCognitoSession().pipe(
      filter((val) => Boolean(val)),
      take(1),
      switchMap(() => this.rdrAuthService.getUser())
    );
    const availability$ = this.availabilityService.availableStatus$.pipe(distinctUntilChanged());

    combineLatest([session$, availability$]).subscribe(([, availability]) => {
      if (
        this.cognitoService.isSales &&
        this.featureService.isFeatureEnabled(RdrFeature.hot_lead_polling) &&
        availability === AvailableStatus.Available
      ) {
        const pollingDismissedUntil = localStorage.getItem(this.POLLING_DISMISSED_UNTIL_KEY) || 0;
        if (!pollingDismissedUntil || new Date(+pollingDismissedUntil) < new Date()) {
          this.startPolling();
        } else {
          const pollingDelay = new Date(+pollingDismissedUntil).getTime() - new Date().getTime();
          this.delayPolling(pollingDelay);
        }
      } else if (availability !== AvailableStatus.Available) {
        this.stopPolling();
      }
    });
  }

  dismiss() {
    this.stopPolling('pollManualBreakDurationMs');
  }

  tryLockLead() {
    return this.lockLeadGraphql
      .mutate()
      .pipe(
        map(({ data }) => data?.lockHotLead?.lead as LeadNode),
        tap((lead) => {
          if (!lead) {
            this.showUnsuccesfulLockMessage$$.next(true);
          } else {
            this.stopPolling('pollAcceptLeadBreakDurationMs');
            window.open(`/call?id=${lead?.id}`, '_blank');
          }
        }),
        finalize(() => this.popupVisibility$$.next(false))
      )
      .subscribe();
  }

  lockLeadForValidation(lead: LeadNode): void {
    this.leadInValidation$$.next(lead);
  }

  hideUnsuccesfulLockMessage() {
    this.showUnsuccesfulLockMessage$$.next(false);
  }

  callRejected(input: RejectLeadOnCallInput) {
    this.callRejectedGql.mutate({ input }).subscribe();
  }

  callStarted(leadId: string, callId: string) {
    return this.callStartedGql.mutate({ leadId, callId });
  }

  callConfirmed(leadId: string, withCall: boolean) {
    if (!withCall) {
      this.leadInValidation$$.next(null);
    }

    return this.callConfirmedGql.mutate({ leadId }).pipe(
      tap(() => {
        void this.router.navigate([`leads/${leadId}`]);
      })
    );
  }

  callFinished() {
    this.leadInValidation$$.next(null);
  }

  private startPolling() {
    const pollConfig = config as HotLeadPollConfig;
    localStorage.removeItem(this.POLLING_DISMISSED_UNTIL_KEY);

    this.updateActiveCallStartedAt();

    if (!this.activeCallStartedAt) {
      this.checkHotLead();
    }

    this.intervalSubscription?.unsubscribe();
    this.intervalSubscription = interval(pollConfig.timeoutMs)
      .pipe(withLatestFrom(this.leadInValidation$$))
      .subscribe(([, leadInValidation]) => {
        this.updateActiveCallStartedAt();

        if (
          !this.checkIfIntervalHasBeenStopped() &&
          !leadInValidation &&
          !this.activeCallStartedAt
        ) {
          this.checkHotLead();
        }
      });
  }

  private checkIfIntervalHasBeenStopped() {
    const pollingDismissedUntil = localStorage.getItem(this.POLLING_DISMISSED_UNTIL_KEY);
    if (pollingDismissedUntil && new Date(+pollingDismissedUntil) > new Date()) {
      this.intervalSubscription?.unsubscribe();
      this.hidePopup();
      const pollingDelay = new Date(+pollingDismissedUntil).getTime() - new Date().getTime();
      this.delayPolling(pollingDelay);
      return true;
    }
    return false;
  }

  private checkHotLead() {
    this.availabilityService.availableStatus$
      .pipe(take(1), withLatestFrom(this.leadInValidation$$))
      .subscribe(([availability, leadInValidation]) => {
        if (availability === AvailableStatus.Available && !leadInValidation) {
          this.hasHotLeadGQL.fetch().subscribe(({ data }) => {
            this.showUnsuccesfulLockMessage$$.next(false);
            this.popupVisibility$$.next(data.hasHotLead);
          });
        }
      });
  }

  private stopPolling(key?: keyof HotLeadPollConfig) {
    this.intervalSubscription?.unsubscribe();
    this.hidePopup();

    if (key) {
      const pollConfig = config as HotLeadPollConfig;
      const pollingStoppedUntil = Date.now() + pollConfig[key];
      localStorage.setItem(this.POLLING_DISMISSED_UNTIL_KEY, pollingStoppedUntil.toString());
      this.delayPolling(pollConfig[key]);
    }
  }

  private delayPolling(pollingDelay: number) {
    of(true)
      .pipe(
        delay(pollingDelay),
        switchMap(() => this.availabilityService.availableStatus$)
      )
      .subscribe((status) => {
        if (status === AvailableStatus.Available) {
          this.startPolling();
        }
      });
  }

  private hidePopup() {
    this.popupVisibility$$.next(false);
  }
}
