import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';

@Component({
  selector: 'typeahead-value-view',
  templateUrl: './typeahead-value-view.component.html',
  styleUrls: ['./typeahead-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeaheadValueViewComponent {
  @Input() value: DropdownItem;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() emitFullObject?: boolean;
}
