import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortFullName',
})
export class ShortFullNamePipe implements PipeTransform {
  transform(fullName = '') {
    return fullName
      .split(' ')
      .map((s, i) => (i ? s.substring(0, 1).toUpperCase() : s))
      .join(' ');
  }
}
