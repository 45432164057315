import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldComponent } from '@app/formly/types/basefield/basefield.component';

@Component({
  selector: 'text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTextComponent extends BasefieldComponent {}
