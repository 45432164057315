<form [formGroup]="formGroup" (ngSubmit)="submitFormV2()" (keydown.enter)="$event.preventDefault()">
  <div class="d-flex flex-column">
    <div class="d-flex text-jazlyn-700">
      <div class="col-4">
        <typography-v2 look="text-semibold" class="text-black">Flight option</typography-v2>
      </div>

      <div class="d-flex flex-column align-items-end col-2">
        <typography-v2 look="small-bold">Price</typography-v2>
        <typography-v2 look="small">per one/group</typography-v2>
      </div>

      <div class="d-flex flex-column align-items-end col-2">
        <typography-v2 look="small-bold">Cost</typography-v2>
        <typography-v2 look="small">per one/group</typography-v2>
      </div>

      <div class="d-flex flex-column align-items-end col-2">
        <typography-v2 look="small-bold">Card processing fee</typography-v2>
        <typography-v2 look="small">per one/group</typography-v2>
      </div>

      <div class="d-flex flex-column align-items-end col-2">
        <typography-v2 look="small-bold">Gross profit</typography-v2>
        <typography-v2 look="small">per one/group</typography-v2>
      </div>
    </div>

    <div class="d-flex v2-mt-3">
      <div class="col-5">
        <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>
      </div>

      <div class="d-flex justify-content-center col-1 flex-column align-items-end">
        <typography-v2 dataId="sold-amount-per-adult">
          /{{ (formModel.numberOfAdults * formModel.soldAmountPerAdult) | rdrCurrency }}
        </typography-v2>
        <typography-v2
          [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
          class="v2-mt-8"
          dataId="sold-amount-per-child"
        >
          /{{ (formModel.numberOfChildren * formModel.soldAmountPerChild) | rdrCurrency }}
        </typography-v2>
        <typography-v2
          [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
          class="v2-mt-8"
          dataId="sold-amount-per-infant"
        >
          /{{ (formModel.numberOfInfants * formModel.soldAmountPerInfant) | rdrCurrency }}
        </typography-v2>
      </div>

      <div class="d-flex justify-content-center col-2 flex-column align-items-end">
        <div class="d-flex">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.costPerAdult; else loading"
            dataId="cost-per-adult"
            >{{ orderPriceEstimateResponse?.costPerAdult | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.costAllAdults"
            dataId="cost-per-adults"
          >
            {{ orderPriceEstimateResponse?.costAllAdults | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.costPerChild; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="cost-per-child"
            >{{ orderPriceEstimateResponse?.costPerChild | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.costAllChildren"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="cost-per-children"
          >
            {{ orderPriceEstimateResponse?.costAllChildren | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.costPerInfant; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="cost-per-infant"
            >{{ orderPriceEstimateResponse?.costPerInfant | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.costAllInfants"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="cost-per-infants"
          >
            {{ orderPriceEstimateResponse?.costAllInfants | rdrCurrency }}
          </typography-v2>
        </div>
      </div>

      <div class="d-flex justify-content-center col-2 flex-column align-items-end">
        <div class="d-flex">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeAdult; else loading"
            dataId="card-processing-fee-adult"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeAdult | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeAdults"
            dataId="card-processing-fee-adults"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeAdults | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeChild; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="card-processing-fee-child"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeChild | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeChildren"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="card-processing-fee-children"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeChildren | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeInfant; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="card-processing-fee-infant"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeInfant | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.cardProcessingFeeInfants"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="card-processing-fee-infants"
          >
            {{ orderPriceEstimateResponse?.cardProcessingFeeInfants | rdrCurrency }}
          </typography-v2>
        </div>
      </div>

      <div class="d-flex justify-content-center col-2 flex-column align-items-end">
        <div class="d-flex">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitPerAdult; else loading"
            dataId="gross-profit-per-adult"
          >
            {{ orderPriceEstimateResponse?.grossProfitPerAdult | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitAllAdults"
            dataId="gross-profit-all-adults"
          >
            {{ orderPriceEstimateResponse?.grossProfitAllAdults | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitPerChild; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="gross-profit-per-child"
          >
            {{ orderPriceEstimateResponse?.grossProfitPerChild | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitAllChildren"
            [ngClass]="{ 'hidden': !formModel.numberOfChildren }"
            dataId="gross-profit-all-children"
          >
            {{ orderPriceEstimateResponse?.grossProfitAllChildren | rdrCurrency }}
          </typography-v2>
        </div>

        <div class="d-flex v2-mt-8">
          <typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitPerInfant; else loading"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="gross-profit-per-infant"
          >
            {{ orderPriceEstimateResponse?.grossProfitPerInfant | rdrCurrency }}/</typography-v2
          ><typography-v2
            *ngIf="orderPriceEstimateResponse?.grossProfitAllInfants"
            [ngClass]="{ 'hidden': !formModel.numberOfInfants }"
            dataId="gross-profit-all-infants"
          >
            {{ orderPriceEstimateResponse?.grossProfitAllInfants | rdrCurrency }}
          </typography-v2>
        </div>
      </div>
    </div>

    <ng-container *ngIf="orderPriceEstimateResponse?.additionalSoldAmounts?.length">
      <div *ngFor="let additionalSoldAmount of orderPriceEstimateResponse?.additionalSoldAmounts">
        <hr class="v2-my-3" />

        <div class="d-flex">
          <typography-v2 look="text-semibold" class="col-3" dataId="additional-sa-type"
            >{{ additionalSoldAmount.type | label }}</typography-v2
          >
          <div class="d-flex justify-content-end col-2"></div>
          <div class="d-flex justify-content-end flex-1" data-id="additional-sa-amount">
            {{ additionalSoldAmount.amount | rdrCurrency }}
          </div>
          <div class="d-flex justify-content-end flex-2"></div>
          <div class="d-flex justify-content-end flex-2"></div>
          <div class="d-flex justify-content-end flex-2">
            {{ additionalSoldAmount.amount | rdrCurrency }}
          </div>
        </div>
      </div>
    </ng-container>

    <hr class="v2-mt-3" />

    <div class="d-flex v2-mt-3">
      <div class="col-5">
        <typography-v2 look="text-semibold">Total</typography-v2>
      </div>

      <div class="d-flex justify-content-end col-1">
        <typography-v2
          *ngIf="orderPriceEstimateResponse?.totalPrice; else loading"
          look="text-semibold"
          dataId="total-price"
        >
          {{ orderPriceEstimateResponse?.totalPrice | rdrCurrency }}
        </typography-v2>
      </div>

      <div class="d-flex justify-content-end col-2">
        <typography-v2
          *ngIf="orderPriceEstimateResponse?.totalCost; else loading"
          look="text-semibold"
          dataId="total-cost"
        >
          {{ orderPriceEstimateResponse?.totalCost | rdrCurrency }}
        </typography-v2>
      </div>

      <div class="d-flex justify-content-end col-2">
        <typography-v2
          *ngIf="orderPriceEstimateResponse?.totalCardProcessingFee; else loading"
          look="text-semibold"
          dataId="total-card-processing-fee"
        >
          {{ orderPriceEstimateResponse?.totalCardProcessingFee | rdrCurrency }}
        </typography-v2>
      </div>

      <div class="d-flex justify-content-end col-2">
        <typography-v2
          *ngIf="orderPriceEstimateResponse?.totalGrossProfit; else loading"
          look="text-semibold"
          dataId="total-gross-profit"
        >
          {{ orderPriceEstimateResponse?.totalGrossProfit | rdrCurrency }}
        </typography-v2>
      </div>
    </div>

    <div
      class="d-flex align-items-center warning v2-p-3 v2-mt-3"
      *ngIf="order.hasMinimalMarkup"
      data-id="markup"
    >
      <rdr-svg-icon key="alert-v-2" class="v2-mr-2" color="var(--color-coral)"></rdr-svg-icon>
      <typography-v2>Note there's a minimum markup requirement</typography-v2>
    </div>

    <div *ngIf="!this.readonly" class="v2-mt-5 d-flex justify-content-end">
      <button-v2 [disabled]="formGroup.pristine" buttonType="submit" formButtonState data-id="save">
        <typography-v2 look="caption-bold">Save</typography-v2>
      </button-v2>
    </div>
  </div>
</form>

<ng-template #loading>
  <skeleton-loader-small [width]="50" [height]="16"></skeleton-loader-small>
</ng-template>
