import { Pipe, PipeTransform } from '@angular/core';
import { TotalTravelTimeFragment } from '@app/generated/graphql';

@Pipe({
  name: 'travelTimeFormat',
})
export class TravelTimeFormatPipe implements PipeTransform {
  transform(value: TotalTravelTimeFragment): string {
    return this.formatTime(value);
  }

  formatTime(time: TotalTravelTimeFragment): string {
    let result = '';

    if (time.days) {
      result += `${time.days}d`;
    }
    if (time.hours) {
      if (result) {
        result += ' ';
      }
      result += `${time.hours}h`;
    }
    if (time.minutes) {
      if (result) {
        result += ' ';
      }
      result += `${time.minutes}m`;
    }
    return result;
  }
}
