import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ClientEdge } from '@generated/graphql';

@Component({
  selector: 'my-clients-block',
  templateUrl: './my-clients-block.component.html',
  styleUrls: ['./my-clients-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyClientsBlockComponent {
  @Input() clients?: ClientEdge[];
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
}
