import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPictureComponent {
  @Input() picture?: string;
}
