<context-menu-v2 class="d-flex flex-column v2-p-4">
  <div class="comments" id="search-task-comments">
    <first-system-comment [searchTask]="searchTask" class="v2-mb-2"></first-system-comment>

    <ng-container *ngFor="let comment of searchTask?.comments?.edges | extractNodes">
      <div class="d-flex v2-mb-2">
        <ng-container *ngIf="comment.createdByUser; else systemComment">
          <user-picture-v2
            size="semi"
            [picture]="comment.createdByUser?.picture"
            [label]="comment.createdByUser?.fullName"
          ></user-picture-v2>

          <typography-v2 class="v2-ml-2 v2-mt-2 description" dataId="description"
            >{{ comment.description }}</typography-v2
          >
        </ng-container>

        <ng-template #systemComment>
          <user-picture-v2 size="semi" picture="/assets/svg/user-icon-voyagu.svg"></user-picture-v2>

          <typography-v2 class="v2-ml-2 v2-mt-2 description" look="small" dataId="description"
            >{{ comment.description }}</typography-v2
          >
        </ng-template>

        <typography-v2
          look="small"
          class="text-black-50 ms-auto mb-auto v2-mt-2 nowrap"
          dataId="created_at"
        >
          {{ comment.createdAt | rdrDate: 'short_full_datetime' }}
        </typography-v2>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="searchTask?.status === ChangeSearchTaskStatusType.SearchingForFlights || searchTask?.status === ChangeSearchTaskStatusType.RequestInitiated"
    class="position-relative"
  >
    <input
      (keydown.enter)="sendTaskComment()"
      [formControl]="formControl"
      data-id="comment_input"
      type="text"
      class="task-input v2-p-2 v2-pr-9"
      placeholder="Write a comment..."
    />

    <div class="d-flex position-absolute actions cursor-pointer">
      <rdr-svg-icon
        *ngIf="formControl.value?.length"
        (click)="sendTaskComment(); $event.stopPropagation()"
        data-id="send_comment"
        color="var(--color-jazlyn-700)"
        size="lg"
        key="send"
      ></rdr-svg-icon>
      <ng-container *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead]"
        ><rdr-svg-icon
          *ngIf="!formControl.value?.length"
          (click)="showedActions = !showedActions; $event.stopPropagation()"
          data-id="menu"
          color="var(--color-jazlyn-700)"
          size="lg"
          key="more"
        ></rdr-svg-icon
      ></ng-container>

      <context-menu-v2
        *ngIf="showedActions"
        (clickOutside)="showedActions = false"
        class="actions-menu"
      >
        <dropdown-item-v2
          [modalTpl]="cancelTaskModal"
          showModalOnClick
          class="text-red"
          data-id="cancel_task"
        >
          <rdr-svg-icon key="close" class="v2-mr-1"></rdr-svg-icon>
          Cancel task
        </dropdown-item-v2>
      </context-menu-v2>
    </div>
  </div>

  <ng-container *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead]">
    <ng-container *ngIf="searchTask?.status === ChangeSearchTaskStatusType.FlightOptionsFound">
      <ng-container *ngTemplateOutlet="closingPanel"></ng-container>
    </ng-container>

    <ng-container *ngIf="searchTask?.status === ChangeSearchTaskStatusType.Closed && !feedback">
      <ng-container *ngTemplateOutlet="feedbackPanel"></ng-container>
    </ng-container>

    <ng-container *ngIf="searchTask | searchTaskShowedReasonsPanel: feedback: selectedReasons">
      <ng-container *ngTemplateOutlet="reasonsPanel"></ng-container>
    </ng-container>

    <ng-container *ngIf="searchTask | searchTaskShowedReopenPanel: feedback: selectedReasons">
      <ng-container *ngTemplateOutlet="reopenPanel"></ng-container>
    </ng-container>
  </ng-container>
</context-menu-v2>

<ng-template #cancelTaskModal>
  <modal-v2 header="Cancel task">
    <typography-v2
      >Are you sure you want to cancel the<br />task? In the future you will be able to<br />open it
      again</typography-v2
    >

    <div class="d-flex justify-content-end v2-mt-5">
      <button-v2
        (click)="changeTaskStatus(ChangeSearchTaskStatusType.Cancelled)"
        type="destructive"
        data-id="cancel"
        >Cancel task</button-v2
      >
    </div>
  </modal-v2>
</ng-template>

<ng-template #closingPanel>
  <div class="v2-p-4 d-flex align-items-center panel">
    <typography-v2 class="text-black"
      >If you are satisfied with the search<br />result, you can close the task. Do
      it?</typography-v2
    >

    <div class="d-flex ms-auto">
      <button-v2
        (click)="changeTaskStatus(ChangeSearchTaskStatusType.Closed)"
        data-id="button_yes"
        type="tertiary"
        class="v2-mr-2"
        formButtonState
        >Yes</button-v2
      >
      <button-v2
        (click)="changeTaskStatus(ChangeSearchTaskStatusType.RequestInitiated)"
        data-id="button_no"
        type="tertiary"
        formButtonState
        >No</button-v2
      >
    </div>
  </div>
</ng-template>

<ng-template #feedbackPanel>
  <div class="v2-p-4 d-flex align-items-center panel">
    <div class="d-flex flex-column">
      <typography-v2 class="text-black">How do you like the results?</typography-v2>
      <typography-v2 class="text-black" look="small"
        >Your rating is anonymous,<br />it won`t be visible to a search specialist</typography-v2
      >
    </div>

    <div class="d-flex ms-auto">
      <button-v2
        (click)="$event.stopPropagation(); setFeedback(FeedbackTypeEnum.Bad)"
        type="tertiary"
        class="v2-mr-2"
        data-id="bad"
        formButtonState
      >
        <typography-v2 look="section-title">👎</typography-v2>
      </button-v2>
      <button-v2
        (click)="$event.stopPropagation(); setFeedback(FeedbackTypeEnum.Neutral)"
        type="tertiary"
        class="v2-mr-2"
        data-id="neutral"
        formButtonState
      >
        <typography-v2 look="section-title">😐</typography-v2>
      </button-v2>
      <button-v2
        (click)="$event.stopPropagation(); setFeedback(FeedbackTypeEnum.Good)"
        type="tertiary"
        data-id="good"
        formButtonState
      >
        <typography-v2 look="section-title">👍</typography-v2>
      </button-v2>
    </div>
  </div>
</ng-template>

<ng-template #reasonsPanel>
  <div class="v2-p-4 d-flex flex-column panel">
    <typography-v2 class="text-black">What could be improved?</typography-v2>

    <div class="d-flex flex-column v2-mt-2">
      <ng-container *ngIf="reasons$ | async as reasons; else loadingReasons">
        <checkbox-v2
          *ngFor="let reason of reasons; let last = last"
          (check)="setReason(reason.id)"
          [label]="reason.reason"
          [ngClass]="{ 'v2-mb-1': !last }"
          data-id="reason"
        ></checkbox-v2>
      </ng-container>

      <ng-template #loadingReasons>
        <skeleton-loader-small class="v2-mb-1" [height]="20" [width]="160"></skeleton-loader-small>
        <skeleton-loader-small class="v2-mb-1" [height]="20" [width]="140"></skeleton-loader-small>
        <skeleton-loader-small class="v2-mb-1" [height]="20" [width]="100"></skeleton-loader-small>
        <skeleton-loader-small [height]="20" [width]="120"></skeleton-loader-small>
      </ng-template>
    </div>

    <div class="d-flex v2-mt-5">
      <button-v2
        (click)="$event.stopPropagation(); selectedReasonsIds.size && sendFeedback()"
        [disabled]="!selectedReasonsIds.size"
        type="tertiary"
        data-id="thanks"
        formButtonState
        >Thanks</button-v2
      >
    </div>
  </div>
</ng-template>

<ng-template #reopenPanel>
  <search-task-reopen-panel
    [onlyReopenButton]="onlyReopenButton"
    (reopenTask)="reopenTask.emit()"
  ></search-task-reopen-panel>
</ng-template>
