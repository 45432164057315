import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { EmailMessageEdge, GetEmailMessagesQueryVariables } from '@app/generated/graphql';
import { EMAILS_LIMIT } from '@app/shared/constants';
import { EmailMessagesService } from '@app/shared/services/email-messages/email-messages.service';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'email-messages',
  templateUrl: './email-messages.component.html',
  styleUrls: ['./email-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailMessagesComponent implements OnInit {
  emailMessages$: Subscription;
  emailMessages: EmailMessageEdge[] = [];

  params: GetEmailMessagesQueryVariables;
  previousEmailMessagesLength = 0;

  @Input() leadId: string;

  constructor(
    private emailMessagesService: EmailMessagesService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.params = {
      filters: {
        byLeadId: this.leadId,
      },
      last: EMAILS_LIMIT,
      before: undefined,
    };

    this.getEmailMessages();
  }

  getEmailMessages(): void {
    this.emailMessages$?.unsubscribe();

    this.emailMessages$ = this.emailMessagesService
      .getEmailMessages(this.params)
      .pipe(
        tap(({ data }) => {
          this.emailMessages = (data.emailMessages?.edges || []).concat(
            this.emailMessages
          ) as EmailMessageEdge[];
          this.params.before = data.emailMessages?.pageInfo.startCursor;
          this.cdRef.detectChanges();
          setTimeout(this.scrollToLast.bind(this));
        })
      )
      .pipe(UntilDestroyed(this))
      // TODO fixme: subsribe with async pipe in templ without rerenders
      .subscribe();
  }

  scrollToLast(): void {
    const emailMessages = document.querySelectorAll('[id="email-message"]');
    emailMessages[emailMessages.length - this.previousEmailMessagesLength - 1]?.scrollIntoView();
    this.previousEmailMessagesLength = emailMessages.length - 1;
  }

  onScroll(e: Event) {
    if ((e.target as HTMLElement)?.scrollTop === 0) {
      this.params.before && this.getEmailMessages();
    }
  }
}
