import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DropdownItem } from '../dropdown.model';
import { DropdownAbstractClass } from '@ui/components/dropdown-abstract';
import { ButtonSize, ButtonType } from '@ui/components/button/btn.component';
import { RdrDropdownPositioning } from '@ui/components/status-picker-abstract';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent
  extends DropdownAbstractClass
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() options: DropdownItem[];
  @Input() selected?: DropdownItem;
  @Input() placeholder: string;
  @Input() look: DropdownType = DropdownType.button;
  @Input() isOpen = false;
  @Input() disabledDropdown: boolean | undefined = false;
  @Input() itemType = 'default';
  @Input() groupedFields = false;
  @Input() stopPropagation = false;

  @HostBinding('class') @Input() positioning?: RdrDropdownPositioning;

  @Output() itemClick: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  btnSize = ButtonSize;
  btnType = ButtonType;
  dropdownType = DropdownType;

  ngOnInit(): void {
    if (this.groupedFields) {
      this.initGroups();
    }

    if (this.selected) {
      this.writeValue(this.selected);
    }
  }

  initGroups(): void {
    const groups: { [key: string]: DropdownItem[] } = {};

    this.options.forEach((option) => {
      const groupName = option.groupBy as string;
      delete option['groupBy'];

      groups[groupName] = groups[groupName] || [];
      groups[groupName].push(option);
    });

    let result: DropdownItem[] = [];
    Object.keys(groups).forEach((groupBy: string) => {
      result.push({ groupBy });
      result = result.concat(groups[groupBy]);
    });

    this.options = result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen?.currentValue) {
      setTimeout(() => {
        this.toggleCollapsed();
      });
    }
  }

  writeValue(selectedOption: DropdownItem): void {
    this.selected = selectedOption;
  }

  onTouched: any = () => noop;

  onChange: any = () => noop;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onItemClick(event: MouseEvent | null, item: DropdownItem): void {
    this.itemClick.emit(item);
    event && this.toggleCollapsed(event);
    this.stopPropagation && event?.stopPropagation();
  }
}

export enum DropdownType {
  button = 'button',
  link = 'link',
  transparent = 'transparent',
}
