<form [formGroup]="formGroup" (ngSubmit)="submitFormV2()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [fields]="fields" [model]="formModel"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2
      formButtonState
      [disabled]="!formGroup.valid || formGroup.pristine"
      buttonType="submit"
      data-id="createUser"
    >
      <typography-v2 look="caption-bold">{{ entityId ? 'Save' : 'Create User' }}</typography-v2>
    </button-v2>
  </div>
</form>
