import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyV2Component } from './components/typography-v2/typography-v2.component';
import { ModalV2Component } from './components/modal-v2/modal-v2.component';
import { ButtonIconV2Component } from './components/button-icon-v2/button-icon-v2.component';
import { ButtonV2Component } from './components/button-v2/button-v2.component';
import { InnerLayoutV2Component } from './components/inner-layout-v2/inner-layout-v2.component';
import { DropdownV2Component } from './components/dropdown-v2/dropdown-v2.component';
import { DropdownItemV2Component } from './components/dropdown-item-v2/dropdown-item-v2.component';
import { SkeletonLoadingDropdownComponent } from './components/skeleton-loading-dropdown/skeleton-loading-dropdown.component';
import { SkeletonLoaderSmallComponent } from './components/skeleton-loader-small/skeleton-loader-small.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputIconContainerComponent } from './components/input-icon-container/input-icon-container.component';
import { ContextMenuV2Component } from './components/context-menu-v2/context-menu-v2.component';
import { ColoredStatusV2Component } from './components/colored-status-v2/colored-status-v2.component';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { ViewCellComponent } from './components/view-cell/view-cell.component';
import { ShowModalOnClickDirective } from './directives/show-modal-on-click.directive';
import { CopyToClipboardV2Component } from './components/copy-to-clipboard-v2/copy-to-clipboard-v2.component';
import { BadgeV2Component } from './components/badge-v2/badge-v2.component';
import { UserPictureV2Component } from './components/user-picture-v2/user-picture-v2.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { PagePreviewPopupComponent } from './components/page-preview-popup/page-preview-popup.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { DueDateBadgeV2Component } from './components/due-date-badge-v2/due-date-badge-v2.component';
import { HeaderBlockComponent } from './components/header-block/header-block.component';
import { InputSelectAllDirective } from './directives/input-select-all.directive';
import { ButtonUserPictureV2Component } from './components/button-user-picture-v2/button-user-picture-v2.component';
import { SearchInputV2Component } from './search-input-v2/search-input-v2.component';
import { PageHeaderV2Component } from './components/page-header-v2/page-header-v2.component';
import { TypeaheadV3Component } from './components/typeahead-v3/typeahead-v3.component';
import { FilterByLabelPipe } from './components/typeahead-v3/filter-by-group-label.pipe';
import { GroupByFieldPipe } from './components/typeahead-v3/group-by-field.pipe';
import { DraggableComponent } from './components/draggable/draggable.component';
import { CheckboxV2Component } from './components/checkbox-v2/checkbox-v2.component';
import { IconsModule } from '@app/icons/icons.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { RadioButtonsV2Component } from './components/radio-buttons-v2/radio-buttons-v2.component';
import { ChipsV2Component } from './components/chips-v2/chips-v2.component';
import { ConfirmModalV2Component } from './components/confirm-modal-v2/confirm-modal-v2.component';
import { ConfirmOnDeleteDirective } from './directives/confirm-delete.directive';
import { ThreeDotsLoaderComponent } from './components/three-dots-loader/three-dots-loader.component';
import { SliderComponent } from './components/slider/slider.component';
import { ShowBuildVersionOnHoverDirective } from './directives/show-build-version-on-hover.directive';

const declarations = [
  TypographyV2Component,
  ButtonV2Component,
  ModalV2Component,
  ButtonIconV2Component,
  InnerLayoutV2Component,
  DropdownV2Component,
  DropdownItemV2Component,
  SkeletonLoaderSmallComponent,
  SkeletonLoadingDropdownComponent,
  InputIconContainerComponent,
  ContextMenuV2Component,
  ColoredStatusV2Component,
  ViewCellComponent,
  ShowModalOnClickDirective,
  CopyToClipboardV2Component,
  BadgeV2Component,
  UserPictureV2Component,
  SwitchButtonComponent,
  PagePreviewPopupComponent,
  StatusBadgeComponent,
  DueDateBadgeV2Component,
  HeaderBlockComponent,
  InputSelectAllDirective,
  ButtonUserPictureV2Component,
  SearchInputV2Component,
  PageHeaderV2Component,
  TypeaheadV3Component,
  FilterByLabelPipe,
  GroupByFieldPipe,
  DraggableComponent,
  CheckboxV2Component,
  ClickOutsideDirective,
  RadioButtonsV2Component,
  ChipsV2Component,
  ConfirmModalV2Component,
  ConfirmOnDeleteDirective,
  ThreeDotsLoaderComponent,
  SliderComponent,
  ShowBuildVersionOnHoverDirective,
];

@NgModule({
  declarations: [declarations],
  exports: [declarations],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    IconsModule,
    TooltipModule,
  ],
  providers: [FilterByLabelPipe],
})
export class UiV2Module {}
