<input
  (accept)="onAccept()"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [attr.placeholder]="to.placeholder || ''"
  [attr.data-id]="field.key"
  [imask]="imaskNumeric"
  [class.disabled]="to.disabled"
  [unmask]="true"
  autocomplete="off"
  class="numeric-input"
/>
