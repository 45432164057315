import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import lodash from 'lodash';
import { getModelDiff, purifyModel } from '@shared/utils/model-utils';
import { Scalars } from '@generated/graphql';
import { UntypedFormGroup } from '@angular/forms';
import { RdrFormlyFieldConfig } from '@app/formly/types/basefield/basefield.component';
import { GenericNode, GraphqlID } from '@app/shared/types';
import { merge, Observable, pipe, switchMap } from 'rxjs';
import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
import { AppInjector } from '@app/app-injector.service';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { FormLoadingStateService } from '@shared/services/form-loading-state.service';
import { MutationResult } from 'apollo-angular';

export declare interface RadarForm {
  errorsBlockId?: string;

  scrollToErrors?(): void;
}

export interface FormSubmitData<T = GenericNode | any> {
  data: T;
  id?: GraphqlID;
}

export type MultiFormErrors = Record<string, string[]>;

@Directive()
export abstract class RadarFormAbstract implements OnInit, OnChanges, RadarForm, AfterViewInit {
  /**
   * V2 forms: whether the Delete button is enabled
   */
  @Input() allowDelete: boolean;

  /**
   * V2 forms: delete button handler
   */
  @Output() deleteEntity = new EventEmitter();

  /**
   * EntityId is passed back into the submit handler
   * If set, acts like isEditing = true
   */
  @Input() entityId?: Scalars['ID'];

  /**
   * If true, submits the form on every input change.
   * If false, submits when the 'submit' event of the form occurs.
   *
   * Use when no entityId can be passed
   */
  @Input() isEditing = false;

  @Input() onCancel: () => void;

  /**
   * Form model
   */
  @Input() data?: any;

  @Input() errors: string[];

  @Input() readonly = false;

  @Input() fetching: boolean;

  @Input() initials: Record<string, unknown>;

  /**
   * whether the form data should be pulled in on each @Input() data change
   */
  @Input() autoUpdate = true;

  @Output() formSubmit: EventEmitter<FormSubmitData> = new EventEmitter<FormSubmitData>();

  formModel: any = {};

  /**
   * The modelBackup is used to restore form's state to it's original
   */
  modelBackup: GenericNode;
  fields: RdrFormlyFieldConfig[];
  errorsBlockId?: string;
  formGroup = new UntypedFormGroup({});

  formDataSubject$: Observable<GenericNode>;

  newState: GenericNode = {};

  ngOnInit(): void {
    if (this.readonly) {
      this.resetForm();
      return;
    }

    if (!this.data) {
      this.data = {};
    }

    if (this.data) {
      this.modelBackup = purifyModel(this.data);
      this.resetForm();
    }
  }

  ngAfterViewInit() {
    if (this.readonly) {
      setTimeout(() => this.formGroup.disable());
      return;
    }

    const keys = Object.keys(this.formGroup.controls);
    const observables = keys.map((key) => {
      return this.formGroup.controls[key].valueChanges.pipe(
        startWith(this.formGroup.controls[key].value),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((val) => {
          return {
            key,
            val,
          };
        })
      );
    });

    merge(...observables).subscribe((val) => {
      this.newState[val.key] = val.val;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly?.currentValue === true) {
      this.formGroup.disable();
      return;
    }

    if (this.autoUpdate && changes.data) {
      this.modelBackup = purifyModel(this.data);
      this.resetForm();
    }
    if (changes.errors?.currentValue) {
      this.scrollToErrors();
    }
  }

  resetForm(): void {
    this.formModel = lodash.cloneDeep(this.data);
  }

  isInEditMode(): boolean {
    return !!this.entityId || this.isEditing;
  }

  modelChanged(data: any) {
    if (!this.isInEditMode() || this.readonly) {
      return;
    }

    const model = purifyModel(data);

    if (!lodash.isEqual(model, this.modelBackup)) {
      const diff = getModelDiff(model, this.modelBackup);
      if (!this.formGroup.valid) {
        return;
      }

      this.formSubmit.emit({ data: diff, id: this.entityId });

      this.modelBackup = {
        ...this.modelBackup,
        ...diff,
      };
    }
  }

  submitForm() {
    if (!this.formGroup.valid) {
      return;
    }
    const model = purifyModel(this.formModel);
    this.formSubmit.emit({ data: model });
  }

  submitFormV2() {
    const formLoadingStateService = AppInjector.injector.get(FormLoadingStateService);
    const model = purifyModel(this.formModel);

    if (!lodash.isEqual(model, this.modelBackup || {})) {
      const diff = getModelDiff(model, this.modelBackup || {});

      this.formSubmit.emit({ data: diff, id: this.entityId });
      formLoadingStateService.isLoading$.next(true);
    }
  }

  /**
   * Override this in derived class to handle scroll for specific page and its
   * header
   */
  scrollToErrors() {}

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }
}

export function setFormLoadingState(isLoading: boolean) {
  const formLoadingStateService = AppInjector.injector.get(FormLoadingStateService);
  return pipe(
    map((resp: MutationResult | any) => {
      formLoadingStateService.isLoading$.next(isLoading);

      return resp;
    })
  );
}

export function hideFormModalIfNoErrors() {
  const formModalService = AppInjector.injector.get(ModalV2Service);

  return pipe(
    map((resp: MutationResult | any) => {
      if (!resp?.errors) {
        formModalService.hideModal();
      }

      return resp;
    }),
    switchMap((resp) => formModalService.onHidden().pipe(map(() => resp))),
    take(1)
  );
}
