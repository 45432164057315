import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyTypeaheadV2Component } from '@app/formly/types/v2/typeahead-v2/formly-typeahead-v2.component';

@Component({
  selector: 'airlines-v2',
  templateUrl: './airlines-v2.component.html',
  styleUrls: ['./airlines-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirlinesV2Component extends FormlyTypeaheadV2Component {}
