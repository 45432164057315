<card>
  <div class="d-flex flex-column">
    <copy-to-clipboard-v2 *ngIf="passenger.firstName" [value]="passenger.firstName">
      <typography-v2 tooltip="First Name" position="top" look="text-semibold" dataId="first-name"
        >{{ passenger.firstName }}</typography-v2
      >
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2
      *ngIf="passenger.middleName"
      [value]="passenger.middleName"
      class="v2-mt-1"
    >
      <typography-v2 tooltip="Middle Name" position="top" look="text-semibold" dataId="middle-name"
        >{{ passenger.middleName }}</typography-v2
      >
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2 *ngIf="passenger.lastName" [value]="passenger.lastName" class="v2-mt-1">
      <typography-v2 tooltip="Last Name" position="top" look="text-semibold" dataId="last-name"
        >{{ passenger.lastName }}</typography-v2
      >
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2
      *ngIf="passenger.gender"
      [value]="passenger.gender | label"
      class="text-black-50 v2-mt-1"
    >
      <typography-v2 look="caption" dataId="gender">{{ passenger.gender | label }}</typography-v2>
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2
      *ngIf="passenger.birthday"
      [value]="(passenger.birthday | rdrDate)!"
      class="v2-mt-1"
    >
      <typography-v2>{{ passenger.birthday | rdrDate }}</typography-v2>
    </copy-to-clipboard-v2>

    <ng-container *ngIf="passenger.emails?.length || passenger.phones?.length">
      <hr class="hr v2-my-3" />

      <copy-to-clipboard-v2 *ngFor="let email of passenger.emails" [value]="email" class="v2-mt-1">
        <a data-id="passenger-email" [attr.href]="'mailto:'+ email">
          <typography-v2>{{ email }}</typography-v2>
        </a>
      </copy-to-clipboard-v2>

      <copy-to-clipboard-v2 *ngFor="let phone of passenger.phones" [value]="phone" class="v2-mt-1">
        <a data-id="passenger-phone" [attr.href]="'tel:'+ phone">
          <typography-v2>{{ phone }}</typography-v2>
        </a>
      </copy-to-clipboard-v2>
    </ng-container>

    <hr
      *ngIf="passenger.passport?.number || passenger.passport?.country?.name || passenger.passport?.expiration"
      class="hr v2-my-3"
    />

    <copy-to-clipboard-v2 *ngIf="passenger.passport?.number" [value]="passenger.passport?.number!">
      <typography-v2 dataId="passport-num">{{ passenger.passport?.number }}</typography-v2>
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2
      *ngIf="passenger.passport?.country?.name"
      [value]="passenger.passport?.country?.name!"
      class="v2-mt-1"
    >
      <typography-v2 dataId="passport-country"
        >{{ passenger.passport?.country?.name }}</typography-v2
      >
    </copy-to-clipboard-v2>

    <copy-to-clipboard-v2
      *ngIf="passenger.passport?.expiration"
      [value]="(passenger.passport?.expiration | rdrDate)!"
      class="v2-mt-1"
    >
      <typography-v2 dataId="passport-exp"
        >{{ passenger.passport?.expiration | rdrDate }}</typography-v2
      >
    </copy-to-clipboard-v2>

    <ng-container *ngIf="passenger.passport?.fileDocument?.id">
      <hr class="hr v2-my-3" />

      <button-v2
        (click)="downloadDocument.emit(passenger.passport?.fileDocument?.id)"
        type="tertiary"
      >
        <rdr-svg-icon key="image" class="v2-mr-2"></rdr-svg-icon>
        Download Doc
      </button-v2>
    </ng-container>
  </div>
</card>
