import {
  AirlineAlliancesType,
  AirlineNode,
  AirportNode,
  BookingRevenueProductNode,
  SearchTaskFlightNode,
  SearchTaskNode,
} from '@app/generated/graphql';
import { TripType } from '../create-task.component';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';
import { mapEntityToDropdownItem, mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { mapAirlineToDD } from '@app/shared/services/airlines/airlines.service';
import { airportsDropDownMapper } from '@app/shared/services/airports/airports.service';

export const getReopenSearchTaskTripType = (searchTask: SearchTaskNode) => {
  const isMulti = (searchTask.flights?.edges.length || 0) > 1;
  const isRound = searchTask.flights?.edges[0].node?.returnDate;

  if (isMulti) {
    return TripType.Multi;
  }

  if (isRound) {
    return TripType.Round;
  }

  return TripType.Oneway;
};

export const getReopenFirstStepTaskData = (searchTask: SearchTaskNode) => {
  let data: FormSubmitData['data'] = {
    serviceClass: searchTask.serviceClass,
    numberOfAdults: searchTask.numberOfAdults,
    numberOfChildren: searchTask.numberOfChildren,
    numberOfInfants: searchTask.numberOfInfants,
    budgetFrom: searchTask.budgetFrom,
    budgetTo: searchTask.budgetTo,
  };

  const isMultiTrip = getReopenSearchTaskTripType(searchTask) === TripType.Multi;

  if (isMultiTrip) {
    const flights = searchTask.flights?.edges;

    if (!flights?.length) return data;

    const arrivalAirports = (node: SearchTaskFlightNode) =>
      node.arrivalAirports?.edges.map(({ node }) => airportsDropDownMapper(node as AirportNode));

    const departureAirports = (node: SearchTaskFlightNode) =>
      node.departureAirports?.edges.map(({ node }) => airportsDropDownMapper(node as AirportNode));

    data = Object.assign(data, {
      flights: flights.map(({ node }) => ({
        departureDate: node?.departureDate,
        returnDate: node?.returnDate,
        departureFlexibility: node?.departureFlexibility,
        returnFlexibility: node?.returnFlexibility,
        arrivalAirports: arrivalAirports(node as SearchTaskFlightNode),
        departureAirports: departureAirports(node as SearchTaskFlightNode),
      })),
    });
  } else {
    const flight = searchTask.flights?.edges[0].node;

    if (!flight) return data;

    const arrivalAirports = flight.arrivalAirports?.edges.map(({ node }) =>
      airportsDropDownMapper(node as AirportNode)
    );

    const departureAirports = flight.departureAirports?.edges.map(({ node }) =>
      airportsDropDownMapper(node as AirportNode)
    );

    data = Object.assign(data, {
      departureDate: flight.departureDate,
      returnDate: flight.returnDate,
      departureFlexibility: flight.departureFlexibility,
      returnFlexibility: flight.returnFlexibility,
      arrivalAirports,
      departureAirports,
    });
  }

  return data;
};

export const getReopenSecondStepTaskData = (searchTask: SearchTaskNode) => {
  const products = searchTask.products?.edges?.map(({ node }) =>
    mapEntityToDropdownItem(node as BookingRevenueProductNode)
  );

  const avoidAirlines = searchTask.avoidAirlines?.edges.map(({ node }) =>
    mapAirlineToDD(node as AirlineNode)
  );

  const preferredAlliances = mapEnumToDropdownItems(AirlineAlliancesType, {
    included: searchTask.preferredAlliances || [],
  }).map((item) => ({
    ...item,
    groupBy: 'alliance',
  }));

  const preferredAirlines = [
    ...preferredAlliances,
    ...(searchTask.preferredAirlines?.edges.map(({ node }) => ({
      ...mapAirlineToDD(node as AirlineNode),
      groupBy: 'airline',
    })) || []),
  ];

  const data: FormSubmitData['data'] = {
    preferredAirlines,
    avoidAirlines,
    products,
    stops: searchTask.stops,
    maxLayover: searchTask.maxLayover,
    notes: searchTask.notes,
  };

  return data;
};
