<global-search-form [formModel]="data" (formSubmit)="submit($event)"></global-search-form>

<div *ngIf="!collapsed" class="navbar-dropdown p-2">
  <div *ngIf="loading" class="d-flex align-items-center justify-content-center">
    <loading-spinner></loading-spinner>
  </div>

  <block-empty-msg *ngIf="isEmpty$ | async">Nothing found</block-empty-msg>

  <ng-container *ngIf="!isTicketingAgent && !isAccountant && (hotLeads$ | async) as hotLeads">
    <global-search-block-wrapper
      *ngIf="hotLeads.totalCount"
      [totalCount]="hotLeads.totalCount"
      [itemsCount]="hotLeads?.edges?.length"
      class="block"
      caption="Hot Leads"
      data-id="hot-leads"
    >
      <hot-leads-block
        [leads]="hotLeads?.edges"
        (rowClick)="assignHotLead($event)"
      ></hot-leads-block>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="(orders$ | async) as orders">
    <global-search-block-wrapper
      *ngIf="orders.totalCount"
      [totalCount]="orders.totalCount"
      [itemsCount]="orders?.edges?.length"
      caption="Orders"
      class="block"
      data-id="orders"
    >
      <result-orders-block
        (rowClick)="clearSearch()"
        [orders]="orders?.edges"
      ></result-orders-block>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="(bookings$ | async) as bookings">
    <global-search-block-wrapper
      *ngIf="bookings.totalCount"
      [totalCount]="bookings.totalCount"
      [itemsCount]="bookings?.edges?.length"
      caption="Bookings"
      class="block"
      data-id="bookings"
    >
      <result-bookings-block
        (rowClick)="clearSearch()"
        [bookings]="bookings?.edges"
      ></result-bookings-block>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="(myClients$ | async) as clients">
    <global-search-block-wrapper
      *ngIf="clients.totalCount"
      [totalCount]="clients.totalCount"
      [itemsCount]="clients?.edges?.length"
      [caption]="isTicketingAgent || isAccountant ? 'Clients' : 'My Clients'"
      class="block"
      data-id="my-clients"
    >
      <my-clients-block [clients]="clients?.edges" (rowClick)="clearSearch()"></my-clients-block>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="(otherClients$ | async) as otherClients">
    <global-search-block-wrapper
      *ngIf="otherClients.totalCount"
      [totalCount]="otherClients.totalCount"
      [itemsCount]="otherClients?.edges?.length"
      caption="Other Clients"
      class="block"
      data-id="other-clients"
    >
      <ng-container *ngIf="isSalesDirector; else otherClientsBlock">
        <my-clients-block
          [clients]="otherClients?.edges"
          (rowClick)="clearSearch()"
        ></my-clients-block>
      </ng-container>
      <ng-template #otherClientsBlock>
        <other-clients-block [clients]="otherClients?.edges"></other-clients-block>
      </ng-template>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="!isTicketingAgent && !isAccountant && (myLeads$ | async) as myLeads">
    <global-search-block-wrapper
      *ngIf="myLeads.totalCount"
      [totalCount]="myLeads.totalCount"
      [itemsCount]="myLeads?.edges?.length"
      class="block"
      caption="My Leads"
      data-id="my-leads"
    >
      <my-leads-block [leads]="myLeads?.edges" (rowClick)="clearSearch()"></my-leads-block>
    </global-search-block-wrapper>
  </ng-container>

  <ng-container *ngIf="!isTicketingAgent && !isAccountant && (otherLeads$ | async) as otherLeads">
    <global-search-block-wrapper
      *ngIf="otherLeads.totalCount"
      [totalCount]="otherLeads.totalCount"
      [itemsCount]="otherLeads?.edges?.length"
      caption="Other Leads"
      class="block"
      data-id="other-leads"
    >
      <ng-container *ngIf="isSalesDirector; else otherLeadsBlock">
        <my-leads-block
          [leads]="otherLeads?.edges"
          (rowClick)="clearSearch()"
          class="block"
        ></my-leads-block>
      </ng-container>
      <ng-template #otherLeadsBlock>
        <other-leads-block [leads]="otherLeads?.edges" class="block"></other-leads-block>
      </ng-template>
    </global-search-block-wrapper>
  </ng-container>
</div>
