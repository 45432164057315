import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'multi-wrapper-v2',
  templateUrl: './multi-wrapper-v2.component.html',
  styleUrls: ['./multi-wrapper-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiWrapperV2Component extends FieldWrapper {}
