import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import {
  CHECKBOX_V2,
  CURRENCY_V2,
  SELECT_V2,
  TEXTAREA_V2,
  TYPEAHEAD_V2,
} from '@app/formly/helpers/fields';
import { RdrFormlyFieldConfig } from '@app/formly/types/basefield/basefield.component';
import { RdrTypeaheadFormlyFieldConfig } from '@app/formly/types/typeahead/typeahead.component';
import { CostPerType, CostType, CreditCardNode, OrderNode } from '@app/generated/graphql';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { ACCOUNT_HOLDER, COST_WIRE_TRANSFER } from '@app/shared/constants';
import { GenericNode, GraphqlID } from '@app/shared/types';
import { BookingType } from '@app/shared/models/booking-type.enum';
import { CreditCardTitlePipe } from '@app/shared/pipes/cost-method.pipe';
import { CreditCardsService } from '@app/shared/services/cards/credit-cards.service';
import { SuppliersService } from '@app/shared/services/suppliers/suppliers.service';
import { mapEntityToDropdownItem, mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { toLabel } from '@app/shared/utils/string-utils';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import lodash from 'lodash';
import { map, of } from 'rxjs';
import { PassengersService } from '@app/shared/services/passengers/passengers.service';

@Component({
  selector: 'order-booking-cost-form',
  templateUrl: './order-booking-cost-form.component.html',
  styleUrls: ['./order-booking-cost-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBookingCostFormComponent extends RadarFormAbstract implements OnInit {
  @Input() bookingType: BookingType;
  @Input() orderId: GraphqlID;
  @Input() order?: OrderNode;
  @Input() withDirect: boolean;

  @Output() deleteEntity: EventEmitter<GraphqlID> = new EventEmitter<GraphqlID>();

  isSales: boolean;
  isSearcher: boolean;

  constructor(
    private creditCardsService: CreditCardsService,
    private suppliersService: SuppliersService,
    private cognitoAuthService: CognitoAuthService,
    private passengersService: PassengersService,
    private costMethodPipe: CreditCardTitlePipe
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isSales = this.cognitoAuthService.isSales;
    this.isSearcher = this.cognitoAuthService.isSearcher;

    this.fields = [
      this.getCostTypeField(),
      this.getSupplierField(),
      this.getUnitAmountField(),
      this.getPerField(),
      this.getDirectField(),
      this.getPaymentField(),
      this.getPassengerField(),
      this.getNoteField(),
    ];
  }

  getCostTypeField() {
    return SELECT_V2(
      {
        key: 'type',
        templateOptions: {
          label: 'Type',
          initial: {
            value: this.data?.type,
            label: toLabel(this.data?.type as string),
          },
          required: true,
          placeholder: 'Choose a cost type',
        },
      },
      of(this.filterCostTypes())
    );
  }

  getSupplierField(): RdrFormlyFieldConfig {
    if (this.isSales || this.isSearcher) {
      return {};
    }

    const defaultArgs: RdrTypeaheadFormlyFieldConfig = {
      key: 'supplier',
      templateOptions: {
        label: 'Supplier',
        initial: this.getInitialSupplierValue(),
        emitFullObject: true,
        placeholder: 'Choose a supplier',
      },
    };

    if (this.bookingType === BookingType.MILES) {
      const args: RdrTypeaheadFormlyFieldConfig = {
        templateOptions: {
          groupedFields: true,
        },
      };
      lodash.merge(args, defaultArgs);

      return TYPEAHEAD_V2(
        args,
        this.suppliersService
          .getAllSuppliers(false)
          .pipe(map((suppliers) => this.addSupplierFromBooking(suppliers)))
      );
    } else {
      return TYPEAHEAD_V2(
        defaultArgs,
        this.suppliersService
          .getSuppliersByTypeAsDDItems(this.bookingType, false)
          .pipe(map((suppliers) => this.addSupplierFromBooking(suppliers)))
      );
    }
  }

  getPassengerField() {
    return SELECT_V2(
      {
        key: 'passengerId',
        templateOptions: {
          label: 'Passenger',
          initial: this.data.passenger
            ? {
                value: this.data.passenger.id,
                label: this.data.passenger.fullName,
              }
            : null,
          required: false,
          placeholder: 'Choose a passenger',
        },
      },
      this.passengersService.loadPassengersForOrder(this.orderId)
    );
  }

  getInitialSupplierValue() {
    if (this.data?.revenueSupplier) {
      return mapEntityToDropdownItem(this.data.revenueSupplier as GenericNode);
    }

    if (this.data?.milesSupplier) {
      return mapEntityToDropdownItem(this.data.milesSupplier as GenericNode);
    }

    return { ...EMPTY_DROP_DOWN_ITEM, label: '(Supplier from Booking)', groupBy: '' };
  }

  addSupplierFromBooking(suppliers: DropdownItem[]) {
    return [
      { ...EMPTY_DROP_DOWN_ITEM, label: '(Supplier from Booking)', groupBy: '' },
      ...suppliers,
    ];
  }

  filterCostTypes() {
    return this.getCostTypes().map(getCostTypeAsDD);
  }

  getCostTypes() {
    if (this.bookingType === BookingType.MILES) {
      return [
        CostType.Mileage,
        CostType.Taxes,
        CostType.CancellationFee,
        CostType.Luggage,
        CostType.SeatAssignment,
      ];
    }
    if (this.bookingType === BookingType.HOTEL) {
      return [CostType.Hotel, CostType.Commission, CostType.IssuingFee, CostType.Insurance];
    }

    // if (this.bookingType == BookingType.REVENUE)
    return [
      CostType.Ticket,
      CostType.Commission,
      CostType.IssuingFee,
      CostType.Upgrade,
      CostType.Luggage,
      CostType.SeatAssignment,
      CostType.Insurance,
    ];
  }

  getPaymentField(): RdrFormlyFieldConfig {
    if (this.isSales || this.isSearcher) return {};

    const alternativeItems = [COST_WIRE_TRANSFER];
    if (this.bookingType === BookingType.MILES) {
      alternativeItems.push(ACCOUNT_HOLDER);
    }

    return SELECT_V2(
      {
        key: 'method',
        templateOptions: {
          label: 'Method',
          required: false,
          initial: this.getInitialMethodValue(),
          placeholder: 'Choose a method',
        },
      },
      this.creditCardsService
        .getCards({ orderId: this.orderId, alternativeItems })
        .pipe(map((values) => [EMPTY_DROP_DOWN_ITEM, ...values]))
    );
  }

  getNoteField() {
    return TEXTAREA_V2({
      key: 'note',
      templateOptions: {
        label: 'Note',
        placeholder: 'Write a note here',
        initial: this.data?.note,
      },
    });
  }

  getInitialMethodValue() {
    if (this.data?.isWireTransfer) {
      return COST_WIRE_TRANSFER;
    }

    if (this.data?.isAccountHolder) {
      return ACCOUNT_HOLDER;
    }

    if (this.data?.secureCreditCard?.id) {
      return {
        label: this.costMethodPipe.transform(this.data?.secureCreditCard as CreditCardNode),
        value: this.data?.secureCreditCard?.id,
      };
    }
    return null;
  }

  getPerField() {
    const perTypes = mapEnumToDropdownItems(CostPerType);

    return SELECT_V2(
      {
        key: 'perType',
        templateOptions: {
          label: 'Per',
          required: false,
          initial: this.data?.perType
            ? perTypes.find(({ value }) => value === this.data?.perType)
            : null,
          placeholder: 'Choose a option',
        },
      },
      of(perTypes)
    );
  }

  getUnitAmountField() {
    return CURRENCY_V2({
      key: 'unitAmount',
      templateOptions: { label: 'Amount', required: true },
    });
  }

  getDirectField() {
    if (this.withDirect) {
      return CHECKBOX_V2({
        key: 'isDirect',
        templateOptions: {
          label: 'Pay with Client CC',
        },
      });
    }
    return {};
  }
}

export const getCostTypeAsDD = (value: string) => {
  return {
    value,
    label: toLabel(value),
  };
};
