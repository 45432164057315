import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { IconSize } from '@app/icons/rdr-svg-icon/rdr-svg-icon.component';

@Component({
  selector: 'button-icon-v2',
  templateUrl: './button-icon-v2.component.html',
  styleUrls: ['./button-icon-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconV2Component {
  @Input() disabled: boolean;
  @HostBinding('class.readonly') @Input() readonly: boolean;
  @Input() color: string;
  @Input() type: 'default' | 'header' | 'just-icon' = 'default';
  @Input() key = '';
  @Input() pressed = false;
  @Input() size: IconSize = 'lg';

  @Output() btnClick = new EventEmitter();
}
