<div class="d-flex v2-mt-8">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <div class="d-flex justify-content-between v2-mb-1">
      <skeleton-loader-small [width]="80" [height]="16"></skeleton-loader-small>
      <skeleton-loader-small [width]="120" [height]="16"></skeleton-loader-small>
    </div>

    <skeleton-loader-small [width]="336" [height]="20"></skeleton-loader-small>
  </div>
</div>

<div class="d-flex v2-mt-8">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <div class="d-flex justify-content-between v2-mb-1">
      <skeleton-loader-small [width]="80" [height]="16"></skeleton-loader-small>
      <skeleton-loader-small [width]="120" [height]="16"></skeleton-loader-small>
    </div>

    <skeleton-loader-small [width]="336" [height]="20"></skeleton-loader-small>
  </div>
</div>

<div class="d-flex v2-mt-8">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <div class="d-flex justify-content-between v2-mb-1">
      <skeleton-loader-small [width]="80" [height]="16"></skeleton-loader-small>
      <skeleton-loader-small [width]="120" [height]="16"></skeleton-loader-small>
    </div>

    <skeleton-loader-small [width]="336" [height]="20"></skeleton-loader-small>
  </div>
</div>
