import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'minutes-range-filter',
  templateUrl: './minutes-range-filter.component.html',
  styleUrls: ['./minutes-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinutesRangeFilterComponent extends FieldType<FieldTypeConfig> {
  change(range: [number, number]) {
    this.formControl.setValue(range);
  }
}
