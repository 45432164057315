import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesFormat',
})
export class MinutesFormatPipe implements PipeTransform {
  transform(value: number) {
    let h = value / 60;
    h = Math.floor(h);

    let m = value % 60;
    m = Math.floor(m);

    return `${h}h ${m.toString().padStart(2, '0')}m`;
  }
}
