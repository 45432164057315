import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

export type RdrCurrencyFormat = 'default' | 'cents' | 'rounded';

@Pipe({
  name: 'rdrCurrency',
})
export class RdrCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}
  transform(
    value: number | null | undefined,
    format: RdrCurrencyFormat = 'default'
  ): string | null {
    if (format === 'cents') {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.5');
    }

    if (format === 'rounded') {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
    }

    if (Number(value) === Math.round(Number(value))) {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0');
    }

    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2');
  }
}
