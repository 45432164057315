import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'rich',
  templateUrl: './rich.component.html',
  styleUrls: ['./rich.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichComponent extends BasefieldV2Component implements OnInit, OnDestroy {
  editor: Editor;

  toolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  ngOnInit() {
    this.editor = new Editor();
  }

  ngOnDestroy() {
    this.editor?.destroy();
  }
}
