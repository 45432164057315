import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[radarOnEscape]',
})
export class RadarOnEscapeDirective {
  @Output() radarOnEscape: EventEmitter<any> = new EventEmitter();

  @HostListener('document:keydown.escape', ['$event']) onEscape(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.radarOnEscape.emit();
  }
}
