import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TEAM_DESK_ROOT_URL } from '@app/shared/constants';

type TeamDeskEntity = 'passenger' | 'client' | 'milesAccount' | 'flightRequest' | 'order';

enum TeamDeskEntityLinks {
  passenger = '209467',
  client = '208434',
  milesAccount = '209589',
  flightRequest = '211303',
  order = '208532',
}

@Component({
  selector: 'teamdesk-link',
  templateUrl: './teamdesk-link.component.html',
  styleUrls: ['./teamdesk-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamdeskLinkComponent {
  teamDeskUrl = TEAM_DESK_ROOT_URL;
  tdLinks = TeamDeskEntityLinks;

  @Input() entityType: TeamDeskEntity;
  @Input() entityId: string;
  @Input() href: string;
}
