import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { get, groupBy } from 'lodash';

@Pipe({
  name: 'groupByField',
})
export class GroupByFieldPipe implements PipeTransform {
  transform(
    itemsToGroup: DropdownItem[],
    groupByField?: string | null
  ): { key: string; items: DropdownItem[] }[] {
    if (groupByField) {
      const itemDictionary = groupBy(itemsToGroup, (item: DropdownItem) => get(item, groupByField));
      const groupedItems = Object.keys(itemDictionary).map((key) => ({
        key,
        items: itemDictionary[key],
      }));
      return groupedItems;
    } else {
      return [{ key: '', items: itemsToGroup }];
    }
  }
}
