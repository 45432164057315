<input
  #track
  (change)="change($event)"
  (mousedown)="mousedown()"
  (mouseup)="mouseup()"
  type="range"
  max="0"
  value="0"
  class="track"
  [ngClass]="{ 'active': playing }"
/>

<audio
  *ngIf="audioLink"
  (timeupdate)="timeupdate()"
  #audioTag
  controls
  [src]="audioLink"
  class="d-none"
></audio>
