import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { RdrCurrencyFormat } from '@app/ui/pipes/rdr-currency.pipe';

@Component({
  selector: 'calculated-v2',
  templateUrl: './calculated-v2.component.html',
  styleUrls: ['./calculated-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculatedV2Component extends BasefieldV2Component {
  Number = Number;

  get format() {
    return (this.to.attributes?.format as RdrCurrencyFormat) || 'default';
  }
}
