import { Injectable } from '@angular/core';
import { BookingType } from '@app/shared/models/booking-type.enum';
import { MilesSuppliersGQL, RevenueSuppliersGQL } from '@generated/graphql';
import { fetchAsDropDownItems } from '@shared/services/dropdown-fetch';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@ui/components/dropdown.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SuppliersService {
  constructor(
    private milesSuppliersGQL: MilesSuppliersGQL,
    private revenueSuppliersGQL: RevenueSuppliersGQL
  ) {}

  getSuppliersByTypeAsDDItems(bookingType: BookingType, includeEmptyItem = true) {
    return bookingType === BookingType.MILES
      ? this.getMilesSuppliersAsDropDownItems(includeEmptyItem)
      : this.getRevenueSuppliersAsDropDownItems(includeEmptyItem);
  }

  getMilesSuppliersAsDropDownItems(includeEmptyItem = true): Observable<DropdownItem[]> {
    return fetchAsDropDownItems(
      this.milesSuppliersGQL,
      ({ data }) => data.milesSuppliers,
      undefined,
      undefined,
      includeEmptyItem
    );
  }

  getRevenueSuppliersAsDropDownItems(includeEmptyItem = true): Observable<DropdownItem[]> {
    return fetchAsDropDownItems(
      this.revenueSuppliersGQL,
      ({ data }) => data.revenueSuppliers,
      undefined,
      undefined,
      includeEmptyItem
    );
  }

  getAllSuppliers(includeEmptyItem = true): Observable<DropdownItem[]> {
    return combineLatest([
      this.getMilesSuppliersAsDropDownItems(false),
      this.getRevenueSuppliersAsDropDownItems(false),
    ]).pipe(
      map(([milesSuppliers, revSuppliers]) => {
        const milesArr = milesSuppliers.map((el) => ({ ...el, groupBy: 'mile' }));
        const revArr = revSuppliers.map((el) => ({ ...el, groupBy: 'revenue' }));
        const suppliersArr = [...milesArr.concat(revArr)];

        return includeEmptyItem
          ? [
              {
                ...EMPTY_DROP_DOWN_ITEM,
                groupBy: '',
              },
              ...suppliersArr,
            ]
          : suppliersArr;
      })
    );
  }
}
