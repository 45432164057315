import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FlightItineraryGroupNode } from '@generated/graphql';

@Component({
  selector: 'itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryComponent {
  @Input() itineraryGroups: FlightItineraryGroupNode[];
  @Input() withColors = false;
}
