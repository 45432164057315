import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectFilterComponent extends FieldType<FieldTypeConfig> implements OnInit {
  expanded: boolean;

  checked = new Set();

  showedItemsCount = 5;

  ngOnInit() {
    if (this.formControl.value) {
      let vl;

      if (Array.isArray(this.formControl.value)) {
        vl = this.formControl.value as string[];
      } else {
        vl = [this.formControl.value as string];
      }

      this.checked = new Set(vl);
    }
  }

  change(value: { key: string; description: string }) {
    if (this.checked.has(value.key)) {
      this.checked.delete(value.key);
    } else {
      this.checked.add(value.key);
    }

    this.formControl.setValue(Array.from(this.checked));
  }

  getChecked(value: { key: string; description: string }) {
    return this.checked.has(value.key);
  }
}
