import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { LeadCallTrackComponent } from '../lead-call-track/lead-call-track.component';
import { CallsService } from '@app/shared/services/calls/calls.service';
import { WholeCalls } from '@app/generated/graphql';

@Component({
  selector: 'call-event',
  templateUrl: './call-event.component.html',
  styleUrls: ['./call-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallEventComponent {
  playing: boolean;
  loading: boolean;

  audioLinks: string[] = [];
  currentLinkIndex = -1;

  @Input() interaction: WholeCalls;

  @ViewChild('leadCallTrack') leadCallTrack: LeadCallTrackComponent;

  constructor(private callsService: CallsService, private cdRef: ChangeDetectorRef) {}

  getAudioLinks() {
    this.loading = true;

    const params = {
      id: this.interaction.id as string,
      callProvider: this.interaction.callProvider,
    };

    this.callsService.getCallLinks(params).subscribe((callLinks) => {
      if (callLinks) {
        this.audioLinks = callLinks;
        this.currentLinkIndex = 0;
        this.loading = false;
        this.cdRef.detectChanges();

        this.play();
      }
    });
  }

  onAudioEnded() {
    if (this.currentLinkIndex < this.audioLinks.length - 1) {
      this.currentLinkIndex++;
      this.cdRef.detectChanges();

      this.play();
    }
  }

  setPlaying(playing: boolean) {
    this.playing = playing;
  }

  play() {
    if (!this.audioLinks?.length) {
      return this.getAudioLinks();
    }

    this.leadCallTrack.play();
  }

  pause() {
    this.leadCallTrack.pause();
  }
}
