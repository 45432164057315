<modal-v2 header="{{header}}" data-id="confirm-modal">
  <div class="d-flex flex-column">
    <typography-v2>{{ message }}</typography-v2>
    <div class="d-flex justify-content-end align-items-center">
      <button-v2 (click)="hideModalAndEmit()" [type]="confirmButtonType" data-id="confirmed"
        >{{ confirmText }}</button-v2
      >
      <button-v2 class="v2-ml-3" data-id="cancel" (click)="hideModal()">Cancel</button-v2>
    </div>
  </div>
</modal-v2>
