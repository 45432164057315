import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[inputSelectAll]',
})
export class InputSelectAllDirective {
  selected: boolean;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  @HostListener('click') onCLick() {
    if (!this.selected) {
      this.elementRef.nativeElement?.select();
    }
    this.selected = !this.selected;
  }
}
