import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { GlobalSearchFormComponent } from './components/global-search-form/global-search-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@ui/ui.module';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { MyClientsBlockComponent } from './components/my-clients-block/my-clients-block.component';
import { OtherClientsBlockComponent } from './components/other-clients-block/other-clients-block.component';
import { MyLeadsBlockComponent } from './components/my-leads-block/my-leads-block.component';
import { OtherLeadsBlockComponent } from './components/other-leads-block/other-leads-block.component';
import { RadarFormlyModule } from '@app/formly/formly.module';
import { SearchClientRowComponent } from './components/search-client-row/search-client-row.component';
import { ResultOrdersBlockComponent } from './components/result-orders-block/result-orders-block.component';
import { ResultBookingsBlockComponent } from './components/result-bookings-block/result-bookings-block.component';
import { GlobalSearchBlockWrapperComponent } from './components/global-search-block-wrapper/global-search-block-wrapper.component';
import { PipesModule } from '@ui/pipes/pipes.module';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { HotLeadsBlockComponent } from './components/my-leads-block copy/hot-leads-block.component';

@NgModule({
  declarations: [
    GlobalSearchComponent,
    GlobalSearchFormComponent,
    MyClientsBlockComponent,
    OtherClientsBlockComponent,
    MyLeadsBlockComponent,
    OtherLeadsBlockComponent,
    SearchClientRowComponent,
    ResultOrdersBlockComponent,
    ResultBookingsBlockComponent,
    GlobalSearchBlockWrapperComponent,
    HotLeadsBlockComponent,
  ],
  exports: [GlobalSearchComponent, GlobalSearchFormComponent],
  imports: [
    CommonModule,
    RadarFormlyModule,
    ReactiveFormsModule,
    UiModule,
    UiV2Module,
    PipesModule,
    SharedModule,
    RouterModule,
  ],
})
export class GlobalSearchModule {}
