import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { UserType } from '@generated/graphql';
import { concatMap, map } from 'rxjs/operators';
import { RdrToastService } from '@ui/services/rdr-toast.service';
import { RdrAuthService } from '@auth/services/rdr-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private toastService: RdrToastService,
    private rdrAuth: RdrAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognitoAuthService.hasCognitoSession().pipe(
      concatMap(() => this.rdrAuth.getUser()),
      map(() => {
        const roles = route.data?.roles as UserType[];
        if (roles && !roles.some((r) => this.cognitoAuthService.hasRole(r))) {
          this.toastService.info({
            message: 'This page has restricted access',
          });

          return false;
        }

        return true;
      })
    );
  }
}
