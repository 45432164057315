import lodash from 'lodash';

/**
 * Removes all 'null' and 'undefined' fields from the model
 * @param model
 */
export function purifyModel<T>(model: T): T {
  const purified: any = {};

  for (const key in model) {
    const value = model[key];
    if (value !== null && value !== undefined) {
      if (value instanceof FileList) {
        purified[key] = value;
      } else if (lodash.isArray(value)) {
        purified[key] = value
          .filter((item: any) => item !== null && item !== undefined)
          .map((item) => {
            if (typeof item === 'string') {
              return item.trim();
            }
            return item;
          });
      } else if (lodash.isObject(value) && !(value instanceof Date)) {
        purified[key] = purifyModel(value);
      } else if (typeof value === 'string') {
        purified[key] = (value as string).trim();
      } else {
        purified[key] = value;
      }
    }
  }
  return purified;
}

/**
 * Compares a model with it's original version, provides diff of what
 * has been changed.
 * Does NOT provide deep diff for objects in the arrays.
 *
 * @param model
 * @param source
 */
export function getModelDiff<T extends Record<string, any>>(model: T, source: T): T {
  const result: any = {};

  Object.keys(model).forEach(function (k) {
    if (Array.isArray(model[k])) {
      if (!lodash.isEqual(model[k], source[k])) {
        result[k] = model[k];
      }
    } else if (
      typeof source[k] === 'object' &&
      !(source[k] instanceof Date) &&
      !(model[k] instanceof Date)
    ) {
      const diff = getModelDiff(model[k], source[k]);
      if (!lodash.isEqual(diff, {})) {
        result[k] = diff;
      }
    } else if (!lodash.isEqual(source[k], model[k])) {
      result[k] = model[k];
    }
  });

  return result;
}
