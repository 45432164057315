import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl-v2' | 'xxl' | 'xxl-v2';

@Component({
  selector: 'rdr-svg-icon',
  templateUrl: './rdr-svg-icon.component.html',
  styleUrls: ['./rdr-svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdrSvgIconComponent {
  @Input() key: string;
  @Input() color: string;
  @Input() size: IconSize;
}
