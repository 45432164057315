import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ColoredStatusComponent } from '@app/ui/components/colored-status/colored-status.component';

@Component({
  selector: 'colored-status-v2',
  templateUrl: './colored-status-v2.component.html',
  styleUrls: ['./colored-status-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredStatusV2Component extends ColoredStatusComponent {}
