import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BasefieldComponent } from '../basefield/basefield.component';

@Component({
  selector: 'cc-expiration',
  templateUrl: './cc-expiration.component.html',
  styleUrls: ['./cc-expiration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCcExpirationComponent extends BasefieldComponent implements OnInit {
  innerModel = new UntypedFormControl('');

  ngOnInit(): void {
    if (this.formControl.value) {
      this.innerModel.setValue(this.formControl.value);
    }
  }

  onBlur() {
    this.formControl.setValue(this.innerModel.value);
  }
}
