import { ChangeDetectorRef, Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { UserType } from '@generated/graphql';

@Directive()
export class RoleAccessAbstractDirective implements OnInit {
  roles: UserType[] | UserType;
  isInverted = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit() {
    const roles: UserType[] = Array.isArray(this.roles) ? this.roles : [this.roles];
    const hasAccess = roles.some((r) => this.cognitoAuthService.hasRole(r));

    if (this.isInverted) {
      this.updateDirectiveState(!hasAccess);
    } else {
      this.updateDirectiveState(hasAccess);
    }
  }

  private createView(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.cdRef.markForCheck();
  }

  private clearView(): void {
    this.viewContainer.clear();
  }

  public updateDirectiveState(isEnabled: boolean): void {
    if (isEnabled) {
      this.createView();
    } else {
      this.clearView();
    }
  }
}
