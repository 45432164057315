import { Pipe, PipeTransform } from '@angular/core';
import { FlightItinerarySegmentFragment } from '@app/generated/graphql';

@Pipe({
  name: 'seatsFromSegment',
})
export class SeatsFromSegmentPipe implements PipeTransform {
  transform(segment: FlightItinerarySegmentFragment): string[] {
    let seats: string[] = [];

    segment?.flightSegment?.seatAssignments?.edges?.forEach(({ node }) => {
      node?.seat && seats.push(node?.seat);
    });

    if (segment?.additionalSeats?.length) {
      seats = [
        ...seats,
        ...segment.additionalSeats.map((additionalSeat) => additionalSeat.seat as string),
      ];
    }

    return seats.sort();
  }
}
