import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as lpn from 'google-libphonenumber';
import { Country } from '@app/formly/types/tel-input/country.model';
import IMask from 'imask';
import { CountryCode } from '../../../../../static/country-code';
import { BasefieldComponent } from '../basefield/basefield.component';
import { DEFAULT_COUNTRY } from '@app/formly/types/v2/phone-v2/phone-v2.component';

@Component({
  selector: 'tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CountryCode,
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => TelInputComponent),
      multi: true,
    },
  ],
})
export class TelInputComponent extends BasefieldComponent implements OnInit {
  innerModel = new UntypedFormControl('');
  phoneUtil: any = lpn.PhoneNumberUtil.getInstance();

  selectedCountry: Country = DEFAULT_COUNTRY;

  imask: IMask.MaskedDynamicOptions;
  defaultMask = {
    mask: '+0 000 000-00-00',
  };

  constructor(private countries: CountryCode, cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.imask = {
      mask: [this.defaultMask],
    };

    if (this.formControl.value) {
      this.setSelectedCountry(this.formControl.value as string);
      this.innerModel.setValue(this.formControl.value);
    }
  }

  setSelectedCountry(val: string) {
    const number = this.getParsedNumber(val, '');
    const countryCode =
      number && number.getCountryCode()
        ? this.getCountryIsoCode(number.getCountryCode(), number)
        : this.selectedCountry.iso2;

    if (countryCode && countryCode !== this.selectedCountry.iso2) {
      const newCountry = this.countries.allCountries
        .sort((a, b) => {
          return a.priority - b.priority;
        })
        .find((c) => c.iso2 === countryCode);
      if (newCountry) {
        this.selectedCountry = {
          ...newCountry,
          priority: +newCountry.priority || 0,
          flagClass: `iti__${newCountry.iso2.toLocaleLowerCase()}`,
        };

        this.imask = {
          mask: newCountry.mask.map((el) => ({
            mask: `+${el}`,
          })),
        };
      }
    }

    return countryCode;
  }

  public onPhoneNumberChange(): void {
    const val = this.innerModel.value as string;
    if (val === '') {
      this.imask = {
        mask: [this.defaultMask],
      };
      this.selectedCountry = DEFAULT_COUNTRY;
    }
    this.setSelectedCountry(val);
  }

  private getParsedNumber(phoneNumber: string, countryCode: string): lpn.PhoneNumber | undefined {
    let number: lpn.PhoneNumber | undefined = undefined;
    try {
      number = this.phoneUtil.parse(phoneNumber, countryCode.toUpperCase());
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return number;
  }

  /**
   * Sifts through all countries and returns iso code of the primary country
   * based on the number provided.
   * @param countryCode country code in number format
   * @param number PhoneNumber object
   */
  private getCountryIsoCode(countryCode: number | undefined, number: any): string | undefined {
    // Will use this to match area code from the first numbers
    const rawNumber = number['values_']['2'].toString();
    // List of all countries with countryCode (can be more than one. e.x. US, CA, DO, PR all have +1 countryCode)
    const countries = this.countries.allCountries.filter(
      (c) => c.dialCode === countryCode?.toString()
    );
    // Main country is the country, which has no areaCodes specified in country-code.ts file.
    const mainCountry = countries.find((c) => c.areaCodes === null);
    // Secondary countries are all countries, which have areaCodes specified in country-code.ts file.
    const secondaryCountries = countries.filter((c) => c.areaCodes !== null);
    let matchedCountry = mainCountry ? mainCountry.iso2 : undefined;

    /*
      Iterate over each secondary country and check if nationalNumber starts with any of areaCodes available.
      If no matches found, fallback to the main country.
    */
    secondaryCountries.forEach((country) => {
      country.areaCodes?.forEach((areaCode) => {
        if (rawNumber.startsWith(areaCode)) {
          matchedCountry = country.iso2;
        }
      });
    });

    return matchedCountry;
  }

  onBlur() {
    if (this.innerModel.value !== '') {
      this.formControl.setValue(this.innerModel.value);
    }
  }
}
