import { Pipe, PipeTransform } from '@angular/core';
import { CreditCardNode, SecureCreditCard } from '@app/generated/graphql';
import { companyCardLabel } from '@shared/services/cards/credit-cards.service';

@Pipe({
  name: 'creditCardTitle',
})
export class CreditCardTitlePipe implements PipeTransform {
  transform(card?: CreditCardNode | SecureCreditCard): string {
    if (!card) {
      return '';
    }
    if (card.isCompany) {
      return companyCardLabel(card);
    }
    return `${card.holder || 'Holder'} **** ${card.number?.slice(-4) || ''}`;
  }
}
