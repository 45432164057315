<div class="d-flex align-items-center position-relative">
  <div
    *ngIf="selectedCountry?.flagClass"
    class="flag iti__flag v2-mr-2"
    [ngClass]="selectedCountry?.flagClass!"
  ></div>

  <input
    (ngModelChange)="onPhoneNumberChange()"
    [imask]="imask"
    [formControl]="innerModel"
    [formlyAttributes]="field"
    [attr.data-id]="field.key"
    [class.disabled]="to.disabled"
    [ngClass]="{ 'with-flag': selectedCountry?.flagClass, 'has-error': formControl.invalid }"
    type="tel"
    autocomplete="off"
    class="text-input"
  />
</div>
