import { GenericNode } from '@app/shared/types';
import { DropdownItem } from '@ui/components/dropdown.model';
import { mapEntityToDropdownItem } from '@shared/utils/dropdown-utils';
import { COST_WIRE_TRANSFER, EMPTY_ID } from '../constants';
import { CostNodeInput, CostPerTypeInput, CostTypeInput } from '@app/generated/graphql';

export function supplierFormToMutation({
  data,
  formKey = 'supplierId',
}: {
  data: GenericNode;
  formKey?: string;
}) {
  if (data[formKey] as DropdownItem) {
    if (data[formKey].groupBy === 'mile') {
      data.milesSupplierId = data[formKey].value;
      data.revenueSupplierId = '';
    } else {
      data.revenueSupplierId = data[formKey].value;
      data.milesSupplierId = '';
    }
    delete data[formKey];
  }
}

export function supplierFilterToQuery(data: DropdownItem) {
  const key = data.groupBy === 'mile' ? 'milesSupplierId' : 'revenueSupplierId';

  return {
    [key]: data.value,
  };
}

export function supplierNodeToForm({ data }: { data: GenericNode }): DropdownItem {
  const supplier = (data?.milesSupplier || data?.revenueSupplier) as GenericNode;

  return {
    ...mapEntityToDropdownItem(supplier),
    groupBy: data?.milesSupplier ? 'miles' : 'revenue',
  };
}

export const paymentFormToMutation = ({
  data,
  formKey = 'formOfPayment',
}: {
  data: GenericNode;
  formKey?: string;
}) => {
  if (data[formKey]) {
    if (data[formKey] === COST_WIRE_TRANSFER.value) {
      data['isWireTransfer'] = true;
      data['companyCreditCardId'] = EMPTY_ID;
    } else {
      data['isWireTransfer'] = false;
      data['companyCreditCardId'] = data[formKey];
    }
    delete data[formKey];
  }
};

export const gdsPnrsToFlightItineraries = (data: GenericNode) => {
  const isJSON = itineraryIsObject(data.sabrePnrCode as string);

  if (data['sabrePnrCode'] || data['sabrePnrCode'] === '') {
    data['flightItineraries'] = isJSON
      ? data['sabrePnrCode']
      : splitgdsPnrs(data['sabrePnrCode'] as string);

    delete data['sabrePnrCode'];
  }

  if (isJSON) {
    delete data['fareType'];
    delete data['products'];
    delete data['pcc'];
    delete data['pnr'];
  }
};

export const splitgdsPnrs = (sabrePnrCode: string) =>
  sabrePnrCode.split('\n').filter((str: string) => str.trim().length) || [];

export const hasErrorJSON = (string = '') => {
  try {
    JSON.parse(string);
  } catch (e) {
    return true;
  }
  return false;
};

export const itineraryIsObject = (itinerary = '') => {
  return Boolean(itinerary && itinerary[0] === '{' && itinerary[itinerary.length - 1] === '}');
};

export const beautifyJSON = (string = '') => {
  return JSON.stringify(string, null, 2);
};

export const isDefined = (value: any) => value !== undefined && value !== null;

export const isEmptyObject = (value: any) =>
  isDefined(value) && typeof value === 'object' && Object.keys(value as object).length === 0;

export const prepareCosts = (data: GenericNode) => {
  data.costs?.forEach((cost: CostNodeInput) => {
    if (cost.type === CostTypeInput.Hotel) {
      cost.perType = CostPerTypeInput.Booking;
    } else {
      delete cost.hotelName;
      delete cost.hotelNights;
    }
  });
};

export const prepareCostsForHotelBooking = (data: GenericNode) => {
  data.costs?.forEach((cost: CostNodeInput) => {
    delete cost.hotelName;
    delete cost.hotelNights;
    cost.perType = CostPerTypeInput.Booking;
  });
};
