<div *ngIf="value" [ngClass]="{ 'pressed': pressed }" class="d-flex align-items-center due-badge">
  <badge-v2
    *ngIf="look === 'default'; else header"
    [look]="(value | isDateBefore) ? 'emergency' : 'due-date'"
    class="d-flex"
  >
    <span class="d-flex align-items-center">
      <rdr-svg-icon
        key="alarm"
        color="{{ (value | isDateBefore) ? 'var(--color-white)'  : 'var(--color-black-50)' }}"
        class="v2-mr-1"
      ></rdr-svg-icon>
      <ng-template *ngTemplateOutlet="formattedVal"></ng-template>
    </span>
  </badge-v2>
</div>

<ng-template #header>
  <badge-v2
    [look]="(value | isDateBefore) ? 'emergency' : 'due-date'"
    class="d-flex v2-p-2 header-badge"
  >
    <span class="d-flex align-items-center">
      <rdr-svg-icon
        key="alarm"
        color="{{ (value | isDateBefore) ? 'var(--color-white)' : 'var(--color-black)' }}"
        class="v2-mr-1"
      ></rdr-svg-icon>
      <ng-template *ngTemplateOutlet="formattedVal"></ng-template>
    </span>
  </badge-v2>
</ng-template>

<ng-template #formattedVal>
  <span data-id="due-date"
    >{{ value | rdrDate: (value | isDateToday) ? 'shortTime' : 'short' }}</span
  >
</ng-template>
