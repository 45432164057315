import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CostNode, CreditCardTypeEnum } from '@app/generated/graphql';

@Component({
  selector: 'payment-method-v2',
  templateUrl: './payment-method-v2.component.html',
  styleUrls: ['./payment-method-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodV2Component {
  CreditCardTypeEnum = CreditCardTypeEnum;

  @Input() cost: CostNode;

  showCardPopup = false;
}
