import { Directive, ElementRef, HostListener, QueryList } from '@angular/core';
import { DropdownItem } from './dropdown.model';
import { DropdownAbstractClass } from './dropdown-abstract';

@Directive()
export abstract class NavigatableContainerAbstract extends DropdownAbstractClass {
  hoveredItem: DropdownItem | null;

  abstract get itemsForNavigation(): DropdownItem[];
  abstract onItemSelected(item: DropdownItem): void;
  abstract get itemElementRefs(): QueryList<ElementRef>;

  @HostListener('document:keydown.arrowup', ['$event'])
  previousHoveredItem(event: Event) {
    const nextIndexGetter = (hoveredItemIndex: number) => Math.max(0, hoveredItemIndex - 1);
    this.handleArrowButtonPress(nextIndexGetter, event);
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  nextHoveredItem(event: Event) {
    const nextIndexGetter = (hoveredItemIndex: number) =>
      Math.min(this.itemsForNavigation.length - 1, hoveredItemIndex + 1);
    this.handleArrowButtonPress(nextIndexGetter, event);
  }

  @HostListener('document:keydown.enter', ['$event'])
  toggleItemSelection(event: Event) {
    if (!this.collapsed && this.hoveredItem) {
      event?.preventDefault();
      event?.stopPropagation();

      this.onItemSelected(this.hoveredItem);
    }
  }

  private handleArrowButtonPress(
    nextIndexGetter: (hoveredItemIndex: number) => number,
    event: Event
  ) {
    if (!this.collapsed && this.itemsForNavigation?.length) {
      event?.preventDefault();
      event?.stopPropagation();

      if (this.hoveredItem) {
        const hoveredItemIndex = this.itemsForNavigation.findIndex(
          (item) => item === this.hoveredItem
        );
        const nextIndex = nextIndexGetter(hoveredItemIndex);
        this.hoveredItem = this.itemsForNavigation[nextIndex];

        const elem = this.itemElementRefs?.find(
          (item) => item.nativeElement.id === this.hoveredItem?.value
        );
        elem?.nativeElement?.scrollIntoView(false);
      } else {
        this.hoveredItem = this.itemsForNavigation[0];
      }
    }
  }
}
