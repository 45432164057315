import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChangeSearchTaskStatusType } from '@app/generated/graphql';

@Component({
  selector: 'search-task-reopen-panel',
  templateUrl: './search-task-reopen-panel.component.html',
  styleUrls: ['./search-task-reopen-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTaskReopenPanelComponent implements OnInit {
  ChangeSearchTaskStatusType = ChangeSearchTaskStatusType;

  showReopen: boolean;

  @Input() onlyReopenButton: boolean;
  @Output() reopenTask = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.onlyReopenButton) {
      setTimeout(() => {
        this.showReopen = true;
        this.cdRef.detectChanges();
      }, 3000);
    }
  }
}
