import { Pipe, PipeTransform } from '@angular/core';
import { isBefore } from '@app/formly/types/date/date-helpers';

@Pipe({
  name: 'isDateBefore',
})
export class IsDateBeforePipe implements PipeTransform {
  transform(value?: string | Date): boolean {
    if (!value) {
      return false;
    }
    return isBefore(value.toString());
  }
}
