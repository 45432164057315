import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'colored-status',
  templateUrl: './colored-status.component.html',
  styleUrls: ['./colored-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredStatusComponent {
  _status: string;
  get status() {
    return this._status;
  }
  @HostBinding('class') @Input() set status(val: string) {
    this._status = val.toLowerCase();
  }
}
