import { CommonModule } from '@angular/common';
import { Component, Input, inject, OnInit, OnDestroy } from '@angular/core';
import { TwilioService } from '@app/calls/services/twilio/twilio.service';
import { LeadNode, TwilioCallRejectReasonEnumType } from '@app/generated/graphql';
import { HotLeadPollingService } from '@app/hot-lead-polling/service/hot-lead-polling.service';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { UiModule } from '@app/ui/ui.module';
import { Call } from '@twilio/voice-sdk';
import * as Sentry from '@sentry/browser';
import { AvailabilityService } from '@app/calls/services/availability/availability.service';

@Component({
  selector: 'call-popup-v2',
  templateUrl: './call-popup-v2.component.html',
  styleUrls: ['./call-popup-v2.component.scss'],
  standalone: true,
  imports: [CommonModule, UiModule, UiV2Module, PipesModule],
})
export class CallPopupV2Component implements OnInit, OnDestroy {
  private pollerService = inject(HotLeadPollingService);
  private twilioService = inject(TwilioService);
  private availabilityService = inject(AvailabilityService);

  @Input() lead: LeadNode;
  call: Call;

  connected: boolean;
  callDeclined: boolean;
  callConfirmed: boolean;
  showReasonsPopover: boolean;
  callDuration = 0;
  callDurationIntervalId: number;
  leadShouldMakeCall: boolean;
  isConfirming: boolean;

  readonly reasons = mapEnumToDropdownItems(
    TwilioCallRejectReasonEnumType as Record<string, TwilioCallRejectReasonEnumType>
  );

  async ngOnInit() {
    this.leadShouldMakeCall = this.lead.shouldMakeCall as boolean;
    if (this.leadShouldMakeCall) {
      this.pollerService.setActiveCallStartedAt();
      await this.startCall();
    }
  }

  beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    this.pollerService.removeActiveCallStartedAt();

    if (this.call?.status() !== Call.State.Closed) {
      event.preventDefault();
      return (event.returnValue = '');
    }
    return null;
  };

  async startCall() {
    await this.twilioService.login(
      {
        handleLoginSuccess: () => {
          console.log('logged in successfully');
        },
        handleError: (twilioError, call) => {
          console.log(twilioError);
          Sentry.captureEvent({
            message: 'Received an error during call',
            extra: {
              leadId: this.lead.id,
              twilioError,
              call,
            },
          });
          this.availabilityService.stopPolling();
        },
      },
      ''
    );
    const device = this.twilioService.device;
    if (device) {
      const params = { lead_id: this.lead.id };

      this.call = await device.connect({
        params: params as unknown as Record<string, string>,
      });

      window.addEventListener('beforeunload', this.beforeUnloadHandler);

      this.callDurationIntervalId = window.setInterval(() => {
        this.callDuration++;
        const currentStatus = this.call?.status();

        if (currentStatus === Call.State.Closed) {
          this.leaveCall();
          this.call.disconnect();
        }
      }, 1000);

      this.call.on('accept', () => {
        this.pollerService
          .callStarted(this.lead.id, this.call.parameters['CallSid'])
          .subscribe(() => {
            this.connected = true;
          });
      });

      this.call.on('disconnect', () => this.pollerService.removeActiveCallStartedAt());
    }
  }

  ngOnDestroy(): void {
    this.leaveCall();
  }

  leaveCall() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    window.clearInterval(this?.callDurationIntervalId);

    this.pollerService.removeActiveCallStartedAt();
  }

  finishCall() {
    this.call?.disconnect();
    this.pollerService.callFinished();

    this.pollerService.removeActiveCallStartedAt();
  }

  toggleMute() {
    this.call?.mute(!this.call.isMuted());
  }

  onDecline(reason: DropdownItem<TwilioCallRejectReasonEnumType>) {
    this.pollerService.callRejected({
      leadId: this.lead.id,
      rejectReason: reason.value as TwilioCallRejectReasonEnumType,
    });
    this.callDeclined = true;
    this.call?.disconnect();
    window.clearInterval(this.callDurationIntervalId);

    this.pollerService.removeActiveCallStartedAt();
  }

  onConfirm() {
    this.isConfirming = true;
    this.pollerService.callConfirmed(this.lead?.id, this.leadShouldMakeCall).subscribe(() => {
      this.callConfirmed = true;
    });

    this.pollerService.removeActiveCallStartedAt();
  }

  get microphoneIconKey() {
    return this.call.isMuted() ? 'microphone-off' : 'microphone';
  }
}
