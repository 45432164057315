<ng-container *ngIf="agent; else empty">
  <user-picture-v2
    [picture]="agent.picture"
    [label]="agent.fullName"
    [tooltip]="agent.fullName"
    container="body"
    size="small"
  ></user-picture-v2>

  <div *ngIf="!hideName" class="ps-1 text-truncate" data-id="user-name">
    {{ agent.fullName || '-' }}
  </div>
</ng-container>

<ng-template #empty>
  <div data-id="user-name">(none)</div>
</ng-template>
