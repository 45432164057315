import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RadarTableColumnType } from '@app/shared/components/radar-table/radar-table.types';
import { distinctUntilChanged, Subject } from 'rxjs';
import { FormlyTypeaheadV2Component } from '../typeahead-v2/formly-typeahead-v2.component';

@Component({
  selector: 'miles-account-v2',
  templateUrl: './miles-account-v2.component.html',
  styleUrls: ['./miles-account-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilesAccountV2Component extends FormlyTypeaheadV2Component implements OnInit {
  resetField$: Subject<boolean> = new Subject<boolean>();

  columns = [
    {
      prop: 'firstAvailableTransfer',
      name: 'First Available',
      cellType: RadarTableColumnType.Date,
      width: 108,
    },
    {
      prop: 'login',
      name: 'Login',
      width: 98,
    },
    {
      prop: 'balance',
      name: 'Balance',
      cellType: RadarTableColumnType.Number,
      width: 61,
    },
    {
      prop: 'pricePerMile',
      name: 'Price Per Mile',
      cellType: RadarTableColumnType.Currency,
      width: 94,
    },
    {
      prop: 'pendingBookings',
      name: 'Pending Bookings',
      cellType: RadarTableColumnType.Number,
      width: 130,
    },
    {
      prop: 'lastUsedAt',
      name: 'Last Used',
      cellType: RadarTableColumnType.Date,
      width: 90,
    },
  ];

  ngOnInit() {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      if (!this.formControl.disabled && val === '') {
        this.resetField$.next(true);
      }
    });
  }
}
