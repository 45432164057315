import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { SecureCreditCard } from '@app/generated/graphql';

@Component({
  selector: 'credit-card-preview',
  templateUrl: './credit-card-preview.component.html',
  styleUrls: ['./credit-card-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardPreviewComponent {
  @Input() creditCard?: SecureCreditCard;

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    event.stopPropagation();
  }
}
