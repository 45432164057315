import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CHIPS_V2, SELECT_V2, TEXTAREA_V2 } from '@app/formly/helpers/fields';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { map, of } from 'rxjs';
import { FlightRequestPageV2Service } from '../../../services/flight-request-page-v2.service';
import { AirlinesService } from '@app/shared/services/airlines/airlines.service';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { AirlineAlliancesType, MaxLayoverType, StopsType } from '@app/generated/graphql';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { AppInjector } from '@app/app-injector.service';
import { FormLoadingStateService } from '@app/shared/services/form-loading-state.service';
import {
  getInitialMaxLayover,
  getInitialStops,
} from '@app/pages/flight-requests/components/create-task/helpers/entities';

@Component({
  selector: 'second-step-form',
  templateUrl: './second-step-form.component.html',
  styleUrls: ['./second-step-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondStepFormComponent extends RadarFormAbstract implements OnInit {
  @Output() goBack = new EventEmitter();

  constructor(
    private flightRequestPageV2Service: FlightRequestPageV2Service,
    private airlinesService: AirlinesService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.fields = [
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          CHIPS_V2(
            {
              key: 'preferredAirlines',
              className: 'col-6',
              templateOptions: {
                label: 'Preferred airlines and alliances',
                groupByField: 'groupBy',
                placeholder: '1 or more options',
                filterCallback: filterDDWithCode,
                initial: this.data.preferredAirlines,
              },
            },
            this.airlinesService.getAirlines().pipe(
              map((items) => [
                ...mapEnumToDropdownItems(AirlineAlliancesType).map((item) => ({
                  ...item,
                  groupBy: 'alliance',
                })),
                ...items.map((item) => ({ ...item, groupBy: 'airline' })),
              ])
            )
          ),
          CHIPS_V2(
            {
              key: 'avoidAirlines',
              className: 'col-6',
              templateOptions: {
                label: 'Avoid airlines',
                placeholder: '1 or more options',
                filterCallback: filterDDWithCode,
                initial: this.data.avoidAirlines,
              },
            },
            this.airlinesService.getAirlines()
          ),
        ],
      },
      CHIPS_V2(
        {
          key: 'products',
          templateOptions: {
            label: 'Type of products, product options',
            initial: this.data.products,
          },
        },
        this.flightRequestPageV2Service.getBookingRevenueProducts()
      ),
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          SELECT_V2(
            {
              key: 'stops',
              className: 'col-6',
              templateOptions: {
                label: 'Stops',
                placeholder: 'Any',
                initial: this.data.stops ? getInitialStops(this.data.stops as StopsType) : null,
              },
            },
            of(mapEnumToDropdownItems(StopsType))
          ),
          SELECT_V2(
            {
              key: 'maxLayover',
              className: 'col-6',
              templateOptions: {
                label: 'Max Layover',
                placeholder: 'Any',
                initial: this.data.maxLayover
                  ? getInitialMaxLayover(this.data.maxLayover as MaxLayoverType)
                  : null,
              },
            },
            of(mapEnumToDropdownItems(MaxLayoverType))
          ),
        ],
      },
      TEXTAREA_V2({
        key: 'notes',
        templateOptions: {
          label: 'Notes',
        },
      }),
    ];
  }

  submitForm() {
    const formLoadingStateService = AppInjector.injector.get(FormLoadingStateService);
    formLoadingStateService.isLoading$.next(true);

    super.submitForm();
  }
}
