<div data-id="group" class="mt-2 group" *ngFor="let group of itineraryGroups">
  <div class="head">
    <typography data-id="group-head" class="title mb-1" look="H3">
      {{ group.departureAirport.cityName }} ({{ group.departureAirport.locationCode }}) to {{
      group.arrivalAirport.cityName }} ({{ group.arrivalAirport.locationCode }}) — {{
      group.departureAt | rdrDate }}
    </typography>
    <div data-id="group-total-time" class="total-time ms-auto">
      Total travel time: {{ group.travelTime | travelTimeFormat }}
    </div>
  </div>
  <div *ngFor="let segment of group.segments; let last = last">
    <itinerary-segment
      [ngClass]="{
        'd-block mb-2': last
      }"
      [segment]="segment"
      [withColors]="withColors"
    ></itinerary-segment>
  </div>
</div>
