import { Pipe, PipeTransform } from '@angular/core';
import { Interaction } from '@app/generated/graphql';
import { isEmailMessageNode, isLeadMessageNode, isWholeCalls } from '../utils/utils';

@Pipe({
  name: 'getIcon',
})
export class GetIconPipe implements PipeTransform {
  transform(interaction: Interaction) {
    if (isWholeCalls(interaction)) {
      return 'phone';
    }
    if (isLeadMessageNode(interaction)) {
      return 'message';
    }
    if (isEmailMessageNode(interaction)) {
      return 'mail';
    }

    return 'voyagu';
  }
}
