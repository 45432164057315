import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { DropdownAbstractClass } from '@ui/components/dropdown-abstract';
import { AuthUser } from '@auth/user.model';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { BehaviorSubject } from 'rxjs';
import {
  AvailabilityService,
  AvailableStatus,
} from '@app/calls/services/availability/availability.service';
import { UserType } from '@app/generated/graphql';
import { RdrFeature } from '@app/ui/services/feature-toggle.service';

@Component({
  selector: 'user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNavbarComponent extends DropdownAbstractClass {
  AvailableStatus = AvailableStatus;
  RdrFeature = RdrFeature;

  availableStatus$: BehaviorSubject<AvailableStatus>;

  isSales: boolean;
  readonly UserType = UserType;
  @Input() user: AuthUser;

  @HostBinding('class.pressed') get pressed() {
    return !this.collapsed;
  }

  constructor(
    elRef: ElementRef,
    cdRef: ChangeDetectorRef,
    private authService: CognitoAuthService,
    private availabilityService: AvailabilityService
  ) {
    super(elRef, cdRef);
    this.isSales = this.authService.isSales;
    this.availableStatus$ = this.availabilityService.availableStatus$;
  }
}
