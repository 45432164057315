import { Pipe, PipeTransform } from '@angular/core';
import { trimFirstWord } from '@app/shared/utils/string-utils';

@Pipe({
  name: 'trimFirstWord',
})
export class TrimFirstWordPipe implements PipeTransform {
  transform(str = ''): string {
    return trimFirstWord(str);
  }
}
