<ng-container
  *ngIf="isReadonly; else editableTpl"
  [ngTemplateOutlet]="valueView"
  [ngTemplateOutletContext]="{ readonly: isReadonly }"
></ng-container>

<ng-template #editableTpl>
  <ng-content select="[editableView]"></ng-content>
</ng-template>

<ng-template #valueView>
  <div class="readonly-view">
    <ng-content select="[readonlyView]"></ng-content>
  </div>
</ng-template>
