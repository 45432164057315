import { Injectable } from '@angular/core';
import { Device, Call } from '@twilio/voice-sdk';
import { SignJWT } from 'jose';
import { TwilioClientConfig, twilioConfig } from '@calls/services/twilio/twilio.config';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TwilioService {
  device: Device | null;

  async login(callbacks: TwilioCallbacks, identity: string) {
    twilioConfig.identity = twilioConfig.identity || identity;

    const token = await this.createToken(twilioConfig);
    return this.createDevice({ token, ...callbacks });
  }

  logout() {
    this.destroyDevice();
  }

  async createToken({
    keySid,
    accountSid,
    expirationTimeSec,
    identity,
    secret,
  }: TwilioClientConfig) {
    const now = Math.floor(Date.now() / 1000);

    const token = await new SignJWT({
      jti: keySid + '-' + now.toString(),
      iss: keySid,
      sub: accountSid,
      iat: now,
      exp: now + Number(expirationTimeSec),
      grants: {
        identity: identity,
        voice: {
          incoming: { allow: true },
          outgoing: {
            application_sid: environment.TWILIO_SID,
          },
        },
      },
    })
      .setProtectedHeader({ typ: 'JWT', alg: 'HS256', cty: 'twilio-fpa;v=1' })
      .sign(new TextEncoder().encode(secret));

    return token;
  }

  createDevice({ token, ...callbacks }: { token: string } & TwilioCallbacks) {
    const { handleLoginSuccess, handleError } = callbacks;
    const device = new Device(token);

    device.on(DeviceEvent.Registered, () => handleLoginSuccess());
    device.on(DeviceEvent.Error, (twilioError, call: Call) => handleError(twilioError, call));

    this.device = device;
  }

  destroyDevice() {
    this.device?.destroy();
    this.device = null;
  }
}

export enum DeviceEvent {
  Registered = 'registered',
  Incoming = 'incoming',
  Error = 'error',
}

export enum CallEvent {
  Accept = 'accept',
  Cancel = 'cancel',
  Disconnect = 'disconnect',
}

export type TwilioCallbacks = {
  handleLoginSuccess: () => void;
  handleError: (twilioError: any, call: Call) => void;
};

/**
 * make outbound call:
 *
 * const appSid = 'AP6c6b5e03b66cda353e61f2c71ceb5e4e';
 *
 * grants: {
 *  ...
 *  voice {
 *    ...
 *    outgoing: {
 *      application_sid: appSid
 *    }
 *  }
 *
 * device.connect({ params: { To: '+48...' } });
 */
