import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'msToTime',
})
export class MsToTimePipe implements PipeTransform {
  transform(value: number) {
    return DateTime.fromMillis(value).toFormat('h:mm a').toLowerCase();
  }
}
