import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNavbarComponent } from './components/user-navbar/user-navbar.component';
import { BtnIconComponent } from './components/btn-icon/btn-icon.component';
import { UserNavbarItemComponent } from './components/user-navbar-item/user-navbar-item.component';
import { TypographyComponent } from './components/typography/typography.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabsItemComponent } from './components/tabs-item/tabs-item.component';
import { BtnComponent } from './components/button/btn.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { BackBtnComponent } from './components/back-btn/back-btn.component';
import { DropdownComponent } from '@ui/components/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { FoldableSectionComponent } from './components/foldable-section/foldable-section.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HoverClassDirective } from './directives/hover-class.directive';
import { OptionButtonComponent } from './components/option-button/option-button.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { BlockEmptyMsgComponent } from './components/block-empty-msg/block-empty-msg.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ColoredStatusComponent } from './components/colored-status/colored-status.component';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ShowForRoleDirective } from '@ui/directives/show-for-role/show-for-role.directive';
import { HideForRoleDirective } from './directives/show-for-role/hide-for-role.directive';
import { SectionComponent } from './components/section/section.component';
import { InfoBadgeComponent } from '@ui/components/info-badge/info-badge.component';
import { FinanceCardComponent } from '@ui/components/finance-card/finance-card.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { DropdownMilesAccItemsComponent } from './components/dropdown-miles-acc-items/dropdown-miles-acc-items.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SectionLoadingBarComponent } from './components/section-loading-bar/section-loading-bar.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { AirlineLogoComponent } from './components/airline-logo/airline-logo.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { RouterModule } from '@angular/router';
import { CollapsedTooltipComponent } from './components/collapsed-tooltip/collapsed-tooltip.component';
import { UserPictureComponent } from './components/user-picture/user-picture.component';
import { TeamMembersComponent } from './components/team-members/team-members.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '@ui/pipes/pipes.module';
import { CardComponent } from './components/card/card.component';
import { FeatureToggleOnDirective } from './directives/feature-toggle/feature-toggle-on.directive';
import { FeatureToggleOffDirective } from './directives/feature-toggle/feature-toggle-off.directive';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { TripInfoComponent } from './components/trip-info/trip-info.component';
import { IconsModule } from '@app/icons/icons.module';
import { TeamMembersV2Component } from './components/team-members-v2/team-members-v2.component';

@NgModule({
  declarations: [
    UserNavbarComponent,
    BtnIconComponent,
    UserNavbarItemComponent,
    TypographyComponent,
    TabsComponent,
    TabsItemComponent,
    BtnComponent,
    LoadingOverlayComponent,
    BackBtnComponent,
    DropdownComponent,
    DropdownItemComponent,
    FoldableSectionComponent,
    HoverClassDirective,
    OptionButtonComponent,
    LoadingSpinnerComponent,
    BlockEmptyMsgComponent,
    BadgeComponent,
    ColoredStatusComponent,
    ToasterComponent,
    ShowForRoleDirective,
    HideForRoleDirective,
    UserPictureComponent,
    SectionComponent,
    InfoBadgeComponent,
    FinanceCardComponent,
    DropdownMilesAccItemsComponent,
    SectionLoadingBarComponent,
    AgentCardComponent,
    AirlineLogoComponent,
    ClientCardComponent,
    CollapsedTooltipComponent,
    TeamMembersComponent,
    TeamMembersV2Component,
    CardComponent,
    FeatureToggleOnDirective,
    FeatureToggleOffDirective,
    TripInfoComponent,
  ],
  exports: [
    UserNavbarComponent,
    BtnIconComponent,
    UserNavbarItemComponent,
    TypographyComponent,
    TabsComponent,
    TabsItemComponent,
    BtnComponent,
    LoadingOverlayComponent,
    BackBtnComponent,
    DropdownComponent,
    FoldableSectionComponent,
    HoverClassDirective,
    DropdownItemComponent,
    OptionButtonComponent,
    LoadingSpinnerComponent,
    BlockEmptyMsgComponent,
    BadgeComponent,
    ColoredStatusComponent,
    ShowForRoleDirective,
    HideForRoleDirective,
    UserPictureComponent,
    SectionComponent,
    InfoBadgeComponent,
    FinanceCardComponent,
    DropdownMilesAccItemsComponent,
    SectionLoadingBarComponent,
    AgentCardComponent,
    AirlineLogoComponent,
    ClientCardComponent,
    CollapsedTooltipComponent,
    TeamMembersComponent,
    TeamMembersV2Component,
    CardComponent,
    FeatureToggleOnDirective,
    FeatureToggleOffDirective,
    TripInfoComponent,
  ],
  imports: [
    NgxDatatableModule,
    EditableModule,
    CommonModule,
    RouterModule,
    IconsModule,
    PipesModule,
    TooltipModule,
    ReactiveFormsModule,
    UiV2Module,
    ToastNoAnimationModule.forRoot({
      disableTimeOut: true,
      // extendedTimeOut: 3000,
      positionClass: 'rdr-toastr-container',
      toastClass: 'rdr-toastr ngx-toastr',
      toastComponent: ToasterComponent,
      closeButton: true,
    }),
  ],
})
export class UiModule {}
