<nav>
  <ul>
    <li *ngIf="hasBackButton">
      <back-btn>
        <typography look="H4">BACK</typography>
      </back-btn>
    </li>
    <anchor-item [header]="header" *ngFor="let link of items; trackBy: trackByFn" [for]="link.id"
      >{{ link.label }}</anchor-item
    >
    <ng-content></ng-content>
  </ul>
</nav>
