import { AbstractControl } from '@angular/forms';

export function alphanumericValidator(control: AbstractControl) {
  const { value } = control;
  if (!value) {
    return null;
  }

  return /^[a-z0-9]+$/i.test(value?.toString() as string) ? null : { alphanumeric: true };
}
