import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DropdownItem } from '@ui/components/dropdown.model';

@Component({
  selector: 'dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
  @Input() item: DropdownItem;
  @Output() itemClick: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  @HostBinding('class.colored')
  public get isColor(): boolean {
    return this.item?.colored || false;
  }

  @HostBinding('class.unselectable')
  public get isGroupBy(): boolean {
    return !!this.item?.groupBy;
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.itemClick.emit(this.item);
  }
}
