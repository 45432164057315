import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { RdrFormlyFieldConfig } from '../../basefield/basefield.component';

@Component({
  selector: 'booking-cost-repeat',
  templateUrl: './booking-cost-repeat.component.html',
  styleUrls: ['./booking-cost-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCostRepeatComponent extends FieldArrayType<RdrFormlyFieldConfig> {
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  add() {
    super.add();
    this.formControl.parent?.markAsDirty();
    this.cdRef.detectChanges();
  }
}
