import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { InvoiceNode } from '@app/generated/graphql';

@Component({
  selector: 'costs-table-cell',
  templateUrl: './costs-table-cell.component.html',
  styleUrls: ['./costs-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostsTableCellComponent {
  @Input() costInvoices: InvoiceNode[];
  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
