import { Pipe, PipeTransform } from '@angular/core';
import {
  ChangeSearchTaskStatusType,
  FeedbackTypeEnum,
  IdInput,
  SearchTaskNode,
} from '@app/generated/graphql';

@Pipe({
  name: 'searchTaskShowedReopenPanel',
})
export class SearchTaskShowedReopenPanelPipe implements PipeTransform {
  transform(
    searchTask: SearchTaskNode | undefined,
    feedback: FeedbackTypeEnum,
    selectedReasons: IdInput[] = []
  ): boolean {
    const cancelled = searchTask?.status === ChangeSearchTaskStatusType.Cancelled;
    const closed = searchTask?.status === ChangeSearchTaskStatusType.Closed;
    const skipReasons = !!(selectedReasons.length || feedback === FeedbackTypeEnum.Good);

    return cancelled || (closed && feedback && skipReasons);
  }
}
