import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { CC_CVV, CC_EXP, CC_NUMBER, TEXT_INPUT, TYPEAHEAD } from '@app/formly/helpers/fields';
import { CountriesService } from '@shared/services/countries/countries.service';
import { UntypedFormGroup } from '@angular/forms';
import { mapEntityToDropdownItem } from '@shared/utils/dropdown-utils';
import { GenericNode } from '@app/shared/types';

@Component({
  selector: 'credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardFormComponent extends RadarFormAbstract implements OnInit {
  formGroup = new UntypedFormGroup({});

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      CC_NUMBER({
        key: 'number',
        templateOptions: {
          required: true,
        },
      }),
      CC_EXP({
        key: 'expiration',
        modelOptions: {
          updateOn: 'blur',
        },
      }),
      CC_CVV({
        key: 'cvv2',
      }),
      TEXT_INPUT({
        key: 'holder',
        templateOptions: { label: 'Cardholder Name' },
      }),
      TEXT_INPUT({
        key: 'billingAddress1',
        templateOptions: { label: 'Billing Address 1' },
      }),
      TEXT_INPUT({
        key: 'billingAddress2',
        templateOptions: { label: 'Billing Address 2' },
      }),
      TEXT_INPUT({
        key: 'city',
        templateOptions: { label: 'City' },
      }),
      TEXT_INPUT({
        key: 'state',
        templateOptions: { label: 'State' },
      }),
      TYPEAHEAD(
        {
          key: 'countryId',
          id: 'country',
          templateOptions: {
            clearable: true,
            label: 'Country',
            initial:
              this.formModel?.country &&
              mapEntityToDropdownItem(this.formModel?.country as GenericNode),
          },
        },
        this.countriesService.getCountriesAsDropDownItems()
      ),
      TEXT_INPUT({
        key: 'zipCode',
        templateOptions: { label: 'ZIP' },
      }),
    ];
  }

  constructor(private countriesService: CountriesService) {
    super();
  }
}
