import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum PageHeaderVariant {
  small = 'small',
  big = 'big',
}

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() variant: 'small' | 'big' = PageHeaderVariant.small;
  headerVariant = PageHeaderVariant;
}
