<div class="d-flex row striped-row py-1" *ngFor="let lead of leads | extractNodes">
  <div class="col text-truncate" title="{{ lead | userName }}" data-id="client-name">
    {{ lead | userName }}
  </div>
  <div class="col text-truncate" title="{{ lead.emails ? lead.emails[0] : '' }}" data-id="email">
    {{ lead.emails ? lead.emails[0] : '' }}
  </div>
  <div class="col text-truncate" title="{{ lead.phones ? lead.phones[0] : '' }}" data-id="phone">
    {{ lead.phones ? lead.phones[0] : '' }}
  </div>
  <div class="col text-truncate" data-id="assign" (click)="rowClick.emit(lead.id)">
    <button-v2 look="link">Assign to me</button-v2>
  </div>
</div>
