import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@Component({
  selector: 'card-cvv-v2',
  templateUrl: './card-cvv-v2.component.html',
  styleUrls: ['./card-cvv-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCvvV2Component extends BasefieldV2Component {}
