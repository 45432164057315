import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('copiedAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
    ]),
    trigger('btnAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CopyToClipboardComponent {
  constructor(private cdRef: ChangeDetectorRef) {}

  copied = false;
  @Input() value: string | null;

  copyDone() {
    setTimeout(() => {
      this.copied = false;
      this.cdRef.detectChanges();
    }, 1000);
  }

  copy() {
    if (!this.value) {
      return;
    }
    void navigator.clipboard
      .writeText(this.value || '')
      .then(() => {
        this.copied = true;
        this.cdRef.detectChanges();
      })
      .catch((error) => console.log(error));
  }
}
