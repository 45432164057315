<a
  *ngFor="let booking of bookings | extractNodes"
  (click)="openBooking($event, booking); rowClick.emit()"
  class="row striped-row py-1"
>
  <div class="col-2 text-truncate" title="{{ booking | bookingType }}" data-id="type">
    {{ booking | bookingType | label }}
  </div>
  <div class="col-4 text-truncate" data-id="flights">
    <short-flight-info [flightItinerary]="booking.firstFlightItinerary"></short-flight-info>
  </div>
  <div
    class="col-2 text-truncate"
    title="{{ booking.totalCost | currency: 'USD' }}"
    data-id="total-cost"
  >
    {{ booking.totalCost | currency: 'USD' }}
  </div>
  <div class="col-2 text-truncate" title="{{ booking.bookingRevenue?.pnr }}" data-id="pnr">
    {{ booking.bookingRevenue?.pnr }}
  </div>
  <div class="col-2 text-truncate" title="{{ booking.status | label }}" data-id="status">
    {{ booking.status | label }}
  </div>
</a>
