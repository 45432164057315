<div class="textarea-wrapper">
  <editable
    [ngClass]="{'readonly': to.readonly}"
    (modeChange)="modeChange()"
    [attr.data-id]="field.dataId"
    #editableRef
  >
    <ng-template viewMode>
      <div
        (click)="changeMode($event)"
        [ngClass]="{'input-error-border': showError}"
        class="view-mode"
        #viewModeRef
      >
        <typography *ngIf="!formControl.value" look="inputPlaceholder">
          {{ field.templateOptions?.placeholder }}
        </typography>

        <typography *ngIf="formControl.value">
          <pre class="pre"> {{ formControl.value }} </pre>
        </typography>

        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <textarea
        id="textarea"
        class="formly-input textarea"
        radarOnEnter
        radarEditableFocus
        editableOnEnter
        editableOnEscape
        (radarOnEscape)="cancel()"
        [disabled]="field.templateOptions?.readonly === true"
        [placeholder]="field.templateOptions?.placeholder"
        [formControl]="formControl"
        [formlyAttributes]="field"
      ></textarea>
    </ng-template>
  </editable>
</div>
