import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { BookingType } from '@app/shared/models/booking-type.enum';
import { RdrFeature } from '@app/ui/services/feature-toggle.service';

@Component({
  selector: 'booking-type-selector',
  templateUrl: './booking-type-selector.component.html',
  styleUrls: ['./booking-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingTypeSelectorComponent {
  BookingType = BookingType;
  RdrFeature = RdrFeature;

  @Input() selectedBookingType: BookingType;
  @Input() isDemo: boolean;
  @Input() withoutDemo: boolean;

  @Output() selectBooking = new EventEmitter();
  @Output() selectIsDemo = new EventEmitter();
}
