import { Injectable } from '@angular/core';
import { AirportNode } from '@app/generated/graphql';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AirportsService {
  getAirportsAsDropDownItems() {
    return from(import('static/airports').then((mod) => mod.default)).pipe(
      map((airports) => airports)
    );
  }
}

export function airportsDropDownMapper(node: AirportNode): DropdownItem {
  return { value: node.id, label: `${node.cityName} (${node.locationCode})` };
}
