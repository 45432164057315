import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { AuthUser } from '@auth/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  user: AuthUser;

  constructor(public authService: CognitoAuthService, public router: Router) {}

  ngOnInit(): void {
    this.user = this.authService.user;
  }
}
