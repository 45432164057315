import { AbstractControl } from '@angular/forms';
import { CostNodeInput, CostTypeInput } from '@generated/graphql';
import { isNil } from 'lodash';

export function bookingCostValidator(control: AbstractControl) {
  const { value } = control;

  if (!value || value.length === 0) {
    return null;
  }

  const hasInvalid = (value as CostNodeInput[]).find((cost) => {
    if (!cost) return null;

    const keys: (keyof CostNodeInput)[] =
      cost.type === CostTypeInput.Hotel
        ? ['unitAmount', 'type', 'hotelName', 'hotelNights']
        : ['unitAmount', 'perType', 'type'];

    return keys.some((key: keyof CostNodeInput) => isBlank(cost[key]));
  });

  if (hasInvalid) {
    return { 'booking-cost': true };
  }

  return null;
}

function isBlank(val: unknown) {
  return isNil(val) || val === '';
}
