import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent extends FieldType<FieldTypeConfig> implements OnInit {
  selected: DropdownItem;

  items: DropdownItem[] = [];

  ngOnInit() {
    if (this.to.values) {
      this.items = this.to.values.map((value: { key: string; description: string }) => {
        return {
          value: value.key,
          label: value.description,
        } as DropdownItem;
      });
    }

    if (this.formControl.value) {
      this.selected = this.items.find(
        (item) => item.value === this.formControl.value
      ) as DropdownItem;
    }
  }

  select(item: DropdownItem) {
    this.selected = item;
    this.formControl.setValue(item.value);
  }
}
