import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: number) {
    return new Date(Number(value) * 1000).toISOString().substring(11, 19);
  }
}
