<typography-v2 *ngIf="type === 'currency'" look="small" class="v2-mb-1"
  >{{ from | rdrCurrency }} – {{ to | rdrCurrency }}</typography-v2
>

<typography-v2 *ngIf="type === 'minutes'" look="small" class="v2-mb-1"
  >{{ from | minutesFormat }} – {{ to | minutesFormat }}</typography-v2
>

<typography-v2 *ngIf="type === 'time'" look="small" class="v2-mb-1"
  >{{ from | msToTime }} – {{ to | msToTime }}</typography-v2
>

<div class="slider v2-my-2">
  <div class="d-flex align-items-center position-relative v2-mx-2">
    <div #fromTemplate class="from cursor-pointer" data-id="from"></div>

    <div class="w-100" #lineTemplate></div>
    <div class="value" #valueTemplate></div>

    <div #toTemplate class="to cursor-pointer" data-id="to"></div>
  </div>
</div>
