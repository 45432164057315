<field-layout [isReadonly]="isReadonly">
  <editable
    editableView
    (modeChange)="onEditableModeChange($event)"
    [attr.data-id]="field.dataId"
    #editable
  >
    <ng-template viewMode>
      <div [ngClass]="{'input-error-border': showError}" class="view-mode">
        <date-value-view
          [value]="formControl.value"
          [placeholder]="field.templateOptions?.placeholder"
        ></date-value-view>
        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <input
        radarEditableFocus
        editableOnEnter
        editableOnEscape
        radarOnEnter
        (radarOnEscape)="cancel()"
        type="text"
        class="formly-input"
        bsDatepicker
        [bsConfig]="{
          adaptivePosition: true,
          customTodayClass: 'custom-today',
          containerClass: 'theme-default'
        }"
        #input
        #dp="bsDatepicker"
        [isOpen]="isOpen"
        (blur)="onBlur()"
        (bsValueChange)="onDatePicked($event)"
        [formControl]="innerModel"
        [formlyAttributes]="field"
      />
    </ng-template>
  </editable>

  <date-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.templateOptions?.placeholder"
  ></date-value-view>
</field-layout>
