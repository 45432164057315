import { Observable } from 'rxjs';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@ui/components/dropdown.model';
import { map } from 'rxjs/operators';
import { Query } from 'apollo-angular';
import { mapEntityToDropdownItem } from '@shared/utils/dropdown-utils';
import { GenericNode } from '@app/shared/types';

export function fetchAsDropDownItems(
  gql: Query<any, any>,
  edgesContainer: (response: any) => any,
  nodeToDD: (node: any) => any = mapEntityToDropdownItem,
  vars?: any,
  includeEmptyItem = true
): Observable<DropdownItem[]> {
  return gql
    .fetch(vars, {
      fetchPolicy: 'cache-first',
    })
    .pipe(
      map((response) => {
        const items =
          edgesContainer(response)?.edges?.map((el: { node: GenericNode }) => nodeToDD(el.node)) ||
          [];
        if (includeEmptyItem) {
          return [EMPTY_DROP_DOWN_ITEM, ...items];
        }
        return items;
      })
    );
}
