import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BookingStatusType, FlightItinerarySegmentNode } from '@generated/graphql';

@Component({
  selector: 'itinerary-segment',
  templateUrl: './itinerary-segment.component.html',
  styleUrls: ['./itinerary-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItinerarySegmentComponent {
  @Input() segment: FlightItinerarySegmentNode;
  @Input() withColors = false;

  bookingStatus = BookingStatusType;
}
