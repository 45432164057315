import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'multi-wrapper',
  templateUrl: './multi-wrapper.component.html',
  styleUrls: ['./multi-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiWrapperComponent extends FieldWrapper {}
