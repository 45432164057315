import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@Component({
  selector: 'textarea-v2',
  templateUrl: './textarea-v2.component.html',
  styleUrls: ['./textarea-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaV2Component extends BasefieldV2Component {
  @HostListener('keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const target = event.target as HTMLTextAreaElement;
    const selectionStart = target.selectionStart;

    const currentValue = (this.formControl.value as string) || '';

    const newValue =
      currentValue.substring(0, selectionStart) +
      '\n' +
      currentValue.substring(selectionStart, currentValue.length);

    this.formControl.setValue(newValue);
    this.formControl.markAsDirty();

    target.setSelectionRange(selectionStart + 1, selectionStart + 1);
  }
}
