<ng-container [ngSwitch]="creditCardType">
  <img
    *ngSwitchCase="CreditCardTypeEnum.Mc"
    src="/assets/images/payment-systems/mastercard.svg"
    alt="mastercard"
  />

  <img
    *ngSwitchCase="CreditCardTypeEnum.Visa"
    src="/assets/images/payment-systems/visa.svg"
    alt="visa"
  />

  <img
    *ngSwitchCase="CreditCardTypeEnum.D"
    src="/assets/images/payment-systems/dinersclub.svg"
    alt="dinersclub"
  />

  <img
    *ngSwitchCase="CreditCardTypeEnum.Disc"
    src="/assets/images/payment-systems/discover.svg"
    alt="discover"
  />

  <img
    *ngSwitchCase="CreditCardTypeEnum.Jcb"
    src="/assets/images/payment-systems/jcb.svg"
    alt="jcb"
  />

  <!-- <img *ngSwitchCase="'maestro'" src="/assets/images/payment-systems/maestro.svg" alt="maestro" /> -->

  <!-- <img
    *ngSwitchCase="'unionpay'"
    src="/assets/images/payment-systems/unionpay.svg"
    alt="unionpay"
  /> -->

  <img
    *ngSwitchCase="CreditCardTypeEnum.Amex"
    src="/assets/images/payment-systems/american_express.svg"
    alt="american_express"
  />

  <img *ngSwitchDefault src="/assets/images/payment-systems/other.svg" alt="other" />
</ng-container>
