import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyTypeaheadV2Component } from '../typeahead-v2/formly-typeahead-v2.component';

@Component({
  selector: 'thread-select',
  templateUrl: './thread-select.component.html',
  styleUrls: ['./thread-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadSelectComponent extends FormlyTypeaheadV2Component {}
