<dropdown-item-v2
  ><skeleton-loader-small [height]="16" [width]="84"></skeleton-loader-small
></dropdown-item-v2>
<dropdown-item-v2
  ><skeleton-loader-small [height]="16" [width]="72"></skeleton-loader-small
></dropdown-item-v2>
<dropdown-item-v2
  ><skeleton-loader-small [height]="16" [width]="60"></skeleton-loader-small
></dropdown-item-v2>
<dropdown-item-v2
  ><skeleton-loader-small [height]="16" [width]="114"></skeleton-loader-small
></dropdown-item-v2>
<dropdown-item-v2
  ><skeleton-loader-small [height]="16" [width]="82"></skeleton-loader-small
></dropdown-item-v2>
