<ng-container *ngIf="value">
  <btn-icon
    class="d-flex"
    *ngIf="!copied"
    @btnAnimation
    title="Copy to Clipboard"
    icon="copy"
    color="var(--gray-300)"
    (click)="copy(); $event.stopPropagation()"
  ></btn-icon>

  <rdr-svg-icon
    class="d-block ms-1"
    size="lg"
    *ngIf="copied"
    @copiedAnimation
    (@copiedAnimation.done)="copyDone()"
    key="check-mark"
  ></rdr-svg-icon>
</ng-container>
