import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

import { DropdownItem } from '@ui/components/dropdown.model';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';
import {
  getPreselectedDay,
  PreselectedDays,
  supportedDateFormats,
} from '@app/formly/types/date/date-helpers';
import { replaceExtraSeparators } from '@app/shared/utils/date-utils';

@Component({
  selector: 'datetime-v3',
  templateUrl: './datetime-v3.component.html',
  styleUrls: ['./datetime-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeV3Component extends BasefieldV2Component implements OnInit {
  @ViewChild('dateInput') dateInput: ElementRef;

  //todo: this amount of the flags is not healthy – need to break into subcomponents
  hasFocus: boolean;
  hasDropdownFocus: boolean;
  hasHover: boolean;
  showDD: boolean;

  dateInputModel = new FormControl<string | null>(null);
  timeInputModel = new FormControl<string | null>(null);
  timeSteps: string[] = [];
  timeStepsDD: DropdownItem[] = [];
  preselected: Record<PreselectedDays, Date> = {
    tomorrow: getPreselectedDay('tomorrow'),
    'after-tomorrow': getPreselectedDay('after-tomorrow'),
    'after-5-hours': getPreselectedDay('after-5-hours'),
  };

  defaultTime = '10:00 AM';
  ddCollapsed = true;

  supportedTimeFormats = [
    'hh/mm/a', // 01:43 AM
    'h/mm/a', // 1:43 AM
    'HH/mm', // 13 43
    'H/mm',
    'hh/mm',
    'h/mm',
    'hh/a',
    'h/a', // 4 pm
    'HH',
    'H',
    'hh',
    'h',
  ];

  supportedDateWithoutYearFormats = [
    'LLL/dd', // Feb 02
    'LLL/d', // Feb 2
    'd/LLL', // 2 Feb
    'dd/LLL', // 02 Feb
    'LL/dd', // 02 02
    'LL/d', // 02 2
    'L/dd', // 2 02
    'L/d', // 2 2
  ];

  constructor(public cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.formControl.value) {
      this.setTimeInputValue();
    }

    const timeSteps = ['12', ...Array.from(Array(11).keys()).map((i) => i + 1)];
    const am = ['AM', 'PM'];

    am.map((am) => {
      timeSteps.map((el) => {
        this.timeSteps.push(`${el}:00 ${am}`);
        this.timeSteps.push(`${el}:30 ${am}`);
      });
    });
    this.timeStepsDD = this.timeSteps.map((el) => ({
      value: el,
      label: el,
    }));
  }

  onDateInputChange() {
    let dateInput = this.dateInputModel.value || '';
    dateInput = replaceExtraSeparators(dateInput);

    const parsedDate = this.parseDateString(dateInput, 'date');

    if (parsedDate.isValid) {
      this.setDateAndTime(parsedDate.toJSDate(), this.timeInputModel.value || this.defaultTime);
    } else {
      this.formControl.setErrors({ invalidDate: true });
      this.formControl.markAsDirty();
    }
  }

  parseDateString(input: string, type: 'date' | 'time'): DateTime {
    let parsedDate: DateTime = DateTime.now();
    const formats = type === 'date' ? supportedDateFormats : this.supportedTimeFormats;

    for (let i = 0; i < formats.length; i++) {
      parsedDate = DateTime.fromFormat(input, formats[i]);
      if (parsedDate.isValid) {
        if (
          this.supportedDateWithoutYearFormats.includes(formats[i]) &&
          parsedDate < DateTime.now()
        ) {
          parsedDate = setAdditionalYear(parsedDate);
        }

        break;
      }
    }

    return parsedDate;
  }

  setDateAndTime(date: Date, time: string) {
    const newDate = new Date(Date.parse(`${date.toDateString()} ${time}`));
    this.formControl.setValue(newDate);
    this.cdRef.detectChanges();
  }

  onDDTimeInputChange({ value }: { value: string }) {
    this.timeInputModel.setValue(value);
    this.onTimeInputChange(value);
    this.showDD = false;
  }

  onTimeInputChange(value: string) {
    const currentDate = new Date(this.formControl.value as string);

    value = replaceExtraSeparators(value);

    const parsedTime = this.parseDateString(value, 'time');
    const formattedTime = parsedTime.toLocaleString(
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      },
      { locale: 'en-US' }
    );

    if (parsedTime.isValid) {
      this.setDateAndTime(currentDate, formattedTime);
      this.timeInputModel.setValue(formattedTime);
      this.dateInput.nativeElement.focus();
      this.cdRef.detectChanges();
    } else {
      this.formControl.setErrors({ invalidTime: true });
      this.formControl.markAsDirty();
    }
  }

  clearDate() {
    this.dateInputModel.setValue('');
    this.timeInputModel.setValue('');
    this.formControl.setValue('');
    this.cdRef.detectChanges();
  }

  onDatepickerChange(newDate: Date) {
    if (newDate) {
      if (this.formControl.value) {
        const parsedDate = DateTime.fromJSDate(this.formControl.value as Date);

        newDate.setHours(parsedDate.hour);
        newDate.setMinutes(parsedDate.minute);
      }

      this.formControl.setValue(newDate);

      const d = DateTime.fromJSDate(newDate);
      this.dateInputModel.setValue(d.toFormat('LLL d, yyyy'));

      if (!this.timeInputModel.value) {
        this.setTimeInputValue();
      }

      this.cdRef.detectChanges();
    }
  }

  preselectDate(when: PreselectedDays) {
    const date: Date = getPreselectedDay(when);

    this.formControl.setValue(date);

    const time = DateTime.fromJSDate(date).toFormat('h:mm a');
    this.timeInputModel.setValue(time);
  }

  setTimeInputState() {
    if (!this.hasDropdownFocus) {
      this.hasFocus = false;
      this.showDD = false;
    }
  }

  private setTimeInputValue() {
    this.timeInputModel.setValue(
      (this.formControl.value as Date).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }) || this.defaultTime
    );
  }
}

const setAdditionalYear = (parsedDate: DateTime) => {
  const now = new Date().getTime();
  const date = parsedDate.toJSDate().getTime();

  if (date < now) {
    return parsedDate.set({ year: parsedDate.year + 1 });
  }

  return parsedDate;
};
