<ngx-datatable
  [rowHeight]="40"
  [columns]="columns"
  [rows]="rows"
  (activate)="onclick($event)"
  class="m-1 dropdown-miles-acc-items"
></ngx-datatable>

<ng-template #dateTemplate let-value="value">
  <span>{{ value | rdrDate }}</span>
</ng-template>

<ng-template #currencyTemplate let-value="value">
  <span *ngIf="value">{{ value | rdrCurrency: 'cents' }}</span>
</ng-template>
