import { Output, ElementRef, Directive, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  firstOpen = true;

  @Input() clickOutsideShouldIgnoreFirstClick = true;
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event']) handleClick(event: MouseEvent) {
    const isClickOutside =
      !this.elementRef.nativeElement.contains(event.target) &&
      (!this.clickOutsideShouldIgnoreFirstClick || !this.firstOpen);

    if (isClickOutside) {
      this.clickOutside.emit(event);
    } else if (this.firstOpen) {
      this.firstOpen = false;
    }
  }

  constructor(protected elementRef: ElementRef) {}
}
