import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { FormSubmitData } from '@shared/components/radar-form-abstract';
import { uniqueId } from 'lodash';

export type NodeWithDueDate = {
  dueDate?: Date;
  dueDescription?: string;
};

@Component({
  selector: 'due-date-reminder-v2',
  templateUrl: './due-date-reminder-v2.component.html',
  styleUrls: ['./due-date-reminder-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueDateReminderV2Component implements OnChanges {
  @Input() node: NodeWithDueDate;
  @Output() reminderSubmit: EventEmitter<FormSubmitData<NodeWithDueDate>> = new EventEmitter<
    FormSubmitData<NodeWithDueDate>
  >();
  id = uniqueId('datetime');

  formData: NodeWithDueDate;
  showContextMenu: boolean;

  constructor(private modalService: ModalV2Service) {}

  @HostListener('document:keydown.escape', ['$event']) onEscape(_e: Event): void {
    if (this.showContextMenu) {
      this.closeDatepicker();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formData = {
      ...changes.node.currentValue,
      dueDate: changes.node.currentValue.dueDate
        ? new Date(changes.node.currentValue.dueDate as string)
        : changes.node.currentValue.dueDate,
    };
  }

  onSubmit(data: FormSubmitData) {
    if (data.data.dueDate === '') {
      data.data.dueDate = null;
    }
    this.reminderSubmit.emit(data);
  }

  triggerDatepicker() {
    if (!this.showContextMenu) {
      this.modalService.preventCloseOnEscape(this.id);
      this.showContextMenu = true;
    } else {
      this.closeDatepicker();
    }
  }

  closeDatepicker() {
    this.showContextMenu = false;
    this.modalService.allowCloseOnEscape(this.id);
  }
}
