import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyComponent {
  @Input() truncate? = false;
  @Input() look?:
    | 'normal'
    | 'small'
    | 'formLabel'
    | 'inputPlaceholder'
    | 'sectionTitle'
    | 'H1'
    | 'H2'
    | 'H3'
    | 'H4'
    | 'details'
    | 'stats-details'
    | 'stats-title';
  @Input() upper = false;
}
