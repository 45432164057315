import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GetRootOrdersGQL,
  GetRootOrdersQuery,
  GetRootOrdersQueryVariables,
  OrderPriceEstimateGQL,
  OrderPriceEstimateQueryVariables,
} from '@app/generated/graphql';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private getRootOrdersGQL: GetRootOrdersGQL,
    private orderPriceEstimateGQL: OrderPriceEstimateGQL
  ) {}

  getRootOrders(
    params: GetRootOrdersQueryVariables
  ): Observable<ApolloQueryResult<GetRootOrdersQuery>> {
    return this.getRootOrdersGQL.watch(params).valueChanges;
  }

  orderPriceEstimate(params: OrderPriceEstimateQueryVariables) {
    return this.orderPriceEstimateGQL
      .fetch(params)
      .pipe(map(({ data }) => data.orderPriceEstimate));
  }
}
