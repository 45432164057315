import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FlightRequestNode, LeadNode } from '@app/generated/graphql';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';
import { FlightRequestTripNotesForm } from '@app/shared/forms/flight-request-trip-notes-form/flight-request-trip-notes-form.component';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';

@Component({
  selector: 'trip-notes-v2',
  templateUrl: './trip-notes-v2.component.html',
  styleUrls: ['./trip-notes-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripNotesV2Component implements OnChanges {
  tripsCardHeight = 0;

  @Input() entity: FlightRequestNode | LeadNode;

  @Output() notesSubmit = new EventEmitter();

  constructor(private modalV2Service: ModalV2Service, private cdRef: ChangeDetectorRef) {}

  ngOnChanges() {
    this.tripsCardHeight = 0;
    setTimeout(this.getHeight.bind(this));
  }

  getHeight() {
    const flightsHeight = document.getElementById('flights')?.clientHeight || 0;
    const qualificationHeight = document.getElementById('qualification')?.clientHeight || 0;
    const priceToBeatHeight = document.getElementById('price-to-beat')?.clientHeight || 0;
    const contactHeight = document.getElementById('contact')?.clientHeight || 0;

    if (this.entity.__typename === 'LeadNode') {
      const newHeight = contactHeight - qualificationHeight;
      this.tripsCardHeight = newHeight > MIN_HEIGHT_TRIP_NOTES ? newHeight : MIN_HEIGHT_TRIP_NOTES;
    } else {
      this.tripsCardHeight =
        flightsHeight +
        (qualificationHeight ? qualificationHeight : 0) +
        (priceToBeatHeight ? priceToBeatHeight : 0);
    }

    this.cdRef.detectChanges();
  }

  showModal(template: TemplateRef<any>): void {
    this.modalV2Service.showModal({ template, className: 'wide' });
  }

  submitNotes(data: FormSubmitData<FlightRequestTripNotesForm>) {
    this.notesSubmit.emit(data);
  }
}

const MIN_HEIGHT_TRIP_NOTES = 86;
