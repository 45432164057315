import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'badge-v2',
  templateUrl: './badge-v2.component.html',
  styleUrls: ['./badge-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeV2Component {
  @HostBinding('class') @Input() look:
    | 'emergency'
    | 'lost-reason'
    | 'booking-request'
    | 'due-date' = 'emergency';
}
