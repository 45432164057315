<ng-container *ngIf="picture; else labelTpl">
  <img
    [ngClass]="{ 'big': size === 'big', 'small': size === 'small', 'semi': size === 'semi' }"
    [src]="picture"
    class="user-icon"
  />
</ng-container>

<ng-template #labelTpl>
  <div
    [ngClass]="{ 'big': size === 'big', 'small': size === 'small', 'semi': size === 'semi' }"
    class="d-flex align-items-center justify-content-center text-purple user-icon icon-label"
  >
    {{ label?.slice(0, 1) || '-' }}
  </div>
</ng-template>
