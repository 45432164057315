<div class="position-relative">
  <ng-container *ngIf="showFullname; else withoutFullnameTemplate">
    <button-user-picture-v2
      [userName]="currentUser?.fullName"
      [picture]="currentUser?.picture"
      [size]="'small'"
      (btnClick)="showAssignModal = true"
    >
    </button-user-picture-v2>
  </ng-container>
  <ng-template #withoutFullnameTemplate>
    <button-user-picture-v2
      [picture]="currentUser?.picture"
      [size]="'small'"
      [tooltip]="currentUser?.fullName"
      container="body"
      placement="left"
      (btnClick)="showAssignModal = true"
    >
    </button-user-picture-v2>
  </ng-template>

  <context-menu-v2
    *ngIf="showAssignModal"
    class="v2-p-4 position-absolute"
    (clickOutside)="closeAssignModal()"
  >
    <search-input-v2
      class="v2-mb-3 w-100"
      [placeholder]="searchPlaceholder"
      (searchTermChanged)="searchFilter = $event"
    ></search-input-v2>
    <div class="agents-list">
      <ng-container
        *ngFor="let userGroup of groupedUsers | groupedUsersFilter : searchFilter; let firstGroup = first"
      >
        <typography-v2
          *ngIf="userGroup.name"
          [ngClass]="{ 'v2-mt-3': !firstGroup }"
          class="d-flex v2-mb-3"
          look="text-bold"
          data-id="title"
          >{{ userGroup.name }}</typography-v2
        >
        <ng-container *ngFor="let user of userGroup.value ; let isLast = last">
          <button-user-picture-v2
            class="w-100"
            [class.v2-mb-3]="!isLast"
            [picture]="user?.picture"
            [size]="'small'"
            [userName]="user?.label"
            (btnClick)="onUserSelected(user)"
          >
          </button-user-picture-v2>
        </ng-container>
      </ng-container>
    </div>
  </context-menu-v2>
</div>
