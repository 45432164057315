import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'foldable-section',
  templateUrl: './foldable-section.component.html',
  styleUrls: ['./foldable-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldableSectionComponent {
  @Input() collapsed = false;
  @Input() sectionTitle: string;

  toggleCollapsed(e: Event) {
    e.preventDefault();
    this.collapsed = !this.collapsed;
  }
}
