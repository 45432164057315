<form [formGroup]="formGroup" (ngSubmit)="submitForm()" (keydown.enter)="$event.preventDefault()">
  <formly-form
    (modelChange)="modelChanged($event)"
    [form]="formGroup"
    [model]="formModel"
    [fields]="fields"
  ></formly-form>

  <div *ngIf="!isInEditMode()" class="mt-3 d-flex">
    <btn
      data-id="cancelCreateCard"
      class="ms-auto"
      look="secondary"
      size="big"
      (btnClick)="onCancel()"
      >Cancel</btn
    >
    <btn data-id="createCard" class="ms-2" type="submit" look="primary" size="big"
      >Create Credit Card</btn
    >
  </div>
</form>
