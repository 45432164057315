import { Injectable } from '@angular/core';
import {
  GetAgentsGQL,
  GetAgentsQuery,
  GetTeamMembersGQL,
  GetTeamMembersQuery,
  Scalars,
  UserNode,
  UserType,
} from '@app/generated/graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class TeamMembersService {
  private selectedTeamMembersIds$: BehaviorSubject<TeamMembers> = new BehaviorSubject(new Set());

  constructor(private getTeamMembersGQL: GetTeamMembersGQL, private getAgentsGQL: GetAgentsGQL) {}

  get selectedMembersIdsSource(): Observable<TeamMembers> {
    return this.selectedTeamMembersIds$;
  }

  getTeamMembers(id: Scalars['ID']): Observable<UserNode[]> {
    const params = { id };

    return this.getTeamMembersGQL
      .fetch(params)
      .pipe(map(teamMembersMapper), map(sortTeamMembersCallback));
  }

  getSalesAgentsAndTeamLeads(): Observable<UserNode[]> {
    const params = {
      filters: { typeIn: [UserType.SalesAgent, UserType.SalesTeamLead], isActive: true },
    };

    return this.getAgentsGQL.fetch(params, { fetchPolicy: 'cache-first' }).pipe(map(agentsMapper));
  }

  getTicketingAgents(): Observable<UserNode[]> {
    const params = {
      filters: { type: UserType.TicketingAgent, isActive: true },
    };

    return this.getAgentsGQL.fetch(params, { fetchPolicy: 'cache-first' }).pipe(map(agentsMapper));
  }

  replaceMembers(newMembers: TeamMembers) {
    this.selectedTeamMembersIds$.next(newMembers);
  }
}

const teamMembersMapper = ({ data }: ApolloQueryResult<GetTeamMembersQuery>) => {
  return data.user?.teamMembers?.edges.map((edge) => edge.node) as UserNode[];
};

const agentsMapper = ({ data }: ApolloQueryResult<GetAgentsQuery>) => {
  return data.users?.edges.map((edge) => edge.node) as UserNode[];
};

export type TeamMembers = Set<Scalars['ID']>;

const sortTeamMembersCallback = (teamMembers: UserNode[]) =>
  teamMembers.sort((a, b) => {
    if ((a.fullName as string) < (b.fullName as string)) {
      return -1;
    }
    if ((a.fullName as string) > (b.fullName as string)) {
      return 1;
    }
    return 0;
  });
