import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { UntypedFormControl } from '@angular/forms';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { CostNodeInput, CostPerType, CostType, CostTypeInput } from '@app/generated/graphql';
import { MAX_HOTEL_NAME_LENGTH } from '@app/shared/constants';
import IMask from 'imask';
import { combineLatest, map, startWith } from 'rxjs';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { isBoolean } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'booking-cost',
  templateUrl: './booking-cost.component.html',
  styleUrls: ['./booking-cost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCostComponent extends BasefieldV2Component implements OnInit {
  CostType = CostType;
  maxHotelNameLength = MAX_HOTEL_NAME_LENGTH;

  imaskNumeric: IMask.MaskedNumberOptions;
  imask: IMask.MaskedNumberOptions;

  costTypes: DropdownItem[] = [];
  selectedCostType: DropdownItem;

  perTypes: DropdownItem[] = [];
  selectedPerType: DropdownItem;

  isDirectTypes: DropdownItem[] = [];
  selectedIsDirect: DropdownItem<any> = IsDirectTypes[0];

  costTypeControl = new UntypedFormControl();
  perTypeControl = new UntypedFormControl();
  hotelNameControl = new UntypedFormControl();
  hotelNightsControl = new UntypedFormControl();
  unitAmountControl = new UntypedFormControl();
  isDirectControl = new UntypedFormControl(IsDirectTypes[0].value);

  ngOnInit() {
    const excluded = this.to.hotelBooking
      ? [CostType.Ticket, CostType.HotelBooking, CostType.Null]
      : [CostType.HotelBooking, CostType.Null, CostType.Hotel];

    this.costTypes = mapEnumToDropdownItems(CostType, {
      excluded: [...excluded, ...(this.to.excludedCostTypes || [])],
    });

    this.perTypes = mapEnumToDropdownItems(CostPerType);

    this.isDirectTypes = IsDirectTypes as DropdownItem<any>[];

    this.imaskNumeric = {
      mask: Number,
      signed: !this.to.positiveOnly,
      scale: this.to.integerOnly ? 0 : 3,
      thousandsSeparator: this.to.delimiter || ' ',
    };

    this.imask = {
      mask: Number,
      signed: true,
      radix: '.',
      thousandsSeparator: ',',
      scale: this.to.currencyFormat === 'cents' ? 5 : 2,
      padFractionalZeros: true,
    };

    if (this.formControl.value) {
      const cost = this.formControl.value as CostNodeInput;

      const disabled = this.form.disabled || !!this.to.disabled || !!this.to.readonly;

      if (disabled) {
        this.costTypeControl.disable();
        this.perTypeControl.disable();
        this.hotelNameControl.disable();
        this.hotelNightsControl.disable();
        this.unitAmountControl.disable();
        this.isDirectControl.disable();
      }

      this.costTypeControl.setValue(cost.type);
      this.perTypeControl.setValue(cost.perType);
      this.hotelNameControl.setValue(cost.hotelName);
      this.hotelNightsControl.setValue(cost.hotelNights);
      this.unitAmountControl.setValue(cost.unitAmount);

      this.selectedCostType = this.costTypes.find(
        ({ value }) => value === cost.type
      ) as DropdownItem;

      this.selectedPerType = this.perTypes.find(
        ({ value }) => value === cost.perType
      ) as DropdownItem;

      this.isDirectControl.setValue(
        isBoolean(cost.isDirect)
          ? cost.isDirect
          : getDefaultIsDirect(this.selectedCostType.value as CostType).value
      );

      this.selectedIsDirect = isBoolean(cost.isDirect)
        ? (this.isDirectTypes.find(
            ({ value }) => Boolean(value) === Boolean(cost.isDirect)
          ) as DropdownItem)
        : getDefaultIsDirect(this.selectedCostType.value as CostType);
    } else if (this.to.hotelBooking) {
      this.costTypeControl.setValue(CostTypeInput.Hotel);

      this.selectedCostType = this.costTypes.find(
        ({ value }) => value === CostTypeInput.Hotel
      ) as DropdownItem;
    }

    combineLatest([
      this.costTypeControl.valueChanges.pipe(startWith(this.costTypeControl.value)),
      this.perTypeControl.valueChanges.pipe(startWith(this.perTypeControl.value)),
      this.hotelNameControl.valueChanges.pipe(startWith(this.hotelNameControl.value)),
      this.hotelNightsControl.valueChanges.pipe(startWith(this.hotelNightsControl.value)),
      this.unitAmountControl.valueChanges.pipe(startWith(this.unitAmountControl.value)),
      this.isDirectControl.valueChanges.pipe(startWith(this.isDirectControl.value)),
    ])
      .pipe(
        map(([type, perType, hotelName, hotelNights, unitAmount, isDirect]) => {
          this.formControl.setValue({
            type,
            perType,
            hotelName,
            hotelNights,
            unitAmount,
            id: this.formControl.value?.id,
            isDirect,
          });
          this.formControl.markAsDirty();
        })
      )
      .pipe(UntilDestroyed(this))
      .subscribe();
  }

  selectCostType(costType: DropdownItem) {
    this.selectedCostType = costType;
    this.costTypeControl.setValue(costType.value);

    this.selectedIsDirect = getDefaultIsDirect(costType.value as CostType);
    this.isDirectControl.setValue(this.selectedIsDirect.value);
  }

  selectPerType(perType: DropdownItem) {
    this.selectedPerType = perType;
    this.perTypeControl.setValue(perType.value);
  }

  selectIsDirect(selectedIsDirect: DropdownItem) {
    this.selectedIsDirect = selectedIsDirect;
    this.isDirectControl.setValue(selectedIsDirect.value);
  }

  onAccept() {} // without this hack form do not work consistently
}

const getDefaultIsDirect = (costType: CostType) => {
  return [CostType.IssuingFee, CostType.Mileage, CostType.Commission].includes(costType)
    ? IsDirectTypes[1]
    : IsDirectTypes[0];
};

const IsDirectTypes = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];
