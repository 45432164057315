import { Injectable } from '@angular/core';
import {
  GetInteractionsGQL,
  GetInteractionsQueryVariables,
  Interaction,
} from '@app/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractionsService {
  constructor(private getInteractionsGQL: GetInteractionsGQL) {}

  getInteractions(params: GetInteractionsQueryVariables) {
    return this.getInteractionsGQL
      .fetch(params)
      .pipe(map(({ data }) => data.interactions as Interaction[]));
  }
}
