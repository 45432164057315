import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingNode } from '@app/generated/graphql';

@Component({
  selector: 'hotel-itinerary',
  templateUrl: './hotel-itinerary.component.html',
  styleUrls: ['./hotel-itinerary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelItineraryComponent {
  @Input() booking: BookingNode;
}
