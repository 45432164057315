<field-layout [isReadonly]="isReadonly">
  <editable editableView #editableRef [attr.data-id]="field.dataId">
    <ng-template viewMode>
      <div
        class="view-mode"
        #viewModeRef
        [ngClass]="{'input-error-border': formControl?.errors?.phone}"
      >
        <phone-value-view
          [value]="formControl.value"
          [placeholder]="field.templateOptions?.placeholder"
          [mask]="imask"
          [flagClass]="selectedCountry.flagClass!"
        ></phone-value-view>
        <rdr-svg-icon
          class="pencil-icon me-1 ms-auto"
          key="pencil-create"
          color="var(--in-place-icon)"
        ></rdr-svg-icon>
      </div>
    </ng-template>
    <ng-template editMode>
      <div class="flag iti__flag" [ngClass]="selectedCountry.flagClass!"></div>
      <input
        class="formly-input tel-input"
        type="tel"
        autocomplete="off"
        radarOnEnter
        (radarOnEscape)="cancel()"
        radarEditableFocus
        editableOnEnter
        editableOnEscape
        [imask]="imask"
        (blur)="onBlur()"
        (ngModelChange)="onPhoneNumberChange()"
        [formControl]="innerModel"
        [formlyAttributes]="field"
      />
    </ng-template>
  </editable>

  <phone-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.templateOptions?.placeholder"
    [mask]="imask"
    [flagClass]="selectedCountry.flagClass!"
  ></phone-value-view>
</field-layout>
