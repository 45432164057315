import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';

@Component({
  selector: 'right-pane-tabs',
  templateUrl: './right-pane-tabs.component.html',
  styleUrls: ['./right-pane-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightPaneTabsComponent implements OnInit {
  @Input() leadId = '';
  @Input() milesAccountId = '';
  @Input() invoiceId = '';

  @Input() historyRoot: string;

  rightPaneTabs = RightPaneTabs;

  tabs: string[];
  activeTab: string;

  constructor(private cognitoAuthService: CognitoAuthService) {}

  ngOnInit(): void {
    this.initTabs();

    if (this.tabs?.length) {
      this.selectTab(this.tabs[0]);
    }
  }

  initTabs(): void {
    if (this.leadId || this.milesAccountId || this.invoiceId) {
      this.tabs = [RightPaneTabs.Notes];
    }

    if (this.historyRoot && this.cognitoAuthService.hasHistoryAccess) {
      this.tabs.push(RightPaneTabs.History);
    }

    if (this.leadId) {
      this.tabs.push(RightPaneTabs.EmailMessages);
    }
  }

  selectTab(tab: string): void {
    this.activeTab = tab;
  }
}

enum RightPaneTabs {
  Notes = 'Notes',
  History = 'History',
  EmailMessages = 'Email Messages',
}
