<div class="col-3 text-truncate" title="{{ item | userName }}" data-id="client-name">
  {{ item | userName }}
</div>
<div class="col-2 text-truncate" title="{{ item.emails ? item.emails[0] : '' }}" data-id="email">
  {{ item.emails ? item.emails[0] : '' }}
</div>
<div class="col-2 text-truncate" title="{{ item.phones ? item.phones[0] : '' }}" data-id="phone">
  {{ item.phones ? item.phones[0] : '' }}
</div>
<div class="col-3 text-truncate" title="{{ item.salesAgent?.fullName }}" data-id="sales-agent">
  <agent-card [agent]="item.salesAgent"></agent-card>
</div>
<div
  *ngIf="($any(item)).status as status"
  class="col-2 text-truncate"
  title="{{ status | label }}"
  data-id="status"
>
  {{ status | label }}
</div>
