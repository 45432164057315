import { Pipe, PipeTransform } from '@angular/core';
import { CreditCard } from 'angular-cc-library';

@Pipe({
  name: 'ccType',
})
export class CcTypePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '';
    }
    return CreditCard.cardType(value);
  }
}
