<div class="d-flex align-items-center wrapper px-1">
  <input
    type="checkbox"
    class="cursor-pointer"
    [attr.data-id]="field.dataId"
    [class.is-invalid]="showError"
    [indeterminate]="to.indeterminate && formControl.value === null"
    [formControl]="formControl"
    [formlyAttributes]="field"
  />
</div>
