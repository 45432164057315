import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@Component({
  selector: 'input-v2',
  templateUrl: './input-v2.component.html',
  styleUrls: ['./input-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputV2Component extends BasefieldV2Component {}
