import { BookingType } from '@app/shared/models/booking-type.enum';
import { BookingNode } from '@generated/graphql';

export function getBookingType(booking: BookingNode): BookingType {
  if (booking.bookingRevenue) {
    return BookingType.REVENUE;
  }
  if (booking.bookingHotel) {
    return BookingType.HOTEL;
  }

  return BookingType.MILES;
}
