import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackBtnComponent {
  constructor(private location: Location) {}

  goBack(event: Event): void {
    this.location.back();
    event.preventDefault();
  }
}
