import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'text-input-value-view',
  templateUrl: './text-input-value-view.component.html',
  styleUrls: ['./text-input-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputValueViewComponent {
  @Input() value: string;
  @Input() placeholder?: string;
}
