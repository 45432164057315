import { Pipe, PipeTransform } from '@angular/core';
import { isNumeric } from '@app/shared/utils';

@Pipe({
  name: 'lessThenZero',
})
export class LessThenZeroPipe implements PipeTransform {
  transform(value?: number): boolean {
    if (!value) return false;
    return isNumeric(value) && value < 0;
  }
}
