import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import {
  BasefieldComponent,
  RdrFormlyFieldConfig,
  RdrFormlyTemplateOptions,
} from '../basefield/basefield.component';
import { debounce, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { interval, Observable, of, Subscriber } from 'rxjs';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

export interface RdrTypeaheadFormlyFieldConfig extends RdrFormlyFieldConfig {
  templateOptions?: {
    emitFullObject?: boolean;
    asyncOptionsFetch?: (value: string) => Observable<any>;
  } & RdrFormlyTemplateOptions;
}

@UntilDestroy()
@Component({
  selector: 'typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyTypeaheadComponent
  extends BasefieldComponent<RdrTypeaheadFormlyFieldConfig>
  implements OnInit
{
  @ViewChild('typeaheadInputRef') typeaheadInputRef: ElementRef;

  innerModel = new UntypedFormControl('');
  selectedItem: DropdownItem | undefined;

  options$: Observable<DropdownItem[]>;
  typeahead$?: Observable<DropdownItem[]>;

  typeaheadOptions: DropdownItem[] = [];

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        tap(() => {
          if (this.formControl.value === null || this.formControl.value === '') {
            this.innerModel.setValue('');
          }
        })
      )
      .pipe(UntilDestroyed(this))
      .subscribe();

    if (this.formControl.value) {
      if (this.to.initial) {
        this.selectOption({ item: this.to.initial } as TypeaheadMatch);
      }
    }

    this.initOptions();

    if (this.to.asyncOptionsFetch) {
      this.initAsyncTypeaheadState();
    } else {
      this.initTypeaheadState();
    }
  }

  initAsyncTypeaheadState(): void {
    this.typeahead$ = new Observable((observer: Subscriber<string>) =>
      observer.next(this.innerModel.value as string)
    ).pipe(
      debounce(() => interval(400)),
      distinctUntilChanged(),
      switchMap((searchValue: string) => {
        return this.to.asyncOptionsFetch(searchValue) as Observable<DropdownItem[]>;
      })
    );
  }

  initTypeaheadState(): void {
    this.typeahead$ = new Observable((observer: Subscriber<string>) =>
      observer.next(this.innerModel.value as string)
    ).pipe(
      switchMap((searchValue: string) => {
        if (searchValue) {
          const filteredOptions: DropdownItem[] = [];
          const leastOptions: DropdownItem[] = [];

          searchValue = searchValue.toLowerCase();

          this.typeaheadOptions.forEach((option: DropdownItem) => {
            const code = option.extras?.code?.toLowerCase();
            const label = option.label?.toLowerCase();

            if (code?.indexOf(searchValue) === 0) {
              filteredOptions.push(option);
            } else if ((label as string)?.indexOf(searchValue) > -1) {
              leastOptions.push(option);
            }
          });
          return of([...filteredOptions, ...leastOptions]);
        }
        return of(this.typeaheadOptions);
      })
    );
  }

  initOptions(): void {
    this.options$ = (this.to.options as Observable<DropdownItem[]>).pipe(
      tap((data) => (this.typeaheadOptions = data))
    );
  }

  selectOption({ item }: TypeaheadMatch): void {
    this.selectedItem = item;
    this.innerModel.setValue(item.label);
    if (this.to.emitFullObject === true) {
      this.formControl.setValue(item);
    } else {
      this.formControl.setValue(item.value);
    }

    this.editable?.cancelEdit();
  }

  cancel(): void {
    this.innerModel?.setValue(this.selectedItem?.label);
  }

  clear() {
    if (this.formControl.value === '') {
      this.innerModel.setValue('');
      this.typeaheadInputRef?.nativeElement?.focus();
    } else {
      this.selectOption({ item: EMPTY_DROP_DOWN_ITEM } as TypeaheadMatch);
    }
  }

  onFocus() {
    setTimeout(() => {
      this.typeaheadInputRef?.nativeElement?.select();
    });
  }
}
