import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'form-field-v2',
  templateUrl: './form-field-v2.component.html',
  styleUrls: ['./form-field-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldV2Component extends FieldWrapper {}
