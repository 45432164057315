import { AfterViewInit, ElementRef } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[radarEditableFocus]',
})
export class RadarEditableFocusDirective implements AfterViewInit {
  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.focus();
  }
}
