import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { ButtonV2Type } from '../button-v2/button-v2.component';

@Component({
  selector: 'confirm-modal-v2',
  templateUrl: './confirm-modal-v2.component.html',
  styleUrls: ['./confirm-modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalV2Component {
  @Input() header = '';
  @Input() message = '';
  @Input() confirmText = 'Confirm';
  @Input() confirmButtonType: ButtonV2Type = 'primary';

  @Output() confirmed = new EventEmitter();

  emitting: boolean;

  constructor(private modalV2Service: ModalV2Service) {}

  hideModalAndEmit(): void {
    if (!this.emitting) {
      this.emitting = true;
      this.modalV2Service.hideFirst();
      this.confirmed.emit();
    }
  }

  hideModal(): void {
    if (!this.emitting) {
      this.emitting = true;
      this.modalV2Service.hideFirst();
    }
  }
}
