import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RdrCurrencyFormat } from '@ui/pipes/rdr-currency.pipe';

@Component({
  selector: 'currency-value-view',
  templateUrl: './currency-value-view.component.html',
  styleUrls: ['./currency-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyValueViewComponent {
  @Input() value: number;
  @Input() placeholder?: string;
  @Input() currencyFormat?: RdrCurrencyFormat;
}
