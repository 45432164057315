import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createdAt',
})
export class CreatedAtPipe implements PipeTransform {
  transform(value: Date | string, short = false) {
    const date = new Date(value);
    const today = new Date();

    const diff = today.getTime() - date.getTime();

    let m = diff / 1000 / 60;
    let h = m / 60;
    let d = h / 24;

    d = Math.floor(d);
    if (d > 0) {
      return short ? `${d}d` : `${d} ${d > 1 ? 'days' : 'day'} ago`;
    }

    h = Math.floor(h);
    if (h > 0) {
      return short ? `${h}h` : `${h} ${h > 1 ? 'hours' : 'hour'} ago`;
    }

    m = Math.floor(m);
    if (m > 0) {
      return short ? `${m}m` : `${m} ${m > 1 ? 'minutes' : 'minute'} ago`;
    }

    return short ? '1m' : '1 minute ago';
  }
}
