<card>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <div class="d-flex flex-column">
        <typography-v2>Current Itinerary</typography-v2>

        <ng-container *ngIf="createdByUserId && createdAt">
          <typography-v2
            *ngIf="createdByUserId | loadUserById | async as user"
            look="small"
            class="text-jazlyn-700"
            dataId="info"
            >{{ user.fullName | shortFullName }} created {{ createdAt | createdAt }}</typography-v2
          >
        </ng-container>
      </div>

      <div class="separator v2-mx-4"></div>

      <div class="d-flex flex-column">
        <typography-v2 dataId="passengers-count">{{ totalPassengersCount }}</typography-v2>
        <typography-v2 look="small" class="text-jazlyn-700">Passengers</typography-v2>
      </div>
    </div>

    <button-v2 (click)="newChangeRequestOption.emit()" type="filter" data-id="copy-to-new-option"
      >Copy to New Option</button-v2
    >
  </div>

  <hr class="v2-my-4" />

  <typography-v2 look="text-bold">Bookings</typography-v2>

  <div class="d-flex overflow-auto v2-mt-2">
    <booking-card
      *ngIf="(bookings.length || 0) > 1"
      (click)="setActiveBooking(null)"
      [bookingsLength]="bookings.length || 0"
      [activeBooking]="activeBooking"
      [readonlyPage]="true"
      data-id="entire-card"
      class="v2-mr-1"
    ></booking-card>

    <ng-container *ngFor="let booking of bookings; let first = first">
      <booking-card
        (click)="setActiveBooking(booking)"
        [booking]="booking"
        [activeBooking]="activeBooking"
        [readonlyPage]="true"
        [ngClass]="{ 'v2-ml-1': !first }"
        data-id="booking-card"
      ></booking-card>
    </ng-container>
  </div>

  <div *ngIf="activeBooking; else entireTemplate" class="v2-mt-2">
    <ng-container [ngSwitch]="activeBooking | bookingType">
      <div class="d-flex align-items-center">
        <booking-miles-head
          *ngSwitchCase="BookingType.MILES"
          [booking]="activeBooking"
        ></booking-miles-head>

        <booking-revenue-head
          *ngSwitchCase="BookingType.REVENUE"
          [booking]="activeBooking"
        ></booking-revenue-head>
      </div>
    </ng-container>

    <ng-container [ngSwitch]="activeBooking | bookingType">
      <hotel-itinerary
        *ngSwitchCase="BookingType.HOTEL"
        [booking]="activeBooking"
      ></hotel-itinerary>

      <itinerary-v2-flights
        *ngSwitchDefault
        [flightItineraries]="activeBooking.flightItineraries"
        [booking]="activeBooking"
        [readonly]="true"
        [readonlyExtra]="true"
      ></itinerary-v2-flights>
    </ng-container>
  </div>
</card>

<ng-template #entireTemplate>
  <itinerary-v2-flights
    [flightItineraries]="changeRequestCurrentFlightItineraries"
    [showSeatsSelection]="true"
    [readonly]="true"
    [readonlyExtra]="true"
    class="v2-mt-3"
  ></itinerary-v2-flights>

  <ng-container *ngFor="let booking of bookings">
    <hotel-itinerary
      *ngIf="booking.bookingHotel"
      [booking]="booking"
      class="v2-mt-2"
    ></hotel-itinerary>
  </ng-container>
</ng-template>
