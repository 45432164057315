import config from '@client-configs/twilio.json';

export type TwilioClientConfig = {
  /** https://console.twilio.com/ -> Account Sid */
  accountSid: string;

  /** https://console.twilio.com/us1/account/keys-credentials/api-keys -> App Sid & Secret (secret will be shown only once) */
  keySid: string;
  secret: string;

  /** https://console.twilio.com/us1/develop/phone-numbers/manage/incoming -> Take a phone number (replace to BE UserNode) */
  identity?: string;

  /** https://www.twilio.com/console/voice/twiml/apps -> TwiML App SID */
  appSid?: string;

  expirationTimeSec: string;
};

export const twilioConfig: TwilioClientConfig = Object.assign({}, config);
