import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { EventService } from '@app/shared/services/event.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

export type ModalClass = 'wide' | 'extra-wide' | 'wider' | string | undefined;

@Injectable({
  providedIn: 'root',
})
export class ModalV2Service {
  modalRefs?: BsModalRef[] = [];

  config: ModalOptions = {
    animated: true,
    ignoreBackdropClick: true,
    keyboard: false,
  };

  openedWindowIds: Set<string> = new Set();

  constructor(private modalService: BsModalService, private eventService: EventService) {
    this.eventService.onEscapeClick().subscribe(() => this.hideFirst());
  }

  showModal({
    template,
    className,
    initialState,
    backdrop,
  }: {
    template:
      | TemplateRef<any>
      | {
          new (...args: any[]): any;
        };
    className?: ModalClass;
    initialState?: any;
    backdrop?: boolean | 'static';
  }) {
    const modalRef = this.modalService.show(template, {
      class: `modal-v2 modal-dialog-centered ${className || ''}`,
      ...this.config,
      initialState,
      backdrop,
    });
    this.modalRefs?.push(modalRef);

    return modalRef;
  }

  preventCloseOnEscape(id: string) {
    this.openedWindowIds.add(id);
  }

  allowCloseOnEscape(id: string) {
    this.openedWindowIds.delete(id);
  }

  hideFirst() {
    if (!this.openedWindowIds.size && this.modalRefs) {
      this.modalService.hide(this.modalRefs[this.modalRefs?.length - 1]?.id);
      this.modalRefs?.splice(this.modalRefs.length - 1, 1);
    }
  }

  hideModal() {
    this.modalRefs?.forEach((modalRef) => {
      this.modalService.hide(modalRef.id);
    });

    this.openedWindowIds.clear();
    this.modalRefs = [];
  }

  onHidden(): EventEmitter<void> {
    return this.modalService.onHidden;
  }
}
