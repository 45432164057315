import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallPopupComponent } from '@calls/components/call-popup/call-popup.component';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { UiModule } from '@app/ui/ui.module';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';

@NgModule({
  declarations: [CallPopupComponent],
  providers: [],
  imports: [CommonModule, UiModule, UiV2Module, PipesModule],
  exports: [CallPopupComponent],
})
export class CallsModule {}
