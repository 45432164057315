import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BookingType } from '@app/shared/models/booking-type.enum';

@Component({
  selector: 'booking-type-badge',
  templateUrl: './booking-type-badge.component.html',
  styleUrls: ['./booking-type-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingTypeBadgeComponent {
  @Input() bookingType: BookingType;
}
