import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { CardTypeEnum } from '@generated/graphql';
import { concatMap, map } from 'rxjs/operators';
import { RdrAuthService } from '@auth/services/rdr-auth.service';

@Injectable({
  providedIn: 'root',
})
export class PipelineAuthGuard implements CanActivate {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
    private rdrAuth: RdrAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognitoAuthService.hasCognitoSession().pipe(
      concatMap(() => this.rdrAuth.getUser()),
      map(() => {
        if (this.cognitoAuthService.isSearcher) {
          if (route.queryParams.type === CardTypeEnum.FlightRequest) {
            void this.router.navigate(['flight-requests', route.queryParams.id]);

            return false;
          }
          if (route.queryParams.type === CardTypeEnum.ChangeRequest) {
            void this.router.navigate(['change-requests', route.queryParams.id]);

            return false;
          }
        }

        return true;
      })
    );
  }
}
