import { Injectable, Type } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GraphQLError } from 'graphql';
import { FormSubmitData } from '@shared/components/radar-form-abstract';
import { GenericNode, GraphqlID } from '@app/shared/types';
import { Observable } from 'rxjs';
import { ModalFormComponent } from '@app/pages/orders/forms/modal-form/modal-form.component';

export type ModalFormSubmitFn = (data: FormSubmitData) => void;

export type ModalFormState<T = GenericNode> = {
  entityId?: GraphqlID;
  modalHeader?: string;
  formComponent?: Type<any>;
  initials?: T;
  formDataSubject$?: Observable<GenericNode>;
  formData?: GenericNode;
  submitHandler?: ModalFormSubmitFn;
  readonly?: boolean;
  className?: 'wide-modal';
};

@Injectable({
  providedIn: 'root',
})
export class ModalFormService {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  //TODO: remove formSubmit param and use submitHandler from initialState instead
  showModal(initialState: ModalFormState, formSubmit?: ModalFormSubmitFn) {
    this.modalRef = this.modalService.show(ModalFormComponent, {
      initialState,
      class: initialState.className,
    });
    this.modalRef.content.formSubmit.subscribe(formSubmit);

    return this.modalRef;
  }

  setErrors(errs: readonly GraphQLError[]) {
    this.modalRef.content.setErrors(errs);
  }

  setFetching(fetching: boolean) {
    this.modalRef.content.setFetching(fetching);
  }

  hideModal(): void {
    this.modalService.hide();
  }
}
