import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormLoadingStateService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();

  setState(state: boolean) {
    this.isLoading$.next(state);
  }
}
