<typography-v2 *ngIf="to.label" class="v2-mb-1">{{ to.label }}</typography-v2>

<slider
  (setRange)="change($event)"
  [min]="timeToValue(to.values.min_value)"
  [max]="timeToValue(to.values.max_value)"
  [from]="formControl.value?.length ? timeToValue(formControl.value[0]) : 0"
  [to]="formControl.value?.length ? timeToValue(formControl.value[1]) : 0"
  [step]="60000"
  [attr.data-id]="field.key"
  type="time"
></slider>
