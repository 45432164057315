import { Injectable } from '@angular/core';
import { CountriesGQL, CountryNode } from '@generated/graphql';
import { fetchAsDropDownItems } from '@shared/services/dropdown-fetch';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private countriesGql: CountriesGQL) {}

  getCountriesAsDropDownItems() {
    return fetchAsDropDownItems(
      this.countriesGql,
      ({ data }) => data.countries,
      getInitialCountryDDItem
    );
  }
}

export const getInitialCountryDDItem = (node: CountryNode) => {
  if (!node) {
    return node;
  }

  return {
    value: node.id,
    label: `${node.code} - ${node.name}`,
    extras: { code: node.code },
  };
};
