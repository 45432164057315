<div
  *ngIf="formControl.value"
  (click)="showEditModal()"
  class="view-mode cursor-pointer"
  [attr.data-id]="field.dataId"
>
  <typography *ngIf="!innerModel.value" look="inputPlaceholder"
    >{{ field.templateOptions?.placeholder }}</typography
  >

  <ng-container *ngIf="innerModel.value">
    <typography> {{ innerModel.value | ccNumberFormat }} </typography>
    <typography class="cc-type position-absolute cursor-default me-1" look="small">
      {{ innerModel.value | ccType }}
    </typography>
  </ng-container>

  <rdr-svg-icon
    class="pencil-icon me-1 ms-auto"
    key="pencil-create"
    color="var(--in-place-icon)"
  ></rdr-svg-icon>
</div>
