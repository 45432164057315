import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchTaskNode } from '@app/generated/graphql';

@Component({
  selector: 'first-system-comment',
  templateUrl: './first-system-comment.component.html',
  styleUrls: ['./first-system-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstSystemCommentComponent {
  @Input() searchTask?: SearchTaskNode;
}
