import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'date-value-view',
  templateUrl: './date-value-view.component.html',
  styleUrls: ['./date-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateValueViewComponent {
  @Input() value: Date | string | number | undefined;
  @Input() placeholder?: string;
}
