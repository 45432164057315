<form [formGroup]="formGroup" (ngSubmit)="submitFormV2()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [fields]="fields" [model]="formModel"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2
      *ngIf="allowDelete"
      [confirmOnDelete]="'passenger'"
      (deleteConfirmed)="deletePassenger.emit()"
      formButtonState
      type="destructive"
      class="v2-mr-3"
      data-id="passenger-delete"
    >
      <typography-v2 look="caption-bold">Delete</typography-v2>
    </button-v2>

    <button-v2
      formButtonState
      [disabled]="!formGroup.valid || formGroup.pristine || hasTotalPassengersError"
      buttonType="submit"
      data-id="passenger-save"
    >
      <typography-v2 look="caption-bold">{{ entityId ? 'Save' : 'Add Passenger' }}</typography-v2>
    </button-v2>
  </div>
</form>
