<a
  *ngFor="let order of orders | extractNodes"
  (click)="rowClick.emit()"
  routerLink="/orders/{{order.id}}"
  class="row striped-row py-1"
>
  <div class="col-2 text-truncate" title="{{ order.id }}" data-id="id">{{ order.id }}</div>
  <div class="col-3 text-truncate" data-id="pax">
    <span *ngIf="order.passengersCount">Passengers: {{ order.passengersCount }}</span>
  </div>
  <div class="col-4 text-truncate" data-id="flights">
    <short-flight-info
      *ngIf="order?.flightItineraries?.length > 0"
      [flightItinerary]="order.flightItineraries[0]"
    ></short-flight-info>
  </div>
  <div class="col-3 text-truncate" title="{{ order.status | label }}" data-id="status">
    {{ order.status | label }}
  </div>
</a>
