<div
  [attr.data-id]="'outer-' + currentField.dataId"
  class="mb-2 position-relative align-items-center"
  [ngClass]="
    currentField.formControl?.value === null && field.fieldArray?.type === 'credit-card-modal'
      ? 'd-none'
      : 'd-flex'
  "
  hoverClass
  *ngFor="let currentField of field.fieldGroup; let i = index"
>
  <div [ngSwitch]="currentField.type" class="field">
    <tel-input *ngSwitchCase="'phone'" [field]="currentField"></tel-input>
    <formly-field-input *ngSwitchCase="'text-input'" [field]="currentField"></formly-field-input>

    <formly-credit-card
      *ngSwitchCase="'credit-card-modal'"
      [field]="currentField"
    ></formly-credit-card>
    <validation-message
      *ngIf="formControl.controls[i].errors?.['phone'] || formControl.controls[i].errors?.['email']"
      [field]="currentField"
    ></validation-message>
  </div>

  <ng-container *ngIf="to.showDeleteConfirmation && !to.readonly">
    <div class="d-flex align-items-center action">
      <div *ngIf="showConfirmDelete[i]" class="d-flex confirm-delete-buttons position-absolute">
        <btn
          data-id="delete-button"
          look="outline-danger"
          (btnClick)="showConfirmDelete[i] = false; remove(i)"
          >Delete</btn
        >
        <btn
          data-id="cancel-button"
          look="outline-secondary"
          (btnClick)="showConfirmDelete[i] = false"
          class="ms-1"
          >Cancel</btn
        >
      </div>

      <rdr-svg-icon
        data-id="delete-w-confirm"
        *ngIf="!showConfirmDelete[i]"
        class="delete-icon ms-1 cursor-pointer"
        key="trash"
        size="md"
        color="var(--cherry-red)"
        (click)="setDeleteConfirm(i, true)"
      ></rdr-svg-icon>
    </div>
  </ng-container>

  <ng-container *ngIf="!to.showDeleteConfirmation && !to.readonly">
    <div class="d-flex align-items-center action">
      <rdr-svg-icon
        data-id="delete-wo-confirm"
        *ngIf="!showConfirmDelete[i]"
        class="delete-icon ms-1 cursor-pointer"
        key="trash"
        size="md"
        color="var(--cherry-red)"
        (click)="remove(i)"
      ></rdr-svg-icon>
    </div>
  </ng-container>

  <copy-to-clipboard
    class="ms-1 copy-tcb"
    *ngIf="to.showCopyToClipboard && currentField.formControl?.value"
    [value]="currentField.formControl?.value | formlyFieldFormattedValue : currentField.type"
  ></copy-to-clipboard>
</div>

<div class="pt-1">
  <btn
    [disabledBtn]="to.readonly"
    [attr.data-id]="'add-' + key"
    look="secondary"
    size="big"
    icon="plus"
    (btnClick)="add()"
  >
    {{ to.addText }}</btn
  >
</div>
