<div (click)="collapsed = !collapsed" class="d-flex align-items-center cursor-pointer">
  <typography-v2 *ngIf="to.label" look="text-semibold">{{ to.label }}</typography-v2>

  <rdr-svg-icon
    [ngClass]="{ 'rotated': collapsed }"
    class="ms-auto chevron"
    size="lg"
    key="chevron-right"
  ></rdr-svg-icon>
</div>

<div *ngIf="!collapsed" class="v2-mt-2">
  <ng-container #fieldComponent></ng-container>
</div>

<hr *ngIf="!to.last" class="v2-mt-4" />
