import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightRequestStatusInteraction, FlightRequestStatusType } from '@app/generated/graphql';

@Component({
  selector: 'flight-request-status-event',
  templateUrl: './flight-request-status-event.component.html',
  styleUrls: ['./flight-request-status-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightRequestStatusEventComponent {
  @Input() interaction: FlightRequestStatusInteraction & {
    flightRequestStatus: FlightRequestStatusType;
    flightRequestPreviousStatus: FlightRequestStatusType;
  };
}
