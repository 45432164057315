import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'checkbox-v2-wrapper',
  templateUrl: './checkbox-v2-wrapper.component.html',
  styleUrls: ['./checkbox-v2-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxV2WrapperComponent extends FieldWrapper {}
