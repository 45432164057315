<typeahead-v3
  [placeholder]="to.placeholder || ''"
  (selectItem)="selectOption($event)"
  [selectedItem]="selectedItem"
  [items]="to.optionsService"
  [disabled]="to.disabled || form.disabled"
  [key]="field.key"
  [isFilter]="to.isFilter"
  [groupByField]="to.groupedFields ? 'groupBy' : ''"
  [filterCallback]="to.filterCallback"
  [fetchOnSearch]="to.asyncOptionsFetch"
></typeahead-v3>
