import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { NavigatableContainerAbstract } from '@app/ui/components/navigatable-dropdown-abstract';
import { DropdownItemV2Component } from '../dropdown-item-v2/dropdown-item-v2.component';

@Component({
  selector: 'dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownV2Component extends NavigatableContainerAbstract {
  @Input() items: DropdownItem[] = [];
  @Input() selectedItem: DropdownItem | null;
  @Input() placeholder = '';
  @Input() look: 'default' | 'link' | 'just-items' | 'default-slim' = 'default';
  @Input() wideItemsContainer = false;
  @Input() disabledDropdown: boolean | undefined = false;
  @Input() isFilter = false;
  @Input() customListTemplate: TemplateRef<any>;

  @Input() selectedItems?: DropdownItem[] = [];
  @Input() multiselect = false;

  @Output() itemSelect = new EventEmitter();
  @HostBinding('class.compact') @Input() compact: boolean;
  @ViewChildren(DropdownItemV2Component, { read: ElementRef })
  itemElementRefs: QueryList<ElementRef>;

  get isScrollable() {
    return false;
  }

  get itemsForNavigation(): DropdownItem<string>[] {
    return this.items;
  }

  onItemSelected = (item: DropdownItem<string>) => {
    this.selectItem(item);
  };

  setHoveredItem = (item: DropdownItem) => {
    this.hoveredItem = item;
  };

  selectItem(item: DropdownItem): void {
    this.itemSelect.emit(item);

    if (!this.multiselect) {
      setTimeout(() => this.toggleCollapsed());
    }
  }

  isSelectedItem(item: DropdownItem) {
    return this.selectedItems?.find(({ value }) => value === item.value);
  }

  getSelectedItemsLabels() {
    return this.selectedItems?.map(({ label }) => label).join(', ');
  }
}
