import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FlightItineraryGroupNode } from '@app/generated/graphql';

@Pipe({
  name: 'flightInfo',
})
export class FlightInfoPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(flightItineraries?: FlightItineraryGroupNode[]): string {
    if (!flightItineraries?.length) {
      return '-';
    }
    const group = flightItineraries[0];
    const date = this.datePipe.transform(group.departureAt) || '';

    return `${group.departureAirport.locationCode}-${group.arrivalAirport.locationCode} | ${date}`;
  }
}
