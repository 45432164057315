<ng-container *ngIf="emitFullObject; else stringTemplate">
  <typography *ngIf="!value?.value" look="inputPlaceholder"> {{ placeholder }} </typography>
  <typography *ngIf="value?.value" class="text-truncate d-inline-block">
    {{ value.label }}
  </typography>
</ng-container>
<ng-template #stringTemplate>
  <typography *ngIf="!value" look="inputPlaceholder"> {{ placeholder }} </typography>
  <typography *ngIf="value" class="text-truncate d-inline-block"> {{ label }} </typography>
</ng-template>
