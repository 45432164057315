<div class="d-flex justify-content-between position-relative">
  <typography-v2 look="subtitle-bold">Notifications</typography-v2>

  <rdr-svg-icon
    (click)="isShowActions = true"
    key="more"
    class="cursor-pointer"
    data-id="more"
  ></rdr-svg-icon>

  <context-menu-v2
    *ngIf="isShowActions"
    (clickOutside)="isShowActions = false"
    class="position-absolute actions"
  >
    <dropdown-item-v2
      (click)="$event.stopPropagation(); markAllAsRead()"
      class="action"
      data-id="mark-all-as-read"
      >Mark all as read</dropdown-item-v2
    >
  </context-menu-v2>
</div>

<div class="scrollable v2-mt-4">
  <div *ngIf="loading && !alerts.length">
    <alerts-loading></alerts-loading>
  </div>

  <div
    *ngFor="let alert of alerts; let first = first"
    (click)="markAlert(alert, $event)"
    [ngClass]="{ 'new': !alert.read }"
    data-id="alert-{{ alert.id }}"
    class="cursor-pointer alert-item-wrapper"
  >
    <div class="d-flex v2-px-2 v2-py-4 alert-item">
      <div class="v2-mr-2">
        <img
          *ngIf="alert.sender else clientTemplate"
          [src]="alert.sender?.picture"
          alt="agent"
          class="icon"
          tooltip="{{ alert.sender?.fullName }}"
          container="body"
        />

        <ng-template #clientTemplate>
          <div
            *ngIf="alert.client; else systemTemplate"
            class="d-flex align-items-center justify-content-center icon client"
            tooltip="{{ alert.client?.firstName }} {{ alert.client?.lastName }}"
            container="body"
          >
            {{ alert.client?.firstName | firstChar }}{{ alert.client?.lastName | firstChar }}
          </div>
        </ng-template>

        <ng-template #systemTemplate>
          <div class="d-flex align-items-center justify-content-center icon">
            <rdr-svg-icon key="voyagu" color="var(--color-jazlyn-700)" size="xl"></rdr-svg-icon>
          </div>
        </ng-template>
      </div>

      <div class="d-flex flex-column">
        <typography-v2 class="text-jazlyn-700" look="small" dataId="created-at"
          >{{ alert.createdAt | createdAt }}</typography-v2
        >

        <typography-v2 class="v2-mt-1 description" dataId="description"
          >{{ alert.description }}</typography-v2
        >

        <div class="d-flex align-items-center text-purple cursor-pointer v2-mt-1">
          <rdr-svg-icon
            key="external-link"
            color="var(--color-purple)"
            class="v2-mr-1"
          ></rdr-svg-icon>

          <typography-v2 dataId="entity"
            >{{ alert.entityType | label }} #{{ alert.entityId }}</typography-v2
          >
        </div>
      </div>

      <div class="v2-pl-2 ms-auto">
        <indicator
          (click)="$event.stopPropagation(); markAlert(alert)"
          [checked]="!alert.read"
          data-id="indicator"
        ></indicator>
      </div>
    </div>
  </div>

  <div
    *ngIf="loadedAll"
    class="d-flex flex-column justify-content-center align-items-center v2-mt-6 v2-mb-2"
  >
    <img src="assets/images/airplane.png" alt="airplane" width="40" height="40" />
    <typography-v2 class="text-jazlyn-700 v2-mt-3">You’ve reached end of the list</typography-v2>
  </div>

  <div *ngIf="!loadedAll" class="d-flex justify-content-center v2-mt-6 v2-mb-2">
    <div
      (click)="!loading && loadMore()"
      data-id="load-more"
      [ngClass]="{ 'text-jazlyn-700': loading, 'text-purple cursor-pointer': !loading }"
      class="d-flex align-items-center"
      data-id="load-more"
    >
      <rdr-svg-icon
        key="change"
        [color]="loading ? 'text-jazlyn-700' : 'var(--color-purple)'"
        class="v2-mr-1"
        size="lg"
      ></rdr-svg-icon>

      <typography-v2>{{ loading ? 'Loading more...' : 'Load more' }}</typography-v2>
    </div>
  </div>
</div>
