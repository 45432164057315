import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'checkbox-v2',
  templateUrl: './checkbox-v2.component.html',
  styleUrls: ['./checkbox-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxV2Component implements OnInit {
  idForLabel: number;

  @Input() label?: string;
  @Input() checked: boolean;
  @HostBinding('class.disabled') @Input() disabled: boolean;

  @Output() check = new EventEmitter<boolean>();

  ngOnInit() {
    this.idForLabel = Math.random();
  }

  change() {
    this.checked = !this.checked;
    this.check.emit(this.checked);
  }
}
