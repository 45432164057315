import { Injectable } from '@angular/core';
import { Observable, fromEvent, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  documentClick: Observable<MouseEvent>;
  private escapeClick: Observable<KeyboardEvent>;

  constructor() {
    this.documentClick = fromEvent<MouseEvent>(document, 'click', { capture: true });
    this.escapeClick = fromEvent<KeyboardEvent>(document, 'keydown', { capture: true });
  }

  onDocumentClick() {
    return this.documentClick;
  }

  onEscapeClick() {
    return this.escapeClick.pipe(filter((event) => event?.key?.toLocaleLowerCase() === 'escape'));
  }
}
