import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { TEXTAREA_V2 } from '@app/formly/helpers/fields';
import { UntypedFormGroup } from '@angular/forms';

export type FlightRequestTripNotesForm = {
  tripNotes: string;
};

@Component({
  selector: 'flight-request-trip-notes-form',
  templateUrl: './flight-request-trip-notes-form.component.html',
  styleUrls: ['./flight-request-trip-notes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightRequestTripNotesFormComponent extends RadarFormAbstract implements OnInit {
  formGroup = new UntypedFormGroup({});

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      TEXTAREA_V2({
        key: 'tripNotes',
        templateOptions: {
          placeholder: 'Notes only about this trip',
        },
      }),
    ];
  }
}
