import { Pipe, PipeTransform } from '@angular/core';
import { UserNode } from '@app/generated/graphql';
import { UsersService } from '@app/pages/users/services/users.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'loadUserById',
})
export class LoadUserById implements PipeTransform {
  constructor(private usersService: UsersService) {}

  transform(id: string | number): Observable<UserNode> | null {
    if (!id) return null;
    return this.usersService.getUserById(id.toString());
  }
}
