<div class="d-flex mb-2">
  <a
    class="d-flex align-items-center w-100"
    href="#"
    (click)="toggleCollapsed($event)"
    data-id="toggle-link"
  >
    <rdr-svg-icon
      *ngIf="!collapsed"
      key="chevron-bottom"
      size="sm"
      color="var(--placeholder)"
    ></rdr-svg-icon>
    <rdr-svg-icon
      *ngIf="collapsed"
      key="chevron-right"
      size="sm"
      color="var(--placeholder)"
    ></rdr-svg-icon>

    <typography *ngIf="sectionTitle" look="sectionTitle" class="ps-2 d-flex w-100">
      {{ sectionTitle }}
    </typography>

    <typography look="sectionTitle" class="ps-2 d-flex w-100">
      <ng-content select="[section-title]"></ng-content>
    </typography>
  </a>
</div>
<div *ngIf="!collapsed">
  <ng-content select="[section-content]"></ng-content>
</div>
