import { DropdownItem } from '@app/ui/components/dropdown.model';
import { DateTime } from 'luxon';

export type PreselectedDays = 'tomorrow' | 'after-tomorrow' | 'after-5-hours';

export const isValidDate = (d: Date): boolean => Boolean(d instanceof Date && d.getTime());

export const toISO8601 = (date: Date): string => {
  return DateTime.fromJSDate(date).toISODate() || '';
};

export const fromISO8601 = (date: string): Date => {
  return DateTime.fromISO(date).toJSDate();
};

export const isToday = (value: string): boolean => {
  if (!value) return false;

  const date = toISO8601(new Date(value));
  const today = toISO8601(new Date());

  return date === today;
};

export const isBefore = (value: string): boolean => {
  if (!value) return false;

  return new Date(value).getTime() < new Date().getTime();
};

export const delayFromNow = (hours: number): string => {
  return new Date(new Date().getTime() + hours * 60 * 60 * 1000)?.toISOString();
};

export const DUE_DATE_DELAYS: DropdownItem[] = [3, 5, 12, 24, 48].map((i) => ({
  label: `${i}h from now`,
  value: `${i}`,
}));

export function getPreselectedDay(when: PreselectedDays): Date {
  switch (when) {
    case 'tomorrow':
      return DateTime.now().plus({ days: 1 }).toJSDate();

    case 'after-tomorrow':
      return DateTime.now().plus({ days: 2 }).toJSDate();

    case 'after-5-hours':
      return DateTime.now().plus({ hours: 5 }).toJSDate();

    default:
      return DateTime.now().toJSDate();
  }
}
export const supportedDateFormats = [
  'LLL/dd/yyyy', // Feb 02, 2023
  'LL/dd/yyyy', // 02 02 2023
  'LLL/d/yyyy', // Feb 2 2023
  'LL/d/yyyy', // 06 2 2023
  'L/dd/yyyy', // 2 02 2023
  'L/d/yyyy', // 2 2 2023
  'LLL/dd/yy', // Feb 02 23
  'LLL/d/yy', // Feb 2 23
  'LL/dd/yy', // 06 23 23
  'LL/d/yy', // 02 2 23
  'L/dd/yy', // 2 02 23
  'L/d/yy', // 2 2 23
  'LLL/dd', // Feb 02
  'LLL/d', // Feb 2
  'd/LLL', // 2 Feb
  'dd/LLL', // 02 Feb
  'LL/dd', // 02 02
  'LL/d', // 02 2
  'L/dd', // 2 02
  'L/d', // 2 2
];
