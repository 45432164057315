<button
  [disabled]="disabled"
  class="button"
  [class.pressed]="pressed"
  [class.md]="size === 'md'"
  [ngClass]="type"
  type="button"
  (click)="btnClick.emit($event)"
  [attr.data-id]="key"
>
  <rdr-svg-icon [key]="key" [color]="color" [size]="size"></rdr-svg-icon>
  <ng-content></ng-content>
</button>
