import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'search-input-v2',
  templateUrl: './search-input-v2.component.html',
  styleUrls: ['./search-input-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputV2Component {
  @Input() placeholder = 'Search';
  @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();
  searchTerm = '';

  searchTermChange(newSearchTerm: string): void {
    this.searchTerm = newSearchTerm?.trim();
    this.searchTermChanged.next(this.searchTerm);
  }
}
