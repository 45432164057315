import { Observable, Subject, takeUntil } from 'rxjs';

export const UntilDestroy = () => {
  return (instance: any) => {
    if (instance?.prototype) {
      const ngOnDestroy = instance.prototype.ngOnDestroy;

      instance.prototype.ngOnDestroy = function () {
        if (this) {
          this.untilDestroy$?.next();
          this.untilDestroy$?.complete();
        }
        if (typeof ngOnDestroy === 'function') {
          ngOnDestroy();
        }
      };
    }
  };
};

export const UntilDestroyed = (instance: any) => {
  return (source: Observable<any>) => {
    const untilDestroy$ = new Subject();
    if (instance && !instance.untilDestroy$) {
      instance.untilDestroy$ = untilDestroy$;
    }
    return source.pipe(takeUntil(instance.untilDestroy$ as Observable<any>));
  };
};
