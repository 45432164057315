import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';

@Component({
  selector: 'checkbox-field-v2',
  templateUrl: './checkbox-v2.component.html',
  styleUrls: ['./checkbox-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxFieldV2Component extends BasefieldV2Component implements OnInit {
  ngOnInit() {
    if (this.to.initialValue) {
      this.formControl.setValue(this.to.initialValue);
    }
  }

  change(value: boolean) {
    this.formControl.setValue(value);
    this.formControl.markAsDirty();
  }
}
