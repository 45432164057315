import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { LoaderService } from '@shared/services/loader.service';
import { AppInjector } from '@app/app-injector.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CustomHeadersService } from '@auth/services/custom-headers.service';
import { IGNORE_OPERATION_FAILURES } from './shared/constants';

const createApollo = (
  authService: CognitoAuthService,
  loaderService: LoaderService,
  customHeadersService: CustomHeadersService
): ApolloClientOptions<any> => {
  const uri = environment.GRAPHQL_URL;

  const fetchWithLoader = async (input: RequestInfo, init?: RequestInit) => {
    loaderService.setLoader(true);

    return await fetch(input, init).finally(() => {
      loaderService.setLoader(false);
    });
  };

  const errorMiddleware = onError(({ graphQLErrors, networkError, operation }) => {
    const toastrService = AppInjector.injector.get(ToastrService);

    console.log(operation);
    Sentry.setContext('graphql', { graphQLErrors, networkError, operation });
    const { response } = operation.getContext();
    if (response?.status === 401) {
      const router = AppInjector.injector.get(Router);
      void authService.logOut();
      void router.navigate(['login'], {
        queryParams: {
          returnUrl: window.location,
        },
      });
      return;
    }

    if (graphQLErrors) {
      const errors = graphQLErrors.map(({ message, locations, path }) => {
        console.log('[GraphQL error]: Message:', message);
        console.log('[GraphQL error]: Location:', locations);
        console.log('[GraphQL error]: Path:', path);
        return message;
      });
      if (!IGNORE_OPERATION_FAILURES.includes(operation.operationName)) {
        toastrService.error(errors.join(' ,'));
      }
    }

    if (networkError) {
      if (!IGNORE_OPERATION_FAILURES.includes(operation.operationName)) {
        toastrService.error(networkError.message);
      }
      console.debug('[Network error]', networkError);
    }
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = authService.user.jwt;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(token === null ? {} : { authorization: `Bearer ${token as string}` }),
      },
    }));

    return forward(operation);
  });

  const httpLink = createUploadLink({
    uri: (operation) => `${uri}?op=${operation.operationName}`,
    fetch: fetchWithLoader,
  });

  const customHeadersMiddleware = new ApolloLink((operation, forward) => {
    const customHeaders = customHeadersService.getCustomHeaders() || {};

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...customHeaders,
      },
    }));

    return forward(operation);
  });

  return {
    cache: new InMemoryCache({
      typePolicies: {
        OrderFinance: {
          merge: false,
        },
        FlightItineraryGroupNode: {
          merge: false,
        },
        SecureCreditCard: {
          keyFields: ['id', 'number'], // for disable deep merge similar cards with identical id and different numbers (for another role etc)
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
    link: ApolloLink.from([errorMiddleware, customHeadersMiddleware, authMiddleware, httpLink]),
  };
};

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [CognitoAuthService, LoaderService, CustomHeadersService],
    },
  ],
})
export class GraphQLModule {}
