<svg width="20" height="38" viewBox="0 0 20 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="6" cy="7" r="2" fill="#141514" />
  <circle cx="14" cy="7" r="2" fill="#141514" />
  <circle cx="6" cy="15" r="2" fill="#141514" />
  <circle cx="14" cy="15" r="2" fill="#141514" />
  <circle cx="6" cy="23" r="2" fill="#141514" />
  <circle cx="14" cy="23" r="2" fill="#141514" />
  <circle cx="6" cy="31" r="2" fill="#141514" />
  <circle cx="14" cy="31" r="2" fill="#141514" />
</svg>
