import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import { toLabel } from './string-utils';
import { GenericNode } from '@app/shared/types';

export function mapEnumToDropdownItems<T extends string>(
  statusEnum: Record<string, T>,
  params?: {
    included?: T[];
    excluded?: T[];
    withoutColors?: boolean;
  }
): DropdownItem<T>[] {
  const dropdownItems: DropdownItem<T>[] = Object.values(statusEnum).map((value) => {
    return mapStatusToDropdownItem<T>(value, params?.withoutColors);
  });

  if (params?.included && params?.excluded) {
    console.log('Please use only one option: included or excluded');
    return dropdownItems;
  }

  if (params?.included) {
    return dropdownItems.filter((dropdownItem) =>
      params?.included?.includes(dropdownItem.value as T)
    );
  }

  if (params?.excluded) {
    return dropdownItems.filter(
      (dropdownItem) => !params?.excluded?.includes(dropdownItem.value as T)
    );
  }

  return dropdownItems;
}

export function mapStatusToDropdownItem<T extends string>(
  status: T,
  withoutColors?: boolean
): DropdownItem<T> {
  return {
    label: toLabel(status),
    value: status,
    ...(withoutColors
      ? {}
      : {
          colored: true,
          className: `status ${status.toLowerCase()}`,
        }),
  };
}

export function mapEntityToDropdownItem(
  entity?: GenericNode,
  labelKey: keyof GenericNode = 'name',
  valueKey: keyof GenericNode = 'id'
): DropdownItem {
  return entity
    ? ({
        label: entity[labelKey],
        value: entity[valueKey],
      } as DropdownItem)
    : EMPTY_DROP_DOWN_ITEM;
}
