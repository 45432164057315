<form [formGroup]="formGroup" (ngSubmit)="submitForm()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2
      [disabled]="!(formModel | validOnewayFormPipe)"
      buttonType="submit"
      data-id="next-step"
    >
      <typography-v2 look="caption-bold">Next</typography-v2>
    </button-v2>
  </div>
</form>
