import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/shared/services/loader.service';
import { RoutingService } from '@app/shared/services/routing.service';
import { RdrFeature } from '@app/ui/services/feature-toggle.service';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { AuthUser } from '@auth/user.model';
import { SaveUserProfileMutationVariables, UserType } from '@generated/graphql';
import { BehaviorSubject, Subject } from 'rxjs';
import * as config from '@client-configs/header.json';
import { Params } from '@angular/router';
import {
  AvailabilityService,
  AvailableStatus,
} from '@app/calls/services/availability/availability.service';
import { CallsService } from '@app/calls/services/calls/calls.service';
import { UsersService } from '@app/pages/users/services/users.service';

type HeaderLink = {
  link: string;
  label: string;
  queryParams: Params;
  featureToggleOff?: RdrFeature;
  featureToggleOn?: RdrFeature;
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  UserType = UserType;
  RdrFeature = RdrFeature;
  AvailableStatus = AvailableStatus;

  user: AuthUser;
  links: HeaderLink[];

  loading$: Subject<boolean>;
  availableStatus$: BehaviorSubject<AvailableStatus>;

  constructor(
    public authService: CognitoAuthService,
    private loaderService: LoaderService,
    private routingService: RoutingService,
    private usersService: UsersService,
    private cdRef: ChangeDetectorRef,
    private availabilityService: AvailabilityService,
    private callsService: CallsService
  ) {}

  get env() {
    try {
      const hostname = window.location.hostname;
      const prefix = hostname.split('.')[0].toLowerCase();

      return prefix === 'develop' ? 'Develop' : prefix === 'staging' ? 'Staging' : '';
    } catch (err) {
      return '';
    }
  }

  ngOnInit() {
    this.user = this.authService.user;
    this.links = (config[this.authService.user.type as UserType] as HeaderLink[]) || [];

    this.loading$ = this.loaderService.loading$;
    this.availableStatus$ = this.availabilityService.availableStatus$;
  }

  logout() {
    void this.authService.logOut();
  }

  goToProfile() {
    this.routingService.navigateTo(['profile']);
  }

  setPhoto({ target }: Event) {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));

    const files = (target as HTMLInputElement).files;

    if (files) {
      const params: SaveUserProfileMutationVariables = {
        userId: this.authService.user.id,
        file: files[0] as unknown as string,
      };

      this.usersService.saveUserProfile(params).subscribe(({ data }) => {
        const ok = data?.saveUserProfile?.ok;

        if (ok) {
          const picture = data?.saveUserProfile?.result?.picture;

          this.user = {
            ...this.user,
            picture,
          };

          this.cdRef.detectChanges();
        }
      });
    }
  }

  startCalls() {
    this.callsService.startCalls();
  }

  stopCalls() {
    this.callsService.stopCalls();
  }
}
