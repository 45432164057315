import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LeadEdge } from '@generated/graphql';

@Component({
  selector: 'my-leads-block',
  templateUrl: './my-leads-block.component.html',
  styleUrls: ['./my-leads-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyLeadsBlockComponent {
  @Input() leads?: LeadEdge[];
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
}
