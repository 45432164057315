import { Injectable } from '@angular/core';
import { CUSTOM_HEADERS_KEY } from '@app/shared/constants';
import { LocalStorageService } from '@app/ui-v2/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CustomHeadersService {
  constructor(private localStorageService: LocalStorageService) {}

  getCustomHeaders() {
    return this.localStorageService.getItem(CUSTOM_HEADERS_KEY);
  }

  setCustomHeaders(data: CustomHeaders) {
    this.localStorageService.setItem(CUSTOM_HEADERS_KEY, data);
  }
}

export type CustomHeaders = {
  'x-superadmin-login-user'?: string;
};
