<div class="w-100 notes" #notes>
  <ng-container *ngIf="notes$ | async as notes">
    <ng-container *ngIf="notes?.length; else emptyStateTemplate">
      <div *ngFor="let note of notes | extractNodes; trackBy: trackByFn" class="note">
        <typography-v2 look="text"><span class="note-text">{{ note.text }}</span></typography-v2>
        <div class="d-flex justify-content-between v2-mt-1 text-black-50">
          <typography-v2 look="caption">@{{ note.user!.fullName }}</typography-v2>
          <typography-v2 look="caption">{{ note.createdAt | rdrDate: 'short' }}</typography-v2>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<textarea
  (keydown.enter)="onEnter($event)"
  [formControl]="formControl"
  placeholder="Add Note"
  class="field"
  #textArea
></textarea>

<button-icon-v2 (click)="onEnter()" key="send-v-2" class="send"></button-icon-v2>

<ng-template #emptyStateTemplate>
  <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
    <img src="/assets/images/no-result.svg" />
    <typography-v2 class="v2-mt-3" look="subtitle-bold">General Note</typography-v2>
    <typography-v2 class="text-align-center v2-mt-1" look="caption">
      It looks like there aren't<br />
      any notes here yet
    </typography-v2>
  </div>
</ng-template>
