<card class="client-preview">
  <div class="d-flex align-items-center">
    <user-picture-v2 [label]="client.firstName" size="big"></user-picture-v2>

    <div class="v2-ml-4">
      <a routerLink="/clients/{{ client.id }}" data-id="client-link">
        <typography-v2 look="subtitle-bold">{{ client.firstName }}</typography-v2>
        <br />
        <typography-v2 look="subtitle-bold">{{ client.lastName }}</typography-v2>
      </a>
    </div>
  </div>

  <hr class="hr v2-my-3" />

  <div class="d-flex flex-column">
    <div *ngIf="client.phones?.length" class="d-flex flex-column">
      <copy-to-clipboard-v2 *ngFor="let phone of client.phones" [value]="phone" class="v2-mb-1">
        <a href="tel:{{ phone }}" data-id="phone">{{ phone }}</a>
      </copy-to-clipboard-v2>
    </div>

    <div *ngIf="client.emails?.length" class="d-flex flex-column">
      <copy-to-clipboard-v2 *ngFor="let email of client.emails" [value]="email" class="v2-mb-1">
        <a href="mailto:{{ email }}" data-id="email" class="text-truncate">{{ email }}</a>
      </copy-to-clipboard-v2>
    </div>
    <ng-container *ngIf="client.referenceClient | userName as userName">
      <hr class="hr v2-mb-3 v2-mt-2" />
      <view-cell label="Referred by"><span data-id="referral">{{ userName }}</span></view-cell>
    </ng-container>

    <ng-container *ngIf="client.note as note">
      <hr class="hr v2-my-3" />
      <view-cell label="Contact Notes"><span data-id="contact-notes">{{ note }}</span></view-cell>
    </ng-container>

    <hr class="hr v2-my-3" />
    <view-cell label="Balance"
      ><span data-id="balance">{{ client.balance | rdrCurrency }}</span></view-cell
    >

    <ng-container *ngIf="showSendNoAnswerEmail">
      <hr class="hr v2-mt-3 v2-mb-3" />

      <button-v2
        (click)="sendNoAnswer.emit()"
        [disabled]="!(notificationSentAt | canSendNotification)"
        formButtonState
        type="tertiary"
        data-id="send-no-answer-email"
      >
        <rdr-svg-icon class="v2-mr-2" key="link-v-2"></rdr-svg-icon>
        Send no answer Email
      </button-v2>
    </ng-container>

    <typography-v2
      *ngIf="notificationSentAt"
      look="caption"
      class="v2-mt-1 text-black-50"
      dataId="no-answer-mail-sent"
      >No Answer e-mail was sent at {{ notificationSentAt | rdrDate : 'extended' }}</typography-v2
    >
  </div>
</card>
