import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ClientNode,
  CreateCreditCardMutationVariables,
  FlightRequestNode,
  OrderNode,
  PassengerPassportFileActionEnum,
  SaveClientV2MutationVariables,
  UpdateCreditCardMutationVariables,
} from '@app/generated/graphql';
import { MainLayoutStyleService } from '@app/layout/main-layout/main-layout-style.service';
import {
  FormSubmitData,
  hideFormModalIfNoErrors,
  setFormLoadingState,
} from '@app/shared/components/radar-form-abstract';
import { RoutingService } from '@app/shared/services/routing.service';
import { isDefined } from '@app/shared/utils/form-utils';
import { Subject, take } from 'rxjs';
import { ClientDetailsPageV2Service } from './services/client-details-page-v2.service';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { FlightFormState } from '@app/shared/forms/flight-request-flight-form-v2/flight-request-flight-form-v2.component';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { openNewTab } from '@app/pages/orders/pages/order-details-page-v2/utils/pdf';
import { PageTitleAbstract } from '@app/shared/components/page-title-abstract';
import { UserNamePipe } from '@app/ui/pipes/user-name.pipe';
import { GraphqlID } from '@app/shared/types';
import { ContactDetailsForm } from '@app/shared/forms/contact-form/contact-form.component';
import { FeatureToggleService, RdrFeature } from '@app/ui/services/feature-toggle.service';

@UntilDestroy()
@Component({
  selector: 'client-details-page-v2',
  templateUrl: './client-details-page-v2.component.html',
  styleUrls: ['./client-details-page-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDetailsPageV2Component extends PageTitleAbstract implements OnInit {
  RdrFeature = RdrFeature;

  clientId: GraphqlID;

  client$ = new Subject<ClientNode>();

  paginatedSortedFlightRequests: FlightRequestNode[];
  totalPaginatedSortedFlightRequests: number;
  paginatedSortedFlightRequestsOffset = 0;

  paginatedSortedOrders: OrderNode[];
  totalPaginatedSortedOrders: number;
  paginatedSortedOrdersOffset = 0;

  paginatedSortedTrips: FlightRequestNode[];
  totalPaginatedSortedTrips: number;
  paginatedSortedTripsOffset = 0;

  constructor(
    private clientDetailsPageV2Service: ClientDetailsPageV2Service,
    private mainLayoutStyleService: MainLayoutStyleService,
    private activatedRoute: ActivatedRoute,
    private routingService: RoutingService,
    private userNamePipe: UserNamePipe,
    private featureToggleService: FeatureToggleService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const withTables = !this.featureToggleService.isFeatureEnabled(RdrFeature.trips);

    this.mainLayoutStyleService.enableV2();

    this.clientId = this.activatedRoute.snapshot.paramMap.get('id') || '';
    this.pageTitle$.next(`Client #${this.clientId}`);
    this.getClient();

    this.activatedRoute.params.pipe(UntilDestroyed(this)).subscribe((params) => {
      if (params.id) {
        this.clientId = params.id;
        this.getClient();

        if (withTables) {
          this.getPaginatedSortedFlightRequests();
          this.getPaginatedSortedOrders();
        } else {
          this.getPaginatedSortedTrips();
        }
      }
    });

    if (withTables) {
      this.getPaginatedSortedFlightRequests();
      this.getPaginatedSortedOrders();
    } else {
      this.getPaginatedSortedTrips();
    }
  }

  getPaginatedSortedFlightRequests() {
    const params = {
      id: this.clientId,
      limit: flightRequestsLimit,
      offset: this.paginatedSortedFlightRequestsOffset,
    };

    this.clientDetailsPageV2Service
      .paginatedSortedFlightRequests(params)
      .pipe(take(1))
      .subscribe((data) => {
        this.paginatedSortedFlightRequests = (data?.flightRequests as FlightRequestNode[]) || [];
        this.totalPaginatedSortedFlightRequests = data?.total || 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  paginateFlightRequests(paginatedSortedFlightRequestsOffset: number) {
    this.paginatedSortedFlightRequestsOffset = paginatedSortedFlightRequestsOffset;
    this.getPaginatedSortedFlightRequests();
  }

  getPaginatedSortedOrders() {
    const params = {
      id: this.clientId,
      limit: ordersLimit,
      offset: this.paginatedSortedOrdersOffset,
    };

    this.clientDetailsPageV2Service
      .paginatedSortedOrders(params)
      .pipe(take(1))
      .subscribe((data) => {
        this.paginatedSortedOrders = (data?.orders as OrderNode[]) || [];
        this.totalPaginatedSortedOrders = data?.total || 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  paginateOrders(paginatedSortedOrdersOffset: number) {
    this.paginatedSortedOrdersOffset = paginatedSortedOrdersOffset;
    this.getPaginatedSortedOrders();
  }

  getPaginatedSortedTrips() {
    const params = {
      id: this.clientId,
      limit: tripsLimit,
      offset: this.paginatedSortedTripsOffset,
    };

    this.clientDetailsPageV2Service
      .paginatedSortedTrips(params)
      .pipe(take(1))
      .subscribe((data) => {
        this.paginatedSortedTrips = (data?.flightRequests as FlightRequestNode[]) || [];
        this.totalPaginatedSortedTrips = data?.total || 0;
        this.changeDetectorRef.detectChanges();
      });
  }

  paginateTrips(paginatedSortedTripsOffset: number) {
    this.paginatedSortedTripsOffset = paginatedSortedTripsOffset;
    this.getPaginatedSortedTrips();
  }

  getClient() {
    this.clientDetailsPageV2Service
      .getClient(this.clientId)
      .pipe(UntilDestroyed(this))
      .subscribe((client: ClientNode) => {
        this.client$.next(client);
        this.pageTitle$.next(this.userNamePipe.transform(client));
      });
  }

  reassignAgent({ value }: DropdownItem<string>) {
    const params = {
      input: {
        id: this.clientId,
        salesAgentId: value,
      },
    };

    this.clientDetailsPageV2Service.saveClient(params).subscribe(({ data }) => {
      const client = data?.saveClient?.result as ClientNode;

      if (client) {
        this.client$.next(client);
      }
    });
  }

  clientFormSubmit({ data }: FormSubmitData<ContactDetailsForm>) {
    const params: SaveClientV2MutationVariables = { input: { ...data, id: this.clientId } };

    this.clientDetailsPageV2Service
      .saveClient(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(({ data }) => {
        const client = data?.saveClient?.result as ClientNode;

        if (client) {
          this.client$.next(client);
          this.pageTitle$.next(this.userNamePipe.transform(client));
        }
      });
  }

  createCard(params: CreateCreditCardMutationVariables) {
    params['clientId'] = this.clientId;

    this.clientDetailsPageV2Service
      .createCreditCard(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe((client: unknown) => {
        if (client) {
          this.client$.next(client as ClientNode);
        }
      });
  }

  updateCard(params: UpdateCreditCardMutationVariables) {
    this.clientDetailsPageV2Service
      .updateCreditCard(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe((client: unknown) => {
        if (client) {
          this.client$.next(client as ClientNode);
        }
      });
  }

  clickOnFlightRequest({
    event,
    flightRequest,
  }: {
    event: MouseEvent;
    flightRequest: FlightRequestNode;
  }) {
    this.routingService.navigateTo(['flight-requests', flightRequest.id], event);
  }

  createNewFlightRequest(flight: FormSubmitData<FlightFormState>) {
    const params = {
      ...flight.data,
      clientId: this.clientId,
    };

    this.clientDetailsPageV2Service
      .createFlighRequest(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(({ data }) => {
        const flightRequestId = data?.createFlightRequestTrip?.result?.flightRequest?.id;

        if (flightRequestId) {
          this.routingService.navigateTo(['flight-requests', flightRequestId as string]);
        }
      });
  }

  clickOnOrder({ event, id }: { event: MouseEvent; id: GraphqlID }) {
    this.routingService.navigateTo(['orders', id], event);
  }

  savePassenger(formSubmitData: FormSubmitData) {
    const params = mapPassenegerFormData(formSubmitData, this.clientId);

    this.clientDetailsPageV2Service
      .saveClientPassenger(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(({ data }) => {
        const client = data?.updatePassengerPassport?.result?.client as ClientNode;

        if (client) {
          this.client$.next(client);
        }
      });
  }

  deletePassenger(id: GraphqlID) {
    const params = { id };

    this.clientDetailsPageV2Service
      .deleteClientPassenger(params)
      .pipe(hideFormModalIfNoErrors(), setFormLoadingState(false))
      .subscribe(() => this.getClient());
  }

  deleteCard(id: GraphqlID) {
    const params = { id };

    this.clientDetailsPageV2Service.deleteClientCard(params).subscribe(() => this.getClient());
  }

  downloadDocument(filePassportId: GraphqlID) {
    const data = { filePassportId };

    this.clientDetailsPageV2Service
      .getPresignedUrl(data)
      .subscribe(({ data }) => openNewTab(data?.getPresignedUrl));
  }

  clickOnTrip({ event, id }: { event: MouseEvent; id: GraphqlID }) {
    this.routingService.navigateTo(['trips', id], event);
  }
}

export const mapPassenegerFormData = (
  { data, id }: FormSubmitData,
  clientId: string,
  addToOrderId?: string
) => {
  const initial = {
    fileAction: PassengerPassportFileActionEnum.NoChanges,
    clientId,
    addToOrderId,
    id,
  };

  const params = Object.assign(initial, data);
  let file = undefined;

  if (params.phone) {
    params.phones = [params.phone];
    delete params.phone;
  }

  if (params.email) {
    params.emails = [params.email];
    delete params.email;
  }

  if (params.passport?.countryId) {
    params.countryId = params.passport.countryId;
  }

  if (params.passport?.expiration) {
    params.expiration = params.passport.expiration;
  }

  if (isDefined(params.passport?.number)) {
    params.number = params.passport.number;
  }

  if (params.passport?.fileDocument?.id === '') {
    params.fileAction = PassengerPassportFileActionEnum.Delete;
  } else if (params.passport?.fileDocument?.id instanceof FileList) {
    file = params.passport?.fileDocument?.id[0];
    params.fileAction = PassengerPassportFileActionEnum.Update;
  }

  if (params.passport) {
    delete params.passport;
  }

  return { params, file };
};

export const flightRequestsLimit = 5;
export const ordersLimit = 5;
export const tripsLimit = 5;
