import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeadStatusInteraction, LeadStatusType } from '@app/generated/graphql';

@Component({
  selector: 'lead-status-event',
  templateUrl: './lead-status-event.component.html',
  styleUrls: ['./lead-status-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadStatusEventComponent {
  @Input() interaction: LeadStatusInteraction & {
    leadStatus: LeadStatusType;
    leadPreviousStatus: LeadStatusType;
  };
}
