import { Injector, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import * as Sentry from '@sentry/angular';

import Amplify from '@aws-amplify/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RadarFormlyModule } from '@app/formly/formly.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import awsconfig from '../aws-config';
import { AppInjector } from './app-injector.service';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { LayoutModule } from './layout/layout.module';
import { ApolloModule } from 'apollo-angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { svgIconsConfig } from './icons/icons.module';
import { CallsModule } from './calls/calls.module';
import { HotLeadPopupComponent } from './hot-lead-polling/hot-lead-popup/hot-lead-popup.component';
import { NoLeadMessageComponent } from './hot-lead-polling/no-lead-message/no-lead-message.component';
import { CallPopupV2Component } from './calls/components/call-popup-v2/call-popup-v2.component';
import { UiV2Module } from './ui-v2/ui-v2.module';
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(awsconfig);

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideSvgIconsConfig(svgIconsConfig),
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ApolloModule,
    RouterModule.forRoot([]),
    GraphQLModule,
    HttpClientModule,
    LayoutModule,
    BrowserAnimationsModule,
    RadarFormlyModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    NgxPageScrollCoreModule.forRoot({ duration: 150 }),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    NgxChartsModule,
    CallsModule,
    HotLeadPopupComponent,
    NoLeadMessageComponent,
    CallPopupV2Component,
    UiV2Module,
  ],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
