import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientNode } from '@app/generated/graphql';

@Component({
  selector: 'client-card-preview',
  templateUrl: './client-card-preview.component.html',
  styleUrls: ['./client-card-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientCardPreviewComponent {
  @Input() client: ClientNode;
  @Input() notificationSentAt: Date | undefined;
  @Input() showSendNoAnswerEmail = true;
  @Output() sendNoAnswer = new EventEmitter();
}
