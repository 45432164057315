import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { combineLatest, from, interval } from 'rxjs';
import { filter, map, flatMap, switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppAmplifyTokenService {
  constructor(private cognitoAuthService: CognitoAuthService) {}

  startTokenPolling() {
    const pollingInterval = 1000 * 60 * 5;
    const loggedIn$ = this.cognitoAuthService
      .hasCognitoSession()
      .pipe(filter((loggedIn) => loggedIn));

    const counter$ = interval(pollingInterval);

    return combineLatest([loggedIn$, counter$]).pipe(
      flatMap(() => {
        return from(Auth.currentSession());
      }),
      switchMap((session) => {
        return from(Auth.currentAuthenticatedUser()).pipe(
          map((user) => {
            return { session, user };
          })
        );
      }),
      map(({ session, user }) => {
        const idTokenExpire = session.getIdToken().getExpiration();
        const refreshToken = session.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);

        if (idTokenExpire - pollingInterval * 2 < currentTimeSeconds) {
          user.refreshSession(refreshToken, (err: any, data: any) => {
            if (err) {
              console.log(err, data);
            }
          });
        }
      }),
      catchError(() => this.cognitoAuthService.logOut())
    );
  }
}
