import { Pipe, PipeTransform } from '@angular/core';
import { ROW_LINK_PARAMS_REGEX } from '../radar-table.constants';
import { RadarTableRow } from '../radar-table.types';

@Pipe({
  name: 'getRowLink',
})
export class GetRowLinkPipe implements PipeTransform {
  transform(link = '', row: RadarTableRow): string {
    return link.replace(ROW_LINK_PARAMS_REGEX, (found, key) => row[key] || found);
  }
}
