import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TEXTAREA_V2 } from '@app/formly/helpers/fields';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';

@Component({
  selector: 'order-note-form-v2',
  templateUrl: './order-note-form-v2.component.html',
  styleUrls: ['./order-note-form-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNoteFormV2Component extends RadarFormAbstract implements OnInit {
  formGroup = new UntypedFormGroup({});

  ngOnInit(): void {
    super.ngOnInit();

    this.fields = [
      TEXTAREA_V2({
        key: 'notes',
        templateOptions: {
          placeholder: 'Note',
        },
      }),
    ];
  }
}
