import { MaxLayoverType, ServiceClassType, StopsType } from '@app/generated/graphql';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';

export const getInitialServiceclass = (serviceClass: ServiceClassType) => {
  return mapEnumToDropdownItems(ServiceClassType, { included: [serviceClass] })[0];
};

export const getInitialMaxLayover = (maxLayover: MaxLayoverType) => {
  return mapEnumToDropdownItems(MaxLayoverType, { included: [maxLayover] })[0];
};

export const getInitialStops = (stops: StopsType) => {
  return mapEnumToDropdownItems(StopsType, { included: [stops] })[0];
};
