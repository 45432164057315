import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'due-date-badge-v2',
  templateUrl: './due-date-badge-v2.component.html',
  styleUrls: ['./due-date-badge-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueDateBadgeV2Component {
  @Input() value: string | Date;
  @Input() pressed: boolean;
  @HostBinding('class') @Input() look: 'default' | 'header' = 'default';
}
