import { Injectable } from '@angular/core';
import {
  CallLinkGQL,
  CallLinkQueryVariables,
  RecordingLinksGQL,
  RecordingLinksQueryVariables,
} from '@app/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallsService {
  constructor(private callLinkGQL: CallLinkGQL, private recordingLinksGQL: RecordingLinksGQL) {}

  getCallLinks(params: CallLinkQueryVariables) {
    return this.callLinkGQL.fetch(params).pipe(map(({ data }) => data.callLinks?.links || []));
  }

  getRecordingLinks(params: RecordingLinksQueryVariables) {
    return this.recordingLinksGQL.fetch(params).pipe(map(({ data }) => data.recordingLinks || []));
  }
}
