<div
  (click)="!disabledDropdown && toggleCollapsed($event)"
  [ngClass]="{ look, 'focused': !collapsed, 'cursor-default': disabledDropdown }"
  [attr.data-id]="selectedItem?.value"
  class="wrapper align-items-center cursor-pointer"
>
  <ng-container [ngSwitch]="look">
    <button-v2 *ngSwitchCase="'link'" [look]="'link'">
      <div class="d-flex align-items-center">
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </div>
    </button-v2>

    <ng-container *ngIf="selectedItem?.colored; else defaultSelectedItem">
      <colored-status-v2 [status]="selectedItem?.value || ''" class="p-0 w-100">
        <ng-container [ngTemplateOutlet]="defaultSelectedItem"></ng-container>
      </colored-status-v2>
    </ng-container>
    <ng-template #defaultSelectedItem>
      <div
        class="default-look d-flex align-items-center"
        [ngClass]="{ 'focused': !collapsed, 'is-filter': isFilter, 'disabled': disabledDropdown  }"
        *ngSwitchCase="'default'"
      >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </div>
      <div
        class="default-look slim d-flex align-items-center"
        [ngClass]="{ 'focused': !collapsed }"
        *ngSwitchCase="'default-slim'"
      >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>

<ng-template #buttonContent>
  <typography-v2
    *ngIf="selectedItem?.label; else miltiselectTemplate"
    [ngClass]="{ 'v2-mr-4': !disabledDropdown }"
    look="text"
    class="text-truncate"
    >{{ selectedItem?.label }}</typography-v2
  >
  <ng-template #miltiselectTemplate>
    <ng-container *ngIf="selectedItems?.length; else placeholderTemplate">
      <span [ngClass]="{ 'v2-mr-4': !disabledDropdown }" class="nowrap text-truncate"
        >{{ getSelectedItemsLabels() }}</span
      >
    </ng-container>
  </ng-template>

  <ng-template #placeholderTemplate>
    <typography-v2
      [ngClass]="{ 'link': look === 'link', 'expanded': !collapsed, 'v2-mr-4': isFilter }"
      look="text"
      class="text-truncate text-placeholder v2-mr-1"
      >{{ placeholder }}</typography-v2
    >
  </ng-template>
  <input-icon-container *ngIf="!disabledDropdown" [link]="look === 'link'">
    <rdr-svg-icon
      [ngClass]="{ 'rotate': !collapsed }"
      color="var(--color-black-50)"
      key="chevron-bottom-v-2"
      size="xs"
    ></rdr-svg-icon>
  </input-icon-container>
</ng-template>

<div
  *ngIf="!collapsed"
  [ngClass]="{ 'wide': wideItemsContainer }"
  class="dd-items"
  data-id="dd-items"
>
  <div class="scrollable">
    <ng-container
      *ngIf="customListTemplate; else defaultList"
      [ngTemplateOutlet]="customListTemplate"
      [ngTemplateOutletContext]="{ items: items, onItemSelected: onItemSelected, hoveredItem: hoveredItem, setHoveredItem: setHoveredItem }"
    >
    </ng-container>

    <ng-template #defaultList>
      <ng-container *ngFor="let item of items; trackBy: trackByFn">
        <ng-container *ngIf="item.colored && !multiselect; else defaultItem">
          <div class="v2-m-1">
            <colored-status-v2 [status]="item?.value || ''" class="d-inline-block p-0">
              <ng-container [ngTemplateOutlet]="defaultItem"></ng-container>
            </colored-status-v2>
          </div>
        </ng-container>
        <ng-template #defaultItem>
          <dropdown-item-v2
            (click)="selectItem(item)"
            [attr.data-id]="item.value"
            class="position-relative w-100"
            [hovered]="hoveredItem === item"
            (mouseenter)="hoveredItem = item"
            (mouseleave)="hoveredItem = null"
          >
            <label *ngIf="multiselect" class="d-flex align-items-center">
              <input
                [checked]="isSelectedItem(item)"
                class="checkbox-input d-none"
                type="checkbox"
              />
              <div (click)="$event.preventDefault()" class="checkbox cursor-pointer"></div>
            </label>
            <typography-v2 type="text">{{ item.label }}</typography-v2>
            <rdr-svg-icon
              *ngIf="item.extras?.description"
              key="alert-v-2"
              color="var(--color-black-30)"
              class="ms-auto"
            ></rdr-svg-icon>
          </dropdown-item-v2>
          <context-menu-v2
            *ngIf="hoveredItem === item && item.extras?.description"
            class="position-absolute extras d-flex flex-column v2-p-4"
          >
            <typography-v2 look="subtitle-bold">{{ item.label }}</typography-v2>
            <typography-v2 class="v2-mt-2">{{ item.extras?.description }}</typography-v2>
          </context-menu-v2>
        </ng-template>
      </ng-container>
    </ng-template>
    <div (click)="toggleCollapsed()"><ng-content></ng-content></div>
  </div>
</div>
