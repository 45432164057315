import { DropdownItem } from '@ui/components/dropdown.model';
import { OrderStatusType } from '@generated/graphql';

export const EMPTY_ID = '';
export const PLACEHOLDER = '(none)';
export const QUERIES_LIMIT = 100;
export const PAGINATION_FIRST_PAGE = 0;
export const TABLE_ROWS_LIMIT = 20;
export const HISTORIES_LIMIT = 15;
export const EMAILS_LIMIT = 15;
export const TEAM_DESK_ROOT_URL = 'https://www.teamdesk.net/secure/db/33111/preview.aspx';
export const POLL_INTERVAL = 60 * 1000;
export const MAX_DATE_RANGE = 91;
export const CUSTOM_HEADERS_KEY = 'custom_headers';
export const MAX_HOTEL_NAME_LENGTH = 90;
export const IGNORE_OPERATION_FAILURES = [
  'AvailabilityPing',
  'UserAuth',
  'UnreadCountAlerts',
  'LeadNotes',
  'InvoiceNotes',
  'MilesAccountNotes',
];

export const WIRE_TRANSFER: DropdownItem = {
  value: 'wire',
  label: 'Wire transfer',
};

export const COST_WIRE_TRANSFER: DropdownItem = {
  value: 'wire',
  label: 'Check Payment',
};

export const ACCOUNT_HOLDER: DropdownItem = {
  value: 'holder',
  label: 'Account Holder',
};

export const ALWAYS_EXCLUDED_ORDER_STATUSES = [
  OrderStatusType.Option,
  OrderStatusType.OptionPublished,
  OrderStatusType.OptionBooked,
  OrderStatusType.OptionSelected,
];

export enum PASSENGER_TYPE {
  EXISTING = 'EXISTING',
  NEW = 'NEW',
}

export const ADD_PASSENGER_ITEMS: DropdownItem[] = [
  { value: PASSENGER_TYPE.EXISTING, label: PASSENGER_TYPE.EXISTING, colored: true },
  { value: PASSENGER_TYPE.NEW, label: PASSENGER_TYPE.NEW, colored: true },
];

export enum AgeGroupLabels {
  Adult = '12+',
  Child = 'ages 2 to 11',
  Infant = 'under 2',
}
