<div class="w-100 position-relative">
  <input
    class="w-100"
    type="text"
    [placeholder]="placeholder"
    [ngModel]="searchTerm"
    (ngModelChange)="searchTermChange($event)"
  />
  <rdr-svg-icon
    class="position-absolute search-icon"
    key="search"
    color="var(--placeholder)"
    size="md"
  ></rdr-svg-icon>
</div>
