import { Pipe, PipeTransform } from '@angular/core';
import { TripType } from '@app/pages/flight-requests/components/create-task/create-task.component';
import { getReopenSearchTaskTripType } from '@app/pages/flight-requests/components/create-task/helpers/reopen-search-task';
import { SearchTaskNode } from '@app/generated/graphql';

@Pipe({
  name: 'isRoundTrip',
})
export class IsRoundTripPipe implements PipeTransform {
  transform(searchTask?: SearchTaskNode) {
    if (!searchTask) return false;
    return getReopenSearchTaskTripType(searchTask) === TripType.Round;
  }
}
