<ng-container *ngIf="selectedItem; else uploading">
  <ng-container *ngIf="withoutLink(); else uploaded">
    <span class="filename ps-1 text-truncate"> {{ selectedItem?.name || 'Document' }} </span>
    <typography look="inputPlaceholder"></typography>
  </ng-container>
  <ng-template #uploaded>
    <btn (btnClick)="download()" size="big" look="secondary" icon="file">Download</btn>
  </ng-template>
  <div class="remove">
    <rdr-svg-icon (click)="remove()" key="trash" size="md" color="var(--cherry-red)"></rdr-svg-icon>
  </div>
</ng-container>
<ng-template #uploading>
  <input type="file" class="d-none" #inputTemplate (change)="change($event)" />
  <btn (btnClick)="upload()" size="big" look="secondary" icon="cloud-upload">Upload</btn>
</ng-template>
