import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderStatusInteraction, OrderStatusType } from '@app/generated/graphql';

@Component({
  selector: 'order-status-event',
  templateUrl: './order-status-event.component.html',
  styleUrls: ['./order-status-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusEventComponent {
  OrderStatusType = OrderStatusType;

  @Input() interaction: OrderStatusInteraction & {
    orderStatus: OrderStatusType;
    orderPreviousStatus: OrderStatusType;
  };
}
