import { Input } from '@angular/core';
import { Directive } from '@angular/core';
import { UserType } from '@generated/graphql';
import { RoleAccessAbstractDirective } from '@ui/directives/show-for-role/role-access-abstract.directive';

@Directive({
  selector: '[showForRole]',
})
export class ShowForRoleDirective extends RoleAccessAbstractDirective {
  @Input('showForRole') roles: UserType[] | UserType;
}
