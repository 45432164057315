<field-layout [isReadonly]="isReadonly">
  <div editableView class="d-flex align-items-center datetime">
    <editable (modeChange)="modeChange($event)" [attr.data-id]="field.dataId" #editable>
      <ng-template viewMode>
        <div class="view-mode">
          <datetime-value-view
            [value]="formControl.value"
            [placeholder]="field.templateOptions?.placeholder"
          ></datetime-value-view>
          <rdr-svg-icon
            class="pencil-icon me-1 ms-auto"
            key="pencil-create"
            color="var(--in-place-icon)"
          ></rdr-svg-icon>
        </div>
      </ng-template>
      <ng-template editMode>
        <div class="d-flex mt-0 mb-0">
          <input
            [bsConfig]="{
            adaptivePosition: true,
            customTodayClass: 'custom-today',
            containerClass: 'theme-default',
            withTimepicker: true
          }"
            [formControl]="innerModel"
            [formlyAttributes]="field"
            #dp="bsDatepicker"
            #input
            id="date"
            type="text"
            class="formly-input"
            bsDatepicker
            onClick="this.select()"
            autocomplete="off"
          />
          <input
            [formControl]="hoursModel"
            id="hours"
            type="number"
            class="formly-input time-input time-types"
            placeholder="hh"
            onClick="this.select()"
            autocomplete="off"
          />
          <span class="d-flex align-items-center">:</span>
          <input
            [formControl]="minutesModel"
            id="minutes"
            type="number"
            class="formly-input time-input"
            placeholder="mm"
            onClick="this.select()"
            autocomplete="off"
          />
          <dropdown
            id="am-pm"
            (itemClick)="selectType($event)"
            [selected]="selectedTimeType"
            [options]="timeTypes"
            [look]="dropdownType.link"
            class="time-types"
          ></dropdown>
        </div>
      </ng-template>
    </editable>
  </div>

  <datetime-value-view
    readonlyView
    [attr.data-id]="field.dataId"
    [value]="formControl.value"
    [placeholder]="field.templateOptions?.placeholder"
  ></datetime-value-view>
</field-layout>
