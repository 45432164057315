import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientEdge } from '@generated/graphql';

@Component({
  selector: 'other-clients-block',
  templateUrl: './other-clients-block.component.html',
  styleUrls: ['./other-clients-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherClientsBlockComponent {
  @Input() clients?: ClientEdge[];
}
