import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'field-layout',
  templateUrl: './field-layout.component.html',
  styleUrls: ['./field-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldLayoutComponent {
  @Input() isReadonly?: boolean;
}
