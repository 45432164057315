import { Pipe, PipeTransform } from '@angular/core';
import { PLACEHOLDER } from '@shared/constants';

export interface GenericUser {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  transform(value?: GenericUser): string {
    if (!value) {
      return '';
    }

    const fullName = [];

    if (value.firstName) {
      fullName.push(value.firstName);
    }
    if (value.middleName) {
      fullName.push(value.middleName);
    }
    if (value.lastName) {
      fullName.push(value.lastName);
    }

    return fullName.join(' ') || PLACEHOLDER;
  }
}
