import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { RdrFormlyFieldConfig } from '../../basefield/basefield.component';

@Component({
  selector: 'count-v2',
  templateUrl: './count-v2.component.html',
  styleUrls: ['./count-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountV2Component extends BasefieldV2Component {
  decrement() {
    if (this.to.readonly) return;

    const value = Number(this.formControl.value) || 0;
    const min = this.to.min || 0;

    if (value > 0 && value > min) {
      this.formControl.setValue(value - 1);
      this.formControl.markAsDirty();
    }
  }

  increment() {
    if (this.to.readonly) return;

    const value = Number(this.formControl.value) || 0;
    this.formControl.setValue(value + 1);

    this.formControl.markAsDirty();
  }
}

export interface CountV2FormlyFieldConfig extends RdrFormlyFieldConfig {
  templateOptions?: {
    label?: string;
    biggerLabelMargin?: boolean;
    extra?: {
      title: string;
      info: string;
    };
    min?: number;
    readonly?: boolean;
  };
}
