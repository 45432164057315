import { Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, UntilDestroyed } from '../utils/until-destroy';

@UntilDestroy()
@Directive()
export abstract class PageTitleAbstract {
  pageTitle$ = new BehaviorSubject('');

  constructor() {
    this.pageTitle$.pipe(UntilDestroyed(this)).subscribe((newTitle) => {
      if (newTitle) {
        const titleElems = document.getElementsByTagName('title');

        if (titleElems[0]) {
          titleElems[0].innerText = newTitle;
        }
      }
    });
  }
}
