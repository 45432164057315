import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BookingNode, FlightItineraryGroupNode } from '@app/generated/graphql';
import { BookingType } from '@app/shared/models/booking-type.enum';

@Component({
  selector: 'current-itinerary',
  templateUrl: './current-itinerary.component.html',
  styleUrls: ['./current-itinerary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentItineraryComponent implements OnInit {
  BookingType = BookingType;

  activeBooking: BookingNode | null = null;

  @Input() totalPassengersCount?: number;
  @Input() createdByUserId?: number;
  @Input() createdAt?: Date;
  @Input() bookings: BookingNode[];
  @Input() changeRequestCurrentFlightItineraries?: FlightItineraryGroupNode[];

  @Output() newChangeRequestOption = new EventEmitter();

  ngOnInit() {
    this.setActiveBooking(this.bookings?.length > 1 ? null : this.bookings[0]);
  }

  setActiveBooking(bookingNode: BookingNode | null) {
    this.activeBooking = bookingNode;
  }

  resetActiveBooking() {
    this.activeBooking = null;
  }
}
