import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccNumberFormat',
})
export class CcNumberFormatPipe implements PipeTransform {
  transform(value: string, hideNumber = true): string {
    const val = value
      .replace(/\s+/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim();

    if (hideNumber) {
      const masked = val.slice(0, -5).replace(/\S/g, '*');
      return `${masked} ${val.substr(-4)}`;
    } else return val;
  }
}
