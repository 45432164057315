import { DropdownItem } from '@ui/components/dropdown.model';

export function filterDDWithCode(options: DropdownItem[] = [], searchValue = '') {
  const filtered: DropdownItem[] = [];
  const least: DropdownItem[] = [];

  const searchStr = searchValue.toLowerCase();

  options.forEach((option: DropdownItem) => {
    const code = option.extras?.code?.toLowerCase();
    const label = option.label?.toLowerCase();

    if (code?.indexOf(searchStr) === 0) {
      filtered.push(option);
    } else if ((label as string)?.indexOf(searchStr) > -1) {
      least.push(option);
    }
  });

  return [...filtered, ...least];
}

export function defaultDDFilter(options: DropdownItem[] = [], searchValue = '') {
  const searchStr = searchValue.toLowerCase();

  return options.filter((item) => item.label?.toLowerCase().indexOf(searchStr) !== -1);
}
