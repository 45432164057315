<div class="d-flex flex-column">
  <div class="d-flex justify-content-between v2-mb-1">
    <typography-v2 class="text-jazlyn-700" look="small" dataId="full-name"
      >{{ interaction.user?.fullName || 'Voyagu' }}</typography-v2
    >
    <typography-v2 class="text-jazlyn-700" look="small" dataId="created-at"
      >{{ interaction.createdAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <typography-v2 *ngIf="interaction.leadPreviousStatus">
    <a routerLink="/leads/{{ interaction.mainObjectId }}" data-id="id"
      >Lead #{{ interaction.mainObjectId }}</a
    >
    status changed from
    <typography-v2 look="text-semibold" dataId="prev-status"
      >{{ interaction.leadPreviousStatus | label }}</typography-v2
    >
    to
    <typography-v2 look="text-semibold" dataId="status"
      >{{ interaction.leadStatus | label }}</typography-v2
    >
  </typography-v2>

  <typography-v2 *ngIf="!interaction.leadPreviousStatus">
    <a routerLink="/leads/{{ interaction.mainObjectId }}" data-id="id"
      >Lead #{{ interaction.mainObjectId }}</a
    >
    created with the
    <typography-v2 look="text-semibold" dataId="status"
      >{{ interaction.leadStatus | label }}</typography-v2
    >
    status
  </typography-v2>
</div>
