import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { DropdownType } from '@app/ui/components/dropdown/dropdown.component';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { Mode } from '@ngneat/edit-in-place/lib/mode';
import { BasefieldComponent } from '../basefield/basefield.component';
import { isValidDate } from '../date/date-helpers';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Component({
  selector: 'datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeComponent extends BasefieldComponent implements OnInit {
  timeTypes = timeTypes;
  dropdownType = DropdownType;

  selectedTimeType: DropdownItem;

  innerModel = new UntypedFormControl('');
  hoursModel = new UntypedFormControl('');
  minutesModel = new UntypedFormControl('');

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('editable') editable: EditableComponent;

  @HostListener('keydown.enter') onEnter(): void {
    this.editable.saveEdit();
  }

  mode: Mode;

  @HostListener('keydown.escape', ['$event']) onEscape(event?: Event): void {
    event?.stopPropagation();

    if (this.formControl.value) {
      const initialValue = new Date(this.formControl.value as string);
      this.setModels(initialValue);
    }

    this.editable.saveEdit();
  }

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.selectedTimeType = timeTypes[0];

    if (this.formControl.value) {
      const initialValue = new Date(this.formControl.value as string);
      this.setModels(initialValue);
    }

    this.formControl.valueChanges.pipe(UntilDestroyed(this)).subscribe((newValue: string) => {
      const newValueDate = new Date(newValue);

      if (new Date(this.innerModel.value as string).getTime() !== newValueDate.getTime()) {
        this.setModels(newValueDate);
      }
    });
  }

  setModels(newValueDate: Date): void {
    this.selectedTimeType = newValueDate.getHours() >= 12 ? timeTypes[1] : timeTypes[0];

    this.innerModel.setValue(newValueDate);

    const hours = newValueDate.getHours() % 12 || 12;
    this.hoursModel.setValue(hours.toString().padStart(2, '0'));

    const minutes = newValueDate.getMinutes();
    this.minutesModel.setValue(minutes.toString().padStart(2, '0'));

    this.cdRef.detectChanges();
  }

  selectType(timeType: DropdownItem): void {
    this.selectedTimeType = timeType;
  }

  modeChange(mode: Mode): void {
    if (mode === this.mode) return;

    this.mode = mode;

    if (mode === 'view') {
      this.setDatetime();
    } else {
      setTimeout(() => this.input?.nativeElement?.select());
    }
  }

  setDatetime(): void {
    const newValue = new Date((this.innerModel.value as string) || new Date());

    let hours = Number(this.hoursModel.value) || 0;
    if (hours === 12) {
      hours = 0;
    }
    if (this.selectedTimeType.value === 'PM') {
      hours += 12;
    }
    newValue.setHours(hours);

    const minutes = Number(this.minutesModel.value) || 0;
    newValue.setMinutes(minutes);

    if (isValidDate(newValue)) {
      if (new Date(this.formControl.value as string).getTime() !== newValue.getTime()) {
        this.formControl.setValue(newValue?.toISOString());
      }
    } else {
      this.onEscape();
    }
  }
}

export const timeTypes: DropdownItem[] = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];
