import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { ConfirmModalV2Component } from '../components/confirm-modal-v2/confirm-modal-v2.component';
import { ModalV2Service } from '../services/modal-v2.service';

@UntilDestroy()
@Directive({
  selector: '[confirmOnDelete]',
})
export class ConfirmOnDeleteDirective {
  @Input('confirmOnDelete') entityName: string;
  @Input() confirmHeader: string;
  @Input() confirmMessage: string;
  @Input() confirmText: string;
  @Input() confirmButtonType = 'destructive';

  @Output() deleteConfirmed = new EventEmitter();

  constructor(private modalService: ModalV2Service) {}

  @HostListener('click') onDelete() {
    const modalRef = this.modalService.showModal({
      template: ConfirmModalV2Component,
      initialState: {
        header: this.confirmHeader || 'Delete Confirmation',
        message:
          this.confirmMessage || `Are you sure that you want to delete this ${this.entityName}?`,
        confirmText: this.confirmText || 'Delete',
        confirmButtonType: this.confirmButtonType,
      },
    });

    modalRef.content.confirmed
      .pipe(UntilDestroyed(this))
      .subscribe(() => this.deleteConfirmed.emit());
  }
}
