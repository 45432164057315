import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldComponent } from '@app/formly/types/basefield/basefield.component';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends BasefieldComponent {}
