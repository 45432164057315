<div class="notes">
  <ng-container *ngIf="notes$ | async as notes">
    <div id="note" class="note" *ngFor="let note of notes | extractNodes; trackBy: trackByFn">
      <typography look="inputPlaceholder"
        >{{ note.createdAt | rdrDate: 'extended' }} @{{ note.user!.fullName }}
      </typography>
      <br />
      <typography [innerText]="note.text"></typography>
    </div>
  </ng-container>
</div>

<textarea
  #textArea
  [formControl]="formControl"
  placeholder="Add Note"
  (keydown.enter)="onEnter($event)"
></textarea>
<btn-icon class="svg-icon" color="var(--in-place-icon)" size="lg" icon="send" (click)="onEnter()">
</btn-icon>
