import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import {
  CreateUserGQL,
  CreateUserMutationVariables,
  GetUsersGQL,
  GetUsersQueryVariables,
  SaveUserProfileGQL,
  SaveUserProfileMutationVariables,
  SetUserStatusGQL,
  SetUserStatusMutationVariables,
  ShiftNode,
  UpdateUserGQL,
  UpdateUserMutationVariables,
  UserByIdGQL,
  UserEdge,
  UserNode,
  UserNodeSortEnum,
  UsersGQL,
  UserShiftsGQL,
  UserType,
} from '@generated/graphql';
import { fetchAsDropDownItems } from '@shared/services/dropdown-fetch';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownItem } from '@ui/components/dropdown.model';
import { mapUserToDropdown } from '@app/pages/users/forms/user-form/user-form.component';
import { TeamMembersService } from '@app/shared/services/team-members/team-members.service';
import { RefetchQueries } from '@app/shared/types';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private usersGql: UsersGQL,
    private getUsersGQL: GetUsersGQL,
    private updateUserGQL: UpdateUserGQL,
    private createUserGQL: CreateUserGQL,
    private teamMembersService: TeamMembersService,
    private cognitoAuthService: CognitoAuthService,
    private userByIdGQL: UserByIdGQL,
    private saveUserProfileGQL: SaveUserProfileGQL,
    private setUserStatusGQL: SetUserStatusGQL,
    private userShiftsGQL: UserShiftsGQL
  ) {}

  getUsersAsDropdownItems(types: UserType[], withEmptyField = true) {
    return fetchAsDropDownItems(
      this.usersGql,
      ({ data }) => data.users,
      mapUserToDropdown,
      {
        typeIn: types,
        isActive: true,
        sort: UserNodeSortEnum.FullNameAsc,
      },
      withEmptyField
    );
  }

  getUsers(params: GetUsersQueryVariables): Observable<UserEdge[]> {
    return this.getUsersGQL
      .watch(params)
      .valueChanges.pipe(map(({ data }) => data.users?.edges as UserEdge[]));
  }

  updateUser(data: UpdateUserMutationVariables, refetchQueries: RefetchQueries = []) {
    return this.updateUserGQL.mutate(data, { refetchQueries });
  }

  createUser(data: CreateUserMutationVariables, refetchQueries: RefetchQueries = []) {
    return this.createUserGQL
      .mutate(data, { refetchQueries })
      .pipe(map(({ data }) => data?.createUser));
  }

  getTeamAndLeads(): Observable<DropdownItem[]> {
    return combineLatest([
      this.teamMembersService.getTeamMembers(this.cognitoAuthService.user.id),
      this.getUsersAsDropdownItems([UserType.SalesTeamLead], false),
    ]).pipe(
      map(([teamMembers, teamLeads]) => {
        const teamMemberItems = teamMembers.map((el) => ({
          ...mapUserToDropdown(el),
          groupBy: 'Team Members',
        }));

        const teamLeadsItems = teamLeads.map((el) => ({ ...el, groupBy: 'Team Leads' }));

        return [...teamMemberItems, ...teamLeadsItems];
      })
    );
  }

  getUserById(id: string): Observable<UserNode> {
    return this.userByIdGQL
      .fetch({ id }, { fetchPolicy: 'cache-first' })
      .pipe(map(({ data }) => data.user as UserNode));
  }

  saveUserProfile(
    variables: SaveUserProfileMutationVariables,
    refetchQueries: RefetchQueries = []
  ) {
    return this.saveUserProfileGQL.mutate(variables, { refetchQueries });
  }

  setUserStatus(params: SetUserStatusMutationVariables) {
    return this.setUserStatusGQL.mutate(params);
  }

  userShifts() {
    return this.userShiftsGQL
      .fetch()
      .pipe(
        map(({ data }) => (data.userShifts?.edges?.map(({ node }) => node) || []) as ShiftNode[])
      );
  }
}
