import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CostNode } from '@generated/graphql';

@Component({
  selector: 'payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodComponent {
  @Input() cost: CostNode;
}
