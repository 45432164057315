import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { GetHistoriesQueryVariables, HistoryEdge } from '@app/generated/graphql';
import { HISTORIES_LIMIT } from '@app/shared/constants';
import { HistoryService } from '@app/shared/services/history/history.service';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent implements OnInit {
  histories$: Subscription;
  histories: HistoryEdge[] = [];

  params: GetHistoriesQueryVariables;
  previousHistoriesLength = 0;

  @Input() historyRoot: string;

  constructor(private historyService: HistoryService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.params = {
      filters: { historyRoot: this.historyRoot },
      last: HISTORIES_LIMIT,
      before: undefined,
    };

    this.getHistories();
  }

  getHistories(): void {
    this.histories$?.unsubscribe();

    this.histories$ = this.historyService
      .getHistories(this.params)
      .pipe(
        tap(({ data }) => {
          this.histories = (data.histories?.edges || []).concat(this.histories) as HistoryEdge[];
          this.params.before = data.histories?.pageInfo.startCursor;
          this.cdRef.detectChanges();
          setTimeout(this.scrollToShowed.bind(this));
        })
      )
      .pipe(UntilDestroyed(this))
      // TODO fixme: subsribe with async pipe in templ without rerenders
      .subscribe();
  }

  onScroll(e: Event) {
    if ((e.target as HTMLElement)?.scrollTop === 0) {
      this.params.before && this.getHistories();
    }
  }

  scrollToShowed(): void {
    const histories = document.querySelectorAll('[id="history"]');
    histories[histories.length - this.previousHistoriesLength - 1]?.scrollIntoView();
    this.previousHistoriesLength = histories.length - 1;
  }
}
