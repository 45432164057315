import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BookingEdge, BookingNode } from '@app/generated/graphql';
import { RoutingService } from '@app/shared/services/routing.service';

@Component({
  selector: 'result-bookings-block',
  templateUrl: './result-bookings-block.component.html',
  styleUrls: ['./result-bookings-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultBookingsBlockComponent {
  @Input() bookings?: BookingEdge[];
  @Output() rowClick = new EventEmitter();

  constructor(private routingService: RoutingService) {}

  openBooking(event: MouseEvent, booking: BookingNode) {
    const queryParams = { booking: booking.id };
    this.routingService.navigateTo(['orders', booking.order?.id as string], event, { queryParams });
  }
}
