import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BasefieldComponent } from '../basefield/basefield.component';
import IMask from 'imask';

@Component({
  selector: 'currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent extends BasefieldComponent implements OnInit {
  imask: IMask.MaskedNumberOptions;

  ngOnInit(): void {
    this.imask = {
      mask: Number,
      signed: true,
      radix: '.',
      thousandsSeparator: ',',
      scale: this.to.currencyFormat === 'cents' ? 5 : 2,
    };
  }
}
