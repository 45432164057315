import { EventService } from '@shared/services/event.service';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DropdownItem } from '@ui/components/dropdown.model';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { ModalV2Service } from '@app/ui-v2/services/modal-v2.service';
import { uniqueId } from 'lodash';

@UntilDestroy()
@Directive()
export abstract class DropdownAbstractClass {
  private eventService: EventService;
  protected modalService: ModalV2Service;

  clickSubscription: Subscription;
  id = uniqueId('dropdown');

  @Input() collapsed = true;
  @Output() collapse = new EventEmitter();

  constructor(private elRef: ElementRef, protected cdRef: ChangeDetectorRef) {
    this.eventService = inject(EventService);
    this.modalService = inject(ModalV2Service);
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape(e: Event): void {
    if (!this.collapsed) {
      this.toggleCollapsed(e as MouseEvent);
    }
  }

  outsideClickHandler(event: MouseEvent) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.collapsed = true;
      this.removeClickSubscription();
      this.modalService.allowCloseOnEscape(this.id);
    } else {
      this.collapsed = false;
      this.modalService.preventCloseOnEscape(this.id);
    }

    this.cdRef.markForCheck();

    this.collapse.emit(this.collapsed);
  }

  toggleCollapsed(e?: MouseEvent, shouldOpen?: boolean) {
    if (this.collapsed || shouldOpen) {
      this.modalService.preventCloseOnEscape(this.id);
      this.collapsed = false;
      this.clickSubscription = this.eventService
        .onDocumentClick()
        .pipe(UntilDestroyed(this))
        .subscribe((event: MouseEvent) => this.outsideClickHandler(event));
    } else {
      this.removeClickSubscription();
      this.collapsed = true;
      this.modalService.allowCloseOnEscape(this.id);
    }

    this.cdRef.markForCheck();

    this.collapse.emit(this.collapsed);
  }

  removeClickSubscription() {
    this.clickSubscription?.unsubscribe();
  }

  trackByFn(index: number, el: DropdownItem) {
    return el.value;
  }
}
