import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { FieldType } from '@ngx-formly/core';
import { FormlyTemplateOptions } from '@ngx-formly/core/lib/components/formly.field.config';
import { FieldTypeConfig } from '@ngx-formly/core/lib/templates/field.type';

export interface RdrFormlyTemplateOptions extends FormlyTemplateOptions {
  type?: 'text' | 'email';
  mask?: 'alphanumeric' | 'email';
}

export interface RdrFormlyTemplateOptions2 extends FormlyTemplateOptions {
  biggerLabelMargin?: boolean;
}

export interface RdrFormlyFieldConfig extends Partial<FieldTypeConfig> {
  dataId?: string;
  colored?: boolean;
  fieldGroup?: RdrFormlyFieldConfig[];
  templateOptions?: RdrFormlyTemplateOptions | FormlyTemplateOptions;
  wideField?: boolean;
}

export interface RdrFormlyFieldConfig2 extends Partial<FieldTypeConfig> {
  dataId?: string;
  fieldGroup?: RdrFormlyFieldConfig[];
  templateOptions?: RdrFormlyTemplateOptions2 | FormlyTemplateOptions;
}

@Directive()
export class BasefieldComponent<F extends RdrFormlyFieldConfig = RdrFormlyFieldConfig>
  extends FieldType<F>
  implements AfterViewInit, AfterViewChecked
{
  @ViewChild('editableRef') editable: EditableComponent;
  @ViewChild('viewModeRef') viewModeRef: ElementRef;

  @HostBinding('class.wide-field') get isWideField() {
    return this.to.wideField;
  }

  innerModel: UntypedFormControl;
  isReadonly? = false;

  constructor(protected cdRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    if (this.to.autofocus && !this.formControl.value) {
      // used hack because displayEditMode from EditableComponent has problems with rerenders
      this.viewModeRef?.nativeElement.click();
    }
  }

  ngAfterViewChecked() {
    // refactor: this check should be moved to a form level
    this.isReadonly = this.to.readonly || (this.field as RdrFormlyFieldConfig).form?.disabled;
    this.cdRef.detectChanges();
  }

  cancel() {
    this.innerModel?.setValue(this.formControl.value);
  }

  changeMode(event: Event): void {
    if (this.isReadonly) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
