import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'page-header-v2',
  templateUrl: './page-header-v2.component.html',
  styleUrls: ['./page-header-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderV2Component {
  @HostBinding('style.height.px') @Input() height = 64;
}
