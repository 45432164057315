import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { map } from 'rxjs/operators';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { UserAuthGQL } from '@app/generated/graphql';
import { Hub } from '@aws-amplify/core';
import { FetchPolicy } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class RdrAuthService {
  constructor(private userAuthGql: UserAuthGQL, private cognitoAuthService: CognitoAuthService) {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          if (data) {
            window.location = data;
          }
          break;
      }
    });
  }

  amplifyLogin(redirectUrl: string) {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: redirectUrl,
    });
  }

  getUser(fetchPolicy: FetchPolicy = 'cache-first') {
    return this.userAuthGql
      .fetch(
        {},
        {
          fetchPolicy,
        }
      )
      .pipe(
        map((el) => {
          // fixme: such a mess
          this.cognitoAuthService.user.id = el.data?.userAuth?.id || '';
          this.cognitoAuthService.user.type = el.data?.userAuth?.type;
          this.cognitoAuthService.user.fullName = el.data?.userAuth?.fullName;
          this.cognitoAuthService.user.picture = el.data?.userAuth?.picture;
          this.cognitoAuthService.user.historyAccess = el.data?.userAuth?.historyAccess;
          this.cognitoAuthService.user.salesDirector = el.data?.userAuth?.salesDirector;
          this.cognitoAuthService.user.isActive = el.data?.userAuth?.isActive;
          this.cognitoAuthService.user.superadmin = el.data?.userAuth?.superadmin;
          this.cognitoAuthService.user.isSuperadminContext = el.data?.userAuth?.isSuperadminContext;
          this.cognitoAuthService.user.profile = el.data?.userAuth?.profile;
          this.cognitoAuthService.user.currentStatus = el.data?.userAuth?.callProfile?.status;
          this.cognitoAuthService.user.authorizedInRc = el.data?.userAuth?.authorizedInRc;

          return el.data.userAuth;
        })
      );
  }
}
