<input
  #searchInput
  [formControl]="searchControl"
  [placeholder]="placeholder"
  [attr.data-id]="key"
  [ngClass]="{ 'has-left-img': leftImgTpl && selectedItem, 'is-filter': isFilter, 'disabled': disabled }"
  class="text-input"
  (focus)="onOpen()"
  inputSelectAll
/>

<rdr-svg-icon
  *ngIf="!clearable || !selectedItem?.value"
  (click)="searchInput.focus()"
  class="search-icon"
  key="search"
  color="var(--placeholder)"
  class="position-absolute icon"
></rdr-svg-icon>

<rdr-svg-icon
  *ngIf="clearable && selectedItem?.value && !disabled"
  class="position-absolute icon cursor-pointer"
  key="circle-x"
  color="var(--placeholder)"
  (click)="clearSelection(); $event.stopPropagation()"
>
</rdr-svg-icon>

<div class="v2-mr-2 selected-item-logo" *ngIf="selectedItem">
  <ng-container *ngTemplateOutlet="leftImgTpl; context: { item: selectedItem }"></ng-container>
</div>

<ng-container *ngIf="loading">
  <ng-container *ngIf="loadingTemplate; else defaultLoading">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </ng-container>
  <ng-template #defaultLoading>
    <skeleton-loading-dropdown
      class="position-absolute item-container loading-options"
    ></skeleton-loading-dropdown>
  </ng-template>
</ng-container>

<ng-container *ngIf="!collapsed && !loading">
  <context-menu-v2
    #dropdownMenu
    (clickOutside)="onBlur()"
    [style.max-height.px]="maxHeight"
    class="position-absolute item-container"
  >
    <ng-container *ngIf="innerItems | filterByLabel: searchValue as filteredItems">
      <ng-container *ngIf="filteredItems.length; else noItemsTemplate">
        <ng-container
          *ngIf="customListTemplate; else defaultList"
          [ngTemplateOutlet]="customListTemplate"
          [ngTemplateOutletContext]="{items: filteredItems, selectMatch: optionSelected, hoveredItem: hoveredItem, setHoveredItem: setHoveredItem}"
        >
        </ng-container>
      </ng-container>
    </ng-container>
  </context-menu-v2>
</ng-container>

<ng-template #defaultList>
  <ng-container *ngIf="(innerItems | filterByLabel: searchValue: filterCallback) as filteredItems">
    <ng-container *ngFor="let group of filteredItems | groupByField: groupByField">
      <typography-v2
        look="subtitle-bold"
        class="text-capitalize v2-mb-1 v2-mt-1 v2-pl-1"
        *ngIf="group.key"
      >
        {{ group.key }}
      </typography-v2>
      <dropdown-item-v2
        [attr.id]="item.value"
        [hovered]="hoveredItem === item"
        (mouseenter)="hoveredItem = item"
        (mouseleave)="hoveredItem = null"
        *ngFor="let item of group.items"
        (mousedown)="optionSelected(item)"
      >
        <ng-container *ngTemplateOutlet="leftImgTpl; context: { item: item }"></ng-container>
        <typography-v2 look="text" class="text-truncate">{{ item.label }}</typography-v2>
      </dropdown-item-v2>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noItemsTemplate>
  <ng-container *ngIf="emptyTemplate; else noResults" [ngTemplateOutlet]="emptyTemplate">
  </ng-container>
</ng-template>

<ng-template #noResults>
  <div class="v2-p-2 text-black-50">No results</div>
</ng-template>
