import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validMultiFormPipe',
  pure: false,
})
export class ValidMultiFormPipePipe implements PipeTransform {
  transform(formModel: any): boolean {
    const allDestinationsFilled = formModel.flights?.every((dest: any) => {
      return dest.departureDate && dest.departureAirports?.length && dest.arrivalAirports?.length;
    });

    return !!(
      formModel.flights?.length > 0 &&
      allDestinationsFilled &&
      formModel.serviceClass &&
      (formModel.numberOfAdults || formModel.numberOfChildren || formModel.numberOfInfants)
    );
  }
}
