import { CommonModule } from '@angular/common';
import { OnDestroy, OnInit, inject } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserType } from '@app/generated/graphql';
import { SharedModule } from '@app/shared/shared.module';
import { HotLeadPollingService } from '../service/hot-lead-polling.service';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { UiModule } from '@app/ui/ui.module';

@Component({
  selector: 'hot-lead-popup',
  templateUrl: './hot-lead-popup.component.html',
  styleUrls: ['./hot-lead-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, UiV2Module, UiModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotLeadPopupComponent implements OnInit, OnDestroy {
  public pollerService = inject(HotLeadPollingService);

  audio: HTMLAudioElement;

  readonly UserType = UserType;
  acceptingLead = false;
  audioLink = '../../../assets/sounds/hot_lead_ringtone.mp3';

  ngOnInit() {
    this.playAudio();
  }

  playAudio() {
    this.audio = new Audio();
    this.audio.src = this.audioLink;
    this.audio.loop = true;
    this.audio.load();

    this.audio.play().catch(() => setTimeout(() => this.playAudio(), 1000));
  }

  dismiss() {
    this.pollerService.dismiss();
    this.audio?.pause();
  }

  acceptLead() {
    this.acceptingLead = true;
    this.pollerService.tryLockLead();
    this.audio?.pause();
  }

  ngOnDestroy() {
    this.audio?.pause();
  }
}
