import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform<T, K extends keyof T>(items: T[], prop: K, val: unknown = true): T[] {
    return items.filter((el) => el[prop] === val);
  }
}
