import { Directive, Input } from '@angular/core';
import { UserType } from '@generated/graphql';
import { RoleAccessAbstractDirective } from '@ui/directives/show-for-role/role-access-abstract.directive';

@Directive({
  selector: '[hideForRole]',
})
export class HideForRoleDirective extends RoleAccessAbstractDirective {
  isInverted = true;
  @Input('hideForRole') roles: UserType[] | UserType;
}
