<div class="d-flex flex-column">
  <div class="d-flex justify-content-between v2-mb-1">
    <typography-v2 class="text-jazlyn-700" look="small" dataId="full-name"
      >{{ interaction.user?.fullName || 'Voyagu' }}</typography-v2
    >
    <typography-v2 class="text-jazlyn-700" look="small" dataId="created-at"
      >{{ interaction.createdAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <ng-container [ngSwitch]="interaction.orderStatus">
    <typography-v2 *ngSwitchCase="OrderStatusType.OptionPublished">
      <a routerLink="/flight-requests/{{ interaction.flightRequestId }}" data-id="flight-request-id"
        >Flight Option #{{ interaction.mainObjectId }}</a
      >
      was published
    </typography-v2>

    <typography-v2 *ngSwitchCase="OrderStatusType.OptionBooked">
      <a routerLink="/flight-requests/{{ interaction.flightRequestId }}" data-id="flight-request-id"
        >Flight Option #{{ interaction.mainObjectId }}</a
      >
      was booked
    </typography-v2>

    <typography-v2 *ngSwitchCase="OrderStatusType.OptionSelected">
      <a routerLink="/flight-requests/{{ interaction.flightRequestId }}" data-id="flight-request-id"
        >Flight Option #{{ interaction.mainObjectId }}</a
      >
      was selected
    </typography-v2>

    <typography-v2 *ngSwitchCase="OrderStatusType.AuthorizationPending">
      <a routerLink="/flight-requests/{{ interaction.flightRequestId }}" data-id="flight-request-id"
        >Flight Option #{{ interaction.mainObjectId }}</a
      >
      converted into
      <a routerLink="/orders/{{ interaction.mainObjectId }}" data-id="order-id"
        >Order #{{ interaction.mainObjectId }}</a
      >
      and its status changed from
      <typography-v2 look="text-semibold" dataId="prev-status"
        >{{ interaction.orderPreviousStatus | label }}</typography-v2
      >
      to
      <typography-v2 look="text-semibold" dataId="status"
        >{{ interaction.orderStatus | label }}</typography-v2
      >
    </typography-v2>

    <typography-v2 *ngSwitchCase="OrderStatusType.Preparing">
      <ng-container
        *ngIf="
          interaction.orderPreviousStatus === OrderStatusType.Option ||
          interaction.orderPreviousStatus === OrderStatusType.OptionPublished ||
          interaction.orderPreviousStatus === OrderStatusType.OptionSelected ||
          interaction.orderPreviousStatus === OrderStatusType.OptionBooked; else defaultTemplate
        "
      >
        <a
          routerLink="/flight-requests/{{ interaction.flightRequestId }}"
          data-id="flight-request-id"
          >Flight Option #{{ interaction.mainObjectId }}</a
        >
        converted into
        <a routerLink="/orders/{{ interaction.mainObjectId }}" data-id="order-id"
          >Order #{{ interaction.mainObjectId }}</a
        >
        and its status changed from
        <typography-v2 look="text-semibold" dataId="prev-status"
          >{{ interaction.orderPreviousStatus | label }}</typography-v2
        >
        to
        <typography-v2 look="text-semibold" dataId="status"
          >{{ interaction.orderStatus | label }}</typography-v2
        >
      </ng-container>
    </typography-v2>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #defaultTemplate>
  <typography-v2 *ngIf="interaction.orderPreviousStatus">
    <a routerLink="/orders/{{ interaction.mainObjectId }}" data-id="order-id"
      >Order #{{ interaction.mainObjectId }}</a
    >
    status changed from
    <typography-v2 look="text-semibold" dataId="prev-status"
      >{{ interaction.orderPreviousStatus | label }}</typography-v2
    >
    to
    <typography-v2 look="text-semibold" dataId="status"
      >{{ interaction.orderStatus | label }}</typography-v2
    >
  </typography-v2>

  <typography-v2 *ngIf="!interaction.orderPreviousStatus">
    <a routerLink="/orders/{{ interaction.mainObjectId }}" data-id="order-id"
      >Order #{{ interaction.mainObjectId }}</a
    >
    created with the
    <typography-v2 look="text-semibold" dataId="status"
      >{{ interaction.orderStatus | label }}</typography-v2
    >
    status
  </typography-v2>
</ng-template>
