<div class="d-flex">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <skeleton-loader-small [width]="54" [height]="16"></skeleton-loader-small>
    <skeleton-loader-small [width]="365" [height]="16" class="v2-mt-1"></skeleton-loader-small>
    <skeleton-loader-small [width]="114" [height]="16" class="v2-mt-1"></skeleton-loader-small>
  </div>
</div>

<div class="d-flex v2-mt-8">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <skeleton-loader-small [width]="54" [height]="16"></skeleton-loader-small>
    <skeleton-loader-small [width]="365" [height]="16" class="v2-mt-1"></skeleton-loader-small>
    <skeleton-loader-small [width]="114" [height]="16" class="v2-mt-1"></skeleton-loader-small>
  </div>
</div>

<div class="d-flex v2-mt-8">
  <skeleton-loader-small [width]="40" [height]="40" class="v2-mr-2"></skeleton-loader-small>

  <div class="d-flex flex-column flex-1">
    <skeleton-loader-small [width]="54" [height]="16"></skeleton-loader-small>
    <skeleton-loader-small [width]="365" [height]="16" class="v2-mt-1"></skeleton-loader-small>
    <skeleton-loader-small [width]="114" [height]="16" class="v2-mt-1"></skeleton-loader-small>
  </div>
</div>
