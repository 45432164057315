<span data-id="method" *ngIf="cost.isWireTransfer">Check Payment</span>
<span data-id="method" *ngIf="cost.isAccountHolder">Account Holder</span>
<ng-container *ngIf="!cost.isWireTransfer && !cost.isAccountHolder">
  <span
    data-id="method"
    [tooltip]="cost.secureCreditCard | creditCardTitle"
    container="body"
    placement="left"
    >{{ cost.secureCreditCard | creditCardTitle }}</span
  >
</ng-container>
