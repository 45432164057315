<ng-container *ngIf="selected?.colored; else regularBtn">
  <colored-status class="p-0" [status]="selected?.value || ''">
    <btn
      class="toggle-btn"
      look="transparent"
      [size]="btnSize.big"
      (btnClick)="toggleCollapsed($event)"
      [disabledBtn]="disabledDropdown"
    >
      <ng-template *ngTemplateOutlet="btnContent"></ng-template>
    </btn>
  </colored-status>
</ng-container>

<ng-template #regularBtn>
  <btn
    class="toggle-btn"
    [look]="look === dropdownType.button ? btnType.primary : btnType.link"
    [size]="btnSize.big"
    (btnClick)="toggleCollapsed($event)"
  >
    <ng-template *ngTemplateOutlet="btnContent"></ng-template>
  </btn>
</ng-template>

<ng-template #btnContent>
  <div class="d-flex align-items-center">
    <user-picture
      *ngIf="itemType === 'agentItem'"
      class="pe-1"
      [picture]="selected?.picture"
    ></user-picture>
    <typography [look]="look === dropdownType.button ? 'H4' : 'normal'">
      <span *ngIf="selected">{{ selected.label }}</span>

      <span *ngIf="!selected">{{ placeholder }}</span>
    </typography>
  </div>

  <rdr-svg-icon
    *ngIf="look === dropdownType.button && !collapsed"
    key="chevron-top"
    class="ms-2"
  ></rdr-svg-icon>
  <rdr-svg-icon
    *ngIf="look === dropdownType.button && collapsed"
    key="chevron-bottom"
    class="ms-2"
  ></rdr-svg-icon>
</ng-template>

<ul
  *ngIf="!collapsed"
  class="navbar-dropdown"
  [ngClass]="{ 'w-auto left-0': itemType === 'milesAccount' }"
>
  <ng-content></ng-content>
  <ng-container [ngSwitch]="itemType">
    <ng-container *ngSwitchCase="'milesAccount'" [ngTemplateOutlet]="milesAccItem"></ng-container>
    <ng-container *ngSwitchCase="'agentItem'" [ngTemplateOutlet]="agentItem"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultItem"></ng-container>
  </ng-container>
</ul>

<ng-template #milesAccItem>
  <dropdown-miles-acc-items
    [rows]="$any(options)"
    (selectRow)="onItemClick(null, $event)"
  ></dropdown-miles-acc-items>
</ng-template>

<ng-template #agentItem>
  <dropdown-item
    *ngFor="let option of options; trackBy: trackByFn"
    (click)="onItemClick($event, option)"
    [item]="option"
    [className]="(option.className ? option.className : '') + ' d-flex'"
  >
    <user-picture class="pe-1" [picture]="option.picture"></user-picture>
  </dropdown-item>
</ng-template>

<ng-template #defaultItem>
  <dropdown-item
    *ngFor="let option of options; trackBy: trackByFn"
    (click)="onItemClick($event, option)"
    [item]="option"
    [className]="option.className ? option.className : ''"
  ></dropdown-item>
</ng-template>
