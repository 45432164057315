import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[hoverClass]',
})
export class HoverClassDirective {
  constructor(public elementRef: ElementRef) {}
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('hover-class') hoverClass = 'hover';

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add(this.hoverClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
  }
}
