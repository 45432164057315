<button-icon-v2
  (click)="showedAlerts = true"
  color="var(--color-jazlyn-700)"
  key="bell"
  type="header"
  data-id="alerts"
></button-icon-v2>

<ng-container *ngIf="unreadCountAlerts$ | async as unreadCountAlerts">
  <div
    class="position-absolute d-flex align-items-center justify-content-center unread-count-alerts"
    data-id="unread-count-alerts"
  >
    {{ unreadCountAlerts }}
  </div>
</ng-container>

<context-menu-v2
  *ngIf="showedAlerts"
  (clickOutside)="showedAlerts = false"
  class="v2-mt-1 v2-p-4 position-absolute alerts"
>
  <alerts-list
    (hide)="showedAlerts = false"
    (markEmitter)="initUnreadCountAlertsPolling()"
  ></alerts-list
></context-menu-v2>
