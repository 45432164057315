import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import {
  BookingChangeRequestStatusType,
  BookingNode,
  ChangeRequestFlightItineraryGroupNode,
  FlightItineraryGroupNode,
  FlightSegmentNode,
} from '@app/generated/graphql';
import { GraphqlID } from '@app/shared/types';
import { RdrFeature } from '@app/ui/services/feature-toggle.service';

@Component({
  selector: 'itinerary-v2-flights',
  templateUrl: './itinerary-v2-flights.component.html',
  styleUrls: ['./itinerary-v2-flights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryV2FlightsComponent implements OnChanges {
  ItineraryTabsEnum = ItineraryTabsEnum;
  RdrFeature = RdrFeature;

  visibleCancellation: boolean;
  isCancellationItineraries: boolean;

  tabs: ItineraryTabsEnum[] = [];
  activeTab: ItineraryTabsEnum = ItineraryTabsEnum.New;

  @Input() flightItineraries?: FlightItineraryGroupNode[];
  @Input() showSeatsSelection = false;
  @Input() booking: BookingNode;
  @Input() readonly: boolean;
  @Input() changeRequestFlightItineraries?: ChangeRequestFlightItineraryGroupNode;
  @Input() readonlyExtra = false;

  @Output() selectSeats = new EventEmitter<FlightSegmentNode>();
  @Output() cancelAllSegments = new EventEmitter();
  @Output() toggleExtra = new EventEmitter();

  constructor(private cognitoAuthService: CognitoAuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.booking) {
      const isChangeRequest = !!this.booking?.parentId;

      if (isChangeRequest) {
        this.updateTabsView();
      } else {
        this.activeTab = ItineraryTabsEnum.New;
      }

      this.visibleCancellation = !(
        !this.cognitoAuthService.isTicketingAgent && this.booking.order?.ticketed
      );
    }
  }

  updateTabsView() {
    const hasItineraryChanges =
      this.booking?.changeRequestStatus === BookingChangeRequestStatusType.Changed;

    this.isCancellationItineraries =
      this.booking?.changeRequestStatus === BookingChangeRequestStatusType.Deleted;

    if (hasItineraryChanges || this.isCancellationItineraries) {
      this.tabs = [ItineraryTabsEnum.New, ItineraryTabsEnum.Original, ItineraryTabsEnum.Mixed];
    } else {
      this.tabs = [ItineraryTabsEnum.Original];
    }

    this.setActiveTab(this.tabs[0]);
  }

  setActiveTab(tab: ItineraryTabsEnum) {
    if (this.changeRequestFlightItineraries) {
      this.activeTab = tab;
      this.flightItineraries = this.changeRequestFlightItineraries[tab] || [];
    }
  }

  setExtra(isExtraLeg: boolean, id?: GraphqlID) {
    this.toggleExtra.emit({ isExtraLeg, id });
  }
}

export enum ItineraryTabsEnum {
  New = 'new',
  Original = 'original',
  Mixed = 'mixed',
}
