import { EMPTY_ID, PLACEHOLDER } from '@shared/constants';

export interface DropdownItem<F = string> {
  label?: string;
  value?: F;
  className?: string;
  colored?: boolean;
  picture?: string;
  extras?: Record<string, any>;
  groupBy?: string;
}

export const EMPTY_DROP_DOWN_ITEM = {
  value: EMPTY_ID,
  label: PLACEHOLDER,
  className: 'empty',
} as DropdownItem;
