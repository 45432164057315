import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { FormSubmitData } from '@shared/components/radar-form-abstract';
import { combineLatest, Observable, of } from 'rxjs';
import {
  BookingConnection,
  ClientConnection,
  LeadConnection,
  OrderConnection,
  UserNode,
} from '@generated/graphql';
import { GlobalSearchService } from '@ui/global-search/services/global-search.service';
import { DropdownAbstractClass } from '@ui/components/dropdown-abstract';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { map, switchMap } from 'rxjs/operators';
import { Maybe } from '@app/shared/types';
import { GlobalSearchForm } from '@ui/global-search/components/global-search-form/global-search-form.component';
import { CallPopupService } from '@app/calls/services/call-popup/call-popup.service';
import { Router } from '@angular/router';
import { TeamMembersService } from '@app/shared/services/team-members/team-members.service';

@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent extends DropdownAbstractClass implements OnInit {
  myClients$: Observable<ClientConnection> = new Observable<ClientConnection>();
  otherClients$: Observable<ClientConnection> = new Observable<ClientConnection>();
  myLeads$: Observable<LeadConnection> = new Observable<LeadConnection>();
  otherLeads$: Observable<LeadConnection> = new Observable<LeadConnection>();
  orders$: Observable<OrderConnection> = new Observable<OrderConnection>();
  bookings$: Observable<BookingConnection> = new Observable<BookingConnection>();
  hotLeads$: Observable<LeadConnection> = new Observable<LeadConnection>();

  isEmpty$: Observable<boolean>;
  teamMembers$: Observable<Maybe<UserNode[]>>;

  data: any = {};
  loading = false;

  isTicketingAgent: boolean;
  isAccountant: boolean;
  isSalesDirector: boolean;
  isOnlySalesAgent: boolean;

  constructor(
    elRef: ElementRef,
    cdRef: ChangeDetectorRef,
    private globalSearchService: GlobalSearchService,
    private cognitoAuth: CognitoAuthService,
    private teamMembersService: TeamMembersService,
    private callPopupService: CallPopupService,
    private router: Router
  ) {
    super(elRef, cdRef);
  }

  ngOnInit(): void {
    this.isTicketingAgent = this.cognitoAuth.isTicketingAgent;
    this.isAccountant = this.cognitoAuth.isAccountant;
    this.isSalesDirector = this.cognitoAuth.isSalesDirector;
    this.isOnlySalesAgent = this.cognitoAuth.isOnlySalesAgent;
  }

  fetchData(term: string) {
    this.loading = true;

    this.teamMembers$ = this.cognitoAuth.isSalesTeamLead
      ? this.teamMembersService.getTeamMembers(this.cognitoAuth.user.id)
      : of([]);

    this.isEmpty$ = this.teamMembers$.pipe(
      switchMap((teamMembers) => {
        this.myClients$ = this.globalSearchService.searchClients({
          term,
          visibility: 'my',
          teamMembers,
          withoutUsersFilter: !this.cognitoAuth.isSales,
        });

        if (!this.isTicketingAgent && !this.isAccountant) {
          this.otherClients$ = this.globalSearchService.searchClients({
            term,
            visibility: 'other',
            teamMembers,
            withoutUsersFilter: !this.cognitoAuth.isSales,
          });
          this.myLeads$ = this.globalSearchService.searchLeads({
            term,
            visibility: 'my',
            teamMembers,
          });
          this.otherLeads$ = this.globalSearchService.searchLeads({
            term,
            visibility: 'other',
            teamMembers,
            isOnlySalesAgent: this.isOnlySalesAgent,
          });
          this.hotLeads$ = this.globalSearchService.searchHotLeads({
            term,
          });
        }

        this.orders$ = this.globalSearchService.searchOrders({
          term,
          teamMembers,
          withoutUsersFilter: !this.cognitoAuth.isSales,
        });
        this.bookings$ = this.globalSearchService.searchBookings({
          term,
          teamMembers,
          withoutUsersFilter: !this.cognitoAuth.isSales || this.cognitoAuth.isSalesDirector,
        });

        return combineLatest([
          this.myClients$,
          ...(this.isTicketingAgent || this.isAccountant
            ? []
            : [this.otherClients$, this.myLeads$, this.otherLeads$, this.hotLeads$]),
          this.orders$,
          this.bookings$,
        ]);
      }),
      map(([myClients, otherClients, myLeads, otherLeads, orders, bookings, hotLeads]) => {
        this.loading = false;

        return !(
          myClients?.totalCount ||
          otherClients?.totalCount ||
          myLeads?.totalCount ||
          otherLeads?.totalCount ||
          orders?.totalCount ||
          bookings?.totalCount ||
          hotLeads?.totalCount
        );
      })
    );
  }

  assignHotLead(leadId: string) {
    this.callPopupService.callAnswered(leadId).subscribe(() => {
      this.clearSearch();
      void this.router.navigate([`/leads/${leadId}`]);
    });
  }

  clearSearch() {
    this.data = {};
    this.toggleCollapsed();
  }

  outsideClickHandler(event: MouseEvent) {
    super.outsideClickHandler(event);
    if (this.collapsed) {
      this.data = {};
    }
  }

  submit({ data }: FormSubmitData<GlobalSearchForm>) {
    if (data.search) {
      if (this.collapsed) {
        this.toggleCollapsed();
      }

      this.fetchData(data.search);
    }
  }
}
