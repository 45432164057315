<form [formGroup]="formGroup" (ngSubmit)="submitForm()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2 (click)="goBack.emit()" type="secondary" data-id="back" class="v2-mr-2">
      <typography-v2 look="caption-bold">Back</typography-v2>
    </button-v2>

    <button-v2 buttonType="submit" data-id="create-task" formButtonState>
      <typography-v2 look="caption-bold">Create Task</typography-v2>
    </button-v2>
  </div>
</form>
