import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldComponent } from '../basefield/basefield.component';

@Component({
  selector: 'cc-cvv',
  templateUrl: './cc-cvv.component.html',
  styleUrls: ['./cc-cvv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCcCvvComponent extends BasefieldComponent {}
