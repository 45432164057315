import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GetHistoriesGQL,
  GetHistoriesQuery,
  GetHistoriesQueryVariables,
} from '@app/generated/graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private getHistoriesGQL: GetHistoriesGQL) {}

  getHistories(
    params: GetHistoriesQueryVariables
  ): Observable<ApolloQueryResult<GetHistoriesQuery>> {
    return this.getHistoriesGQL.fetch(params);
  }
}
