<div
  *ngFor="let item of items; let last = last"
  (click)="!disabled && selectItem.emit(item.value)"
  [attr.data-id]="key + '_' + item.value"
  [ngClass]="{ 'disabled': disabled, 'v2-mt-2': !horizontal, 'v2-mr-5': horizontal && !last }"
  class="d-flex align-items-center"
>
  <div
    [ngClass]="{ 'selected': item.value === value, 'cursor-pointer': !disabled }"
    class="v2-mr-2 radio"
  ></div>
  <typography-v2
    look="text"
    [ngClass]="{ 'cursor-pointer': !disabled, 'selected-label': item.value === value }"
    class="radio-label"
    >{{ item.label }}</typography-v2
  >
</div>
