import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellComponent {
  @Input() data: Date;
}
