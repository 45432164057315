import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

@Pipe({
  name: 'ensureObservable',
})
export class EnsureObservablePipe implements PipeTransform {
  transform(options: any): Observable<any> {
    if (!(options instanceof Observable)) {
      options = observableOf(options);
    }

    return options;
  }
}
