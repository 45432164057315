import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreditCardNode } from '@generated/graphql';
import lodash from 'lodash';
import { ModalFormService, ModalFormState } from '@app/shared/services/modal-form.service';
import { BasefieldComponent } from '../basefield/basefield.component';
import { FormSubmitData } from '@app/shared/components/radar-form-abstract';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { CreditCardFormComponent } from '@app/shared/forms/credit-card-form/credit-card-form.component';

@UntilDestroy()
@Component({
  selector: 'formly-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCreditCardModalComponent extends BasefieldComponent implements OnInit {
  innerModel = new UntypedFormControl('');
  modalState = {
    modalHeader: 'Credit Card',
    formComponent: CreditCardFormComponent,
  };

  constructor(private modalFormService: ModalFormService, cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
    if (this.formControl.value) {
      this.innerModel.setValue((this.formControl.value as CreditCardNode).number);
    } else {
      const initialState: ModalFormState = {
        ...this.modalState,
      };
      this.modalFormService.showModal(initialState, this.processFormData.bind(this));
    }

    this.formControl.valueChanges.pipe(UntilDestroyed(this)).subscribe(() => {
      if (this.formControl.value) {
        this.innerModel.setValue((this.formControl.value as CreditCardNode).number);
        this.cdRef.markForCheck();
      }
    });
  }

  showEditModal(): void {
    if (this.isReadonly) return;

    const initialState: ModalFormState = {
      ...this.modalState,
      entityId: this.formControl.value.id,
      formData: lodash.cloneDeep(this.formControl.value),
    };
    this.modalFormService.showModal(initialState, this.processFormData.bind(this));
  }

  private processFormData({ data, id }: FormSubmitData) {
    const res = {
      ...this.formControl.value,
      ...data,
    };

    if (this.formControl.value?.id) {
      res.id = this.formControl.value.id;
    }

    this.formControl.setValue(res);

    if (!id) {
      this.modalFormService.hideModal();
    }
  }
}
