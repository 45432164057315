import { Injectable } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import { switchMap, filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackerEventsService {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private rdrAuthService: RdrAuthService
  ) {}

  sendLoginEvent() {
    this.cognitoAuthService
      .hasCognitoSession()
      .pipe(
        filter((val) => Boolean(val)),
        take(1),
        switchMap(() => this.rdrAuthService.getUser())
      )
      .subscribe(() => void fetch(loginEventUrl));
  }
}

const loginEventUrl = `${environment.TRACKING_URL}/images/ct.gif?event_name=radar-login`;
