<ng-container *ngIf="column.cellType === RadarTableColumnType.Selection; else nonSelection">
  <div class="content">
    <radar-table-checkbox
      (click)="$event.stopPropagation()"
      (check)="onSelect()"
      [checked]="selected"
    ></radar-table-checkbox>
  </div>
</ng-container>

<ng-template #nonSelection>
  <div
    [ngClass]="[column.cellType || '', column.isHighlighted ? 'is-highlighted' : '']"
    attr.data-id="{{ column.prop }}"
    class="content"
  >
    <ng-container *ngIf="column.cellTemplate; else staticValue">
      <ng-container
        *ngTemplateOutlet="column.cellTemplate; context: { value: row[column.prop || ''], row: row, prop: column.prop }"
      ></ng-container>
    </ng-container>

    <ng-template #staticValue>
      <div class="overflow">
        <ng-container
          *ngTemplateOutlet="switchTpl; context: { data: row | getTableCellData : column.prop }"
        ></ng-container>
        <ng-template #switchTpl let-data="data">
          <ng-container [ngSwitch]="column.cellType">
            <user-card-cell
              *ngSwitchCase="RadarTableColumnType.UserCard"
              [data]="data"
            ></user-card-cell>

            <currency-cell
              *ngSwitchCase="RadarTableColumnType.Currency"
              [data]="data"
              [format]="column.extraAttributes?.format"
            ></currency-cell>

            <date-cell *ngSwitchCase="RadarTableColumnType.Date" [data]="data"></date-cell>

            <datetime-cell
              *ngSwitchCase="RadarTableColumnType.DateTime"
              [data]="data"
            ></datetime-cell>

            <percentage-cell
              *ngSwitchCase="RadarTableColumnType.Percentage"
              [data]="data"
            ></percentage-cell>

            <number-cell *ngSwitchCase="RadarTableColumnType.Number" [data]="data"></number-cell>

            <trip-info *ngSwitchCase="RadarTableColumnType.TripInfo" [trip]="data"></trip-info>

            <flight-info-cell
              *ngSwitchCase="RadarTableColumnType.FlightInfo"
              [data]="data"
            ></flight-info-cell>

            <phone-cell *ngSwitchCase="RadarTableColumnType.Phone" [data]="data"></phone-cell>

            <email-cell *ngSwitchCase="RadarTableColumnType.Email" [data]="data"></email-cell>

            <client-name-cell
              *ngSwitchCase="RadarTableColumnType.ClientName"
              [data]="data"
            ></client-name-cell>

            <reason-cell *ngSwitchCase="RadarTableColumnType.Reason" [data]="data"></reason-cell>

            <airport-name-code-cell
              *ngSwitchCase="RadarTableColumnType.AirportNameAndCode"
              [data]="data"
            ></airport-name-code-cell>

            <flexibility
              *ngSwitchCase="RadarTableColumnType.Flexibility"
              [data]="data"
            ></flexibility>

            <ng-container *ngSwitchCase="RadarTableColumnType.Label">
              {{ data | label }}
            </ng-container>

            <ng-container *ngSwitchCase="RadarTableColumnType.ColoredStatus">
              <colored-status [status]="data">{{ data | label }}</colored-status>
            </ng-container>

            <ng-container *ngSwitchCase="RadarTableColumnType.Duration">
              {{ data | duration }}
            </ng-container>

            <ng-container *ngSwitchDefault>{{ data }}</ng-container>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </div>
</ng-template>
