import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'view-cell',
  templateUrl: './view-cell.component.html',
  styleUrls: ['./view-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewCellComponent {
  dataId: string;
  @Input() label: string;

  constructor(private elementRef: ElementRef) {
    this.dataId = elementRef.nativeElement.getAttribute('data-id');
  }
}
