import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BasefieldV2Component } from '@app/formly/types/v2/basefield-v2/basefield-v2.component';
import IMask from 'imask';

@Component({
  selector: 'numeric-v2',
  templateUrl: './numeric-v2.component.html',
  styleUrls: ['./numeric-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericV2Component extends BasefieldV2Component implements OnInit {
  imaskNumeric: IMask.MaskedNumberOptions;

  ngOnInit(): void {
    this.imaskNumeric = {
      mask: Number,
      signed: !this.to.positiveOnly,
      scale: this.to.integerOnly ? 0 : 3,
      thousandsSeparator: this.to.delimiter || ' ',
    };
  }

  // without this hack form do not work consistently
  onAccept() {}
}
