import { Directive, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { POLL_INTERVAL } from '@app/shared/constants';
import { Observable } from 'rxjs';
import { InvoiceNoteEdge, LeadNoteEdge, MilesAccountNoteEdge } from '@app/generated/graphql';
import lodash from 'lodash';

@Directive()
export abstract class NotesComponent implements OnInit {
  formControl = new UntypedFormControl('');

  @ViewChild('textArea') textArea: ElementRef;
  @ViewChild('notes') notes: ElementRef;

  options: { pollInterval: number };
  notes$: Observable<(LeadNoteEdge | MilesAccountNoteEdge | InvoiceNoteEdge)[]>;

  resize = lodash.debounce(() => {
    this.textArea.nativeElement.style.height = `0px`;
    const height = Number(this.textArea.nativeElement.scrollHeight);
    this.textArea.nativeElement.style.height = `${height}px`;

    if (this.notes?.nativeElement) {
      this.notes.nativeElement.style.height = `${580 - height}px`;
    }
  });

  @HostListener('cut')
  @HostListener('paste')
  @HostListener('keydown')
  change() {
    this.resize();
  }

  ngOnInit(): void {
    this.options = {
      pollInterval: localStorage.getItem('disableNotesPolling') ? 0 : POLL_INTERVAL,
    };

    this.loadNotes();
  }

  loadNotes(): void {}

  scrollToLast(): void {
    const arr = document.querySelectorAll('[id="note"]');
    arr[arr.length - 1]?.scrollIntoView();
  }

  trackByFn(_index: number, n: any): string {
    return n.id;
  }

  onEnter(e?: Event) {
    e?.preventDefault();

    if (this.formControl.value) {
      this.addComment(this.formControl.value as string);
    }
  }

  abstract addComment(text: string): void;
}
