import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { CallProvider, LeadMessageNode } from '@app/generated/graphql';
import { LeadCallTrackComponent } from '../lead-call-track/lead-call-track.component';
import { CallsService } from '@app/shared/services/calls/calls.service';

@Component({
  selector: 'lead-message-event',
  templateUrl: './lead-message-event.component.html',
  styleUrls: ['./lead-message-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadMessageEventComponent {
  expanded = false;

  playing: boolean;
  loading: boolean;

  audioLinks: string[] = [];
  currentLinkIndex = -1;

  @Input() interaction: LeadMessageNode & { body: string };

  @HostBinding('class.cursor-pointer') get classes() {
    return !this.expanded;
  }

  @HostListener('click') click() {
    if (!this.expanded) {
      this.expanded = true;
    }
  }

  @ViewChild('leadCallTrack') leadCallTrack: LeadCallTrackComponent;

  constructor(private callsService: CallsService, private cdRef: ChangeDetectorRef) {}

  getAudioLinks() {
    this.loading = true;

    const params = {
      callOrMessageId: this.interaction.messageId as string,
      callProvider: CallProvider.RingCentralMessage,
    };

    this.callsService.getRecordingLinks(params).subscribe((callLinks) => {
      if (callLinks) {
        this.audioLinks = callLinks;
        this.currentLinkIndex = 0;
        this.loading = false;
        this.cdRef.detectChanges();

        this.play();
      }
    });
  }

  onAudioEnded() {
    if (this.currentLinkIndex < this.audioLinks.length - 1) {
      this.currentLinkIndex++;
      this.cdRef.detectChanges();

      this.play();
    }
  }

  setPlaying(playing: boolean) {
    this.playing = playing;
  }

  play() {
    if (!this.audioLinks?.length) {
      return this.getAudioLinks();
    }

    this.leadCallTrack.play();
  }

  pause() {
    this.leadCallTrack.pause();
  }
}
