<ng-container *showForRole="[UserType.SalesAgent, UserType.SalesTeamLead]">
  <card>
    <div class="d-flex p-1 align-items-center justify-content-between">
      <button-v2 type="destructive" (click)="dismiss()" data-id="decline">Decline</button-v2>
      <typography-v2 look="title">New Lead</typography-v2>
      <button-v2 [disabled]="acceptingLead" (click)="acceptLead()" data-id="get-lead"
        >Get a lead</button-v2
      >
    </div>
  </card>
</ng-container>
