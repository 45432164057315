import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'airline-logo',
  templateUrl: './airline-logo.component.html',
  styleUrls: ['./airline-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AirlineLogoComponent {
  @Input() logoUrl: string | undefined;
  @Input() size = 24;
}
