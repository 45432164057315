import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { Mode } from '@ngneat/edit-in-place/lib/mode';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BasefieldComponent } from '../basefield/basefield.component';
import { isValidDate, toISO8601 } from '../date/date-helpers';
import { DEFAULT_PAGE_DATE_RANGE } from '@app/shared/utils/filter-form-utils';
import { DateTime } from 'luxon';

export type RdrDateRange = [Date, Date];

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent extends BasefieldComponent implements OnInit {
  @ViewChild('dp') dp: BsDatepickerDirective;
  @ViewChild('input') input: ElementRef;
  @ViewChild('editable') editable: EditableComponent;

  innerModel = new UntypedFormControl([]);
  isOpen = false;

  ngOnInit(): void {
    if (this.to.initial) {
      this.formControl.setValue(this.to.initial);
      this.innerModel.setValue([
        new Date(this.to.initial[0] as Date),
        new Date(this.to.initial[1] as Date),
      ]);
    }

    if (this.formControl.value) {
      this.setInnedModel(this.formControl.value as RdrDateRange);
    }
  }

  setInnedModel(dateRange: RdrDateRange) {
    if (isValidDate(dateRange[0]) && isValidDate(dateRange[1])) {
      this.innerModel.setValue([copyDate(dateRange[0]), copyDate(dateRange[1])]);
    }
  }

  onBlur() {
    if (!this.dp?.isOpen) {
      if (
        this.innerModel.value &&
        isValidDate(this.innerModel.value[0] as Date) &&
        isValidDate(this.innerModel.value[1] as Date)
      ) {
        this.formControl.setValue([
          copyDate(this.innerModel.value[0] as Date),
          copyDate(this.innerModel.value[1] as Date),
        ]);
      } else if (this.formControl.value) {
        this.setInnedModel(this.formControl.value as RdrDateRange);
      }

      this.editable.saveEdit();
    }
  }

  onDatePicked(dates: (Date | undefined)[] | undefined): void {
    if (this.dp?.isOpen) {
      if (dates && isValidDate(dates[0] as Date) && isValidDate(dates[1] as Date)) {
        const from = DateTime.fromObject({
          year: dates[0]?.getFullYear(),
          month: (dates[0]?.getMonth() as number) + 1,
          day: dates[0]?.getDate(),
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        const to = DateTime.fromObject({
          year: dates[1]?.getFullYear(),
          month: (dates[1]?.getMonth() as number) + 1,
          day: dates[1]?.getDate(),
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        });

        this.formControl.setValue([from.toISO(), to.toISO()]);

        this.editable.saveEdit();
      }
    }
  }

  onEditableModeChange(mode: Mode) {
    this.isOpen = mode === 'edit';

    if (this.isOpen) {
      setTimeout(() => this.input?.nativeElement?.focus());
    }
  }

  onClear() {
    const defaultRange = [...DEFAULT_PAGE_DATE_RANGE.dateRange] as RdrDateRange;
    this.formControl.setValue(defaultRange);
    this.setInnedModel(defaultRange);
  }
}

const copyDate = (date: Date) => new Date(toISO8601(date));
