<div class="d-flex tabs">
  <div
    *ngFor="let tab of tabs"
    [ngClass]="{'active': activeTab === tab}"
    (click)="selectTab(tab)"
    class="d-flex align-items-center justify-content-center cursor-pointer tab"
  >
    {{ tab }}
  </div>
</div>

<ng-container *ngIf="activeTab === rightPaneTabs.Notes">
  <lead-notes *ngIf="leadId" [leadId]="leadId"></lead-notes>
  <miles-account-notes
    *ngIf="milesAccountId"
    [milesAccountId]="milesAccountId"
  ></miles-account-notes>
  <invoice-notes *ngIf="invoiceId" [invoiceId]="invoiceId"></invoice-notes>
</ng-container>

<ng-container *ngIf="activeTab === rightPaneTabs.History">
  <history [historyRoot]="historyRoot"></history>
</ng-container>

<ng-container *ngIf="activeTab === rightPaneTabs.EmailMessages">
  <email-messages [leadId]="leadId"></email-messages>
</ng-container>
