<editable [attr.data-id]="field.dataId">
  <ng-template viewMode>
    <div class="view-mode" [ngClass]="{'input-error-border': showError}">
      <typography *ngIf="!formControl.value" look="inputPlaceholder"
        >{{ field.templateOptions?.placeholder }}</typography
      >

      <ng-container *ngIf="formControl.value">
        <typography> {{ formControl.value | ccNumberFormat: !isReadonly }} </typography>
        <typography class="type ms-1" look="small"> {{ formControl.value | ccType }} </typography>
      </ng-container>
      <rdr-svg-icon
        class="pencil-icon me-1 ms-auto"
        key="pencil-create"
        color="var(--in-place-icon)"
      ></rdr-svg-icon>
    </div>
  </ng-template>
  <ng-template editMode>
    <input
      type="tel"
      class="formly-input"
      radarOnEnter
      (radarOnEscape)="cancel()"
      radarEditableFocus
      editableOnEnter
      editableOnEscape
      (blur)="onBlur()"
      [formControl]="innerModel"
      [formlyAttributes]="field"
      ccNumber
    />
  </ng-template>
</editable>
