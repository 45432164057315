import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingNode, UserType } from '@app/generated/graphql';
import { BookingType } from '@app/shared/models/booking-type.enum';

@Component({
  selector: 'booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardComponent {
  UserType = UserType;
  BookingType = BookingType;

  isShowActions: boolean;

  @Input() booking: BookingNode;
  @Input() activeBooking: BookingNode | null;
  @Input() bookingsLength: number;
  @Input() totalCost?: number;
  @Input() totalMiles?: number;

  @Input() readonlyPage: boolean;
  @Input() withStatus: boolean;
  @Input() withActions: boolean;
  @Input() withSplitAction: boolean;

  @Output() setEditableBooking = new EventEmitter();
  @Output() acceptScheduleChange = new EventEmitter();
  @Output() registerScheduleChange = new EventEmitter();
  @Output() splitPassengers = new EventEmitter();
}
