import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortByProperty',
})
export class SortByPropertyPipe implements PipeTransform {
  transform<T>(arr: T[], prop: string, propType: 'date' | 'number' | 'string' = 'number'): T[] {
    return arr.sort((a, b) => compareFn(_.get(a, prop), _.get(b, prop)));

    function compareFn(a: unknown, b: unknown): number {
      if (propType === 'date') {
        return new Date(a as string).getTime() - new Date(b as string).getTime();
      } else if (propType === 'string') {
        return (a as string).localeCompare(b as string);
      }
      return (a as number) - (b as number);
    }
  }
}
