import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OrderType } from '@app/generated/graphql';
import { OrderNode } from '@app/generated/graphql';
import { ordersLimit } from '@app/pages/clients/pages/client-details-page-v2/client-details-page-v2.component';
import {
  RadarTableColumn,
  RadarTableColumnType,
  RadarTableRowClick,
} from '@app/shared/components/radar-table/radar-table.types';

@Component({
  selector: 'client-orders-section',
  templateUrl: './client-orders-section.component.html',
  styleUrls: ['./client-orders-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOrdersSectionComponent implements AfterViewInit {
  ordersLimit = ordersLimit;

  OrderType = OrderType;

  columns: RadarTableColumn[];

  @Input() orders: OrderNode[];
  @Input() totalPaginatedSortedOrders: number;
  @Input() paginatedSortedOrdersOffset: number;

  @Output() clickOnOrder = new EventEmitter();
  @Output() paginateOrders = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  @ViewChild('orderAdditional') orderAdditional: TemplateRef<any>;
  @ViewChild('orderChangeTypeTemplate') orderChangeTypeTemplate: TemplateRef<any>;
  @ViewChild('flightDateTemplate') flightDateTemplate: TemplateRef<any>;
  @ViewChild('flightItineraryTemplate') flightItineraryTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ViewChild('soldAmountTemplate') soldAmountTemplate: TemplateRef<any>;
  @ViewChild('grossProfitTemplate') grossProfitTemplate: TemplateRef<any>;
  @ViewChild('totalCostTemplate') totalCostTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.columns = [
      {
        prop: 'status',
        name: 'Stage',
        cellTemplate: this.statusTemplate,
        width: 160,
      },
      {
        cellTemplate: this.orderAdditional,
        width: 64,
      },
      {
        prop: 'id',
        name: '#',
      },
      {
        prop: 'type',
        name: 'Change',
        cellTemplate: this.orderChangeTypeTemplate,
      },
      {
        prop: 'firstFlightItinerary',
        name: 'Date',
        cellTemplate: this.flightDateTemplate,
      },
      {
        prop: 'firstFlightItinerary',
        name: 'Flight',
        cellTemplate: this.flightItineraryTemplate,
      },
      {
        prop: 'finances',
        name: 'Sold Amount',
        cellTemplate: this.soldAmountTemplate,
      },
      {
        prop: 'finances',
        name: 'Total Cost',
        cellTemplate: this.totalCostTemplate,
      },
      {
        prop: 'finances',
        name: 'Gross Profit',
        cellTemplate: this.grossProfitTemplate,
      },
      {
        prop: 'createdAt',
        name: 'Created',
        cellType: RadarTableColumnType.Date,
      },
    ];

    window.setTimeout(() => this.cdr.markForCheck());
  }

  orderClick(event: RadarTableRowClick<OrderNode>) {
    this.clickOnOrder.emit({ event: event.event, id: event.row.id });
  }
}
