<typography-v2 *ngIf="to.extra?.title" class="v2-mr-1 texts">{{ to.extra?.title }}</typography-v2>
<typography-v2 *ngIf="to.extra?.info" look="small" class="text-black-50 me-auto texts"
  >{{ to.extra?.info }}</typography-v2
>

<rdr-svg-icon
  (click)="decrement()"
  [attr.data-id]="key + '_decrement'"
  [ngClass]="{ 'disabled cursor-not-allowed': !formControl.value || formControl.value === to.min || to.readonly }"
  key="minus-button"
  class="action-button cursor-pointer"
></rdr-svg-icon>

<div [attr.data-id]="key" class="v2-mx-1 d-flex justify-content-center count">
  {{ formControl.value || 0 }}
</div>

<rdr-svg-icon
  (click)="increment()"
  [attr.data-id]="key + '_increment'"
  [ngClass]="{ 'disabled cursor-not-allowed': to.readonly }"
  key="plus-button"
  class="action-button cursor-pointer"
></rdr-svg-icon>
