<input
  (change)="change()"
  [checked]="checked"
  [disabled]="disabled"
  [id]="idForLabel"
  type="checkbox"
  class="input-checkbox"
/>
<label *ngIf="label" [for]="idForLabel" [ngClass]="{ 'cursor-pointer': !disabled }" class="v2-ml-2"
  ><typography-v2 look="caption-bold">{{ label }}</typography-v2></label
>
