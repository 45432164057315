<button
  [disabled]="disabled"
  class="button default w-100"
  [class.pressed]="pressed"
  type="button"
  (click)="btnClick.emit($event)"
>
  <user-picture-v2 [picture]="picture" [size]="size"></user-picture-v2>
  <typography-v2 class="v2-ml-2 nowrap text-truncate" *ngIf="userName"
    >{{ userName }}</typography-v2
  >
</button>
