import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { LeadNode } from '@app/generated/graphql';

@Component({
  selector: 'client-name-cell',
  templateUrl: './client-name-cell.component.html',
  styleUrls: ['./client-name-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientNameCellComponent {
  @Input() data: LeadNode;
}
