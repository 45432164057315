<div class="d-flex flex-column">
  <div class="d-flex justify-content-between">
    <typography-v2 *ngIf="expanded" class="text-jazlyn-700" look="small">
      from: {{ interaction.sender }} <br />
      to: {{ interaction.recipient }}
    </typography-v2>

    <typography-v2 *ngIf="!expanded" class="text-jazlyn-700" look="small" dataId="full-name"
      >{{ interaction.clientFullName || interaction.agent?.fullName }}</typography-v2
    >

    <typography-v2 class="text-jazlyn-700" look="small" dataId="datetime-at"
      >{{ interaction.datetimeAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <typography-v2
    look="text-semibold"
    [ngClass]="{ 'text-truncate w-580 v2-my-1': !expanded, 'v2-my-2': expanded }"
    dataId="subject"
    >{{ interaction.subject }}</typography-v2
  >

  <div *ngIf="!expanded" class="d-flex">
    <typography-v2 class="text-truncate w-580" dataId="content"
      >{{ interaction.content }}</typography-v2
    >

    <a class="view text-purple v2-ml-1">View</a>
  </div>

  <ng-container *ngIf="expanded">
    <typography-v2 class="content text-truncate w-580" dataId="content"
      >{{ showFullLetter ? interaction.originalContent : interaction.content }}</typography-v2
    >

    <div *ngIf="!showFullLetter" class="v2-mt-2">
      <a (click)="$event.stopPropagation(); showFullLetter = true" class="text-purple"
        >Show full letter</a
      >
    </div>

    <div class="v2-mt-2">
      <a
        (click)="$event.stopPropagation(); expanded = false; showFullLetter = false"
        class="text-purple"
        >Hide</a
      >
    </div>
  </ng-container>
</div>
