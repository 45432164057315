import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'time-range-filter',
  templateUrl: './time-range-filter.component.html',
  styleUrls: ['./time-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRangeFilterComponent extends FieldType<FieldTypeConfig> {
  change([from, to]: [number, number]) {
    const range = [
      DateTime.fromMillis(from).toFormat('HH:mm:ss'),
      DateTime.fromMillis(to).toFormat('HH:mm:ss'),
    ];
    this.formControl.setValue(range);
  }

  timeToValue(time: string) {
    return DateTime.fromFormat(time, 'HH:mm:ss').toMillis();
  }
}
