<form [formGroup]="formGroup" (ngSubmit)="submitFormV2()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <button-v2
      formButtonState
      [disabled]="readonly"
      *ngIf="data?.id"
      [confirmOnDelete]="'cost'"
      (deleteConfirmed)="deleteEntity.emit(data.id)"
      type="destructive"
      class="v2-mr-3"
      data-id="booking-cost-delete"
    >
      <typography-v2 look="caption-bold">Delete</typography-v2>
    </button-v2>
    <button-v2
      [disabled]="!formGroup.valid || formGroup.pristine || readonly"
      formButtonState
      buttonType="submit"
      data-id="booking-cost-save"
    >
      <typography-v2 look="caption-bold">{{ data?.id ? 'Save' : 'Add' }}</typography-v2>
    </button-v2>
  </div>
</form>
