import { Injectable } from '@angular/core';
import {
  ClientNode,
  DeleteClientCardGQL,
  DeletePassengerV2GQL,
  DeletePassengerV2MutationVariables,
  GetClientGQL,
  SaveClientV2MutationVariables,
  DeleteClientCardMutationVariables,
  SavePassengerV2GQL,
  SavePassengerV2MutationVariables,
  CreateFlightRequestGQL,
  CreateFlightRequestMutationVariables,
  SaveClientV2GQL,
  GetPresignedUrlQueryVariables,
  GetPresignedUrlGQL,
  CreateCreditCardGQL,
  UpdateCreditCardGQL,
  CreateCreditCardMutationVariables,
  UpdateCreditCardMutationVariables,
  PaginatedSortedFlightRequestsGQL,
  PaginatedSortedFlightRequestsQueryVariables,
  PaginatedSortedOrdersGQL,
  PaginatedSortedOrdersQueryVariables,
  PaginatedSortedTripsGQL,
  PaginatedSortedTripsQueryVariables,
} from '@app/generated/graphql';
import { GraphqlID } from '@app/shared/types';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientDetailsPageV2Service {
  constructor(
    private getClientGQL: GetClientGQL,
    private saveClientV2GQL: SaveClientV2GQL,
    private savePassengerV2GQL: SavePassengerV2GQL,
    private deletePassengerV2GQL: DeletePassengerV2GQL,
    private createFlightRequestGQL: CreateFlightRequestGQL,
    private getPresignedUrlGQL: GetPresignedUrlGQL,
    private createCreditCardGQL: CreateCreditCardGQL,
    private updateCreditCardGQL: UpdateCreditCardGQL,
    private deleteClientCardGQL: DeleteClientCardGQL,
    private paginatedSortedFlightRequestsGQL: PaginatedSortedFlightRequestsGQL,
    private paginatedSortedOrdersGQL: PaginatedSortedOrdersGQL,
    private paginatedSortedTripsGQL: PaginatedSortedTripsGQL
  ) {}

  getClient(id: GraphqlID) {
    return this.getClientGQL.fetch({ id }).pipe(map(({ data }) => data.client as ClientNode));
  }

  saveClient(params: SaveClientV2MutationVariables) {
    return this.saveClientV2GQL.mutate(params);
  }

  saveClientPassenger(params: SavePassengerV2MutationVariables) {
    return this.savePassengerV2GQL.mutate(params);
  }

  deleteClientPassenger(params: DeletePassengerV2MutationVariables) {
    return this.deletePassengerV2GQL.mutate(params);
  }

  createFlighRequest(params: CreateFlightRequestMutationVariables) {
    return this.createFlightRequestGQL.mutate(params);
  }

  getPresignedUrl(params: GetPresignedUrlQueryVariables) {
    return this.getPresignedUrlGQL.fetch(params);
  }

  createCreditCard(params: CreateCreditCardMutationVariables) {
    return this.createCreditCardGQL
      .mutate(params)
      .pipe(map(({ data }) => data?.createCreditCard?.result?.client as ClientNode));
  }

  updateCreditCard(params: UpdateCreditCardMutationVariables) {
    return this.updateCreditCardGQL
      .mutate(params)
      .pipe(map(({ data }) => data?.updateCreditCard?.result?.client as ClientNode));
  }

  deleteClientCard(params: DeleteClientCardMutationVariables) {
    return this.deleteClientCardGQL.mutate(params);
  }

  paginatedSortedFlightRequests(params: PaginatedSortedFlightRequestsQueryVariables) {
    return this.paginatedSortedFlightRequestsGQL
      .fetch(params)
      .pipe(map(({ data }) => data.client?.paginatedSortedFlightRequests));
  }

  paginatedSortedOrders(params: PaginatedSortedOrdersQueryVariables) {
    return this.paginatedSortedOrdersGQL
      .fetch(params)
      .pipe(map(({ data }) => data.client?.paginatedSortedOrders));
  }

  paginatedSortedTrips(params: PaginatedSortedTripsQueryVariables) {
    return this.paginatedSortedTripsGQL.fetch(params).pipe(map(({ data }) => data.client?.trips));
  }
}
