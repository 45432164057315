<div class="currency-input-container">
  <input
    [formControl]="formControl"
    [formlyAttributes]="field"
    [attr.placeholder]="to.placeholder || ''"
    [attr.data-id]="field.key"
    [class.disabled]="to.disabled"
    [imask]="imask"
    [unmask]="'typed'"
    (accept)="onAccept()"
    autocomplete="off"
    class="currency-input"
  />
</div>
