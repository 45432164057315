import { Pipe, PipeTransform } from '@angular/core';
import lodash from 'lodash';

@Pipe({
  name: 'getTableCellData',
})
export class GetTableCellDataPipe implements PipeTransform {
  transform<R>(value: R, prop?: string): keyof R | R {
    if (prop) {
      const path = prop?.split('.');
      return lodash.get(value, path);
    }

    return value;
  }
}
