import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeadEdge } from '@generated/graphql';

@Component({
  selector: 'other-leads-block',
  templateUrl: './other-leads-block.component.html',
  styleUrls: ['./other-leads-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherLeadsBlockComponent {
  @Input() leads?: LeadEdge[];
}
