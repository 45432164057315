import { Pipe, PipeTransform } from '@angular/core';
import { SelectableUser } from './user-assign-button.component';

@Pipe({
  name: 'groupedUsersFilter',
})
export class GroupedUsersFilterPipe implements PipeTransform {
  transform(
    groupedUsers: {
      name: string;
      value: SelectableUser[];
    }[],
    searchTerm: string
  ): {
    name: string;
    value: SelectableUser[];
  }[] {
    return groupedUsers
      .map((group) => ({
        name: group.name,
        value: group.value.filter((user) => user.label?.toLowerCase().includes(searchTerm)),
      }))
      .filter((group) => group.value.length);
  }
}
