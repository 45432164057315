import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@ui/pipes/pipes.module';
import { UiModule } from '../../../ui/ui.module';
import { RadarTableComponent } from './radar-table/radar-table.component';
import { RadarTableStateComponent } from './radar-table-state/radar-table-state.component';
import { RadarTableSearchComponent } from './radar-table-search/radar-table-search.component';
import { RadarTablePaginationComponent } from './radar-table-pagination/radar-table-pagination.component';
import { RadarTableCellComponent } from './radar-table-cell/radar-table-cell.component';
import { RadarTableHeadCellComponent } from './radar-table-head-cell/radar-table-head-cell.component';
import { RadarTableCheckboxComponent } from './radar-table-checkbox/radar-table-checkbox.component';
import { UserCardCellComponent } from './cell-types/user-card-cell/user-card-cell.component';
import { CurrencyCellComponent } from './cell-types/currency-cell/currency-cell.component';
import { DateCellComponent } from './cell-types/date-cell/date-cell.component';
import { DatetimeCellComponent } from './cell-types/datetime-cell/datetime-cell.component';
import { PercentageCellComponent } from './cell-types/percentage-cell/percentage-cell.component';
import { NumberCellComponent } from './cell-types/number-cell/number-cell.component';
import { FlightInfoCellComponent } from './cell-types/flight-info-cell/flight-info-cell.component';
import { GetRowLinkPipe } from './pipes/get-row-link.pipe';
import { SizedColumnsPipe } from './pipes/sized-columns.pipe';
import { PhoneCellComponent } from './cell-types/phone-cell/phone-cell.component';
import { EmailCellComponent } from './cell-types/email-cell/email-cell.component';
import { ClientNameCellComponent } from './cell-types/client-name-cell/client-name-cell.component';
import { GetTableCellDataPipe } from '@app/shared/components/radar-table/pipes/get-by-property.pipe';
import { ReasonCellComponent } from './cell-types/reason-cell/reason-cell.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AirportNameCodeComponent } from './cell-types/airport-name-code/airport-name-code.component';
import { FlexibilityComponent } from './cell-types/flexibility/flexibility.component';
import { IconsModule } from '@app/icons/icons.module';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';

@NgModule({
  declarations: [
    RadarTableComponent,
    RadarTableStateComponent,
    RadarTableSearchComponent,
    RadarTablePaginationComponent,
    RadarTableCellComponent,
    RadarTableHeadCellComponent,
    RadarTableCheckboxComponent,
    UserCardCellComponent,
    CurrencyCellComponent,
    DateCellComponent,
    DatetimeCellComponent,
    PercentageCellComponent,
    NumberCellComponent,
    FlightInfoCellComponent,
    GetRowLinkPipe,
    SizedColumnsPipe,
    PhoneCellComponent,
    EmailCellComponent,
    ClientNameCellComponent,
    ReasonCellComponent,
    GetTableCellDataPipe,
    AirportNameCodeComponent,
    FlexibilityComponent,
  ],
  exports: [
    RadarTableComponent,
    RadarTableStateComponent,
    RadarTableHeadCellComponent,
    RadarTablePaginationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    UiModule,
    IconsModule,
    TooltipModule.forRoot(),
    UiV2Module,
  ],
})
export class RadarTableModule {}
