<ng-container *ngIf="item.groupBy; else default"> {{item.groupBy}} </ng-container>

<ng-template #default>
  <ng-content></ng-content>

  <div *ngIf="!item?.colored; else colored" title="{{ item.label }}">{{ item?.label }}</div>

  <ng-template #colored>
    <colored-status class="d-flex h-100" [status]="item?.value || ''">
      {{ item?.label }}
    </colored-status>
  </ng-template>
</ng-template>
