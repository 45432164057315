import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'currency-range-filter',
  templateUrl: './currency-range-filter.component.html',
  styleUrls: ['./currency-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyRangeFilterComponent extends FieldType<FieldTypeConfig> {
  change(range: [number, number]) {
    this.formControl.setValue(range);
  }
}
