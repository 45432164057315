import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  HostBinding,
} from '@angular/core';
import { RadarTableColumn, RadarTableColumnType, RadarTableRow } from '../radar-table.types';

@Component({
  selector: 'radar-table-cell',
  templateUrl: './radar-table-cell.component.html',
  styleUrls: ['./radar-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadarTableCellComponent {
  @Input() row: RadarTableRow;
  @Input() column: RadarTableColumn;
  @Input() selected: boolean;

  @Output() selectRow: EventEmitter<RadarTableRow> = new EventEmitter();

  @HostBinding('style.width') get width() {
    return this.column.width && `${this.column.width}px`;
  }
  @HostBinding('style.max-width') get maxWidth() {
    return this.column.maxWidth && `${this.column.maxWidth}px`;
  }

  onSelect(): void {
    this.selectRow.emit(this.row);
  }

  RadarTableColumnType = RadarTableColumnType;
}
