import { CurrencyRangeFilterComponent } from './filters-fields/currency-range-filter/currency-range-filter.component';
import { GroupFilterComponent } from './filters-fields/group-filter/group-filter.component';
import { MinutesRangeFilterComponent } from './filters-fields/minutes-range-filter/minutes-range-filter.component';
import { MultiselectFilterComponent } from './filters-fields/multiselect-filter/multiselect-filter.component';
import { SelectFilterComponent } from './filters-fields/select-filter/select-filter.component';
import { TimeRangeFilterComponent } from './filters-fields/time-range-filter/time-range-filter.component';

export const filtersTypes = [
  { name: 'CURRENCY_RANGE', component: CurrencyRangeFilterComponent },
  { name: 'MINUTES_RANGE', component: MinutesRangeFilterComponent },
  { name: 'TIME_RANGE', component: TimeRangeFilterComponent },
  { name: 'MULTISELECT', component: MultiselectFilterComponent },
  { name: 'SELECT', component: SelectFilterComponent },
];

export const filtersWrappers = [{ name: 'GROUP', component: GroupFilterComponent }];
