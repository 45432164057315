import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GetPresignedUrlGQL } from '@app/generated/graphql';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { openNewTab } from '@app/pages/orders/pages/order-details-page-v2/utils/pdf';

@Component({
  selector: 'file-v2',
  templateUrl: './file-v2.component.html',
  styleUrls: ['./file-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileV2Component extends BasefieldV2Component {
  constructor(private getPresignedUrlGQL: GetPresignedUrlGQL) {
    super();
  }

  changeFile({ target }: Event): void {
    const files = (target as HTMLInputElement).files;

    this.formControl.setValue(files);
    this.formControl.markAsDirty();
  }

  deleteFile() {
    this.formControl.setValue('');
    this.formControl.markAsDirty();
  }

  downloadFile(): void {
    const params = { [this.to.filetype]: this.formControl.value };
    this.getPresignedUrlGQL
      .fetch(params)
      .subscribe(({ data }) => openNewTab(data?.getPresignedUrl));
  }
}
