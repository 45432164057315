import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private router: Router) {}

  navigateTo(
    params: string[],
    event?: KeyboardEvent | MouseEvent,
    extras?: NavigationExtras
  ): void {
    if (event?.metaKey || event?.ctrlKey) {
      window.open(params.join('/'), '_blank');
    } else {
      void this.router.navigate(params, extras);
    }
  }
}
