import { QueryPaginationParams, RadarTablePagination } from './radar-table.types';

export const RADAR_TABLE_TOTAL_COUNT_LIMIT = 20;
export const RADAR_TABLE_INITIAL_PAGINATION_PAGE = 0;

export const ROW_LINK_PARAMS_REGEX = /{([^{}]*)}/g;

export const getPaginationParams = ({
  offset,
  prevoffset = RADAR_TABLE_INITIAL_PAGINATION_PAGE,
  limit,
  pageInfo,
  totalCount,
}: RadarTablePagination): QueryPaginationParams => {
  const params: QueryPaginationParams = {};

  if (offset === RADAR_TABLE_INITIAL_PAGINATION_PAGE) {
    params['first'] = limit;
  } else if (offset === Math.ceil(totalCount / limit) - 1) {
    params['last'] = totalCount % limit || limit;
  } else if (offset > prevoffset) {
    params['first'] = limit;
    params['after'] = pageInfo?.endCursor;
  } else if (offset < prevoffset) {
    params['last'] = limit;
    params['before'] = pageInfo?.startCursor;
  }

  return params;
};
