import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Mode } from '@ngneat/edit-in-place/lib/mode';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { BasefieldComponent } from '../basefield/basefield.component';
import { fromISO8601, isValidDate, toISO8601 } from './date-helpers';

@Component({
  selector: 'formly-date',
  templateUrl: './formly-date.component.html',
  styleUrls: ['./formly-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyDateComponent extends BasefieldComponent implements OnInit {
  @ViewChild('dp') datePicker: BsDatepickerDirective;
  @ViewChild('input') textInput: ElementRef;
  @ViewChild('editable') editable: EditableComponent;

  innerModel = new UntypedFormControl('');
  isOpen = false;

  ngOnInit(): void {
    if (this.formControl.value) {
      this.innerModel.setValue(fromISO8601(this.formControl.value as string));
    }
  }

  onBlur() {
    if (!this.datePicker?.isOpen) {
      if (isValidDate(this.innerModel.value as Date)) {
        this.formControl.setValue(toISO8601(this.innerModel.value as Date));
      } else if (this.formControl.value) {
        this.innerModel.setValue(fromISO8601(this.formControl.value as string));
      }

      this.editable.saveEdit();
    }
  }

  onDatePicked(newValue: Date): void {
    newValue = new Date(newValue);

    if (this.datePicker?.isOpen && !!newValue.getTime()) {
      this.formControl.setValue(toISO8601(newValue));
      this.innerModel.setValue(newValue);
      this.editable.saveEdit();
    }
  }

  onEditableModeChange(mode: Mode) {
    this.isOpen = mode === 'edit';

    if (this.isOpen) {
      setTimeout(() => this.textInput?.nativeElement?.focus());
    }
  }
}
