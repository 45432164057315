import { Pipe, PipeTransform } from '@angular/core';
import { BookingNode, FlightItinerarySegmentNode } from '@app/generated/graphql';

@Pipe({
  name: 'isAfterExtraLegSegment',
})
export class IsAfterExtraLegSegmentPipe implements PipeTransform {
  transform(booking: BookingNode, segment: FlightItinerarySegmentNode) {
    if (!segment.flightSegment.isExtraLeg) {
      return false;
    }

    for (const { segments } of booking?.flightItineraries || []) {
      for (const { flightSegment } of segments || []) {
        if (flightSegment?.isExtraLeg) {
          return flightSegment?.id !== segment.flightSegment.id;
        }
      }
    }

    return false;
  }
}
