import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as lpn from 'google-libphonenumber';

const phoneValidationError = { phone: true };

export const phoneValidator = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  if (!value) {
    return null;
  }
  const phoneUtil = lpn.PhoneNumberUtil.getInstance();
  const clearedNumber = value.replace(/\D/g, '');

  if (!clearedNumber.length || clearedNumber.length < 10) {
    return phoneValidationError;
  }

  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(value as string)) ? null : phoneValidationError;
  } catch {
    return phoneValidationError;
  }
};
