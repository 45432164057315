import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingNode, UserType } from '@app/generated/graphql';

@Component({
  selector: 'booking-miles-head',
  templateUrl: './booking-miles-head.component.html',
  styleUrls: ['./booking-miles-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingMilesHeadComponent {
  @Input() booking: BookingNode;
  protected readonly UserType = UserType;
}
