import { Directive, Input } from '@angular/core';
import { RdrFeature } from '@ui/services/feature-toggle.service';
import { FeatureToggleAbstractDirective } from '@ui/directives/feature-toggle/feature-toggle-abstract.directive';

@Directive({
  selector: '[featureToggleOff]',
})
export class FeatureToggleOffDirective extends FeatureToggleAbstractDirective {
  isInverted = true;
  @Input('featureToggleOff') feature: RdrFeature;
}
