import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { UserPictureSize } from '../user-picture-v2/user-picture-v2.component';

@Component({
  selector: 'button-user-picture-v2',
  templateUrl: './button-user-picture-v2.component.html',
  styleUrls: ['./button-user-picture-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonUserPictureV2Component {
  @Input() disabled: boolean;
  @HostBinding('class.readonly') @Input() readonly: boolean;
  @Input() picture?: string;
  @Input() pressed = false;
  @Input() size: UserPictureSize = 'small';
  @Input() userName?: string;

  @Output() btnClick = new EventEmitter();
}
