import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'field-with-link-wrapper',
  templateUrl: './field-with-link-wrapper.component.html',
  styleUrls: ['./field-with-link-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldWithLinkWrapperComponent extends FieldWrapper {
  onOpen(event: MouseEvent): void {
    this.to.onOpen && this.to.onOpen(event, this.formControl.value);
  }
}
