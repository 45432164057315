import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'dropdown-item-v2',
  templateUrl: './dropdown-item-v2.component.html',
  styleUrls: ['./dropdown-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemV2Component {
  @Input() hovered?: boolean;

  @HostBinding('class.hover')
  get isHovered() {
    return this.hovered;
  }
}
