import { Directive, ElementRef, OnInit } from '@angular/core';
import { FormLoadingStateService } from '@shared/services/form-loading-state.service';
import { tap } from 'rxjs/operators';
import { UntilDestroy, UntilDestroyed } from '../utils/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[formButtonState]',
})
export class FormButtonStateDirective implements OnInit {
  constructor(private loadingState: FormLoadingStateService, private hostEl: ElementRef) {}

  ngOnInit() {
    this.loadingState.isLoading$
      .pipe(
        tap((isLoading) => {
          if (isLoading) {
            this.hostEl.nativeElement
              .getElementsByTagName('button')[0]
              .setAttribute('disabled', isLoading);
          } else {
            this.hostEl.nativeElement.getElementsByTagName('button')[0].removeAttribute('disabled');
          }
        })
      )
      .pipe(UntilDestroyed(this))
      .subscribe();
  }
}
