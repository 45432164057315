import { Pipe, PipeTransform } from '@angular/core';
import { FlexibilityType } from '@generated/graphql';

@Pipe({
  name: 'flexibility',
})
export class FlexibilityPipe implements PipeTransform {
  transform(status?: FlexibilityType): string {
    return status ? flexibilityLabels[status] : '';
  }
}

export const flexibilityLabels = {
  STRICT: 'Strict',
  PLUS_MINUS_1: '+/-1',
  PLUS_MINUS_2: '+/-2',
  PLUS_MINUS_3: '+/-3',
  PLUS_1: '+1',
  PLUS_2: '+2',
  PLUS_3: '+3',
  MINUS_1: '-1',
  MINUS_2: '-2',
  MINUS_3: '-3',
};
