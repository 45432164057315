import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MilesAccountNode } from '@app/generated/graphql';
import { Column } from '@app/pages/bookings/components/confirmation-numbers-block/confirmation-numbers-block.component';

@Component({
  selector: 'dropdown-miles-acc-items',
  templateUrl: './dropdown-miles-acc-items.component.html',
  styleUrls: ['./dropdown-miles-acc-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMilesAccItemsComponent implements AfterViewInit {
  columns: Column[];

  @ViewChild('dateTemplate') dateTemplate: TemplateRef<any>;
  @ViewChild('currencyTemplate') currencyTemplate: TemplateRef<any>;

  @Input() rows: MilesAccountNode[];
  @Output() selectRow = new EventEmitter();

  ngAfterViewInit(): void {
    this.columns = this.getColumns();
  }

  getColumns(): Column[] {
    return [
      {
        prop: 'login',
        name: 'Login',
        cellClass: 'id-account',
      },
      {
        prop: 'balance',
        name: 'Balance',
        cellClass: 'currency id-balance',
        headerClass: 'currency',
        width: 100,
        canAutoResize: false,
      },
      {
        prop: 'pricePerMile',
        name: 'Price Per Mile',
        cellTemplate: this.currencyTemplate,
        cellClass: 'currency id-price-per-mile',
        headerClass: 'currency',
        width: 100,
        canAutoResize: false,
      },
      {
        prop: 'pendingBookings',
        name: 'Pending Bookings',
        cellClass: 'id-pending-bookings',
      },
      {
        prop: 'firstAvailableTransfer',
        name: 'First Available Transfer',
        cellTemplate: this.dateTemplate,
        width: 180,
        canAutoResize: false,
        cellClass: 'id-first-available-transfer',
      },
    ];
  }

  onclick({ type, row }: { type: string; row: MilesAccountNode }): void {
    type === 'click' && this.selectRow.emit(row);
  }
}
