import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'input-icon-container',
  templateUrl: './input-icon-container.component.html',
  styleUrls: ['./input-icon-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputIconContainerComponent {
  @HostBinding('class.link') @Input() link: boolean;
}
