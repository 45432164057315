import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CHIPS_V2, COUNT_V2, CURRENCY_V2, DATE_V3, SELECT_V2 } from '@app/formly/helpers/fields';
import { of } from 'rxjs';
import { mapEnumToDropdownItems } from '@app/shared/utils/dropdown-utils';
import { FlexibilityType, ServiceClassType } from '@app/generated/graphql';
import { mapFlexibilityToDropdown } from '@app/shared/forms/flight-request-flight-form-v2/flight-request-flight-form-v2.component';
import { filterDDWithCode } from '@app/formly/types/v2/typeahead-v2/typeahead-filters';
import { RadarFormAbstract } from '@app/shared/components/radar-form-abstract';
import { AirportsService } from '@app/shared/services/airports/airports.service';
import { getInitialServiceclass } from '@app/pages/flight-requests/components/create-task/helpers/entities';
import { AgeGroupLabels } from '@app/shared/constants';

@Component({
  selector: 'round-form',
  templateUrl: './round-form.component.html',
  styleUrls: ['./round-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundFormComponent extends RadarFormAbstract implements OnInit {
  constructor(private airportsService: AirportsService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.fields = [
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          CHIPS_V2(
            {
              key: 'departureAirports',
              className: 'col-6',
              templateOptions: {
                label: 'From',
                placeholder: 'From',
                filterCallback: filterDDWithCode,
                initial: this.data.departureAirports,
              },
            },
            this.airportsService.getAirportsAsDropDownItems()
          ),
          CHIPS_V2(
            {
              key: 'arrivalAirports',
              className: 'col-6',
              templateOptions: {
                label: 'To',
                placeholder: 'To',
                filterCallback: filterDDWithCode,
                initial: this.data.arrivalAirports,
              },
            },
            this.airportsService.getAirportsAsDropDownItems()
          ),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          DATE_V3({
            key: 'departureDate',
            className: 'col-3',
            templateOptions: {
              label: 'Departure Date',
            },
          }),
          SELECT_V2(
            {
              key: 'departureFlexibility',
              className: 'col-3',
              templateOptions: {
                label: 'Flexibility',
                initial: this.data?.departureFlexibility
                  ? mapFlexibilityToDropdown(this.data.departureFlexibility as FlexibilityType)
                  : null,
              },
            },
            of(Object.values(FlexibilityType).map(mapFlexibilityToDropdown))
          ),
          DATE_V3({
            key: 'returnDate',
            className: 'col-3',
            templateOptions: {
              label: 'Return Date',
            },
          }),
          SELECT_V2(
            {
              key: 'returnFlexibility',
              className: 'col-3',
              templateOptions: {
                label: 'Flexibility',
                initial: this.data?.returnFlexibility
                  ? mapFlexibilityToDropdown(this.data.returnFlexibility as FlexibilityType)
                  : null,
              },
            },
            of(Object.values(FlexibilityType).map(mapFlexibilityToDropdown))
          ),
        ],
      },
      {
        fieldGroupClassName: 'd-flex w-100',
        fieldGroup: [
          {
            fieldGroupClassName: 'd-flex flex-column w-100',
            className: 'col-6',
            fieldGroup: [
              COUNT_V2({
                key: 'numberOfAdults',
                templateOptions: {
                  label: 'Passengers',
                  extra: {
                    title: 'Adults',
                    info: AgeGroupLabels.Adult,
                  },
                  biggerLabelMargin: true,
                },
                defaultValue: 0,
              }),
              COUNT_V2({
                key: 'numberOfChildren',
                templateOptions: {
                  extra: {
                    title: 'Children',
                    info: AgeGroupLabels.Child,
                  },
                },
                defaultValue: 0,
              }),
              COUNT_V2({
                key: 'numberOfInfants',
                templateOptions: {
                  extra: {
                    title: 'Infants',
                    info: AgeGroupLabels.Infant,
                  },
                },
                defaultValue: 0,
              }),
            ],
          },
          {
            fieldGroupClassName: 'd-flex flex-column w-100',
            className: 'col-6',
            fieldGroup: [
              SELECT_V2(
                {
                  key: 'serviceClass',
                  templateOptions: {
                    label: 'Cabin',
                    initial: this.data.serviceClass
                      ? getInitialServiceclass(this.data.serviceClass as ServiceClassType)
                      : null,
                  },
                },
                of(mapEnumToDropdownItems(ServiceClassType, { withoutColors: true }))
              ),
              {
                fieldGroupClassName: 'd-flex align-items-end',
                fieldGroup: [
                  CURRENCY_V2({
                    className: 'flex-1',
                    key: 'budgetFrom',
                    templateOptions: {
                      label: "Client's Budget",
                    },
                  }),
                  {
                    className: 'v2-mx-2 v2-mb-2',
                    template: '–',
                  },
                  CURRENCY_V2({
                    className: 'flex-1',
                    key: 'budgetTo',
                  }),
                ],
              },
            ],
          },
        ],
      },
    ];
  }
}
