import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[radarOnEnter]',
})
export class RadarOnEnterDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('keyup.enter') onEnter(): void {
    this.el.nativeElement.blur();
  }
}
