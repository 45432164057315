import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import IMask from 'imask';

@Component({
  selector: 'currency-v2',
  templateUrl: './currency-v2.component.html',
  styleUrls: ['./currency-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyV2Component extends BasefieldV2Component implements OnInit {
  imask: IMask.MaskedNumberOptions;

  ngOnInit(): void {
    this.imask = {
      mask: Number,
      signed: true,
      radix: '.',
      thousandsSeparator: ',',
      scale: this.to.currencyFormat === 'cents' ? 5 : 2,
      padFractionalZeros: true,
    };
  }

  // without this hack form do not work consistently
  onAccept() {}
}
