import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { UntilDestroy } from '@app/shared/utils/until-destroy';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';
import { Observable } from 'rxjs';
import { FormlyTemplateOptions } from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'formly-chips-v2',
  templateUrl: './formly-chips-v2.component.html',
  styleUrls: ['./formly-chips-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyChipsV2Component extends BasefieldV2Component implements OnInit {
  declare to: FormlyTemplateOptions & {
    optionsService?: Observable<DropdownItem[]>;
    initial?: DropdownItem[];
    groupByField?: string;
    filterCallback?: (items: DropdownItem[]) => DropdownItem[];
  };

  ngOnInit(): void {
    if (this.to.initial?.length) {
      this.formControl.setValue(
        this.to.initial?.map(({ value }) => {
          if (this.to.isFilter) {
            return value;
          }
          return { id: value };
        })
      );
    }
  }

  onItemSelectionChanged(selectedItems: DropdownItem[]) {
    this.formControl.setValue(
      selectedItems.map(({ value }) => {
        if (this.to.isFilter) {
          return value;
        }
        return { id: value };
      })
    );
    this.formControl.markAsDirty();
  }
}

export type ChipsFormControlValue = { id: unknown };
