<foldable-section [collapsed]="!items?.length" [sectionTitle]="title">
  <div section-content>
    <radar-table
      [skin]="'card-rows'"
      (rowClick)="onClick($event)"
      [rows]="items"
      [columns]="columns"
      [readonly]="true"
      [columnsFixed]="true"
    ></radar-table>
  </div>
</foldable-section>

<ng-template #createdAtTemplate let-value="value">
  <span>{{ value | rdrDate: 'extended' }}</span>
</ng-template>

<ng-template #salesAgentTemplate let-row="row">
  <agent-card [agent]="row.order.salesAgent"></agent-card>
</ng-template>

<ng-template #ticketingAgentTemplate let-row="row">
  <agent-card [agent]="row.ticketingAgent"></agent-card>
</ng-template>
