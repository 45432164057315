<button
  [attr.class]="look"
  [ngClass]="{
    big: size === btnSize.big
  }"
  (click)="handleClick($event)"
  [attr.type]="type"
  [disabled]="disabledBtn"
>
  <ng-container *ngIf="icon && iconPosition === 'left'">
    <rdr-svg-icon
      *ngIf="iconColor"
      size="sm"
      class="me-1"
      key="{{ icon }}"
      color="iconColor"
    ></rdr-svg-icon>
  </ng-container>
  <ng-content></ng-content>
  <ng-container *ngIf="icon && iconPosition === 'right'">
    <rdr-svg-icon
      *ngIf="iconColor"
      size="sm"
      class="ms-1"
      key="{{ icon }}"
      color="iconColor"
    ></rdr-svg-icon>
  </ng-container>
</button>
