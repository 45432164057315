import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { FormControl } from '@angular/forms';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { FilterByLabelPipe } from '@app/ui-v2/components/typeahead-v3/filter-by-group-label.pipe';
import { NavigatableContainerAbstract } from '@app/ui/components/navigatable-dropdown-abstract';
import { DropdownItemV2Component } from '../dropdown-item-v2/dropdown-item-v2.component';

const defaultSearchControlWidth = 30;

@UntilDestroy()
@Component({
  selector: 'chips-v2',
  templateUrl: './chips-v2.component.html',
  styleUrls: ['./chips-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsV2Component extends NavigatableContainerAbstract implements OnInit {
  @Input() items: DropdownItem[] | null;
  @Input() initial?: DropdownItem[];
  @Input() groupByField?: string;
  @Input() isFilter?: boolean;
  @Input() filterCallback?: (items: DropdownItem[]) => DropdownItem[];
  @Input() placeholder?: string;
  @Input() disabled? = false;

  @Output() itemSelectionChanged = new EventEmitter<DropdownItem[]>();

  searchFormControl = new FormControl('');
  searchControlWidth = defaultSearchControlWidth;

  selectedItems: DropdownItem[] = [];
  innerItems: DropdownItem[];

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  get itemsForNavigation() {
    return this.filterByLabel.transform(
      this.items || [],
      this.searchFormControl.value,
      this.filterCallback
    );
  }

  @ViewChildren(DropdownItemV2Component, { read: ElementRef })
  itemElementRefs: QueryList<ElementRef>;

  constructor(
    elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private filterByLabel: FilterByLabelPipe
  ) {
    super(elRef, cdr);
  }

  ngOnInit() {
    if (this.initial?.length) {
      this.selectedItems = this.initial;
    }

    if (this.disabled) {
      this.searchFormControl.disable();
    }

    this.searchFormControl.valueChanges.pipe(UntilDestroyed(this)).subscribe((search) => {
      const currentWidth = search.length * 10;
      this.searchControlWidth = Math.max(currentWidth, defaultSearchControlWidth);
    });
  }

  onItemSelected(item: DropdownItem<string>): void {
    this.selectItem(item);
  }

  initSearch() {
    this.searchFormControl.setValue('');
    this.searchInput?.nativeElement?.focus();

    setTimeout(() => this.searchInput?.nativeElement?.scrollIntoView(false));
  }

  showItems(event?: KeyboardEvent) {
    if (event?.key.toLocaleLowerCase() !== 'escape' && !this.disabled) {
      this.collapsed = false;
      this.modalService.preventCloseOnEscape(this.id);
    }
  }

  removeSelectedItem(selectedItem: DropdownItem) {
    this.selectedItems = this.selectedItems.filter(({ value }) => value !== selectedItem.value);

    this.itemSelectionChanged.next(this.selectedItems);
  }

  isSelectedItem(item: DropdownItem) {
    return this.selectedItems?.find(({ value }) => value === item.value);
  }

  selectItem(item: DropdownItem) {
    const foundIndex = this.selectedItems.findIndex(({ value }) => value === item.value);

    if (foundIndex > -1) {
      this.selectedItems.splice(foundIndex, 1);
    } else {
      this.selectedItems.push(item);
    }

    this.selectedItems = [...this.selectedItems];

    this.itemSelectionChanged.next(this.selectedItems);
    this.initSearch();
  }

  reset() {
    this.selectedItems = [];

    this.itemSelectionChanged.next(this.selectedItems);

    this.initSearch();
    this.toggleCollapsed();

    this.searchInput.nativeElement?.blur();
  }
}
