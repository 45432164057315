import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertsListComponent } from './components/alerts-list/alerts-list.component';
import { IconsModule } from '@app/icons/icons.module';
import { PipesModule } from '@app/ui/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { UiV2Module } from '@app/ui-v2/ui-v2.module';
import { AlertsLoadingComponent } from './components/alerts-loading/alerts-loading.component';
import { FirstCharPipe } from './pipes/first-char.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IndicatorComponent } from './components/indicator/indicator.component';

const components = [
  AlertsListComponent,
  AlertsComponent,
  AlertsLoadingComponent,
  IndicatorComponent,
  FirstCharPipe,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, UiV2Module, PipesModule, RouterModule, IconsModule, TooltipModule],
  exports: components,
})
export class AlertsModule {}
