<dropdown-v2
  *ngIf="to.optionsService | async as items"
  [placeholder]="to.placeholder || ''"
  [items]="$any(items)"
  (itemSelect)="selectOption($event)"
  [customListTemplate]="customListTemplate"
  [selectedItem]="selectedItem"
></dropdown-v2>

<ng-template
  #customListTemplate
  let-items="items"
  let-onItemSelected="onItemSelected"
  let-hoveredItem="hoveredItem"
  let-setHoveredItem="setHoveredItem"
>
  <ng-container *ngIf="items.length; else emptyTemplate">
    <dropdown-item-v2
      *ngFor="let item of items"
      [hovered]="hoveredItem === item"
      (mouseenter)="setHoveredItem(item)"
      (click)="onItemSelected(item)"
      class="d-flex flex-column w-576"
    >
      <div class="d-flex">
        <typography-v2>{{ item.extras?.subject }}</typography-v2>

        <typography-v2 class="v2-mx-2 text-jazlyn-700">&middot;</typography-v2>

        <typography-v2 class="text-jazlyn-700 text-truncate">
          {{ item.extras?.lastContent }}
        </typography-v2>
      </div>

      <div class="text-jazlyn-700 d-flex justify-content-between v2-mt-1">
        <div class="d-flex">
          <typography-v2 look="small" class="text-truncate w-376">
            {{ item.extras?.emails?.join(', ') }}
          </typography-v2>

          <typography-v2 look="small" class="v2-mx-2">&middot;</typography-v2>

          <typography-v2 look="small">{{ item.extras?.messageCount }}</typography-v2>
        </div>

        <typography-v2 look="small">{{ item.extras?.lastDate | rdrDate }}</typography-v2>
      </div>
    </dropdown-item-v2>
  </ng-container>
</ng-template>

<ng-template #emptyTemplate>
  <card class="v2-p-2">
    <typography-v2>No results</typography-v2>
  </card>
</ng-template>
