<div (scroll)="onScroll($event)" class="histories mx-2">
  <div *ngFor="let history of histories | extractNodes" class="mt-1 mb-3" id="history">
    <typography look="inputPlaceholder">
      {{ history.updatedAt | rdrDate: 'extended' }} @{{ history.user.fullName }}
      <span *ngIf="history.operation | operationLabel as operation" class="{{ operation }}"
        >{{ operation }}</span
      >
      {{ history.tableName }}
    </typography>
    <br />
    <div class="d-flex flex-column mt-1 mx-1">
      <div *ngFor="let change of history.changes" class="d-flex flex-row">
        <typography class="cell px-1" look="inputPlaceholder">{{ change.columnName }}</typography>
        <typography class="cell px-1 old">{{ change.oldValue }}</typography>
        <typography class="cell px-1 new">{{ change.newValue }}</typography>
      </div>
    </div>
  </div>
</div>
