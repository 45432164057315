import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasefieldV2Component } from '../basefield-v2/basefield-v2.component';

@Component({
  selector: 'radio-v2',
  templateUrl: './radio-v2.component.html',
  styleUrls: ['./radio-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioV2Component extends BasefieldV2Component {
  selectItemValue(value: unknown) {
    this.formControl.setValue(value);
    this.formControl.markAsDirty();
  }
}
