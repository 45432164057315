export const toLabel = (status = ''): string => {
  status = status || '';

  return status
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const trimFirstWord = (str = ''): string => {
  return str.substring(str.indexOf(' ') + 1);
};
