import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { SecureCreditCard } from '@app/generated/graphql';

@Component({
  selector: 'payment-credit-card-v2',
  templateUrl: './payment-credit-card-v2.component.html',
  styleUrls: ['./payment-credit-card-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCreditCardV2Component {
  @Input() creditCard?: SecureCreditCard;
  showCardPopup = false;

  @HostListener('document:keydown.escape', ['$event']) onEscape(): void {
    this.showCardPopup = false;
  }
}
