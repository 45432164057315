import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { MainLayoutStyleService } from '@app/layout/main-layout/main-layout-style.service';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[isV2Checker]',
})
export class IsV2CheckerDirective {
  constructor(
    private layoutStyle: MainLayoutStyleService,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    layoutStyle.isV2$.pipe(UntilDestroyed(this)).subscribe((val) => {
      if (val) {
        this.renderer.addClass(this.elRef.nativeElement, 'is-v2');
      } else {
        this.renderer.removeClass(this.elRef.nativeElement, 'is-v2');
      }
    });
  }
}
