import { Pipe, PipeTransform } from '@angular/core';
import { isToday } from '@app/formly/types/date/date-helpers';

@Pipe({
  name: 'isDateToday',
})
export class IsDateTodayPipe implements PipeTransform {
  transform(value: string | Date): boolean {
    return isToday(value.toString());
  }
}
