<div class="d-flex">
  <ng-container *ngIf="booking.bookingMiles?.pricePerMile">
    <typography-v2
      container="body"
      tooltip="Price per mile"
      placement="top"
      dataId="price-per-mile"
      class="v2-mb-3"
      >{{ booking.bookingMiles?.pricePerMile | rdrCurrency : 'cents' }}</typography-v2
    >
  </ng-container>

  <ng-container *showForRole="UserType.TicketingAgent">
    <ng-container *ngIf="booking.bookingMiles?.milesAccount?.login">
      <span class="v2-mx-2">&middot;</span>
      <a routerLink="/miles-inventory/{{ booking.bookingMiles?.milesAccount?.id }}">
        <typography-v2
          container="body"
          tooltip="Miles account"
          placement="top"
          dataId="miles-account"
          class="v2-mb-3"
          >{{ booking.bookingMiles?.milesAccount?.login }}</typography-v2
        >
      </a>
    </ng-container>
  </ng-container>
</div>
