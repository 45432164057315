<div
  (click)="initSearch()"
  [ngClass]="{ 'is-filter': isFilter, 'disabled': disabled }"
  class="field-container"
>
  <div class="scrollable">
    <span *ngFor="let selectedItem of selectedItems" class="chip">
      {{ selectedItem.label }}

      <rdr-svg-icon
        *ngIf="!disabled"
        (click)="$event.stopPropagation(); removeSelectedItem(selectedItem)"
        key="close"
        color="var(--color-mystic-300)"
        class="v2-ml-1 cursor-pointer"
      ></rdr-svg-icon>
    </span>

    <input
      (focus)="showItems()"
      (keydown)="showItems($event)"
      [formControl]="searchFormControl"
      [style.min-width.px]="searchControlWidth"
      [placeholder]="selectedItems?.length ? '' : placeholder"
      class="search-input"
      [class.disabled]="disabled"
      #searchInput
    />
  </div>

  <ng-container *ngIf="selectedItems?.length; else searchIconTemplate">
    <rdr-svg-icon
      *ngIf="!disabled"
      (click)="reset(); $event.stopPropagation()"
      class="search-icon cursor-pointer"
      key="circle-x"
      color="var(--placeholder)"
    ></rdr-svg-icon>
  </ng-container>

  <ng-template #searchIconTemplate>
    <rdr-svg-icon
      *ngIf="!disabled"
      (click)="initSearch()"
      class="search-icon"
      key="search"
      color="var(--placeholder)"
    ></rdr-svg-icon>
  </ng-template>
</div>

<context-menu-v2
  *ngIf="!collapsed"
  (clickOutside)="toggleCollapsed()"
  class="position-absolute items-list"
>
  <ng-container *ngIf="items; else loadingState">
    <ng-container
      *ngIf="items | filterByLabel: searchFormControl.value: filterCallback as filteredItems"
    >
      <ng-container *ngIf="filteredItems?.length; else emptyState">
        <div *ngFor="let group of filteredItems | groupByField: groupByField; let first = first">
          <div *ngIf="group.key && !first" class="d-flex v2-m-2">
            <hr />
          </div>

          <div *ngIf="group.key" class="d-flex v2-p-2">
            <typography-v2 look="text-bold" class="text-capitalize">
              {{ group.key }}
            </typography-v2>
          </div>

          <dropdown-item-v2
            *ngFor="let item of group.items"
            (click)="$event.stopPropagation(); $event.preventDefault(); selectItem(item)"
            [attr.id]="item.value"
            [attr.data-id]="item.value"
            class="d-flex"
            [hovered]="hoveredItem === item"
            (mouseenter)="hoveredItem = item"
            (mouseleave)="hoveredItem = null"
          >
            <label class="d-flex align-items-center">
              <input
                [checked]="isSelectedItem(item)"
                class="checkbox-input d-none"
                type="checkbox"
              />

              <div class="checkbox cursor-pointer"></div>

              <typography-v2 type="text">{{ item.label }}</typography-v2>
            </label>
          </dropdown-item-v2>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</context-menu-v2>

<ng-template #emptyState>
  <typography-v2 class="v2-p-2 text-black-50">No results</typography-v2>
</ng-template>

<ng-template #loadingState>
  <typography-v2 class="v2-p-2 text-black-50">Loading..</typography-v2>
</ng-template>
