import { Injectable } from '@angular/core';
import {
  CreditCardEdge,
  CreditCardNode,
  GetCardsGQL,
  GetCompanyCreditCardsGQL,
  SecureCreditCard,
} from '@app/generated/graphql';
import { CreditCardTitlePipe } from '@app/shared/pipes/cost-method.pipe';
import { DropdownItem, EMPTY_DROP_DOWN_ITEM } from '@app/ui/components/dropdown.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { COST_WIRE_TRANSFER } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CreditCardsService {
  constructor(
    private getCardsGQL: GetCardsGQL,
    private costMethodPipe: CreditCardTitlePipe,
    private getCompanyCreditCardsGQL: GetCompanyCreditCardsGQL
  ) {}

  getCards({
    orderId,
    alternativeItems = [],
    withoutCompaniesCards = false,
  }: {
    orderId: string;
    alternativeItems?: DropdownItem[];
    withoutCompaniesCards?: boolean;
  }): Observable<DropdownItem[]> {
    return this.getCardsGQL.fetch({ id: orderId }).pipe(
      map(({ data: { creditCards, order } }) => {
        const companiesCards = this.getCompaniesCardsAsDDItem(
          creditCards?.edges as CreditCardEdge[]
        );

        const clientCards = this.getClientCardsAsDDItem(
          order?.client?.creditCards?.edges as CreditCardEdge[]
        );

        return withoutCompaniesCards
          ? [...clientCards, ...alternativeItems]
          : [...companiesCards, ...clientCards, ...alternativeItems];
      })
    );
  }

  getCompanyCreditCards({
    alternativeItems = [],
    withEmptyField,
  }: {
    alternativeItems?: DropdownItem[];
    withEmptyField?: boolean;
  }): Observable<DropdownItem[]> {
    return this.getCompanyCreditCardsGQL.fetch().pipe(
      map(({ data }) => {
        return [
          ...(withEmptyField ? [EMPTY_DROP_DOWN_ITEM] : []),
          ...this.getCompaniesCardsAsDDItem(data.creditCards?.edges as CreditCardEdge[]),
          ...alternativeItems,
        ] as DropdownItem[];
      })
    );
  }

  getCompaniesCardsAsDDItem(edges: CreditCardEdge[] = []): DropdownItem[] {
    return edges.map(({ node }) => {
      return {
        label: companyCardLabel(node as CreditCardNode),
        value: node?.id,
      };
    });
  }

  getClientCardsAsDDItem(edges: CreditCardEdge[] = []): DropdownItem[] {
    return edges.reduce((acc: DropdownItem[], { node }) => {
      acc.push({
        label: `Client CC - ${this.costMethodPipe.transform(node as CreditCardNode)}`,
        value: node?.id,
      });
      return acc;
    }, []);
  }

  getFormsOfPayment(): Observable<DropdownItem[]> {
    return this.getCompanyCreditCards({
      alternativeItems: [COST_WIRE_TRANSFER],
      withEmptyField: true,
    });
  }
}

export function companyCardLabel(node: CreditCardNode | SecureCreditCard): string {
  return node.title || '';
}
