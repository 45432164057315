import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'canSendNotification',
})
export class CanSendNotificationPipe implements PipeTransform {
  transform(notificationSentAt?: Date) {
    if (!notificationSentAt) return true;

    const date = new Date(notificationSentAt).getTime();
    const now = new Date().getTime();

    return now - date > NOTIFICATIONS_TIMEOUT;
  }
}
export const NOTIFICATIONS_TIMEOUT = 60000;
