import { RadarFormAbstract } from '@shared/components/radar-form-abstract';
import { Directive, Input } from '@angular/core';
import { CostPerTypeInput, CostTypeInput, OrderNode } from '@generated/graphql';

@Directive()
export abstract class BookingFormAbstract extends RadarFormAbstract {
  isEglobalfares: boolean;

  @Input() isMilesBooking: boolean;
  @Input() isOption: boolean;

  @Input() option?: OrderNode;

  @Input() readonlyFields: boolean;
  @Input() isChangeRequest: boolean;

  initCosts() {
    const hasCosts = !!this.data?.costs?.length;

    if (!hasCosts) {
      const hasPasengers = this.option?.totalPassengers && this.option?.totalPassengers > 0;

      if (hasPasengers) {
        const costs = [];

        if (this.isMilesBooking) {
          costs.push({ perType: CostPerTypeInput.Passenger, type: CostTypeInput.Taxes });
          this.formModel = { ...this.formModel, costs };
          return;
        }

        if (this.option?.numberOfAdults) {
          costs.push({ perType: CostPerTypeInput.Adult, type: CostTypeInput.Ticket });
        }
        if (this.option?.numberOfChildren) {
          costs.push({ perType: CostPerTypeInput.Child, type: CostTypeInput.Ticket });
        }
        if (this.option?.numberOfInfants) {
          costs.push({ perType: CostPerTypeInput.Infant, type: CostTypeInput.Ticket });
        }

        this.formModel = { ...this.formModel, costs };
      }

      if (this.isOption) {
        const costs = this.isMilesBooking
          ? [{ perType: CostPerTypeInput.Passenger, type: CostTypeInput.Taxes }]
          : [{ perType: CostPerTypeInput.Adult, type: CostTypeInput.Ticket }];

        this.formModel = { ...this.formModel, costs };
      }
    }
  }
}
