import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'button-v2',
  templateUrl: './button-v2.component.html',
  styleUrls: ['./button-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonV2Component {
  @Input() type: ButtonV2Type = 'primary';
  @Input() buttonType = 'button';
  @Input() look: 'default' | 'link' | 'full-width' = 'default';
  @Input() pressed = false;
  @Input() disabled: boolean | undefined = false;
}

export type ButtonV2Type =
  | 'primary'
  | 'secondary'
  | 'destructive'
  | 'header'
  | 'filter'
  | 'tertiary'
  | 'call';
