import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexibilityType } from '@generated/graphql';

@Component({
  selector: 'flexibility',
  templateUrl: './flexibility.component.html',
  styleUrls: ['./flexibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexibilityComponent {
  @Input() data: FlexibilityType;
}
