import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { chevronBottomIcon } from '@svg/chevron-bottom';
import { chevronRightIcon } from '@svg/chevron-right';
import { chevronTopIcon } from '@svg/chevron-top';
import { arrowBoldReplyIcon } from '@svg/arrow-bold-reply';
import { arrowForwardIcon } from '@svg/arrow_forward';
import { optionsIcon } from '@svg/options';
import { plusIcon } from '@svg/plus';
import { trashIcon } from '@svg/trash';
import { chevronsRightIcon } from '@svg/chevrons-right';
import { pencilCreateIcon } from '@svg/pencil-create';
import { lockIcon } from '@svg/lock';
import { starIcon } from '@svg/star';
import { sendIcon } from '@svg/send';
import { fileIcon } from '@svg/file';
import { cloudUploadIcon } from '@svg/cloud-upload';
import { questionCircleIcon } from '@svg/question-circle';
import { circleXIcon } from '@svg/circle-x';
import { searchIcon } from '@svg/search';
import { chevronLeftIcon } from '@svg/chevron-left';
import { chevronsLeftIcon } from '@svg/chevrons-left';
import { contactIcon } from '@svg/contact';
import { phoneIcon } from '@svg/phone';
import { messageIcon } from '@svg/message';
import { userIcon } from '@svg/user';
import { clientIcon } from '@svg/client';
import { accountBoxIcon } from '@svg/account-box';
import { copyIcon } from '@svg/copy';
import { alarmIcon } from '@svg/alarm';
import { btnGoogleIcon } from '@svg/btn_google';
import { logoIcon } from '@svg/logo';
import { rotateCcwIcon } from '@svg/rotate-ccw';
import { seatIcon } from '@svg/seat';
import { helpTextIcon } from '@svg/help-text';
import { checkMarkIcon } from '@svg/check-mark';
import { commentTextIcon } from '@svg/comment-text';
import { pokeIcon } from '@svg/poke';
import { cupIcon } from '@svg/cup';
import { arrowRightIcon } from '@svg/arrow-right';
import { chevronBottomV2Icon } from '@svg/chevron-bottom-v2';
import { fileV2Icon } from '@app/generated/svg/file-v2';
import { sendV2Icon } from '@app/generated/svg/send-v2';
import { airplaneIcon } from '@svg/airplane';
import { editIcon } from '@svg/edit';
import { awardIcon } from '@app/generated/svg/award';
import { searchV2Icon } from '@app/generated/svg/search-v2';
import { logoutIcon } from '@app/generated/svg/logout';
import { closeIcon } from '@app/generated/svg/close';
import { calendarIcon } from '@app/generated/svg/calendar';
import { searchBlackIcon } from '@app/generated/svg/search-black';
import { minimizeIcon } from '@app/generated/svg/minimize';
import { pinIcon } from '@app/generated/svg/pin';
import { maximizeIcon } from '@app/generated/svg/maximize';
import { stickyNoteIcon } from '@app/generated/svg/sticky_note';
import { mailIcon } from '@app/generated/svg/mail';
import { moreIcon } from '@app/generated/svg/more';
import { callIcon } from '@app/generated/svg/call';
import { arrowRightV2Icon } from '@app/generated/svg/arrow_right-v2';
import { trashV2Icon } from '@svg/trash-v2';
import { alertV2Icon } from '@app/generated/svg/alert-v2';
import { userV2Icon } from '@app/generated/svg/user-v2';
import { arrowDownLeftIcon } from '@app/generated/svg/arrow-down-left';
import { arrowUpRightIcon } from '@app/generated/svg/arrow-up-right';
import { pauseIcon } from '@app/generated/svg/pause';
import { playIcon } from '@app/generated/svg/play';
import { chatIcon } from '@app/generated/svg/chat';
import { slashV2Icon } from '@svg/slash-v2';
import { linkV2Icon } from '@svg/link-v2';
import { crownIcon } from '@app/generated/svg/crown';
import { starFullIcon } from '@app/generated/svg/star-full';
import { clockIcon } from '@svg/clock';
import { referralIcon } from '@app/generated/svg/referral';
import { editPenIcon } from '@app/generated/svg/edit-pen';
import { plusV2Icon } from '@app/generated/svg/plus-v2';
import { archiveIcon } from '@svg/archive';
import { unarchiveIcon } from '@svg/unarchive';
import { imageIcon } from '@app/generated/svg/image';
import { downloadIcon } from '@app/generated/svg/download';
import { repeatIcon } from '@app/generated/svg/repeat';
import { alertIcon } from '@app/generated/svg/alert';
import { phoneOutgoingIcon } from '@app/generated/svg/phone-outgoing';
import { RdrSvgIconComponent } from './rdr-svg-icon/rdr-svg-icon.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { statusAwayIcon } from '@app/generated/svg/status-away';
import { statusOnlineIcon } from '@app/generated/svg/status-online';
import { plusButtonIcon } from '@app/generated/svg/plus-button';
import { minusButtonIcon } from '@app/generated/svg/minus-button';
import { microphoneIcon } from '@app/generated/svg/microphone';
import { microphoneOffIcon } from '@app/generated/svg/microphone-off';
import { returnIcon } from '@app/generated/svg/return';
import { bonusFlightIcon } from '@app/generated/svg/bonus-flight';
import { bonusFlightRedIcon } from '@app/generated/svg/bonus-flight-red';
import { overviewIcon } from '@app/generated/svg/overview';
import { voyaguIcon } from '@app/generated/svg/voyagu';
import { contestStarIcon } from '@app/generated/svg/contest-star';
import { bellIcon } from '@app/generated/svg/bell';
import { externalLinkIcon } from '@app/generated/svg/external_link';
import { changeIcon } from '@app/generated/svg/change';
import { refreshIcon } from '@app/generated/svg/refresh';
import { infoIcon } from '@app/generated/svg/info';
import { pinFullIcon } from '@app/generated/svg/pin_full';
import { profileFullIcon } from '@app/generated/svg/profile_full';
import { blockIcon } from '@app/generated/svg/block';
import { scheduleChangeIcon } from '@app/generated/svg/schedule_change';
import { airplaneActiveIcon } from '@app/generated/svg/airplane_active';
import { airplaneInactiveIcon } from '@app/generated/svg/airplane_inactive';
import { splitIcon } from '@app/generated/svg/split';
import { dragIndicatorIcon } from '@app/generated/svg/drag_indicator';
import { diamondIcon } from '@app/generated/svg/diamond';

const icons = [
  chevronBottomIcon,
  chevronRightIcon,
  chevronTopIcon,
  arrowBoldReplyIcon,
  plusIcon,
  trashIcon,
  chevronsRightIcon,
  pencilCreateIcon,
  starIcon,
  arrowForwardIcon,
  optionsIcon,
  lockIcon,
  sendIcon,
  fileIcon,
  cloudUploadIcon,
  questionCircleIcon,
  circleXIcon,
  searchIcon,
  chevronLeftIcon,
  chevronsLeftIcon,
  contactIcon,
  phoneIcon,
  messageIcon,
  userIcon,
  clientIcon,
  accountBoxIcon,
  copyIcon,
  alarmIcon,
  btnGoogleIcon,
  logoIcon,
  rotateCcwIcon,
  seatIcon,
  helpTextIcon,
  checkMarkIcon,
  commentTextIcon,
  pokeIcon,
  cupIcon,
  arrowRightIcon,
  awardIcon,
  searchV2Icon,
  logoutIcon,
  closeIcon,
  chevronBottomV2Icon,
  calendarIcon,
  searchBlackIcon,
  minimizeIcon,
  maximizeIcon,
  moreIcon,
  pinIcon,
  callIcon,
  mailIcon,
  fileV2Icon,
  sendV2Icon,
  airplaneIcon,
  editIcon,
  stickyNoteIcon,
  arrowRightV2Icon,
  trashV2Icon,
  alertV2Icon,
  userV2Icon,
  arrowDownLeftIcon,
  arrowUpRightIcon,
  pauseIcon,
  playIcon,
  chatIcon,
  externalLinkIcon,
  slashV2Icon,
  linkV2Icon,
  crownIcon,
  starFullIcon,
  clockIcon,
  referralIcon,
  editPenIcon,
  plusV2Icon,
  archiveIcon,
  unarchiveIcon,
  imageIcon,
  downloadIcon,
  repeatIcon,
  alertIcon,
  phoneOutgoingIcon,
  statusAwayIcon,
  statusOnlineIcon,
  plusButtonIcon,
  minusButtonIcon,
  microphoneIcon,
  microphoneOffIcon,
  returnIcon,
  bonusFlightIcon,
  bonusFlightRedIcon,
  overviewIcon,
  voyaguIcon,
  contestStarIcon,
  bellIcon,
  changeIcon,
  refreshIcon,
  infoIcon,
  pinFullIcon,
  profileFullIcon,
  blockIcon,
  scheduleChangeIcon,
  airplaneActiveIcon,
  airplaneInactiveIcon,
  splitIcon,
  dragIndicatorIcon,
  diamondIcon,
];

export const svgIconsConfig = {
  sizes: {
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '20px',
    xl: '26px',
    'xl-v2': '40px',
    xxl: '50px',
    'xxl-v2': '64px',
  },
  icons: icons,
};

@NgModule({
  declarations: [RdrSvgIconComponent],
  imports: [CommonModule, SvgIconComponent],
  exports: [RdrSvgIconComponent],
})
export class IconsModule {}
