<span
  [ngClass]="{
    h1: look === 'H1',
    h2: look === 'H2',
    h3: look === 'H3',
    h4: look === 'H4',
    'stats-details': look === 'stats-details',
    'stats-title': look === 'stats-title',
    'form-label': look === 'formLabel',
    'input-placeholder': look === 'inputPlaceholder',
    'section-title': look === 'sectionTitle',
    small: look === 'small',
    details: look === 'details',
    normal: look === 'normal',
    'text-upper': upper,
    'text-truncate': truncate
  }"
>
  <ng-content></ng-content>
</span>
