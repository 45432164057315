import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { DropdownItem } from '@app/ui/components/dropdown.model';

@Component({
  selector: 'radio-buttons-v2',
  templateUrl: './radio-buttons-v2.component.html',
  styleUrls: ['./radio-buttons-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonsV2Component {
  @Input() key: unknown;
  @Input() disabled: boolean;
  @Input() items: DropdownItem[] = [];
  @Input() value: DropdownItem['value'];
  @HostBinding('class.horizontal') @Input() horizontal: boolean;

  @Output() selectItem = new EventEmitter();
}
