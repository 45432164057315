import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDataID',
})
export class ToDataIdPipe implements PipeTransform {
  transform(value = '') {
    return value.trim().split(' ').join('-').toLowerCase();
  }
}
