<div (click)="toggleEmailMessage()" class="d-flex flex-column email-message cursor-pointer">
  <div class="d-flex align-items-center justify-content-between">
    <typography>{{ emailMessage.subject }}</typography>
    <typography look="inputPlaceholder"
      >{{ emailMessage.datetimeAt | rdrDate: 'short' }}</typography
    >
  </div>
  <typography look="inputPlaceholder">From: {{ emailMessage.sender }}</typography>
  <ng-container *ngIf="expanded">
    <typography look="inputPlaceholder">To: {{ emailMessage.recipient }}</typography>
    <typography class="mt-1" [innerText]="emailMessage.content"></typography>
  </ng-container>
</div>
