<card
  [ngStyle]="{'height.px': tripsCardHeight ? tripsCardHeight : undefined }"
  class="position-relative"
  data-id="trip-notes"
>
  <div class="d-flex align-items-center v2-mb-2">
    <typography-v2 look="section-title-bold">Trip Notes</typography-v2>
    <button-icon-v2
      [modalTpl]="tripNotesForm"
      [modalClass]="'wide'"
      showModalOnClick
      key="edit-pen"
      size="md"
      color="var(--color-black)"
      class="edit-notes v2-ml-1"
    ></button-icon-v2>
  </div>

  <typography-v2
    *ngIf="entity?.tripNotes; else emptyState"
    class="notes-holder"
    dataId="trip-notes-text"
    >{{ entity.tripNotes }}</typography-v2
  >
</card>

<ng-template #tripNotesForm>
  <modal-v2 header="Trip Notes">
    <flight-request-trip-notes-form
      [data]="entity"
      (formSubmit)="submitNotes($event)"
    ></flight-request-trip-notes-form>
  </modal-v2>
</ng-template>

<ng-template #emptyState>
  <typography-v2 class="notes-holder text-black-50"
    >Additional information relevant to this trip</typography-v2
  >
</ng-template>
