<typography-v2 *ngIf="to.label" class="v2-mb-1">{{ to.label }}</typography-v2>

<slider
  (setRange)="change($event)"
  [min]="to.values.min_value"
  [max]="to.values.max_value"
  [from]="formControl.value?.length ? formControl.value[0] : null"
  [to]="formControl.value?.length ? formControl.value[1] : null"
  [step]="1"
  [attr.data-id]="field.key"
  type="minutes"
></slider>
