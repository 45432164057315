import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightItineraryFragment } from '@generated/graphql';

@Component({
  selector: 'short-flight-info',
  templateUrl: './short-flight-info.component.html',
  styleUrls: ['./short-flight-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortFlightInfoComponent {
  @Input() flightItinerary?: FlightItineraryFragment;
}
