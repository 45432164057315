import { Pipe, PipeTransform } from '@angular/core';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { UserNode } from '@app/generated/graphql';

@Pipe({
  name: 'updateUserSelectionList',
})
export class UpdateUserSelectionListPipe implements PipeTransform {
  constructor(private cognitoUserService: CognitoAuthService) {}

  transform(users: Set<UserNode> | null, addUnassigned?: boolean): Set<UserNode> | null {
    if (!users) return users;
    const result = [];
    const currentUserId = this.cognitoUserService.user.id;

    users.forEach((user) => {
      if (user.id === currentUserId) {
        result.unshift(user);
      } else {
        result.push(user);
      }
    });

    if (addUnassigned) {
      result.push({
        id: '',
      } as UserNode);
    }

    return new Set(result);
  }
}
