import { Pipe, PipeTransform } from '@angular/core';
import { HistoryOperationType } from '@app/generated/graphql';

@Pipe({
  name: 'operationLabel',
})
export class OperationLabelPipe implements PipeTransform {
  transform(value: HistoryOperationType): string {
    return operations[value];
  }
}

const operations: Record<HistoryOperationType, string> = {
  [HistoryOperationType.Insert]: 'inserted',
  [HistoryOperationType.Update]: 'updated',
  [HistoryOperationType.Delete]: 'deleted',
  [HistoryOperationType.Truncate]: 'truncated',
};
