<div class="d-flex flex-column">
  <div class="d-flex justify-content-between">
    <typography-v2 class="text-jazlyn-700" look="small" dataId="full-name"
      >{{ interaction.clientName || interaction.user?.fullName }}</typography-v2
    >

    <typography-v2 class="text-jazlyn-700" look="small" dataId="start-at"
      >{{ interaction.startAt | rdrDate: 'extended' }}</typography-v2
    >
  </div>

  <div class="d-flex align-items-center v2-mt-1">
    <ng-container *ngIf="interaction.hasRecording; else noRecordTemplate">
      <button-icon-v2
        *ngIf="playing"
        (click)="pause()"
        color="var(--color-jazlyn-700)"
        key="pause"
        type="just-icon"
        size="md"
        data-id="pause"
      ></button-icon-v2>

      <button-icon-v2
        *ngIf="!playing"
        (click)="play()"
        [disabled]="loading"
        key="play"
        type="just-icon"
        size="md"
        data-id="play"
      ></button-icon-v2>

      <lead-call-track
        #leadCallTrack
        (onAudioEnded)="onAudioEnded()"
        (setPlaying)="setPlaying($event)"
        [audioLink]="audioLinks[currentLinkIndex]"
        class="v2-mx-2"
      ></lead-call-track>

      <typography-v2 [ngClass]="{ 'text-purple': playing }"
        >{{ interaction.duration! * 1000 | rdrDate: 'mm_ss' }}</typography-v2
      >
    </ng-container>

    <ng-template #noRecordTemplate>
      <typography-v2 dataId="no-records">No records</typography-v2>
    </ng-template>
  </div>
</div>
