import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'copy-to-clipboard-v2',
  templateUrl: './copy-to-clipboard-v2.component.html',
  styleUrls: ['./copy-to-clipboard-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('copiedAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
    ]),
    trigger('btnAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CopyToClipboardV2Component {
  copied = false;

  @Input() value?: string;
  @Output() copyCallback = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('click')
  onClick() {
    void this.copy();
  }

  copyDone() {
    setTimeout(() => {
      this.copied = false;
      this.cdRef.detectChanges();
    }, 1500);
  }

  copy() {
    void navigator.clipboard
      .writeText(this.value || '')
      .then(() => {
        this.copied = true;
        this.cdRef.detectChanges();
        this.copyCallback.emit();
      })
      .catch((error) => console.log(error));
  }
}
