import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ElementRef,
  HostListener,
} from '@angular/core';
import { UserType } from '@app/generated/graphql';

@Component({
  selector: 'communication-container',
  templateUrl: './communication-container.component.html',
  styleUrls: ['./communication-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationContainerComponent {
  activeTab: ActiveTab = null;

  @Input() leadId? = '';
  @Input() outgoingCalls? = 0;

  @HostBinding('class.in-place') @Input() inPlace: boolean;

  extendedCommunicationRoles = [
    UserType.SalesAgent,
    UserType.SalesTeamLead,
    UserType.Administrator,
  ];

  constructor(private elementRef: ElementRef) {}

  setActiveTab(tab: ActiveTab) {
    this.activeTab = tab;
  }

  clickOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.activeTab = null;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape() {
    if (this.activeTab) {
      this.activeTab = null;
    }
  }
}

type ActiveTab = 'notes' | 'interactions' | null;
