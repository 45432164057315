import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BookingStatusType } from '@generated/graphql';

@Component({
  selector: 'booking-status-badge',
  templateUrl: './booking-status-badge.component.html',
  styleUrls: ['./booking-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingStatusBadgeComponent {
  @Input() status: BookingStatusType;
}
