import { Pipe, PipeTransform } from '@angular/core';
import {
  ChangeSearchTaskStatusType,
  FeedbackTypeEnum,
  IdInput,
  SearchTaskNode,
} from '@app/generated/graphql';

@Pipe({
  name: 'searchTaskShowedReasonsPanel',
})
export class SearchTaskShowedReasonsPanelPipe implements PipeTransform {
  transform(
    searchTask: SearchTaskNode | undefined,
    feedback: FeedbackTypeEnum,
    selectedReasons: IdInput[] = []
  ): boolean {
    return (
      searchTask?.status === ChangeSearchTaskStatusType.Closed &&
      feedback &&
      feedback !== FeedbackTypeEnum.Good &&
      !selectedReasons.length
    );
  }
}
