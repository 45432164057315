import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GetEmailMessagesGQL,
  GetEmailMessagesQuery,
  GetEmailMessagesQueryVariables,
} from '@app/generated/graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailMessagesService {
  constructor(private getEmailMessagesGQL: GetEmailMessagesGQL) {}

  getEmailMessages(
    params: GetEmailMessagesQueryVariables
  ): Observable<ApolloQueryResult<GetEmailMessagesQuery>> {
    return this.getEmailMessagesGQL.fetch(params);
  }
}
