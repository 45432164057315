<form [formGroup]="formGroup" (ngSubmit)="submitFormV2()" (keydown.enter)="$event.preventDefault()">
  <formly-form [form]="formGroup" [model]="formModel" [fields]="fields"></formly-form>

  <div class="v2-mt-5 d-flex justify-content-end">
    <div *ngIf="!isOrder" class="d-flex flex-column total">
      <typography-v2 look="small"
        >Total cost for {{ formModel.guestCount || 0 }} guests</typography-v2
      >
      <typography-v2 dataId="costs-total-amount">{{ totalAmount | rdrCurrency }}</typography-v2>
    </div>

    <button-v2
      *ngIf="allowDelete && !readonly"
      formButtonState
      [confirmOnDelete]="'booking'"
      (deleteConfirmed)="deleteEntity.emit(data?.id)"
      type="destructive"
      class="v2-mr-3"
      data-id="booking-delete"
    >
      <typography-v2 look="caption-bold">Delete</typography-v2>
    </button-v2>

    <button-v2
      [disabled]="!formGroup.valid || formGroup.pristine || readonly"
      formButtonState
      buttonType="submit"
      data-id="booking-save"
    >
      <typography-v2 *ngIf="!isOption" look="caption-bold"
        >{{ data.id ? 'Save' : 'Add Booking' }}</typography-v2
      >
      <typography-v2 *ngIf="isOption" look="caption-bold">Add Option</typography-v2>
    </button-v2>
  </div>
</form>
