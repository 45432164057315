import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, UntilDestroyed } from '@app/shared/utils/until-destroy';
import { DropdownItem } from '@app/ui/components/dropdown.model';
import { NavigatableContainerAbstract } from '@app/ui/components/navigatable-dropdown-abstract';
import { Observable, Subscription, debounceTime, filter, switchMap, tap } from 'rxjs';
import { FilterByLabelPipe } from './filter-by-group-label.pipe';
import { DropdownItemV2Component } from '../dropdown-item-v2/dropdown-item-v2.component';

@UntilDestroy()
@Component({
  selector: 'typeahead-v3',
  templateUrl: './typeahead-v3.component.html',
  styleUrls: ['./typeahead-v3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeaheadV3Component extends NavigatableContainerAbstract implements OnInit {
  @Input() items: DropdownItem[] | Observable<DropdownItem[]> | null;
  @Input() selectedItem: DropdownItem | null;
  @Input() disabled = false;
  @Input() customListTemplate: TemplateRef<any>;
  @Input() emptyTemplate: TemplateRef<any>;
  @Input() placeholder: string;
  @Input() isFilter: boolean;
  @Input() loadingTemplate: TemplateRef<any>;
  @Input() leftImgTpl: TemplateRef<any>;
  @Input() key?: string | number | string[];
  @Input() groupByField?: string;
  @Input() resetSearch$: Observable<boolean>;
  @Input() clearable?: boolean;
  @Input() filterCallback: (items: DropdownItem[], searchValue: string) => DropdownItem[];
  @Input() fetchOnSearch: (searchValue: string) => Observable<DropdownItem[]>;

  @Output() selectItem = new EventEmitter<DropdownItem>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChildren(DropdownItemV2Component, { read: ElementRef })
  itemElementRefs: QueryList<ElementRef>;

  loading = false;
  searchValue = '';
  itemHeight = 32;
  defaultHeight = 325;
  maxHeight = 325;

  searchControl = new UntypedFormControl('');
  itemsSub: Subscription;
  innerItems: DropdownItem[] = [];

  get itemsForNavigation(): DropdownItem<string>[] {
    return this.filterByLabelPipe.transform(this.innerItems, this.searchValue, this.filterCallback);
  }

  constructor(
    elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private filterByLabelPipe: FilterByLabelPipe
  ) {
    super(elRef, cdr);
  }

  ngOnInit(): void {
    if (this.selectedItem) {
      this.searchControl.setValue(this.selectedItem.label, { emitEvent: false });
    }

    if (!this.fetchOnSearch) {
      this.searchControl.valueChanges.pipe(UntilDestroyed(this)).subscribe((value) => {
        this.searchValue = value;
        this.collapsed = false;
        this.setOrientation(this.items as DropdownItem[]);

        if (value === '') {
          this.clearSelection();
        }
      });
    } else {
      this.searchControl.valueChanges
        .pipe(UntilDestroyed(this))
        .pipe(
          tap((value) => {
            this.searchValue = value;

            if (value === '') {
              this.clearSelection();
            }
          }),
          filter((value) => value !== this.selectedItem?.label),
          tap(() => (this.loading = true)),
          debounceTime(200),
          switchMap((value) => this.fetchOnSearch((value as string).trim()))
        )
        .subscribe((items) => {
          this.setInnerItems(items);
        });
    }

    this.resetSearch$?.pipe(UntilDestroyed(this)).subscribe(() => {
      this.clearSelection();
    });
  }

  onOpen() {
    if (!this.disabled && this.collapsed) {
      if (this.items instanceof Observable<DropdownItem[]>) {
        this.loading = true;
        this.items.subscribe((items) => this.setInnerItems(items));
      } else {
        this.innerItems = this.items as DropdownItem[];
        this.setOrientation(this.items as DropdownItem[]);
        this.toggleCollapsed();
      }
    }
  }

  onItemSelected(item: DropdownItem<string>) {
    this.optionSelected(item);
  }

  optionSelected = (item: DropdownItem) => {
    this.collapsed = true;
    this.searchValue = item.label as string;
    this.searchControl.setValue(item.label, { emitEvent: false });
    this.selectItem.emit(item);
  };

  onBlur() {
    window.setTimeout(() => {
      this.collapsed = true;
    });
  }

  setOrientation(items?: DropdownItem<string>[]) {
    const containerHeight = this.itemHeight * (items?.length || 0);
    const distanceToLowerBound =
      window.innerHeight - +this.searchInput.nativeElement.getBoundingClientRect().bottom;

    if (containerHeight < distanceToLowerBound) return;

    const distanceToUpperBound = +this.searchInput.nativeElement.getBoundingClientRect().top;

    const maxDistanceToBound = Math.max(distanceToLowerBound, distanceToUpperBound);

    this.maxHeight = Math.min(maxDistanceToBound - 5, this.defaultHeight);
  }

  setHoveredItem = (item: DropdownItem) => {
    this.hoveredItem = item;
  };

  setInnerItems(items: DropdownItem[]) {
    this.innerItems = items;
    this.loading = false;
    this.hoveredItem = null;
    this.toggleCollapsed(undefined, true);
    this.setOrientation(items);
    this.cdr.detectChanges();
  }

  clearSelection() {
    this.searchControl.setValue('', { emitEvent: false });
    this.selectItem.emit(undefined);
    this.searchValue = '';
  }
}
