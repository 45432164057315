import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { OrderEdge } from '@app/generated/graphql';

@Component({
  selector: 'result-orders-block',
  templateUrl: './result-orders-block.component.html',
  styleUrls: ['./result-orders-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultOrdersBlockComponent {
  @Input() orders?: OrderEdge[];
  @Output() rowClick = new EventEmitter();
}
