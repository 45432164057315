import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import IMask from 'imask';

@Component({
  selector: 'phone-value-view',
  templateUrl: './phone-value-view.component.html',
  styleUrls: ['./phone-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneValueViewComponent {
  @Input() value: string;
  @Input() placeholder?: string;
  @Input() flagClass: string;
  @Input() mask: IMask.MaskedDynamicOptions;
}
