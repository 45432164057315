import { Directive, HostListener, Input, TemplateRef } from '@angular/core';
import { ModalClass, ModalV2Service } from '@app/ui-v2/services/modal-v2.service';

@Directive({
  selector: '[showModalOnClick]',
})
export class ShowModalOnClickDirective {
  @Input() modalTpl: TemplateRef<any>;
  @Input() modalClass: ModalClass;

  @HostListener('click', ['$event'])
  onClick() {
    this.modalV2Service.showModal({ template: this.modalTpl, className: this.modalClass });
  }

  constructor(private modalV2Service: ModalV2Service) {}
}
