import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validRoundFormPipe',
  pure: false,
})
export class ValidRoundFormPipePipe implements PipeTransform {
  transform(formModel: any): boolean {
    return !!(
      formModel.departureAirports?.length &&
      formModel.arrivalAirports?.length &&
      formModel.departureDate &&
      formModel.departureFlexibility &&
      formModel.returnDate &&
      formModel.returnFlexibility &&
      formModel.serviceClass &&
      (formModel.numberOfAdults || formModel.numberOfChildren || formModel.numberOfInfants)
    );
  }
}
