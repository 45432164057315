import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChangeSearchTaskStatusType,
  FlightRequestStatusDashboardType,
  LeadStatusTypeDashboard,
  OrderStatusDashboardType,
  SupportRequestStatusType,
} from '@app/generated/graphql';

@Component({
  selector: 'status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input() status?:
    | LeadStatusTypeDashboard
    | FlightRequestStatusDashboardType
    | OrderStatusDashboardType
    | SupportRequestStatusType
    | ChangeSearchTaskStatusType;
}
