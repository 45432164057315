import { Injectable } from '@angular/core';
import { LeadCallAnsweredGQL } from '@app/generated/graphql';
import { Call } from '@twilio/voice-sdk';
import { BehaviorSubject } from 'rxjs';
import { CallEvent } from '../twilio/twilio.service';

@Injectable({
  providedIn: 'root',
})
export class CallPopupService {
  call: Call | null = null;
  customParameters: CustomParameters | null = null;
  incomingCallCustomParameters$ = new BehaviorSubject(this.customParameters);

  // hack for first disconnect event from another tab
  accepting = false;

  constructor(private leadCallAnsweredGQL: LeadCallAnsweredGQL) {
    this.initCustomParameters();
    this.initPageListeners();
  }

  initPageListeners() {
    window.addEventListener('storage', () => this.initCustomParameters());

    window.addEventListener('beforeunload', (event) => {
      if (this.call && this.call['_isAnswered']) {
        event.preventDefault();
        return (event.returnValue = '');
      }
      return null;
    });
  }

  initCustomParameters() {
    const customParameters = JSON.parse(localStorage.getItem(customParametersKey) || '""');

    if (customParameters) {
      this.customParameters = customParameters;
      this.incomingCallCustomParameters$.next(this.customParameters);

      if (this.customParameters?.status === CallPopupStatus.Accepted) {
        this.setAccepting();
        this.call?.accept();
      }

      if (this.customParameters?.status === CallPopupStatus.Disconnected) {
        this.call?.disconnect();
        this.call?.removeAllListeners();
      }

      if (this.customParameters?.status === CallPopupStatus.Rejected) {
        this.call?.reject();
        this.call?.removeAllListeners();
      }
    } else {
      this.close();
    }
  }

  setCustomParameters(customParameters: CustomParameters) {
    this.customParameters = customParameters;
    this.incomingCallCustomParameters$.next(this.customParameters);

    localStorage.setItem(customParametersKey, JSON.stringify(this.customParameters));
  }

  setIncomingCall(call: Call) {
    this.call = call;

    this.call.on(CallEvent.Cancel as string, () => {
      this.reject();
      this.close();
    });

    this.call.on(CallEvent.Disconnect as string, () => {
      if (!this.accepting) {
        this.disconnect();
      }
    });

    this.call.on(CallEvent.Accept as string, () => {
      this.callAnswered(this.customParameters?.leadId as string).subscribe();
    });

    const customParameters = {
      ...(Object.fromEntries(this.call.customParameters) as CustomParameters),
      status: CallPopupStatus.Incoming,
    };

    this.setCustomParameters(customParameters);
  }

  setAccepting() {
    this.accepting = true;
    setTimeout(() => (this.accepting = false), 1000);
  }

  accept() {
    this.setAccepting();
    this.call?.accept();

    const customParameters = {
      ...(this.customParameters as CustomParameters),
      status: CallPopupStatus.Accepted,
    };

    this.setCustomParameters(customParameters);
  }

  disconnect() {
    this.call?.disconnect();
    this.call?.removeAllListeners();

    const customParameters = {
      ...(this.customParameters as CustomParameters),
      status: CallPopupStatus.Disconnected,
    };

    this.setCustomParameters(customParameters);
  }

  reject() {
    this.call?.reject();
    this.call?.removeAllListeners();

    const customParameters = {
      ...(this.customParameters as CustomParameters),
      status: CallPopupStatus.Rejected,
    };

    this.setCustomParameters(customParameters);
  }

  close() {
    this.call = null;

    this.customParameters = null;

    this.incomingCallCustomParameters$.next(this.customParameters);

    localStorage.removeItem(customParametersKey);
  }

  callAnswered(leadId: string) {
    return this.leadCallAnsweredGQL.mutate({ leadId });
  }
}

export enum CallPopupStatus {
  Incoming = 'Incoming',
  Accepted = 'Accepted',
  Disconnected = 'Disconnected',
  Rejected = 'Rejected',
}

export type CustomParameters = {
  leadId: string;
  firstName: string;
  lastName: string;
  departureAt: string;
  departure: string;
  arrival: string;
  status: CallPopupStatus;
};

export const customParametersKey = 'customParameters';
