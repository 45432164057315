import { TemplateRef } from '@angular/core';
import { PageInfo } from '@app/generated/graphql';
import { GenericNode } from '@app/shared/types';
import { RADAR_TABLE_TOTAL_COUNT_LIMIT } from '@app/shared/components/radar-table/radar-table.constants';
import { PAGINATION_FIRST_PAGE } from '@shared/constants';

export type RadarTableRow<R = any> = R;

export type RadarTableColumn<F = GenericNode> = {
  prop?: keyof F | string;
  name?: string;
  cellType?: RadarTableColumnType;
  isHighlighted?: boolean;
  cellTemplate?: TemplateRef<any>;
  width?: number;
  maxWidth?: number;
  extraAttributes?: Record<string, any>;
  sort?: {
    asc: RadarTableSort;
    desc: RadarTableSort;
  };
};

export enum RadarTableColumnType {
  Number = 'number',
  Percentage = 'percentage',
  Currency = 'currency',
  Date = 'date',
  DateTime = 'date_time',
  Selection = 'selection',
  UserCard = 'user_card',
  FlightInfo = 'flight_info',
  TripInfo = 'trip_info',
  Phone = 'phone',
  Email = 'email',
  ClientName = 'client_name',
  Reason = 'reason',
  AirportNameAndCode = 'airport_name_code',
  Flexibility = 'flexibility',
  Label = 'label',
  ColoredStatus = 'colored_status',
  Duration = 'duration',
}

export type RadarTablePagination = {
  offset: number;
  prevoffset: number;
  totalCount: number;
  limit: number;
  pageInfo?: PageInfo;
};

export type TableWithSearch = {
  search?: string;
};

export interface TableWithSorting<S = string> {
  sort?: S;
}

export interface SelectableTable {
  selected?: RadarTableRow[];
}

export interface RadarTableState<F = RadarTableFilter, S = RadarTableSort>
  extends TableWithSearch,
    SelectableTable,
    TableWithSorting<S> {
  pagination: RadarTablePagination;
  filters: F & { selectedUserIds?: string[] };
}

export type RadarTableRowClick<T = any> = {
  event: MouseEvent;
  row: T;
};

export type RadarTableFilter<F = Record<string, any>> = F;

export type RadarTableSort<T = any> = T;

export type TableNode<F> = Partial<F>;

export const INITIAL_OFFSET_PAGINATION_PARAMS: RadarTablePagination = {
  limit: RADAR_TABLE_TOTAL_COUNT_LIMIT,
  prevoffset: PAGINATION_FIRST_PAGE,
  offset: PAGINATION_FIRST_PAGE,
  totalCount: 0,
  pageInfo: undefined,
};

export interface QueryPaginationParams {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}
