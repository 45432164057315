import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDaysShift',
})
export class GetDaysShiftPipe implements PipeTransform {
  transform(daysShift: number) {
    if (daysShift && daysShift > 0) {
      return `+${daysShift}`;
    }

    if (daysShift && daysShift < 0) {
      return daysShift;
    }

    return null;
  }
}
