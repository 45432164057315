<div class="d-flex">
  <user-picture-v2 size="semi" picture="/assets/svg/user-icon-voyagu.svg"></user-picture-v2>

  <div class="d-flex flex-column">
    <typography-v2 class="v2-ml-2 v2-my-2" look="small">
      Deal Assistance Request has been created
    </typography-v2>

    <ng-container *ngIf="searchTask | isRoundTrip; else defaultTemplate">
      <typography-v2 class="d-flex align-items-center v2-ml-2 v2-mb-2">
        <ng-container *ngIf="searchTask?.flights?.edges as flightEdges">
          <span data-id="departure_date"
            >{{ flightEdges[0].node?.departureDate | rdrDate: 'short_date' }}</span
          >&nbsp;<span data-id="departure_flexibility"
            >{{ flightEdges[0].node?.departureFlexibility | flexibility }}</span
          >

          <ng-container
            *ngIf="flightEdges[0].node?.returnDate || flightEdges[0].node?.returnFlexibility"
          >
            <rdr-svg-icon class="d-inline v2-mx-3" key="return"></rdr-svg-icon>

            <span *ngIf="flightEdges[0].node?.returnDate" data-id="return_date"
              >{{ flightEdges[0].node?.returnDate | rdrDate: 'short_date' }}</span
            >&nbsp;<span *ngIf="flightEdges[0].node?.returnFlexibility" data-id="return_flexibility"
              >{{ flightEdges[0].node?.returnFlexibility | flexibility }}</span
            >
          </ng-container>

          <span class="v2-mx-3">&middot;</span>

          <span
            *ngFor="let departureAirport of flightEdges[0].node?.departureAirports?.edges | extractNodes; let lastDepartureAirport = last"
            data-id="departure_airport"
          >
            {{ departureAirport.cityName }} ({{ departureAirport.locationCode }})<ng-container
              *ngIf="!lastDepartureAirport"
              >,
            </ng-container>
          </span>

          <rdr-svg-icon class="d-inline v2-mx-3" key="return"></rdr-svg-icon>

          <span
            *ngFor="let arrivalAirport of flightEdges[0].node?.arrivalAirports?.edges | extractNodes; let lastArrivalAirport = last"
            data-id="arrival_airport"
          >
            {{ arrivalAirport.cityName }} ({{ arrivalAirport.locationCode }})<ng-container
              *ngIf="!lastArrivalAirport"
              >,
            </ng-container>
          </span>
        </ng-container>
      </typography-v2>
    </ng-container>

    <ng-template #defaultTemplate>
      <typography-v2
        *ngFor="let flight of searchTask?.flights?.edges | extractNodes"
        class="v2-ml-2 v2-mb-2"
      >
        <span data-id="departure_date">{{ flight.departureDate | rdrDate: 'short_date' }}</span
        >&nbsp;<span data-id="departure_flexibility"
          >{{ flight.departureFlexibility | flexibility }}</span
        >

        <span class="v2-mx-2">&middot;</span>

        <span
          *ngFor="let departureAirport of flight?.departureAirports?.edges | extractNodes; let lastDepartureAirport = last"
          data-id="departure_airport"
          >{{ departureAirport.cityName }} ({{ departureAirport.locationCode }})<ng-container
            *ngIf="!lastDepartureAirport"
            >,
          </ng-container>
        </span>

        &rarr;

        <span
          *ngFor="let arrivalAirport of flight?.arrivalAirports?.edges | extractNodes; let lastArrivalAirport = last"
          data-id="arrival_airport"
        >
          {{ arrivalAirport.cityName }} ({{ arrivalAirport.locationCode }})<ng-container
            *ngIf="!lastArrivalAirport"
            >,
          </ng-container>
        </span>
      </typography-v2>
    </ng-template>

    <typography-v2 class="v2-ml-2 v2-mb-2">
      <span data-id="service_class">{{ searchTask?.serviceClass | label }}</span>
      <span class="v2-mx-2">&middot;</span>

      <ng-container *ngIf="searchTask?.numberOfAdults">
        <span data-id="number_of_adults">{{ searchTask?.numberOfAdults }}</span> adults
        <span class="v2-mx-2">&middot;</span>
      </ng-container>

      <ng-container *ngIf="searchTask?.numberOfChildren">
        <span data-id="number_of_children">{{ searchTask?.numberOfChildren }}</span> children
        <span class="v2-mx-2">&middot;</span>
      </ng-container>

      <ng-container *ngIf="searchTask?.numberOfInfants">
        <span data-id="number_of_infants">{{ searchTask?.numberOfInfants }}</span> infants
        <span class="v2-mx-2">&middot;</span>
      </ng-container>

      <span data-id="budget_from">{{ searchTask?.budgetFrom | rdrCurrency }}</span> -
      <span data-id="budget_to">{{ searchTask?.budgetTo | rdrCurrency }}</span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.preferredAirlines?.edges?.length" class="v2-ml-2 v2-mb-2">
      Preferred airlines:

      <span
        *ngFor="let preferredAirline of searchTask?.preferredAirlines?.edges | extractNodes; let lastPeferredAirline = last"
        data-id="preferred_airline"
      >
        {{ preferredAirline.shortName || preferredAirline.name }}<ng-container
          *ngIf="!lastPeferredAirline"
          >,
        </ng-container>
      </span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.avoidAirlines?.edges?.length" class="v2-ml-2 v2-mb-2">
      Avoid airlines:

      <span
        *ngFor="let avoidAirline of searchTask?.avoidAirlines?.edges | extractNodes; let lastAvoidAirline = last"
        data-id="avoid_airline"
      >
        {{ avoidAirline.shortName || avoidAirline.name }}<ng-container *ngIf="!lastAvoidAirline"
          >,
        </ng-container>
      </span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.preferredAlliances?.length" class="v2-ml-2 v2-mb-2">
      Use aviation alliance:

      <span
        *ngFor="let preferredAlliance of searchTask?.preferredAlliances; let lastPreferredAlliance = last"
        data-id="preferred_alliance"
      >
        {{ preferredAlliance | label }}<ng-container *ngIf="!lastPreferredAlliance"
          >,
        </ng-container>
      </span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.products?.edges?.length" class="v2-ml-2 v2-mb-2">
      Type of product:

      <span
        *ngFor="let product of searchTask?.products?.edges | extractNodes; let lastProduct = last"
        data-id="product"
      >
        {{ product.name }}<ng-container *ngIf="!lastProduct">, </ng-container>
      </span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.stops && searchTask?.maxLayover" class="v2-ml-2 v2-mb-2">
      <span data-id="stops">Stops: {{ searchTask?.stops | label }}</span>

      <span *ngIf="searchTask?.stops" class="v2-mx-2">&middot;</span>

      <span data-id="max_layover">Layover: {{ searchTask?.maxLayover | label }}</span>
    </typography-v2>

    <typography-v2 *ngIf="searchTask?.notes" class="v2-ml-2 v2-mb-2 note" dataId="notes"
      >{{ searchTask?.notes }}</typography-v2
    >
  </div>

  <typography-v2
    look="small"
    class="text-black-50 ms-auto mb-auto v2-mt-2 nowrap"
    dataId="created_at"
  >
    {{ searchTask?.createdAt | rdrDate: 'shortTime' }}
  </typography-v2>
</div>
