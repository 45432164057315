<ng-container *ngIf="!callDeclined && !callConfirmed; else decidedCallTemplate">
  <card class="w-525 v2-p-4">
    <typography-v2 class="v2-mb-3" look="title-semi-bold">{{lead | userName}}</typography-v2>
    <typography-v2 class="v2-mb-4"
      >Connected to a real person? Confirm that you've taken the lead. If the lead doesn't answer,
      send it back to the queue so it won't count towards your daily limit.</typography-v2
    >
    <div class="d-flex align-items-center justify-content-stretch">
      <button-v2
        *ngIf="connected || !this.leadShouldMakeCall"
        look="full-width"
        (click)="showReasonsPopover = true"
        class="position-relative flex-1 v2-mr-2"
        type="destructive"
        data-id="send-back-to-queue"
      >
        Send back to the queue
        <context-menu-v2
          *ngIf="showReasonsPopover"
          (clickOutside)="showReasonsPopover = false"
          class="reasons-popover d-flex flex-column v2-p-2 position-absolute"
        >
          <dropdown-item-v2
            class="cursor-pointer"
            *ngFor="let reason of reasons"
            (click)="onDecline(reason)"
          >
            <typography-v2 class="v2-p-2 text-black">{{reason.label}}</typography-v2>
          </dropdown-item-v2>
        </context-menu-v2>
      </button-v2>
      <ng-container *ngIf="!connected; else callDurationTemplate">
        <typography-v2
          *ngIf="leadShouldMakeCall"
          class="v2-p-4 v2-py-2 w-100 text-align-center text-black flex-1"
        >
          Connecting...
        </typography-v2>
      </ng-container>
      <button-v2
        *ngIf="connected || !this.leadShouldMakeCall"
        [disabled]="isConfirming"
        look="full-width"
        class="v2-ml-2 flex-1"
        data-id="confirm"
        (click)="onConfirm()"
      >
        Confirm
      </button-v2>
    </div>
  </card>
</ng-container>

<ng-template #decidedCallTemplate>
  <ng-container *ngIf="callDeclined">
    <card class="w-300 align-items-center v2-p-4">
      <typography-v2 dataId="call-is-over"
        >The call is over. We have saved your answer. Please close this window.</typography-v2
      >
    </card>
  </ng-container>
  <ng-container *ngIf="callConfirmed">
    <div class="call-wrapper d-flex align-items-center v2-p-1">
      <button-icon-v2
        class="finish-call-button v2-mr-8"
        (click)="finishCall()"
        color="var(--color-white)"
        key="phone"
        data-id="finish-call"
      ></button-icon-v2>
      <ng-container *ngTemplateOutlet="callDurationTemplate"></ng-container>
      <button-icon-v2
        class="microphone-icon v2-ml-8"
        (click)="toggleMute()"
        [key]="microphoneIconKey"
        data-id="toggle-mute"
      ></button-icon-v2>
    </div>
  </ng-container>
</ng-template>

<ng-template #callDurationTemplate>
  <typography-v2
    class="d-flex align-items-center justify-content-center v2-p-4 v2-py-2 mw-75"
    dataId="call-duration"
    >{{ callDuration! * 1000 | rdrDate: 'mm_ss' }}</typography-v2
  >
</ng-template>
