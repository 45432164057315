import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface AnchorNavigationItem {
  label: string;
  id: string;
}

@Component({
  selector: 'anchor-navigation',
  templateUrl: './anchor-navigation.component.html',
  styleUrls: ['./anchor-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorNavigationComponent {
  @Input() header: 'big' | 'small' = 'big';
  @Input() hasBackButton = false;
  @Input() items: AnchorNavigationItem[] = [];

  trackByFn(index: number, item: AnchorNavigationItem): string {
    return item.label;
  }
}
