import { Pipe, PipeTransform } from '@angular/core';
import { Interaction } from '@app/generated/graphql';

@Pipe({
  name: 'reverse',
})
export class ReversePipe implements PipeTransform {
  transform(interactions: Interaction[]) {
    return interactions.slice().reverse();
  }
}
