import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isObject, isString } from 'lodash';

const DEFAULT_DATE_FORMAT = 'MMM d, yyyy';
const EXTENDED_DATE_FORMAT = 'MMM d, yyyy h:mm a';
const FULL_DATE_WITHOUT_YEAR = 'MMM d, h:mm a';
const FULL_DATE_FORMAT = 'EEEE, MMMM d, y';
const MEDIUM_FORMAT = 'MMM d, yy';
const MONTH_YEAR = 'MMMM y';
const MM_SS = 'mm:ss';
const DATE_ONLY = 'MMM d';
const SHORT_TIME = 'h:mm a';
const WEEK_DAY = 'EEEE, MMM d';

type RdrFormat =
  | 'default'
  | 'extended'
  | 'shortTime'
  | 'fullDate'
  | 'short'
  | 'medium'
  | 'month_year'
  | 'mm_ss'
  | 'date_only'
  | 'message_date'
  | 'short_date'
  | 'short_full_datetime'
  | 'week_day';

type DateInput = Date | string | number | undefined;

type RdrArgs = {
  format?: RdrFormat;
  withoutTimezones: boolean;
};
@Pipe({
  name: 'rdrDate',
})
export class RdrDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: DateInput = '', arg?: RdrFormat | RdrArgs): string | null {
    let tz = '';
    let format = 'default';

    if (arg) {
      if (isObject(arg)) {
        format = arg.format || format;

        if (arg.withoutTimezones) {
          value = value.toString();
          tz = value.substring(value.length - 6);
          value = new Date(Date.parse(value))?.toISOString();
        }
      } else if (isString(arg)) {
        format = arg;
      }
    }

    switch (format) {
      case 'shortTime':
        return this.datePipe.transform(value, SHORT_TIME, tz);

      case 'extended':
        return this.datePipe.transform(value, EXTENDED_DATE_FORMAT, tz);

      case 'fullDate':
        return this.datePipe.transform(value, FULL_DATE_FORMAT, tz);

      case 'short':
        return this.datePipe.transform(value, FULL_DATE_WITHOUT_YEAR, tz);

      case 'medium':
        return this.datePipe.transform(value, MEDIUM_FORMAT, tz);

      case 'month_year':
        return this.datePipe.transform(value, MONTH_YEAR, tz);

      case 'mm_ss':
        return this.datePipe.transform(value, MM_SS, tz);

      case 'date_only':
        return this.datePipe.transform(value, DATE_ONLY, tz);

      case 'message_date':
        return this.getMessageDate(value, tz);

      case 'short_date':
        return this.getShortDate(value, tz);

      case 'short_full_datetime':
        return this.getShortFullDatetime(value, tz);

      case 'week_day':
        return this.datePipe.transform(value, WEEK_DAY, tz);

      default:
        return this.datePipe.transform(value, DEFAULT_DATE_FORMAT, tz);
    }
  }

  getMessageDate(value: DateInput, tz: string) {
    const isCurrentYear = new Date(value || '').getFullYear() === new Date().getFullYear();

    return isCurrentYear
      ? this.datePipe.transform(value, FULL_DATE_WITHOUT_YEAR, tz)
      : this.datePipe.transform(value, EXTENDED_DATE_FORMAT, tz);
  }

  getShortDate(value: DateInput, tz: string) {
    const isCurrentYear = new Date(value || '').getFullYear() === new Date().getFullYear();

    return isCurrentYear
      ? this.datePipe.transform(value, DATE_ONLY, tz)
      : this.datePipe.transform(value, DEFAULT_DATE_FORMAT, tz);
  }

  getShortFullDatetime(value: DateInput, tz: string) {
    const today = new Date();
    const date = new Date(value || '');

    const isCurrentYear = date.getFullYear() === today.getFullYear();
    const isCurrentMonth = date.getMonth() === today.getMonth();
    const isCurrentDay = date.getDate() === today.getDate();

    if (isCurrentYear && isCurrentMonth && isCurrentDay) {
      return this.datePipe.transform(value, SHORT_TIME, tz);
    }

    if (!isCurrentYear) {
      return this.datePipe.transform(value, EXTENDED_DATE_FORMAT, tz);
    }

    return this.datePipe.transform(value, FULL_DATE_WITHOUT_YEAR, tz);
  }
}
