<button
  (click)="selectBooking.emit(BookingType.REVENUE); selectIsDemo.emit(false)"
  [ngClass]="{ 'selected': selectedBookingType === BookingType.REVENUE && !isDemo }"
  class="d-flex align-items-center v2-px-5 v2-py-4 cursor-pointer booking-type"
  data-id="revenue-type"
>
  <img width="32" height="32" src="/assets/images/revenue-booking.svg" />
  <typography-v2 class="v2-ml-3">Revenue</typography-v2>
</button>

<button
  (click)="selectBooking.emit(BookingType.MILES)"
  [ngClass]="{ 'selected': selectedBookingType === BookingType.MILES }"
  class="d-flex align-items-center v2-px-5 v2-py-4 v2-ml-2 cursor-pointer booking-type"
  data-id="miles-type"
>
  <img width="32" height="32" src="/assets/images/miles-booking.svg" />
  <typography-v2 class="v2-ml-3">Miles</typography-v2>
</button>

<button
  *ngIf="!withoutDemo"
  (click)="selectBooking.emit(BookingType.REVENUE); selectIsDemo.emit(true)"
  [ngClass]="{ 'selected': selectedBookingType === BookingType.REVENUE && isDemo }"
  class="d-flex align-items-center v2-px-5 v2-py-4 v2-ml-2 cursor-pointer booking-type"
  data-id="demo-type"
>
  <img width="32" height="32" src="/assets/images/demo-booking.svg" />
  <typography-v2 class="v2-ml-3">Demo</typography-v2>
</button>

<button
  (click)="selectBooking.emit(BookingType.HOTEL); selectIsDemo.emit(false)"
  [ngClass]="{ 'selected': selectedBookingType === BookingType.HOTEL && !isDemo }"
  class="d-flex align-items-center v2-px-5 v2-py-4 v2-ml-2 cursor-pointer booking-type"
  data-id="hotel-type"
>
  <img width="32" height="32" src="/assets/images/hotel-booking.svg" />
  <typography-v2 class="v2-ml-3">Hotel</typography-v2>
</button>
