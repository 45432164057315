import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinProperty',
})
export class JoinPropertyPipe implements PipeTransform {
  transform(items: any[], propertyName: string): string {
    const result = [];

    for (const item of items) {
      result.push(item[propertyName]);
    }

    return result.join(', ');
  }
}
