import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { EmailMessageNode } from '@app/generated/graphql';

@Component({
  selector: 'email-message-event',
  templateUrl: './email-message-event.component.html',
  styleUrls: ['./email-message-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailMessageEventComponent {
  expanded = false;
  showFullLetter = false;

  @Input() interaction: EmailMessageNode;

  @HostBinding('class.cursor-pointer') get classes() {
    return !this.expanded;
  }

  @HostListener('click') click() {
    if (!this.expanded) {
      this.expanded = true;
    }
  }
}
